import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import { get } from "../utils/Api";
import { useDispatch } from "react-redux";
import { updatePurchaseOptions } from "../redux/slices/orderSlice";

const PurchaseOptionsDialog = ({
  selected,
  visible,
  onHide,
  onSelect,
}: any) => {
  const dispatch = useDispatch();
  const [allPurchaseOptions, setAllPurchaseOptions] = useState<any[]>([]);
  useEffect(() => {
    const getAllSettings = async () => {
      try {
        const purchaseOptionsResponse = await get(
          `/product/get_purchase_options`,
        );
        if (purchaseOptionsResponse.success && purchaseOptionsResponse.data) {
          dispatch(updatePurchaseOptions(purchaseOptionsResponse.data));
          setAllPurchaseOptions(purchaseOptionsResponse.data);
        }
      } catch (error) {
        toast.error("Something went wrong!");
      }
    };
    getAllSettings();
  }, []);

  return (
    <Dialog
      header="Purchase Options"
      visible={visible}
      style={{ width: "350px", border: 0 }}
      onHide={onHide}
    >
      <div className="flex flex-col items-center gap-y-4">
        {allPurchaseOptions.map((item: any) => (
          <button
            className={`text-lg py-[2px] w-full border border-solid rounded ${
              selected === item?.name
                ? " border-[#DA5E18] text-[#DA5E18] "
                : " border-[#D8D8D8] text-[#000] "
            }`}
            key={item?.id}
            onClick={() => {
              onSelect(item?.name);
              onHide();
            }}
          >
            {item?.name}
          </button>
        ))}
      </div>
    </Dialog>
  );
};

export default PurchaseOptionsDialog;

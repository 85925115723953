import { useEffect, useState } from "react";
import { Inventory } from "../../interfaces";
import { formatCurrency } from "../../utils/Common";
import { Button } from "primereact/button";
import { useAppSelector } from "../../hooks/useStore";

const GridView = (props: any) => {
  const { cartItems } = useAppSelector((state) => state.order);
  const [quantity, setQuantity] = useState<number>(
    props?.item?.selected_quantity ?? 0,
  );
  const [img, setImg] = useState(
    props?.item?.image_url || require("../../assets/images/order/no-image.png"),
  );

  const handleFocus = () => {
    if (quantity === 0) {
      setQuantity(0);
    }
  };

  const handleBlur = () => {
    if (!quantity) {
      setQuantity(0);
    }
  };

  const handleChange = (event: any) => {
    const qty = event?.target?.value.slice(0, 4);
    setQuantity(qty);
    if (props?.onQuantityChange) {
      const updatedItem: Inventory = { ...props?.item, quantity: qty };
      props.onQuantityChange(updatedItem);
    }
  };

  const handleMinusClick = () => {
    const updatedQuantity = Math.max(quantity - 1, 0);
    if (props?.onQuantityChange) {
      const updatedItem: Inventory = {
        ...props?.item,
        quantity: updatedQuantity,
      };
      props.onQuantityChange(updatedItem);
    }
    setQuantity(updatedQuantity);
  };

  const handleAddClick = () => {
    const stockQuantity = props?.item?.quantity || 0;
    const updatedQuantity = Math.min(quantity + 1, stockQuantity);
    if (props?.onQuantityChange) {
      const updatedItem: Inventory = {
        ...props?.item,
        quantity: updatedQuantity,
      };
      props.onQuantityChange(updatedItem);
    }
    setQuantity(updatedQuantity);
  };

  useEffect(() => {
    const items = [...cartItems];
    const index = items.findIndex((el: Inventory) => el.id === props?.item?.id);
    if (index >= -1) {
      if (items[index]?.quantity > 0) {
        setQuantity(items[index]?.quantity);
      }
    }
  }, [cartItems, props?.item?.id]);

  const price = formatCurrency(props?.item?.price);

  return (
    <div className="rounded-lg shadow-md h-[345px] px-2 py-2.5 bg-[#F7F7F7] relative overflow-hidden">
      {props?.item?.low_stocks && props?.item?.quantity > 0 && (
        <div className="text-xs px-[20px] py-[3px] bg-[#d86565] text-white rounded rotate-[-45deg] absolute left-[-17px] top-[3px] z-10">
          LOW
        </div>
      )}
      <div className="flex flex-col justify-between h-full">
        <div className="bg-[#fff] h-[163px] rounded-lg relative">
          <div className="flex items-center justify-center h-full">
            <div className="h-[140px] w-[140px] shrink-0">
              <img
                src={img}
                alt=""
                onError={() => setImg(`${process.env.PUBLIC_URL}/no-image.png`)}
                className="h-full w-full object-cover"
              />
            </div>
          </div>
          <div className="">
            <p className="text-sm leading-4 my-3 line-clamp-3 text-[#3E3E3E]">
              {props?.item?.name}
            </p>
            <p className="text-base font-bold text-[#DA5E18]">
              {price}{" "}
              <span className="font-normal text-sm text-[#3E3E3E]">
                (Stock: {props?.item?.quantity})
              </span>
            </p>
          </div>
        </div>
        <div
          className={`flex flex-row items-center justify-between rounded-[7px] p-2 m-h-[40px] h-[40px] ${
            props?.item?.quantity <= 0 ? "bg-[#DA5E18]" : "bg-[#fff]"
          }`}
        >
          {props?.item?.quantity <= 0 ? (
            <>
              <p className="text-sm text-center font-bold m-auto text-[#fff]">
                Out of stock
              </p>
            </>
          ) : (
            <>
              <Button
                className="p-0"
                link
                onClick={handleMinusClick}
                disabled={props?.item?.quantity <= 0}
              >
                <img
                  src={require("../../assets/images/order/minus-icon.png")}
                  alt="Icon minus"
                  className="w-[24px] h-[24px]"
                />
              </Button>
              <input
                value={quantity}
                type="number"
                style={{
                  width: "80px",
                  background: "transparent",
                  textAlign: "center",
                  color: "#DA5E18",
                  fontSize: "16px",
                }}
                onFocus={handleFocus}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={4}
                disabled={props?.item?.quantity <= 0}
              />
              <Button
                className="p-0"
                link
                onClick={handleAddClick}
                disabled={props?.item?.quantity <= 0}
              >
                <img
                  src={require("../../assets/images/order/plus-icon.png")}
                  alt="Icon Plus"
                  className="w-[24px] h-[24px]"
                />
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default GridView;

import React, { useState } from "react";
import Layout from "../../../components/Layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { InventoryCategory } from "../../../interfaces";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import ValidationError from "../../../components/ValidationError";
import { get, post } from "../../../utils/Api";
import { toast } from "react-toastify";
import TableFooter from "../../../components/TableFooter";
import AdminButton from "../../../components/AdminButton";
import TableHeader from "../../../components/TableHeader";
import TableSkeleton from "../../../components/TableSkeleton";
import AcceptButton from "../../../components/AcceptButton";
import EmptyData from "../../../components/EmptyData";
import { showConfirmDialog } from "../../../utils/Common";

const ProductCategories = () => {
  type ProductCategorySearchParams = {
    email: string;
    first_name: string;
    last_name: string;
  };

  const {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  } = usePaginatedApiData<InventoryCategory, ProductCategorySearchParams>(
    "/admin/get_categories",
    { email: "", first_name: "", last_name: "" },
    1,
  );
  const rawRecords = data as any;
  const records = rawRecords?.data as InventoryCategory[];
  const search = searchParams as ProductCategorySearchParams;
  const [visible, setVisible] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [visibleSub, setVisibleSub] = useState(false);
  const [categorySubLoading, setCategorySubLoading] = useState(false);

  const deleteConfirmation = (id: number) => {
    showConfirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      accept: async () => {
        const response = await get(`/admin/delete_category/${id}`);
        if (response.success) {
          toast.success(response.message);
        } else {
          toast.error("Something went wrong!");
        }
        handleReload();
      },
    });
  };

  const deleteConfirmationSub = (id: number) => {
    showConfirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      accept: async () => {
        const response = await get(`/admin/delete_sub_category/${id}`);
        if (response.success) {
          toast.success(response.message);
        } else {
          toast.error("Something went wrong!");
        }
        handleReload();
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      id: 0,
      name: "",
      recognised_percentage: "",
      before_commission_percentage: "",
      after_commission_percentage: "",
      fixed_commission_percentage: "",
      status: true,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Category name is required"),
      recognised_percentage: Yup.string().required(
        "Recognised percentage is required",
      ),
      before_commission_percentage: Yup.string().required(
        "Before commission percentage is required",
      ),
      after_commission_percentage: Yup.string().required(
        "After commission percentage is required",
      ),
      fixed_commission_percentage: Yup.string().required(
        "Fixed commission percentage is required",
      ),
    }),
    onSubmit: async (values) => {
      const {
        id,
        name,
        recognised_percentage,
        before_commission_percentage,
        after_commission_percentage,
        fixed_commission_percentage,
        status,
      } = values;
      try {
        setCategoryLoading(true);
        const response = await post("/admin/save_category", {
          id: id === 0 ? null : id,
          name,
          recognised_percentage,
          before_commission_percentage,
          after_commission_percentage,
          fixed_commission_percentage,
          status: status ? 1 : 0,
        });
        setCategoryLoading(false);
        if (response?.success) {
          toast.success(response?.message);
          setVisible(false);
          formik.resetForm();
          handleReload();
        }
      } catch (error: any) {
        setCategoryLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  const formikSub = useFormik({
    initialValues: {
      id: 0,
      category_name: "",
      category_id: "",
      name: "",
      status: true,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Sub. Category name is required"),
    }),
    onSubmit: async (values) => {
      const { id, category_id, name, status } = values;
      try {
        setCategorySubLoading(true);
        const response = await post("/admin/save_sub_category", {
          id: id === 0 ? null : id,
          category_id,
          name,
          status: status ? 1 : 0,
        });
        setCategorySubLoading(false);
        if (response?.success) {
          toast.success(response?.message);
          setVisibleSub(false);
          formikSub.resetForm();
          handleReload();
        }
      } catch (error: any) {
        setCategorySubLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  return (
    <Layout>
      <Dialog
        header="Category"
        visible={visible}
        style={{ width: "70vw", border: 0 }}
        onHide={() => {
          setVisible(false);
          formik.resetForm();
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="pb-[10px]">
            <p>Name:</p>
            <InputText
              className={`w-full ${
                formik.touched.name && formik.errors.name ? "p-invalid" : ""
              }`}
              name="name"
              placeholder="Category name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ValidationError
              className={
                formik.touched.name && formik.errors.name
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.name}
            </ValidationError>
          </div>
          <div className="pb-[10px]">
            <p>Recognised Percentage %</p>
            <InputText
              className={`w-full ${
                formik.touched.recognised_percentage &&
                formik.errors.recognised_percentage
                  ? "p-invalid"
                  : ""
              }`}
              name="recognised_percentage"
              placeholder="Recognised Percentage %"
              value={formik.values.recognised_percentage}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ValidationError
              className={
                formik.touched.recognised_percentage &&
                formik.errors.recognised_percentage
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.recognised_percentage}
            </ValidationError>
          </div>
          <div className="pb-[10px]">
            <p>Before Commission Percentage %</p>
            <InputText
              className={`w-full ${
                formik.touched.before_commission_percentage &&
                formik.errors.before_commission_percentage
                  ? "p-invalid"
                  : ""
              }`}
              name="before_commission_percentage"
              placeholder="Before Commission Percentage %"
              value={formik.values.before_commission_percentage}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ValidationError
              className={
                formik.touched.before_commission_percentage &&
                formik.errors.before_commission_percentage
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.before_commission_percentage}
            </ValidationError>
          </div>
          <div className="pb-[10px]">
            <p>After Commission Percentage %</p>
            <InputText
              className={`w-full ${
                formik.touched.after_commission_percentage &&
                formik.errors.after_commission_percentage
                  ? "p-invalid"
                  : ""
              }`}
              name="after_commission_percentage"
              placeholder="After Commission Percentage %"
              value={formik.values.after_commission_percentage}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ValidationError
              className={
                formik.touched.after_commission_percentage &&
                formik.errors.after_commission_percentage
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.after_commission_percentage}
            </ValidationError>
          </div>
          <div className="pb-[10px]">
            <p>Fixed Commission Percentage %</p>
            <InputText
              className={`w-full ${
                formik.touched.fixed_commission_percentage &&
                formik.errors.fixed_commission_percentage
                  ? "p-invalid"
                  : ""
              }`}
              name="fixed_commission_percentage"
              placeholder="Fixed Commission Percentage %"
              value={formik.values.fixed_commission_percentage}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ValidationError
              className={
                formik.touched.fixed_commission_percentage &&
                formik.errors.fixed_commission_percentage
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.fixed_commission_percentage}
            </ValidationError>
          </div>
          <div className="pb-[10px] flex flex-row justify-start items-center flex-nowrap gap-x-4">
            <label htmlFor="status">Status</label>
            <InputSwitch
              name="status"
              inputId="status"
              checked={formik.values.status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="flex flex-row justify-between items-center flex-nowrap gap-x-4">
            {formik.values.id > 0 && (
              <Button
                severity="danger"
                type="button"
                label="Delete"
                onClick={() => {
                  setVisible(false);
                  deleteConfirmation(formik.values.id);
                }}
              />
            )}
            <AcceptButton
              label={formik.values.id > 0 ? "Update" : "Save"}
              loading={categoryLoading}
            />
          </div>
        </form>
      </Dialog>
      <Dialog
        header="Sub. Category"
        visible={visibleSub}
        style={{ width: "70vw", border: 0 }}
        onHide={() => {
          setVisibleSub(false);
          formikSub.resetForm();
        }}
      >
        <form onSubmit={formikSub.handleSubmit}>
          <div className="py-[10px]">
            <span className="p-float-label">
              <InputText
                disabled
                className={`w-full`}
                name="category_name"
                id="category_name"
                placeholder="Category name"
                value={formikSub.values.category_name}
                onChange={formikSub.handleChange}
                onBlur={formikSub.handleBlur}
              />
              <label htmlFor="category_name">Category</label>
            </span>
          </div>
          <div className="pb-[10px]">
            <InputText
              className={`w-full ${
                formikSub.touched.name && formikSub.errors.name
                  ? "p-invalid"
                  : ""
              }`}
              name="name"
              placeholder="Sub. Category name"
              value={formikSub.values.name}
              onChange={formikSub.handleChange}
              onBlur={formikSub.handleBlur}
            />
            <ValidationError
              className={
                formikSub.touched.name && formikSub.errors.name
                  ? "visible"
                  : "invisible"
              }
            >
              {formikSub.errors.name}
            </ValidationError>
          </div>
          <div className="pb-[10px] flex flex-row justify-start items-center flex-nowrap gap-x-4">
            <label htmlFor="status">Status</label>
            <InputSwitch
              name="status"
              inputId="status"
              checked={formikSub.values.status}
              onChange={formikSub.handleChange}
              onBlur={formikSub.handleBlur}
            />
          </div>
          <div className="flex flex-row justify-between items-center flex-nowrap gap-x-4">
            {formikSub.values.id > 0 && (
              <Button
                severity="danger"
                type="button"
                label="Delete"
                onClick={() => {
                  setVisibleSub(false);
                  deleteConfirmationSub(formikSub.values.id);
                }}
              />
            )}
            <AcceptButton
              label={formikSub.values.id > 0 ? "Update" : "Save"}
              loading={categorySubLoading}
            />
          </div>
        </form>
      </Dialog>
      <TableHeader>
        <AdminButton label="Create" onClick={() => setVisible(true)} />
      </TableHeader>
      <div className="px-[17px] pt-[17px] card-cfg">
        {loading && <TableSkeleton />}
        {!loading && records?.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="table-cfg">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Sub. Categories</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((row: InventoryCategory) => {
                    return (
                      <tr key={`inventory-category-table-row-${row?.id}`}>
                        <td data-label="Category">
                          <label
                            className="text-[#E5622A] cursor-pointer underline"
                            onClick={() => {
                              formik.setFieldValue("id", row?.id);
                              formik.setFieldValue("name", row?.name);
                              formik.setFieldValue(
                                "recognised_percentage",
                                row?.recognised_percentage,
                              );
                              formik.setFieldValue(
                                "before_commission_percentage",
                                row?.before_commission_percentage,
                              );
                              formik.setFieldValue(
                                "after_commission_percentage",
                                row?.after_commission_percentage,
                              );
                              formik.setFieldValue(
                                "fixed_commission_percentage",
                                row?.fixed_commission_percentage,
                              );
                              formik.setFieldValue("status", row?.status === 1);
                              setVisible(true);
                            }}
                          >
                            {row?.name}
                          </label>
                        </td>
                        <td data-label="Sub. Categories">
                          <div className="flex gap-x-5 gap-y-4 flex-wrap pl-6 items-center">
                            {row?.sub_category.map(
                              (subItems: any, index: number) => (
                                <label
                                  className="text-[#E5622A] cursor-pointer underline"
                                  key={`inventory-sub-category-${index}`}
                                  onClick={() => {
                                    formikSub.setFieldValue("id", subItems?.id);
                                    formikSub.setFieldValue(
                                      "category_id",
                                      row?.id,
                                    );
                                    formikSub.setFieldValue(
                                      "category_name",
                                      row?.name,
                                    );
                                    formikSub.setFieldValue(
                                      "name",
                                      subItems?.name,
                                    );
                                    formikSub.setFieldValue(
                                      "status",
                                      subItems?.status === 1,
                                    );
                                    setVisibleSub(true);
                                  }}
                                >
                                  {subItems?.name}
                                </label>
                              ),
                            )}
                            <AcceptButton
                              label="Create"
                              className="block ml-auto"
                              onClick={() => {
                                formikSub.setFieldValue("category_id", row?.id);
                                formikSub.setFieldValue(
                                  "category_name",
                                  row?.name,
                                );
                                setVisibleSub(true);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            />
          </>
        )}
        {!loading && records?.length <= 0 && <EmptyData />}
      </div>
    </Layout>
  );
};

export default ProductCategories;

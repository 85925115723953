import { useState } from "react";
import Layout from "../../../components/Layout";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
// import { useNavigate } from "react-router-dom";
import TableHeader from "../../../components/TableHeader";
import TableFooter from "../../../components/TableFooter";
import { Dialog } from "primereact/dialog";
import TableSkeleton from "../../../components/TableSkeleton";
import { InputText } from "primereact/inputtext";
import { ProgressBar } from "primereact/progressbar";
import { formatDate } from "../../../utils/Common";
import CancelButton from "../../../components/CancelButton";
import AcceptButton from "../../../components/AcceptButton";
import FilterButton from "../../../components/FilterButton";
import FullScreenDialog from "../../../components/FullScreenDialog";
import OrderDetails from "../../OrderDetails";
import ExportButton from "../../../components/ExportButton";
import { format } from "date-fns";
import EmptyData from "../../../components/EmptyData";
import { post } from "../../../utils/Api";
import { toast } from "react-toastify";
import { RadioButton } from "primereact/radiobutton";

type UserData = {
  id: number;
  order_id: number;
  order: any;
  order_items: [];
  delivery_start_date: string;
  delivery_end_date: string;
  remarks: string;
  status: number;
  subscription_option: any;
  subscription_completed: number;
  subscription_option_id: number;
  subscription_total: number;
};
type UserSearchParams = {
  customer_name: string | null;
  mobile: string | null;
  order_id: string | null;
  status: number | null;
  sort_by: string | null;
};

const Shipments = () => {
  // const navigate = useNavigate();
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterCustomerName, setFilterCustomerName] = useState("");
  const [filterMobile, setFilterMobile] = useState("");
  const [filterOrderId, setFilterOrderId] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [showDetailParam, setShowDetailParam] = useState({});
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [sortVisible, setSortVisible] = useState(false);
  const [filterSortBy, setFilterSortBy] = useState("");

  const handleView = (order: any) => {
    // navigate("/order-details", {
    //   state: { order_id: order?.order?.id },
    // });
    const subscriptionMonth = order?.subscription_option?.subscription_month;
    let currentMonthIndex = order?.subscription_completed;
    if (subscriptionMonth !== 6 || subscriptionMonth !== 12) {
      currentMonthIndex += 1;
    }

    setShowDetailParam({
      order_id: order?.order?.id,
      current_month_index: currentMonthIndex,
    });
    setShowDetail(true);
  };
  const {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  } = usePaginatedApiData<UserData, UserSearchParams>(
    "/admin/get_subscription_orders",
    {
      customer_name: null,
      mobile: null,
      order_id: null,
      status: null,
      sort_by: null,
    },
    1,
  );

  const rawRecords = data as any;
  const records = rawRecords?.data as UserData[];
  const handleStatusClick = (status: any) => {
    setSelectedStatus(status);
    handleSearchParamsChange({
      status: status,
    });
  };

  const handleSortBy = (event: any) => {
    setFilterSortBy(event?.value);
  };
  // const updateSubscriptionStatus = async (
  //   id: number,
  //   selectedStatus: number,
  // ) => {
  //   const status = selectedStatus === 0 ? 2 : 0;
  //   try {
  //     const response = await post("admin/update_subscription_status", {
  //       id,
  //       status,
  //     });
  //     if (response.success) {
  //       console.log(response.data);
  //       handleSearchParamsChange({});
  //       toast.success(response.message);
  //     } else {
  //       toast.error("Update unsuccessfull!");
  //     }
  //   } catch (error: any) {
  //     if (
  //       error?.response?.data?.message &&
  //       typeof error?.response?.data?.message === "string"
  //     ) {
  //       toast.error(error.response.data.message);
  //     }
  //   }
  // };

  return (
    <Layout>
      <FullScreenDialog
        visible={showDetail}
        onHide={() => setShowDetail(false)}
      >
        <OrderDetails
          params={showDetailParam}
          close={() => {
            setShowDetail(false);
            handleReload();
          }}
        />
      </FullScreenDialog>
      <Dialog
        header="Filter"
        position="bottom"
        visible={filterVisible}
        style={{
          width: "100vw",
          maxWidth: "450px",
          margin: 0,
          border: 0,
        }}
        onHide={() => setFilterVisible(false)}
      >
        <div className="mb-[10px]">
          <label htmlFor="filter-order-id" className="mb-[5px] block">
            Order ID #
          </label>
          <div>
            <InputText
              id="filter-order-id"
              className="w-full"
              value={filterOrderId}
              onChange={(e) => setFilterOrderId(e?.target?.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-customer-name" className="mb-[5px] block">
            Customer name
          </label>
          <div>
            <InputText
              id="filter-customer-name"
              className="w-full"
              value={filterCustomerName}
              onChange={(e) => setFilterCustomerName(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-mobile" className="mb-[5px] block">
            Mobile
          </label>
          <div>
            <InputText
              id="filter-mobile"
              className="w-full"
              value={filterMobile}
              onChange={(e) => setFilterMobile(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between items-center pt-[10px]">
          <CancelButton
            label="Reset"
            onClick={() => {
              setFilterCustomerName("");
              setFilterMobile("");
              setFilterOrderId("");
              handleSearchParamsChange({
                customer_name: "",
                mobile: "",
                order_id: "",
              });
              setFilterVisible(false);
            }}
          />
          <AcceptButton
            label="Apply"
            onClick={() => {
              handleSearchParamsChange({
                customer_name: filterCustomerName,
                mobile: filterMobile,
                order_id: filterOrderId,
              });
              setFilterVisible(false);
            }}
          />
        </div>
      </Dialog>

      <Dialog
        header="Sort"
        position="bottom"
        visible={sortVisible}
        style={{
          width: "100vw",
          maxWidth: "450px",
          margin: 0,
          border: 0,
        }}
        onHide={() => setSortVisible(false)}
      >
        <div className="mb-[10px]">
          <label className="text-[16px] text-[#292929] font-[500] capitalize mb-[10px] block">
            Sort By
          </label>
          <div className="flex flex-wrap gap-3">
            <div className="flex items-center">
              <RadioButton
                name="sort"
                value="earliest_delivery_date"
                onChange={handleSortBy}
                checked={filterSortBy === "earliest_delivery_date"}
              />
              <label htmlFor="in" className="ml-2">
                Earliest Delivery Date
              </label>
            </div>
            <div className="flex items-center">
              <RadioButton
                name="sort"
                value="latest_subscription"
                onChange={handleSortBy}
                checked={filterSortBy === "latest_subscription"}
              />
              <label htmlFor="out" className="ml-2">
                Latest Subscription
              </label>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center pt-[10px]">
          <CancelButton
            label="Reset"
            onClick={() => {
              setFilterSortBy("");
              handleSearchParamsChange({
                sort_by: "",
              });
              setSortVisible(false);
            }}
          />
          <AcceptButton
            label="Apply"
            onClick={() => {
              handleSearchParamsChange({
                sort_by: filterSortBy,
              });
              setSortVisible(false);
            }}
          />
        </div>
      </Dialog>
      <TableHeader>
        <div className="flex flex-row justify-between items-center flex-nowrap w-full">
          <ExportButton
            className="mb-[5px]"
            api={`/admin/export_subscriptions?customer_name=${filterCustomerName}&mobile=${filterMobile}`}
            filename={`subscriptions_${format(
              new Date(),
              "yyyyMMddHHmmss",
            )}.xlsx`}
          />
        </div>
        <div className="flex gap-2 ml-auto">
          <FilterButton
            onClick={() => setFilterVisible(true)}
            className="mb-[5px]"
          />

          <button
            className="flex flex-row flex-nowrap justify-center items-center gap-x-[5px] bg-[#FFFFFF] rounded-[10px] px-[12px] py-[7px] mb-[5px]"
            onClick={() => setSortVisible(true)}
          >
            <span className="text-[#DA5E18] text-[12px] leading-[15px] font-[450]">
              Sort
            </span>
            <img
              src={require("../../../assets/images/order/sort-icon.png")}
              alt="Sort icon"
              className="w-[18px]"
            />
          </button>
        </div>
      </TableHeader>
      <div className="px-[17px] pt-[17px] card-cfg">
        <div className="flex items-center">
          <div
            className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] mb-[12px] mr-[15px] cursor-pointer ring-1 ${
              selectedStatus === null
                ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }`}
            onClick={() => handleStatusClick(null)}
          >
            Active & Paused{" "}
          </div>
          <div
            className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] mb-[12px] mr-[15px] cursor-pointer ring-1 ${
              selectedStatus === 0
                ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }`}
            onClick={() => handleStatusClick(0)}
          >
            Active{" "}
          </div>
          <div
            className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] mb-[12px] mr-[15px] cursor-pointer ring-1 ${
              selectedStatus === 2
                ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }`}
            onClick={() => handleStatusClick(2)}
          >
            Paused{" "}
          </div>
        </div>
        {loading && <TableSkeleton />}
        {!loading && records !== null && records?.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="table-cfg">
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Product</th>
                    <th>Progress</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Customer</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((row: UserData) => {
                    return (
                      <tr key={`subscription-table-row-${row?.id}`}>
                        <td data-label="Order ID">
                          <label
                            className="text-[#E5622A] cursor-pointer underline"
                            onClick={() => handleView(row)}
                          >
                            #{row?.order_id}
                          </label>
                          {row?.order?.remarks !== null && (
                            <span
                              className={
                                "pi pi-comment md:float-right pt-[4px] text-[#E5622A] md:pl-0 pl-2 "
                              }
                              style={{ fontSize: "12px" }}
                            ></span>
                          )}
                        </td>
                        <td data-label="Product">
                          {row?.order_items?.map((item: any) => (
                            <div
                              className="whitespace-nowrap"
                              key={`subscription-table-item-${item?.id}`}
                            >
                              {item?.quantity} x {item?.name}
                            </div>
                          ))}
                        </td>
                        <td data-label="Progress" className="text-center">
                          <div className="whitespace-nowrap">
                            {row?.subscription_completed} /{" "}
                            {row?.subscription_total}
                          </div>
                          <ProgressBar
                            value={
                              (row?.subscription_completed /
                                row?.subscription_total) *
                              100
                            }
                            displayValueTemplate={() => {
                              return <></>;
                            }}
                          ></ProgressBar>
                        </td>
                        <td data-label="Start Date" className="text-center">
                          <div className="whitespace-nowrap">
                            {formatDate(row?.delivery_start_date)}
                          </div>
                        </td>
                        <td data-label="End Date" className="text-center">
                          <div className="whitespace-nowrap">
                            {formatDate(row?.delivery_end_date)}
                          </div>
                        </td>
                        <td data-label="Customer Name">
                          <div className="whitespace-nowrap capitalize">
                            {row?.order?.customer?.first_name}{" "}
                            {row?.order?.customer?.last_name}
                          </div>
                        </td>
                        <td data-label="Status" className="text-center">
                          <div className="text-center flex justify-center">
                            <div
                              className={`${
                                row?.status === 0
                                  ? "bg-green-300"
                                  : row?.status === 2
                                  ? "bg-gray-300"
                                  : "bg-red-300 "
                              }  rounded-[30px] inline text-xs w-fit lg:w-full items-center flex justify-center`}
                            >
                              {row?.status === 0
                                ? "Active"
                                : row?.status === 2
                                ? "Paused"
                                : "Completed"}
                            </div>
                            {/* <AcceptButton
                              label={row.status === 0 ? "Pause" : "Unpause"}
                              className="block ml-auto ml-5"
                              onClick={() => {
                                updateSubscriptionStatus(row.id, row.status);
                              }}
                            /> */}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            />
          </>
        )}
        {!loading && records?.length <= 0 && <EmptyData />}
      </div>
    </Layout>
  );
};

export default Shipments;

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

const publicKey = process.env.REACT_APP_STRIPE_KEY as string;
const stripePromise = loadStripe(publicKey);

export default function MakePayment(props: any) {
  const { clientSecret } = props;

  return (
    <div className="MakePayment bg-white p-6 rounded-xl">
      {clientSecret && (
        <Elements options={{ clientSecret }} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { useFormik } from "formik";
import * as Yup from "yup";
import ValidationError from "../../../components/ValidationError";
import { get, post } from "../../../utils/Api";
import { toast } from "react-toastify";
import AcceptButton from "../../../components/AcceptButton";

const InventoryMovementForm = ({
  product_id,
  stockVisible,
  setStockVisible,
  handleReload,
}: any) => {
  const [stockLoading, setStockLoading] = useState(false);
  const [stockTypes, setStockTypes] = useState([]);
  // const [sources, setSources] = useState([]);
  useEffect(() => {
    const getTypes = async () => {
      try {
        const response = await get("/admin/get_product_movement_types");
        if (response?.success && response?.data) {
          setStockTypes(response.data);
        }
      } catch (error: any) {
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    };
    const getSources = async () => {
      try {
        const response = await get("/admin/get_sources");
        if (response?.success && response?.data) {
          // setSources(response.data);
        }
      } catch (error: any) {
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    };
    getTypes();
    // getSources();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      quantity: "",
      product_id: product_id,
      type: "1",
      type_id: "",
      remarks: "",
      // source: "",
    },
    validationSchema: Yup.object({
      quantity: Yup.string()
        .matches(/^\d*$/, "Quantity is not valid")
        .required("Quantity is required"),
      type_id: Yup.string().when("type", (type, schema) => {
        if (type[0] === "2") return schema.required("Type is required");
        return schema;
      }),
    }),
    onSubmit: async (values) => {
      // const { quantity, product_id, type, type_id, remarks, source } = values;
      const { quantity, product_id, type, type_id, remarks } = values;
      let postParams = {
        quantity,
        product_id,
        type,
        remarks,
        // source,
      };
      if (type === "2") {
        postParams = { ...postParams, ...{ type_id } };
      }
      try {
        setStockLoading(true);
        const response = await post("/admin/stock_movement", postParams);
        setStockLoading(false);
        if (response?.success) {
          toast.success(response?.message);
          setStockVisible(false);
          formik.resetForm();
          handleReload();
        }
      } catch (error: any) {
        setStockLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });
  return (
    <Dialog
      header="Stock Movement"
      visible={stockVisible}
      style={{ width: "70vw", border: 0 }}
      onHide={() => {
        setStockVisible(false);
        formik.resetForm();
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="col-span-2 sm:col-span-1">
            <label className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block">
              Movement
            </label>
            <div className="flex flex-wrap gap-3 flex-col">
              <div className="flex align-items-center">
                <RadioButton
                  name="type"
                  inputId="in"
                  value="1"
                  onChange={formik.handleChange}
                  checked={formik.values.type === "1"}
                />
                <label htmlFor="in" className="ml-2">
                  Stock In
                </label>
              </div>
              <div className="flex items-center">
                <RadioButton
                  name="type"
                  inputId="out"
                  value="2"
                  onChange={formik.handleChange}
                  checked={formik.values.type === "2"}
                />
                <label htmlFor="out" className="ml-2">
                  Stock Out
                </label>
              </div>
            </div>
          </div>
          {/* <div className="col-span-2 sm:col-span-1">
            <label className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block">
              Sources
            </label>
            {sources.map((source: any) => (
              <div
                className="flex flex-wrap gap-3 flex-col"
                key={`source-${source.id}`}
              >
                <div className="flex align-items-center">
                  <RadioButton
                    name="source"
                    inputId={`source-${source.id}`}
                    value={source.id}
                    onChange={formik.handleChange}
                    checked={formik.values.source === source.id}
                  />
                  <label htmlFor="in" className="ml-2">
                    {source.name}
                  </label>
                </div>
              </div>
            ))}
          </div> */}
          {formik.values.type === "2" && (
            <div className="col-span-2 sm:col-span-1">
              <label className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block">
                Type
              </label>
              {stockTypes.map((type: any) => (
                <div
                  className="flex items-center my-2"
                  key={`type-input-${type?.id}`}
                >
                  <RadioButton
                    name="type_id"
                    inputId={`type-input-${type?.id}`}
                    value={type?.id}
                    onChange={formik.handleChange}
                    checked={formik.values.type_id === type?.id}
                  />
                  <label htmlFor={`type-input-${type?.id}`} className="ml-2">
                    {type?.name}
                  </label>
                </div>
              ))}
              <ValidationError
                className={
                  formik.touched.type_id && formik.errors.type_id
                    ? "visible"
                    : "invisible hidden"
                }
              >
                {formik.errors.type_id}
              </ValidationError>
            </div>
          )}
          <div className="col-span-2 sm:col-span-1">
            <label
              htmlFor="quantity"
              className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block"
            >
              Quantity
            </label>
            <InputText
              className={`w-full ${
                formik.touched.quantity && formik.errors.quantity
                  ? "p-invalid"
                  : ""
              }`}
              name="quantity"
              id="quantity"
              placeholder="Quantity"
              value={formik.values.quantity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ValidationError
              className={
                formik.touched.quantity && formik.errors.quantity
                  ? "visible"
                  : "invisible hidden"
              }
            >
              {formik.errors.quantity}
            </ValidationError>
          </div>
          <div className="col-span-2">
            <label
              htmlFor="remarks"
              className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block"
            >
              Remarks
            </label>
            <InputTextarea
              className={`w-full ${
                formik.touched.remarks && formik.errors.remarks
                  ? "p-invalid"
                  : ""
              }`}
              name="remarks"
              id="remarks"
              placeholder="Remarks"
              value={formik.values.remarks}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              rows={2}
              cols={20}
            />
            <ValidationError
              className={
                formik.touched.remarks && formik.errors.remarks
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.remarks}
            </ValidationError>
          </div>
        </div>
        <AcceptButton
          className="ml-auto block"
          label="Save"
          loading={stockLoading}
        />
      </form>
    </Dialog>
  );
};

export default InventoryMovementForm;

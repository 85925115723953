import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Layout from "../../../components/Layout";
import ValidationError from "../../../components/ValidationError";
import { HeaderType, Role } from "../../../interfaces";
import { get, post } from "../../../utils/Api";
import { isRole } from "../../../utils/Common";
import AcceptButton from "../../../components/AcceptButton";
import CancelButton from "../../../components/CancelButton";
import CountryCodeDropdown from "../../../components/CountryCodeDropdown";
import { useAppSelector } from "../../../hooks/useStore";

const UserDetails = () => {
  const roles = useAppSelector((state) => state.common.roles);
  const [user_data, setUserData] = useState<any>(null);
  const [user_id] = useState(useLocation()?.state?.user_id);
  const [selectedFile, setSelectedFile] = useState("");
  const [userLoading, setUserLoading] = useState(false);
  const [resetPasswordDialogVisible, setResetPasswordDialogVisible] =
    useState(false);

  const handleResetPasswordClick = () => {
    setResetPasswordDialogVisible(true);
  };

  const handleResetPasswordConfirm = async (user_id: any) => {
    try {
      const response = await post("admin/reset_user_password", {
        user_id,
      });
      if (response?.success) {
        setResetPasswordDialogVisible(false);
        toast.success("Password reset successful.");
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const handleResetPasswordCancel = () => {
    setResetPasswordDialogVisible(false);
  };

  const onFileUpload = async (event: any) => {
    const file = event?.target?.files[0];
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = function () {
        const base64data = reader.result;
        setSelectedFile(base64data as string);
      };
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    try {
      const response = await get(`/agent/get_agent_profile/${user_id}`);
      if (response?.success && response?.data) {
        setUserData(response?.data);
        formik.setValues({
          id: response?.data?.id || null,
          first_name: response?.data?.first_name || "",
          last_name: response?.data?.last_name || "",
          email: response?.data?.email || "",
          agent_code: response?.data?.agent_code || "",
          mobile: response?.data?.mobile || "",
          country_code: response?.data?.country_code || "65",
          status: response?.data?.status === 1 ? true : false,
          image_url: response?.data?.image_url || "",
          role_id: response?.data?.role_id || "",
        });
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      id: user_data?.id || null,
      first_name: user_data?.first_name || "",
      last_name: user_data?.last_name || "",
      email: user_data?.email || "",
      agent_code: user_data?.agent_code || "",
      mobile: user_data?.mobile || "",
      country_code: user_data?.country_code || "65",
      status: user_data?.status === 1 ? true : false,
      image_url: user_data?.image_url || "",
      role_id: user_data?.role_id || "",
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last name is required"),
      mobile: Yup.string().required("Mobile is required"),
      email: Yup.string().required("Email is required"),
      agent_code: Yup.string().test(
        "agentCodeRequired",
        "Agent Code is required",
        function (value) {
          if (isRole(["Agent", "Agent Leader"], this.parent.role_id)) {
            return !!value;
          }
          return true;
        },
      ),
    }),
    onSubmit: async (values) => {
      const {
        id,
        image_url,
        first_name,
        last_name,
        email,
        agent_code,
        mobile,
        country_code,
        status,
        role_id,
      } = values;
      const file: any = image_url;
      try {
        setUserLoading(true);
        const formData = new FormData();
        if (id > 0) {
          formData.append("id", id as unknown as string);
        }
        if (typeof file === "object" && file !== null) {
          formData.append("image_url", file);
        }
        formData.append("first_name", first_name);
        formData.append("last_name", last_name);
        formData.append("email", email);
        formData.append("agent_code", agent_code);
        formData.append("mobile", mobile);
        formData.append("country_code", country_code);
        formData.append("role_id", role_id);
        formData.append("status", status ? "1" : "0");
        const response = await post("/admin/save_user", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setUserLoading(false);
        if (response?.success) {
          toast.success(response?.message);
          setSelectedFile("");
          const updatedUserData = {
            ...user_data,
            id: response?.data?.id,
            first_name: response?.data?.first_name,
            last_name: response?.data?.last_name,
            email: response?.data?.email,
            agent_code: response?.data?.agent_code,
            mobile: response?.data?.mobile,
            country_code: response?.data?.country_code,
            status: response?.data?.status,
            image_url: response?.data?.image_url || "",
          };
          setUserData(updatedUserData);
        }
      } catch (error: any) {
        setUserLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  return (
    <div>
      <Dialog
        header="Reset Password"
        visible={resetPasswordDialogVisible}
        onHide={handleResetPasswordCancel}
        style={{
          width: "100vw",
          maxWidth: "450px",
          margin: 0,
          border: 0,
        }}
      >
        <div className="p-d-flex p-flex-column p-ai-center p-jc-center">
          <p>
            Confirm reset password? User will not be able to login with old
            password.
          </p>
          <div className="mt-[20px] flex flex-row justify-between items-center flex-nowrap">
            <CancelButton
              label="Cancel"
              className="p-button-secondary mr-[20px]"
              onClick={handleResetPasswordCancel}
            />
            <AcceptButton
              label="Confirm"
              className="p-button-primary"
              onClick={() => {
                handleResetPasswordConfirm(user_data?.id);
              }}
            />
          </div>
        </div>
      </Dialog>
      <Layout headerType={HeaderType.back} stickyHeaderFooter={true}>
        <div className="p-5 card-cfg max-w-2xl mx-auto">
          <form onSubmit={formik.handleSubmit}>
            <label className="text-[#DA5E18]">User Details</label>
            <div className="flex flex-col py-5">
              <div className="flex flex-col justify-center items-center pb-6">
                <div
                  className={`w-[128px] h-[128px] border border-solid rounded-[6px] bg-[#FFFFFF] flex justify-center relative ${
                    formik.touched.image_url && formik.errors.image_url
                      ? "border-[#f44336]"
                      : "border-[#ACACAC]"
                  }`}
                >
                  {selectedFile ? (
                    <div className="w-full h-full">
                      <img
                        src={selectedFile}
                        alt="Preview"
                        className="m-[auto] w-full h-full object-cover rounded-[6px]"
                      />
                      <button
                        className="absolute top-[-10px] left-[-10px] rounded-[50%] bg-gradient-to-b from-[#FFD3AB] to-[#FFF6E3"
                        onClick={() => {
                          formik.setFieldValue("image_url", null);
                          setSelectedFile("");
                        }}
                      >
                        <img
                          src={require("../../../assets/images/icon-close.png")}
                          alt="Remove icon"
                          className="w-[25px]"
                        />
                      </button>
                    </div>
                  ) : (
                    <>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        id="image_url"
                        name="image_url"
                        hidden
                        onChange={(e: any) => {
                          onFileUpload(e);
                          formik.setFieldValue(
                            "image_url",
                            e?.target?.files[0],
                          );
                        }}
                      />
                      <label
                        htmlFor="image_url"
                        className="w-full h-full object-fill text-[12px] leading-[15px] text-[#4C4C4C] font-[400] flex justify-center items-center flex-col text-center gap-y-[7px]"
                      >
                        <img
                          src={
                            user_data?.image_url ||
                            formik.values.image_url ||
                            require("../../../assets/images/order/no-image.png")
                          }
                          alt="Add icon"
                          className="w-full h-full object-fill rounded-[6px]"
                        />
                      </label>
                    </>
                  )}
                </div>

                <div className="flex items-center mt-[15px]">
                  <label
                    htmlFor="status"
                    className="text-[12px] text-[#6F6F6F] mr-[5px]"
                  >
                    Status
                  </label>
                  <InputSwitch
                    name="status"
                    inputId="status"
                    checked={formik.values.status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="flex flex-col">
                  <label className="ml-[5px] text-[12px]">First Name</label>
                  <InputText
                    className={`w-full ${
                      formik.touched.first_name && formik.errors.first_name
                        ? "p-invalid"
                        : ""
                    }`}
                    name="first_name"
                    placeholder="First name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <ValidationError
                    className={
                      formik.touched.first_name && formik.errors.first_name
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik.errors.first_name}
                  </ValidationError>
                </div>
                <div className="flex flex-col">
                  <label className="ml-[5px] text-[12px]">Last Name</label>
                  <InputText
                    className={`w-full ${
                      formik.touched.last_name && formik.errors.last_name
                        ? "p-invalid"
                        : ""
                    }`}
                    name="last_name"
                    placeholder="Last Name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <ValidationError
                    className={
                      formik.touched.last_name && formik.errors.last_name
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik.errors.last_name}
                  </ValidationError>
                </div>
                {isRole(["Agent", "Agent Leader"], formik.values.role_id) && (
                  <div className="flex flex-col">
                    <label className="ml-[5px] text-[12px]">Agent Code</label>
                    <InputText
                      className={`w-full ${
                        formik.touched.agent_code && formik.errors.agent_code
                          ? "p-invalid"
                          : ""
                      }`}
                      name="agent_code"
                      placeholder="Agent Code"
                      value={formik.values.agent_code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <ValidationError
                      className={
                        formik.touched.agent_code && formik.errors.agent_code
                          ? "visible"
                          : "invisible"
                      }
                    >
                      {formik.errors.agent_code}
                    </ValidationError>
                  </div>
                )}
                <div className="flex flex-col">
                  <label className="ml-[5px] text-[12px]">Email</label>
                  <InputText
                    className={`w-full ${
                      formik.touched.email && formik.errors.email
                        ? "p-invalid"
                        : ""
                    }`}
                    name="email"
                    placeholder="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <ValidationError
                    className={
                      formik.touched.email && formik.errors.email
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik.errors.email}
                  </ValidationError>
                </div>
                <div className="flex flex-col">
                  <label className="ml-[5px] text-[12px]">Mobile</label>
                  <div className="flex">
                    <CountryCodeDropdown
                      className={`w-[100px] mr-[10px] ${
                        formik.touched.country_code &&
                        formik.errors.country_code
                          ? "p-invalid"
                          : ""
                      }`}
                      name="country_code"
                      value={formik.values.country_code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <InputText
                      className={`w-full ${
                        formik.touched.mobile && formik.errors.mobile
                          ? "p-invalid"
                          : ""
                      }`}
                      name="mobile"
                      placeholder="Mobile"
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <ValidationError
                    className={
                      formik.touched.mobile && formik.errors.mobile
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik.errors.mobile}
                  </ValidationError>
                </div>
                <div className="flex flex-col">
                  <label className="ml-[5px] text-[12px]">User Role</label>
                  <Dropdown
                    className={`w-full ${
                      formik.touched.role_id && formik.errors.role_id
                        ? "p-invalid"
                        : ""
                    }`}
                    options={roles.map((role: Role) => ({
                      label: role.name,
                      value: role.id,
                    }))}
                    name="role_id"
                    inputId="role_id"
                    placeholder="Select Role"
                    value={formik.values.role_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <ValidationError
                    className={
                      formik.touched.role_id && formik.errors.role_id
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik.errors.role_id}
                  </ValidationError>
                </div>
              </div>
            </div>
            <div className="flex justify-end items-center gap-5">
              <AcceptButton label="Update" loading={userLoading} />
              <AcceptButton
                type="button"
                label="Reset Password"
                onClick={handleResetPasswordClick}
              />
            </div>
          </form>
        </div>
      </Layout>
    </div>
  );
};

export default UserDetails;

import React from "react";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import AcceptButton from "./AcceptButton";
import CancelButton from "./CancelButton";

const DateDialog = (props: any) => {
  const { open, close, ...calenderProps } = props;
  return (
    <Dialog
      showHeader={false}
      contentStyle={{ background: "#ffedd6", padding: 0 }}
      visible={open}
      onHide={close}
      style={{
        width: "90%",
        maxWidth: "500px",
        border: 0,
        background: "#ffedd6",
      }}
      closable={false}
    >
      <Calendar
        className="w-full"
        inline
        {...calenderProps}
        pt={{
          panel: { className: "border-0" },
        }}
      />
      <div className="flex flex-row flex-nowrap justify-between items-center p-3">
        <CancelButton
          label="Cancel"
          onClick={() => {
            props?.onChange(null);
            props?.close(false);
          }}
        />
        <AcceptButton label="Apply" onClick={() => props?.close(false)} />
      </div>
    </Dialog>
  );
};

export default DateDialog;

import { Dialog } from "primereact/dialog";
import Lottie from "react-lottie-player";
import SuccessPayment from "./../assets/lottie/payment_failed.json";
import FailedPayment from "./../assets/lottie/payment_success.json";
import AcceptButton from "./AcceptButton";
import { Navigate, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const PaymentNotification = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Dialog
      header={`Payment for Order #${props?.data?.body?.order_id}`}
      visible={props?.open}
      onHide={props?.close}
      style={{ width: "350px", border: 0 }}
    >
      <div className="flex flex-col justify-center items-center">
        <label className="text-[#000000] text-center leading-4 font-bold top-[30px] relative">
          {props?.data?.body?.customer_name}
        </label>
        {props?.data?.body?.type === "payment_intent.succeeded" ? (
          <Lottie
            loop={false}
            animationData={SuccessPayment}
            play
            style={{ width: 250, height: 250 }}
          />
        ) : (
          <Lottie
            loop={false}
            animationData={FailedPayment}
            play
            style={{ width: 250, height: 250 }}
          />
        )}
        <label className="text-[#000000] text-center leading-4 font-[18px] top-[-30px] relative">
          Payment{" "}
          {props?.data?.body?.type === "payment_intent.succeeded"
            ? "Success"
            : "Failed"}
        </label>
      </div>
      {location.pathname !== "/order-details" && (
        <div className="flex justify-center items-center">
          <AcceptButton
            label="View Order"
            onClick={() => {
              navigate("/order-details", {
                state: {
                  order_id: props?.data?.body?.order_id,
                  doNotCallPusher: false,
                },
              });
            }}
          />
        </div>
      )}
    </Dialog>
  );
};

export default PaymentNotification;

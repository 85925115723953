import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Inventory,
  OrderSettings,
  PurchaseOption,
  SubscriptionOption,
  DeliveryOption,
} from "../../interfaces";
import { post } from "../../utils/Api";
import { formatCurrency } from "../../utils/Common";

const initialState = {
  isLoading: false,
  isError: null,
  orderSettings: {} as OrderSettings,
  purchaseOptions: [] as PurchaseOption[],
  subscriptionOptions: [] as SubscriptionOption[],
  deliveryOptions: [] as DeliveryOption[],
  cartItems: [] as Inventory[],
  orderInfo: {} as any,
  cart: [] as any[],
  billingInfo: {} as any,
  additionalInfo: {} as any,
  hasPromotionCode: false,
};

export const getAdditionalInfo: any = createAsyncThunk(
  "getAdditionalInfo",
  async (arg: any, { getState, dispatch, rejectWithValue }: any) => {
    const { cart, additionalInfo, billingInfo } = getState().order;
    const {
      sum,
      sum_formatted,
      tax,
      tax_formatted,
      delivery,
      delivery_formatted,
      total,
      total_formatted,
      discount,
      discount_formatted,
      promotion_discount,
      promotion_discount_formatted,
      ...remAdditionalInfo
    } = additionalInfo;

    const hasPromotionCode = arg?.promotionCode && arg.promotionCode !== "";
    dispatch(setHasPromotionCode(hasPromotionCode));
    dispatch(setAdditionalInfo(remAdditionalInfo));
    try {
      if (cart?.length > 0) {
        const formData = new FormData();
        cart.map(({ cartItems, orderInfo }: any, cartIndex: number) => {
          if (orderInfo?.subscriptionInfo?.subscriptionId) {
            formData.append(
              `items[${cartIndex}][subscription_option_id]`,
              orderInfo?.subscriptionInfo?.subscriptionId,
            );
          }
          if (orderInfo?.purchaseOption?.length > 0) {
            formData.append(
              `items[${cartIndex}][purchase_option_id]`,
              orderInfo?.purchaseOption[0]?.id,
            );
          }
          cartItems?.map((item: any, productIndex: number) => {
            if (item?.id) {
              formData.append(
                `items[${cartIndex}][products][${productIndex}][product_id]`,
                item?.id,
              );
            }
            if (item?.delivery_option_id) {
              formData.append(
                `items[${cartIndex}][products][${productIndex}][delivery_option_id]`,
                item?.delivery_option_id,
              );
            }
            if (item?.quantity) {
              formData.append(
                `items[${cartIndex}][products][${productIndex}][quantity]`,
                item?.quantity,
              );
            }
            if (orderInfo?.purchaseOption[0]?.id === 3) {
              item?.bundle_items?.map((bundle: any, bundleIndex: number) => {
                if (bundle?.id) {
                  formData.append(
                    `items[${cartIndex}][products][${productIndex}][products][${bundleIndex}][product_id]`,
                    bundle?.id,
                  );
                }
                if (bundle?.quantity && item?.quantity) {
                  formData.append(
                    `items[${cartIndex}][products][${productIndex}][products][${bundleIndex}][quantity]`,
                    (bundle?.quantity * item?.quantity).toString(),
                  );
                }
                if (bundle?.type) {
                  formData.append(
                    `items[${cartIndex}][products][${productIndex}][products][${bundleIndex}][type]`,
                    bundle?.type,
                  );
                  if (
                    bundle?.type === "product" &&
                    item?.giveaway_designated_products.length > 0
                  ) {
                    formData.append(
                      `items[${cartIndex}][products][${productIndex}][products][${bundleIndex}][giveaway_id]`,
                      item?.giveaway_designated_products[0].giveaway_id,
                    );
                  }
                }

                if (bundle?.addon_id) {
                  formData.append(
                    `items[${cartIndex}][products][${productIndex}][products][${bundleIndex}][addon_id]`,
                    bundle?.addon_id,
                  );
                }
              });
            }
          });
          if (hasPromotionCode) {
            formData.append("promotion_code", arg.promotionCode);
            formData.append("email", billingInfo.email);
          }
        });
        const response = await post(`order/calculate_cart_summary`, formData);
        if (response?.success && response?.data) {
          const {
            delivery_fee,
            gst_amount,
            subtotal,
            total,
            discount,
            promotion_discount,
          } = response.data;
          return {
            ...additionalInfo,
            ...{
              ...arg,
              sum: subtotal,
              sum_formatted: formatCurrency(subtotal),
              tax: gst_amount,
              tax_formatted: formatCurrency(gst_amount),
              delivery: delivery_fee,
              delivery_formatted: formatCurrency(delivery_fee),
              total,
              total_formatted: formatCurrency(total),
              discount_formatted: formatCurrency(discount),
              promotion_discount: promotion_discount,
              promotion_discount_formatted: formatCurrency(promotion_discount),
            },
          };
        } else {
          throw new Error("Something went wrong.");
          return rejectWithValue(response?.data.message);
        }
        throw new Error("Something went wrong.");
      }
    } catch (error: any) {
      // throw error?.response?.data;
      return rejectWithValue(error?.response?.data.message);
    }
  },
);

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    updateOrderSettings: (state, action: PayloadAction<OrderSettings>) => {
      state.orderSettings = action.payload;
    },
    updatePurchaseOptions: (state, action: PayloadAction<PurchaseOption[]>) => {
      state.purchaseOptions = action.payload;
    },
    updateSubscriptionOptions: (
      state,
      action: PayloadAction<SubscriptionOption[]>,
    ) => {
      state.subscriptionOptions = action.payload;
    },
    updateDeliveryOptions: (state, action: PayloadAction<DeliveryOption[]>) => {
      state.deliveryOptions = action.payload;
    },
    setCartItems: (state, action: PayloadAction<Inventory[]>) => {
      state.cartItems = action.payload;
    },
    setOrderInfo: (state, action: PayloadAction<any>) => {
      state.orderInfo = action.payload;
    },
    setCart: (state, action: PayloadAction<any[]>) => {
      state.cart = action.payload;
    },
    setBillingInfo: (state, action: PayloadAction<any>) => {
      state.billingInfo = action.payload;
    },
    setAdditionalInfo: (state, action: PayloadAction<any>) => {
      state.additionalInfo = action.payload;
    },
    clearError: (state) => {
      state.isError = null;
    },
    setHasPromotionCode(state, action: PayloadAction<any>) {
      state.hasPromotionCode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdditionalInfo.pending, (state, action) => {
      state.isLoading = true;
      state.isError = null;
    });
    builder.addCase(getAdditionalInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.additionalInfo = action.payload;
    });
    builder.addCase(getAdditionalInfo.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = action.payload;
    });
  },
});

export const {
  updateOrderSettings,
  updatePurchaseOptions,
  updateSubscriptionOptions,
  updateDeliveryOptions,
  setCartItems,
  setOrderInfo,
  setCart,
  setBillingInfo,
  setAdditionalInfo,
  clearError,
  setHasPromotionCode,
} = orderSlice.actions;

export default orderSlice.reducer;

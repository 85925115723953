import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { useState, useEffect } from "react";
import AcceptButton from "../../../components/AcceptButton";
import CancelButton from "../../../components/CancelButton";
import EmptyData from "../../../components/EmptyData";
import FilterButton from "../../../components/FilterButton";
import ExportButton from "../../../components/ExportButton";
import Layout from "../../../components/Layout";
import TableFooter from "../../../components/TableFooter";
import TableSkeleton from "../../../components/TableSkeleton";
import useApiData from "../../../hooks/useApiData";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import { formatCurrency, formatDate } from "../../../utils/Common";
import { useLocation, useNavigate } from "react-router-dom";
import { get, post } from "../../../utils/Api";
import { Calendar } from "primereact/calendar";
import { format } from "date-fns";

type SalesData = {
  date: string;
  daily_total: number;
  order_count: number;
};
type SearchParams = {
  venue_id: number;
  source: number;
};
type SummaryData = {
  total_daily_total: number;
  total_order_count: number;
};
const Salesdetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const filtered = location.state.filtered;
  const filteredVenueDate = location.state.date;
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterVenue, setFilterVenue] = useState(filtered);
  const [selectedFilterVenue, setSelectedFilterVenue] = useState(filterVenue);
  const [orderProducts, setOrderProducts] = useState<any>(null);
  const [selectedStatus, setSelectedStatus] = useState("today");
  const [venues, setVenues] = useState<any>(null);
  const [summaryProducts, setSummaryProducts] = useState<any>(null);
  const [filterVenueDate, setFilterVenueDate] = useState(filteredVenueDate);
  const [filterSource, setFilterSource] = useState(0);
  const venue = useApiData("/venue/get_all_venues");
  // const order_products = useApiData(
  //   `/admin/get_orders_products?venue_id=${filtered}`,
  // );
  // let orderData = order_products as any;
  // if (orderData?.data) {
  //   orderData = orderData?.data;
  // }
  const venueData = venue as any;
  let venueList = [];
  let selectedData = null;

  if (venueData?.data) {
    venueList = venueData.data.map((item: any) => {
      return { label: item?.name, value: item?.id };
    });
    venueList.unshift({ label: "All Venues", value: 0 });
    selectedData = venueData?.data.find(
      (item: { id: number }) => item?.id === selectedFilterVenue,
    );
    console.log(selectedData);
  }

  const selectedLabel = venueList.find(
    (item: { value: number }) => item.value === selectedFilterVenue,
  )?.label;

  const source = useApiData("/get_source_type");
  const sourceData = source as any;
  let sourceList = [];

  if (sourceData?.data) {
    sourceList = sourceData.data.map((item: any) => {
      return { label: item?.name, value: item?.id };
    });
    sourceList.unshift({ label: "All Sources", value: 0 });
  }

  const {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  } = usePaginatedApiData<SalesData, SearchParams>(
    "/admin/get_daily_sales",
    {
      venue_id: 0,
      source: 0,
    },
    1,
  );
  const rawRecords = data as any;
  const records = rawRecords.data as SalesData[];
  const summary = rawRecords?.summary as SummaryData;

  useEffect(() => {
    getData(filterVenue, filterVenueDate, selectedStatus, filterSource);
  }, []);
  const getData = async (
    venueId: any,
    venueDate: any,
    status: any,
    filterSource: any,
  ) => {
    const [order_products] = await Promise.all([
      // get(`/admin/get_orders_products?venue_id=${venueId}&date=${status}`),
      get(
        `/admin/get_orders_products?venue_id=${venueId}&start_date=${
          venueDate ? formattedFilterDate?.startDate : ""
        }&end_date=${
          venueDate ? formattedFilterDate?.endDate : ""
        }&source=${filterSource}`,
      ),
    ]);
    if (order_products.success && order_products.data) {
      setOrderProducts(order_products.data.data);
      setSummaryProducts(order_products.data.summary);
    }
  };
  const formatFilterDate = (date: (Date | null)[]) => {
    if (!date || date.length < 1 || !date[0]) {
      return { startDate: "", endDate: "" };
    }

    const startDate = date[0];
    const endDate = date[1];

    let startDateString = "";
    let endDateString = "";

    if (startDate) {
      const start_year = startDate.getFullYear();
      const start_month = String(startDate.getMonth() + 1).padStart(2, "0");
      const start_day = String(startDate.getDate()).padStart(2, "0");
      startDateString = `${start_year}-${start_month}-${start_day}`;
    }

    if (endDate) {
      const end_year = endDate.getFullYear();
      const end_month = String(endDate.getMonth() + 1).padStart(2, "0");
      const end_day = String(endDate.getDate()).padStart(2, "0");
      endDateString = `${end_year}-${end_month}-${end_day}`;
    }

    return { startDate: startDateString, endDate: endDateString };
  };

  const formattedFilterDate = formatFilterDate(filterVenueDate);

  const handleReset = () => {
    setFilterVenue(0);
    setSelectedFilterVenue(0);
    setFilterVisible(false);
    setFilterVenueDate(null);
    setFilterSource(0);
    getData(0, null, selectedStatus, 0);
  };
  function handleDateFilterText(status: string) {
    if (status === "today") {
      setSelectedStatus("today");
    } else if (status === "current_week") {
      setSelectedStatus("current_week");
    } else if (status === "current_month") {
      setSelectedStatus("current_month");
    } else {
      setSelectedStatus("current_year");
    }
  }
  const handleStatusClick = (status: any) => {
    setSelectedStatus(status);
    handleDateFilterText(status);
    getData(filterVenue, filterVenueDate, status, filterSource);
    if (status === "today") {
      console.log("today");
    }
    if (status === "current_week") {
      console.log("current_week");
    }
    if (status === "current_month") {
      console.log("current_month");
    }
    if (status === "current_year") {
      console.log("current_year");
    }
  };

  return (
    <Layout>
      <div className="flex justify-end">
        <ExportButton
          className="mb-[5px]"
          api={
            `/admin/export_orders_products?source=${filterSource}` +
            `&start_date=${formattedFilterDate?.startDate}` +
            `&end_date=${formattedFilterDate?.endDate}` +
            `&venue_id=${filterVenue}`
          }
          filename={`salesDetails_${format(new Date(), "yyyyMMddHHmmss")}.xlsx`}
        />
      </div>
      <Dialog
        header="Filter"
        position="bottom"
        visible={filterVisible}
        style={{
          width: "100vw",
          maxWidth: "450px",
          margin: 0,
          border: 0,
        }}
        onHide={() => setFilterVisible(false)}
      >
        {filterSource === 1 && (
          <div className="mb-3">
            <label htmlFor="filter-venue" className="mb-2 block">
              Venue
            </label>
            <div>
              <Dropdown
                id="filter-venue"
                optionLabel="label"
                value={filterVenue}
                options={venueList}
                onChange={(e) => setFilterVenue(e?.value)}
                className="w-full"
              />
            </div>
          </div>
        )}
        <div className="mb-[10px]">
          <label htmlFor="filter-mobile" className="mb-[5px] block">
            Order Date
          </label>
          <Calendar
            className="w-full"
            name="delivery_date"
            dateFormat="dd M yy"
            selectionMode="range"
            value={filterVenueDate}
            onChange={(e) => {
              if (Array.isArray(e.value) && e.value.length === 2) {
                const [startDate, endDate] = e.value;
                setFilterVenueDate([startDate, endDate]);
              }
            }}
          />
        </div>
        <div className="flex flex-row justify-between items-center pt-[10px]">
          <CancelButton
            label="Reset"
            onClick={() => {
              handleReset();
            }}
          />
          <AcceptButton
            label="Apply"
            onClick={() => {
              setSelectedFilterVenue(filterVenue);
              getData(
                filterVenue,
                filterVenueDate,
                selectedStatus,
                filterSource,
              );
              setFilterVisible(false);
            }}
          />
        </div>
      </Dialog>
      <div className="p-3 card-cfg">
        <div className="flex flex-wrap gap-3 mb-4 items-center">
          <div className="flex flex-wrap gap-3 mb-4 items-center">
            <div
              className={`w-fit flex h-[51px] items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]`}
            >
              <img
                src={require("../../../assets/images/icon-customer-count.png")}
                alt="Filter icon"
                className="w-[27px] mr-[10px]"
              />
              <Dropdown
                id="filter-venue"
                optionLabel="label"
                value={filterSource}
                options={sourceList}
                onChange={(e) => {
                  const newSource = e.value;
                  console.log(newSource);
                  setFilterSource(newSource);
                  getData(0, filterVenueDate, selectedStatus, newSource);
                  setFilterVenue(0);
                  setSelectedFilterVenue(0);
                }}
                className="w-full"
              />
            </div>
            {filterSource === 1 && (
              <div
                className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]`}
                onClick={() => setFilterVisible(true)}
              >
                {selectedLabel}
                {selectedData && (
                  <>
                    <label className="text-xs ml-2">
                      {formatDate(selectedData?.start_date)} -{" "}
                      {formatDate(selectedData?.end_date)}
                    </label>
                  </>
                )}
              </div>
            )}
            <div
              className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]`}
            >
              <label className="text-xs mr-1">Total Count:</label>
              {summaryProducts?.total_count}
            </div>
            <div
              className={`mr-20 w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]`}
            >
              <label className="text-xs mr-1">Total Sales:</label>
              {summaryProducts?.total_sales
                ? formatCurrency(summaryProducts?.total_sales)
                : 0}
            </div>
            {/* <div
              className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 ${
                selectedStatus === "today"
                  ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                  : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
              }`}
              onClick={() => handleStatusClick("today")}
            >
              <img
                src={require("../../../assets/images/icon-customer-count.png")}
                alt="Filter icon"
                className="w-[27px] mr-[10px]"
              />
              Today <span className="text-[24px] ml-[8px]"></span>
            </div>
            <div
              className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 ${
                selectedStatus === "current_week"
                  ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                  : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
              }`}
              onClick={() => handleStatusClick("current_week")}
            >
              <img
                src={require("../../../assets/images/icon-customer-count.png")}
                alt="Filter icon"
                className="w-[27px] mr-[10px]"
              />
              Current Week <span className="text-[24px] ml-[8px]"></span>
            </div>
            <div
              className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 ${
                selectedStatus === "current_month"
                  ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                  : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
              }`}
              onClick={() => handleStatusClick("current_month")}
            >
              <img
                src={require("../../../assets/images/icon-customer-count.png")}
                alt="Filter icon"
                className="w-[27px] mr-[10px]"
              />
              Current Month <span className="text-[24px] ml-[8px]"></span>
            </div>
            <div
              className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 ${
                selectedStatus === "current_year"
                  ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                  : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
              }`}
              onClick={() => handleStatusClick("current_year")}
            >
              <img
                src={require("../../../assets/images/icon-customer-count.png")}
                alt="Filter icon"
                className="w-[27px] mr-[10px]"
              />
              Current Year <span className="text-[24px] ml-[8px]"></span>
            </div> */}
            {/* <div className="ml-auto">
              <FilterButton onClick={() => setFilterVisible(true)} />
            </div> */}
          </div>
          {/* {filterSource === 1 && ( */}
          <FilterButton
            onClick={() => setFilterVisible(true)}
            className="ml-auto"
          />
          {/* )} */}
        </div>
        {loading && <TableSkeleton />}
        {!loading && orderProducts?.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="table-cfg">
                <thead>
                  <tr>
                    <th>Product ID</th>
                    <th>SKU</th>
                    <th>Name</th>
                    <th>Quantity</th>
                    <th>Sales</th>
                  </tr>
                </thead>
                <tbody>
                  {orderProducts.map((order: any) => {
                    return (
                      <tr key={`sales-table-row-${order?.id}`}>
                        <td data-label="Date">{order?.id}</td>
                        <td data-label="Date">{order?.sku}</td>
                        <td data-label="Orders">
                          <div className="flex items-center gap-2 justify-end lg:justify-start w-[200px]">
                            <img
                              src={
                                order.image_url ||
                                require("../../../assets/images/order/no-image.png")
                              }
                              alt=""
                              className="w-[40px] h-[40px] rounded object-cover shrink-0"
                            />
                            <div>
                              <label>{order.name}</label>
                            </div>
                          </div>
                        </td>
                        <td data-label="Sales">{order?.total_quantity}</td>
                        <td data-label="Sales">
                          {formatCurrency(order?.total_sales) ?? 0}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
        {!loading && orderProducts?.length <= 0 && <EmptyData />}
      </div>
    </Layout>
  );
};

export default Salesdetails;

import Layout from "../../../components/Layout";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import TableFooter from "../../../components/TableFooter";
import TableHeader from "../../../components/TableHeader";
import TableSkeleton from "../../../components/TableSkeleton";
import { formatDate } from "../../../utils/Common";
import { useLocation } from "react-router-dom";
import FullScreenDialog from "../../../components/FullScreenDialog";
import OrderDetails from "../../OrderDetails";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import EmptyData from "../../../components/EmptyData";
import { Dialog } from "primereact/dialog";
import CancelButton from "../../../components/CancelButton";
import { InputText } from "primereact/inputtext";
import AcceptButton from "../../../components/AcceptButton";
import { post, get } from "../../../utils/Api";
import { toast } from "react-toastify";
import ExportButton from "../../../components/ExportButton";
import { format } from "date-fns";
import { useAppSelector } from "../../../hooks/useStore";
import FilterButton from "../../../components/FilterButton";

type ProductMovementData = {
  id: number;
  order_item_id: number;
  product_id: number;
  quantity: number;
  remarks: string;
  status: number;
  type: {
    id: number;
    name: string;
  } | null;
  order_item: {
    id: number;
    order_id: number;
    order: {
      id: number;
      source: {
        id: number;
        name: string;
      } | null;
    } | null;
  } | null;
  type_id: number;
  created_at: string;
  created_by: {
    full_name: string;
  } | null;
};

type UserSearchParams = {
  id: number;
};

type TypeData = {
  id: number;
  name: string;
  in: number;
  out: number;
};

const InventoryMovements = (props: any) => {
  const { user } = useAppSelector((state) => state.userAuth);
  let product: { id: number; name: string } = useLocation()?.state?.product;
  if (props?.params?.product) {
    product = props?.params?.product;
  }
  const [skuData, setSkuData] = useState<TypeData[]>([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  // const [filterVisible, setFilterVisible] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showDetailParam, setShowDetailParam] = useState({});
  const [showUpdateRemarkDialog, setShowUpdateRemarkDialog] = useState(false);
  const [remarkData, setRemarkData] = useState<{
    id: number;
    remarks: string;
  }>({
    id: 0,
    remarks: "",
  });

  const updateRemarksData = (newRemarks: string, newId?: number) => {
    setRemarkData((prevRemarkData) => ({
      id: newId !== undefined ? newId : prevRemarkData.id,
      remarks: newRemarks,
    }));
  };

  async function getSku(status: string) {
    try {
      const response = await get(
        `admin/get_sku_info/${product.id}/${status}/null`,
        {},
      );
      const skuRecords = response as any;
      const newData = skuRecords?.data as TypeData[];
      setSkuData(newData);
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  }

  const updateRemarks = async () => {
    try {
      const response = await post("/admin/update_remarks", {
        id: remarkData.id,
        remarks: remarkData.remarks,
      });
      handleReload();
      toast.success(response?.message);
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  } = usePaginatedApiData<ProductMovementData, UserSearchParams>(
    `/admin/get_product_movements`,
    {
      id: product.id,
    },
    1,
  );
  useEffect(() => {
    getSku("current_month");
    setSelectedStatus("current_month");
    handleDateFilterText("current_month");
  }, []);

  const rawRecords = data as any;
  const records = rawRecords?.data?.map((item: ProductMovementData) => {
    return {
      ...item,
    };
  });

  function handleDateFilterText(status: string) {
    if (status === "current_week") {
      setSelectedStatus("current_week");
    } else if (status === "current_month") {
      setSelectedStatus("current_month");
    } else {
      setSelectedStatus("current_year");
    }
  }
  const handleStatusClick = (status: any) => {
    setSelectedStatus(status);
    handleDateFilterText(status);
    getSku(status);
    // if (status === "current_week") {
    //   console.log("current_week");
    // }
    // if (status === "current_month") {
    //   console.log("current_month");
    // }
    // if (status === "current_year") {
    //   console.log("current_year");
    // }
  };

  return (
    <Layout>
      <FullScreenDialog
        visible={showDetail}
        onHide={() => setShowDetail(false)}
      >
        <OrderDetails params={showDetailParam} close={setShowDetail} />
      </FullScreenDialog>
      <Dialog
        header="Update Remarks"
        position="center"
        visible={showUpdateRemarkDialog}
        style={{
          width: "100vw",
          maxWidth: "650px",
          margin: 0,
          border: 0,
        }}
        onHide={() => setShowUpdateRemarkDialog(false)}
      >
        <div className="mb-[10px]">
          <label htmlFor="remarks" className="mb-[5px] block">
            Remarks
          </label>
          <div>
            <InputText
              id="update-remarks-input"
              className="w-full"
              value={remarkData.remarks}
              onChange={(e) => updateRemarksData(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between items-center pt-[10px]">
          <CancelButton
            label="Cancel"
            onClick={() => {
              setShowUpdateRemarkDialog(false);
            }}
          />
          <AcceptButton
            label="Update"
            onClick={() => {
              updateRemarks();
              setShowUpdateRemarkDialog(false);
            }}
          />
        </div>
      </Dialog>
      {props?.close && (
        <Button link onClick={() => props.close(false)}>
          <img
            src={require("../../../assets/images/icon-back.png")}
            alt="Back icon"
            className="w-[34px] h-[34px]"
          />
        </Button>
      )}
      <TableHeader>
        <div className="flex flex-row justify-between items-center flex-nowrap w-full">
          <ExportButton
            className="mb-[5px]"
            api={`admin/export_product_movements?id=${product.id}`}
            filename={`productMovements_${product.name}_${format(
              new Date(),
              "yyyyMMddHHmmss",
            )}${user?.id}.xlsx`}
          />
          <h1 className="mx-auto mb-4 text-center text-xl">{product.name}</h1>
        </div>
      </TableHeader>
      <div className="px-[17px] pt-[17px] pb-[17px] mb-5 card-cfg">
        <div className="overflow-x-auto p-[5px]">
          <div className="flex flex-wrap gap-3 mb-4 items-center">
            <div
              className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 ${
                selectedStatus === "current_week"
                  ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                  : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
              }`}
              onClick={() => handleStatusClick("current_week")}
            >
              <img
                src={require("../../../assets/images/icon-customer-count.png")}
                alt="Filter icon"
                className="w-[27px] mr-[10px]"
              />
              Current Week <span className="text-[24px] ml-[8px]"></span>
            </div>
            <div
              className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 ${
                selectedStatus === "current_month"
                  ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                  : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
              }`}
              onClick={() => handleStatusClick("current_month")}
            >
              <img
                src={require("../../../assets/images/icon-customer-count.png")}
                alt="Filter icon"
                className="w-[27px] mr-[10px]"
              />
              Current Month <span className="text-[24px] ml-[8px]"></span>
            </div>
            <div
              className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 ${
                selectedStatus === "current_year"
                  ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                  : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
              }`}
              onClick={() => handleStatusClick("current_year")}
            >
              <img
                src={require("../../../assets/images/icon-customer-count.png")}
                alt="Filter icon"
                className="w-[27px] mr-[10px]"
              />
              Current Year <span className="text-[24px] ml-[8px]"></span>
            </div>
            {/* <div className="ml-auto">
              <FilterButton onClick={() => setFilterVisible(true)} />
            </div> */}
          </div>
          {!loading && skuData?.length > 0 && (
            <>
              <div className="overflow-x-auto">
                <table className="table-cfg">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>In</th>
                      <th>Out</th>
                    </tr>
                  </thead>
                  <tbody>
                    {skuData.map((row: TypeData) => {
                      return (
                        <tr key={`sku-table-row-${row.id}`}>
                          <td data-label="Name">{row.name}</td>
                          <td data-label="In">{row.in}</td>
                          <td data-label="Out">{row.out}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="px-[17px] pt-[17px] card-cfg">
        {loading && <TableSkeleton />}
        {!loading && records?.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="table-cfg">
                <thead>
                  <tr>
                    <th>Movement</th>
                    <th>Source</th>
                    <th>Quantity</th>
                    <th>Remarks</th>
                    <th>Created At</th>
                    <th>Created By</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((row: ProductMovementData) => {
                    return (
                      <tr key={`inventory-table-row-${row.id}`}>
                        <td data-label="Movement">
                          {row.status === 1 ? (
                            "Stock In"
                          ) : row.status === 2 && row.type?.name ? (
                            `Stock Out - ${row.type.name}`
                          ) : row.order_item_id ? (
                            <span>
                              Stock Out -{" "}
                              <label
                                className="text-[#E5622A] cursor-pointer underline"
                                onClick={() => {
                                  setShowDetailParam({
                                    order_id: row.order_item?.order_id,
                                  });
                                  setShowDetail(true);
                                }}
                              >
                                {`Order #${row.order_item?.order_id}`}
                              </label>
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td data-label="Quantity" className="text-center">
                          {row.order_item ? (
                            <span> {row?.order_item?.order?.source?.name}</span>
                          ) : (
                            <span>POS</span>
                          )}
                        </td>
                        <td data-label="Quantity" className="text-center">
                          {row.status === 1 ? (
                            <span className="!text-green-500">
                              {row.quantity}
                            </span>
                          ) : (
                            <span className="!text-red-500">
                              -{row.quantity}
                            </span>
                          )}
                        </td>
                        <td data-label="Remarks">
                          <div className="flex items-center gap-3 justify-end">
                            {row.remarks}
                            <span
                              className={
                                "pi pi-pencil lg:ml-auto cursor-pointer text-[#DA5E18]"
                              }
                              style={{ fontSize: "13px" }}
                              onClick={() => {
                                updateRemarksData(row.remarks || "", row.id);
                                setShowUpdateRemarkDialog(true);
                              }}
                            ></span>
                          </div>
                        </td>
                        <td data-label="Created At">
                          <div className="whitespace-nowrap">
                            {formatDate(row?.created_at)}
                          </div>
                        </td>
                        <td data-label="Created By">
                          <div className="whitespace-nowrap">
                            {row?.created_by?.full_name || "System"}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            />
          </>
        )}
        {!loading && records?.length <= 0 && <EmptyData />}
      </div>
    </Layout>
  );
};

export default InventoryMovements;

import { Navigate, useLocation } from "react-router-dom";
import { store } from "../redux/store";

const OnlyGuest = ({ children }: any) => {
  const location = useLocation();
  const email: any = store.getState().userAuth?.user?.email || null;
  if (email) {
    return <Navigate to="/" state={{ path: location.pathname }} />;
  }
  return children;
};

export default OnlyGuest;

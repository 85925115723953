import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ValidationError from "../../components/ValidationError";
import { post } from "../../utils/Api";
import { toast } from "react-toastify";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const ForgotPassword = () => {
  const { height } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values) => {
      const { email } = values;
      try {
        setLoading(true);
        const response = await post("/password/email", { email });
        setLoading(false);
        if (response?.success) {
          // console.log(response);
          navigate("/verification-code", { state: { email } });
        }
      } catch (error: any) {
        setLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  return (
    <div
      className="flex bg-[#FFEDD7] md:bg-[#F1F1F1] md:bg-ipad-background-image md:bg-cover md:bg-center md:bg-no-repeat"
      style={{ height: `${height}px` }}
    >
      <div className="w-full max-w-md m-auto bg-white bg-opacity-90 p-[20px] rounded-[7px]">
        <header className="flex flex-col items-center">
          <img
            src={require("../../assets/images/logo-cfg-circle.png")}
            alt="CFG Logo"
            className="h-[131px] mx-auto mb-[30px]"
          />
          <h3 className="text-[16px] mb-[20px] text-[#DA5E18]">
            Reset Password
          </h3>
        </header>
        <form onSubmit={formik.handleSubmit}>
          <div className="pb-[20px]">
            <InputText
              className={`w-full ${
                formik.touched.email && formik.errors.email ? "p-invalid" : ""
              }`}
              name="email"
              placeholder="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ValidationError
              className={
                formik.touched.email && formik.errors.email
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.email}
            </ValidationError>
          </div>
          <div className="pb-[20px]">
            <Button
              label="Next"
              loading={loading}
              className="w-full bg-[#DA5E18] text-[#fff] text-[20px] border-0"
            />
          </div>
        </form>
        <footer className="flex justify-center items-center">
          <NavLink
            to="/login"
            className="text-[#C54700] hover:text-[#E5622A] text-[18px]"
          >
            Just remember? Login
          </NavLink>
        </footer>
      </div>
    </div>
  );
};

export default ForgotPassword;

import React, { useState } from "react";
import Layout from "../../../components/Layout";
import { Dialog } from "primereact/dialog";
import ValidationError from "../../../components/ValidationError";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dropdown } from "primereact/dropdown";
import { post } from "../../../utils/Api";
import { toast } from "react-toastify";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import TableFooter from "../../../components/TableFooter";
import AdminButton from "../../../components/AdminButton";
import TableHeader from "../../../components/TableHeader";
import TableSkeleton from "../../../components/TableSkeleton";
import { useNavigate } from "react-router-dom";
import AcceptButton from "../../../components/AcceptButton";
import CancelButton from "../../../components/CancelButton";
import FilterButton from "../../../components/FilterButton";
import CountryCodeDropdown from "../../../components/CountryCodeDropdown";
import ExportButton from "../../../components/ExportButton";
import { format } from "date-fns";
import { useAppSelector } from "../../../hooks/useStore";
import EmptyData from "../../../components/EmptyData";
import { Role } from "../../../interfaces";
import { store } from "../../../redux/store";
import { isRole } from "../../../utils/Common";
// import { showConfirmDialog } from "../../../utils/Common";

type UserData = {
  agent_code: string;
  country_code: string;
  email: string;
  first_name: string;
  full_name: string;
  id: number;
  last_name: string;
  mobile: string;
  role_id: number;
  role: Role;
  status: number;
  total_sales_amount: number;
  total_sales_quantity: string;
};

type UserSearchParams = {
  first_name: string;
  last_name: string;
  email: string;
  mobile: string;
  agent_code: string;
};

const Users = () => {
  const role_id: any = store.getState().userAuth?.user?.role_id || null;
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.userAuth);
  let roles = useAppSelector((state) => state.common.roles);
  if (role_id === 5) {
    roles = [{ id: 1, name: "Agent" }];
  }
  const [visible, setVisible] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterAgentCode, setFilterAgentCode] = useState("");
  const [filterFirstName, setFilterFirstName] = useState("");
  const [filterLastName, setFilterLastName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterMobile, setFilterMobile] = useState("");

  const {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  } = usePaginatedApiData<UserData, UserSearchParams>(
    "/admin/get_users_list",
    { first_name: "", last_name: "", email: "", mobile: "", agent_code: "" },
    1,
  );
  const rawRecords = data as any;
  const records = rawRecords?.data?.map((item: UserData) => {
    return {
      ...item,
    };
  });
  // const search = searchParams as UserSearchParams;

  // const deleteConfirmation = (data: UserData) => {
  //   showConfirmDialog({
  //     message: "Do you want to delete this record?",
  //     header: "Delete Confirmation",
  //     accept: async () => {
  //       const response = await get(`/admin/delete_category/${data.id}`);
  //       if (response.success) {
  //         toast.success(response.message);
  //       } else {
  //         toast.error("Something went wrong!");
  //       }
  //       handleReload();
  //     },
  //   });
  // };

  const formik = useFormik({
    initialValues: {
      id: 0,
      first_name: "",
      last_name: "",
      email: "",
      password: "asdfasdf",
      country_code: "65",
      mobile: "",
      role_id: null,
      agent_code: "",
      status: true,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      // country_code: Yup.string().required("Country code is required"),
      mobile: Yup.string()
        .min(8, "Invalid Mobile")
        // .matches(/^[0-9]{1,10}$/, "Mobile is not valid")
        .required("Mobile is required"),
      role_id: Yup.string().required("Role is required"),
      // agent_code: Yup.string().required("Agent code is required"),
    }),
    onSubmit: async (values) => {
      const {
        id,
        first_name,
        last_name,
        email,
        password,
        country_code,
        mobile,
        role_id,
        agent_code,
        status,
      } = values;
      try {
        setUserLoading(true);
        const endpoint =
          id === 0 ? "/admin/create_account" : "/admin/edit_user";

        let params = {
          id: id === 0 ? null : id,
          first_name,
          last_name,
          email,
          password,
          country_code,
          mobile,
          role_id,
          status: status ? 1 : 0,
        };
        if (agent_code !== "" && isRole(["Agent", "Agent Leader"], role_id)) {
          params = { ...params, ...{ agent_code } };
        }
        const response = await post(endpoint, params);
        setUserLoading(false);
        if (response?.success) {
          toast.success(response?.message);
          setVisible(false);
          formik.resetForm();
          handleReload();
        }
      } catch (error: any) {
        setUserLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  const handleView = (user: any) => {
    navigate("/user-details", {
      state: { user_data: user, user_id: user?.id },
    });
  };

  return (
    <Layout>
      <Dialog
        header="User"
        visible={visible}
        style={{ width: "70vw", border: 0 }}
        onHide={() => {
          setVisible(false);
          formik.resetForm();
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="pb-[10px]">
            <InputText
              className={`w-full ${
                formik.touched.first_name && formik.errors.first_name
                  ? "p-invalid"
                  : ""
              }`}
              name="first_name"
              placeholder="First name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ValidationError
              className={
                formik.touched.first_name && formik.errors.first_name
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.first_name}
            </ValidationError>
          </div>
          <div className="pb-[10px]">
            <InputText
              className={`w-full ${
                formik.touched.last_name && formik.errors.last_name
                  ? "p-invalid"
                  : ""
              }`}
              name="last_name"
              placeholder="Last name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ValidationError
              className={
                formik.touched.last_name && formik.errors.last_name
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.last_name}
            </ValidationError>
          </div>
          <div className="pb-[10px]">
            <InputText
              className={`w-full ${
                formik.touched.email && formik.errors.email ? "p-invalid" : ""
              }`}
              name="email"
              placeholder="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ValidationError
              className={
                formik.touched.email && formik.errors.email
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.email}
            </ValidationError>
          </div>
          <div className="pb-[10px]">
            <div className="flex flex-row justify-start items-center flex-nowrap gap-x-2">
              <CountryCodeDropdown
                className={`w-[100px] ${
                  formik.touched.country_code && formik.errors.country_code
                    ? "p-invalid"
                    : ""
                }`}
                name="country_code"
                value={formik.values.country_code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <InputText
                className={`w-full ${
                  formik.touched.mobile && formik.errors.mobile
                    ? "p-invalid"
                    : ""
                }`}
                name="mobile"
                placeholder="Mobile"
                value={formik.values.mobile}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            {/* <ValidationError
              className={
                formik.touched.country_code && formik.errors.country_code
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.country_code}
            </ValidationError> */}
            <ValidationError
              className={
                formik.touched.mobile && formik.errors.mobile
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.mobile}
            </ValidationError>
          </div>
          <div className="pb-[10px]">
            <Dropdown
              className={`w-full ${
                formik.touched.role_id && formik.errors.role_id
                  ? "p-invalid"
                  : ""
              }`}
              options={roles.map((role: Role) => ({
                label: role.name,
                value: role.id,
              }))}
              name="role_id"
              placeholder="Select Role"
              value={formik.values.role_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ValidationError
              className={
                formik.touched.role_id && formik.errors.role_id
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.role_id}
            </ValidationError>
          </div>
          {isRole(["Agent", "Agent Leader"], formik.values.role_id) && (
            <div className="pb-[10px]">
              <InputText
                className={`w-full ${
                  formik.touched.agent_code && formik.errors.agent_code
                    ? "p-invalid"
                    : ""
                }`}
                name="agent_code"
                placeholder="Agent code"
                value={formik.values.agent_code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <ValidationError
                className={
                  formik.touched.agent_code && formik.errors.agent_code
                    ? "visible"
                    : "invisible"
                }
              >
                {formik.errors.agent_code}
              </ValidationError>
            </div>
          )}
          <div className="pb-[10px] flex flex-row justify-start items-center flex-nowrap gap-x-4">
            <label htmlFor="status">Status</label>
            <InputSwitch
              name="status"
              inputId="status"
              checked={formik.values.status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <AcceptButton label="Save" loading={userLoading} />
        </form>
      </Dialog>
      <Dialog
        header="Filter"
        position="bottom"
        visible={filterVisible}
        style={{
          width: "100vw",
          maxWidth: "450px",
          margin: 0,
          border: 0,
        }}
        onHide={() => setFilterVisible(false)}
      >
        <div className="mb-[10px]">
          <label htmlFor="filter-agent-code" className="mb-[5px] block">
            Agent Code
          </label>
          <div>
            <InputText
              id="filter-agent-code"
              className="w-full"
              value={filterAgentCode}
              onChange={(e) => setFilterAgentCode(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-first-name" className="mb-[5px] block">
            First name
          </label>
          <div>
            <InputText
              id="filter-first-name"
              className="w-full"
              value={filterFirstName}
              onChange={(e) => setFilterFirstName(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-last-name" className="mb-[5px] block">
            Last name
          </label>
          <div>
            <InputText
              id="filter-last-name"
              className="w-full"
              value={filterLastName}
              onChange={(e) => setFilterLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-email" className="mb-[5px] block">
            Email
          </label>
          <div>
            <InputText
              id="filter-email"
              className="w-full"
              value={filterEmail}
              onChange={(e) => setFilterEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-mobile" className="mb-[5px] block">
            Mobile
          </label>
          <div>
            <InputText
              id="filter-mobile"
              className="w-full"
              value={filterMobile}
              onChange={(e) => setFilterMobile(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between items-center pt-[10px]">
          <CancelButton
            label="Reset"
            onClick={() => {
              setFilterFirstName("");
              setFilterLastName("");
              setFilterEmail("");
              setFilterMobile("");
              setFilterAgentCode("");
              handleSearchParamsChange({
                first_name: "",
                last_name: "",
                email: "",
                mobile: "",
                agent_code: "",
              });
              setFilterVisible(false);
            }}
          />
          <AcceptButton
            label="Apply"
            onClick={() => {
              handleSearchParamsChange({
                first_name: filterFirstName,
                last_name: filterLastName,
                email: filterEmail,
                mobile: filterMobile,
                agent_code: filterAgentCode,
              });
              setFilterVisible(false);
            }}
          />
        </div>
      </Dialog>
      <TableHeader>
        <div className="flex flex-row justify-between items-end flex-nowrap w-full">
          <ExportButton
            className="mb-[5px]"
            api={`/export_users?first_name=${filterFirstName}&last_name=${filterLastName}&email=${filterEmail}&mobile=${filterMobile}&agent_code=${filterAgentCode}`}
            filename={`users_${format(new Date(), "yyyyMMddHHmmss")}${
              user?.id
            }.xlsx`}
          />
          <div className="flex flex-row justify-end items-end flex-nowrap">
            <FilterButton
              onClick={() => setFilterVisible(true)}
              className="mb-[5px] mr-[10px]"
            />
            <AdminButton label="Create" onClick={() => setVisible(true)} />
          </div>
        </div>
      </TableHeader>
      <div className="px-[17px] pt-[17px] card-cfg">
        {loading && <TableSkeleton />}
        {!loading && records?.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="table-cfg">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Contact</th>
                    <th>Role</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((row: UserData) => {
                    return (
                      <tr key={`user-table-row-${row.id}`}>
                        <td data-label="User">
                          <div onClick={() => handleView(row)}>
                            <p className="whitespace-nowrap text-[#E5622A] cursor-pointer underline">
                              {row.full_name}
                            </p>
                            <p className="text-xs">{row.agent_code}</p>
                          </div>
                        </td>
                        <td data-label="Contact">
                          <div>
                            <p>{row.email}</p>
                            <p className="text-xs">
                              {row.country_code} {row.mobile}
                            </p>
                          </div>
                        </td>
                        <td data-label="Role">
                          <div
                            className={`${
                              row.role.name === "Agent"
                                ? "bg-blue-300"
                                : row.role.name === "Admin"
                                ? "bg-yellow-300"
                                : "bg-gray-300"
                            } px-[8px] py-[3px] rounded-[30px] inline text-xs whitespace-nowrap w-fit lg:w-full`}
                          >
                            {row.role.name}
                          </div>
                        </td>
                        <td data-label="Status">
                          <div
                            className={`${
                              row.status === 1 ? "bg-green-300" : "bg-red-300"
                            } px-[8px] py-[3px] rounded-[30px] inline text-xs whitespace-nowrap w-fit lg:w-full`}
                          >
                            {row.status === 1 ? "Active" : "In-active"}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            />
          </>
        )}
        {!loading && records?.length <= 0 && <EmptyData />}
      </div>
    </Layout>
  );
};

export default Users;

import { format } from "date-fns";
import { useFormik } from "formik";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AcceptButton from "../../../components/AcceptButton";
import AdminButton from "../../../components/AdminButton";
import CancelButton from "../../../components/CancelButton";
import EmptyData from "../../../components/EmptyData";
import FilterButton from "../../../components/FilterButton";
import FullScreenDialog from "../../../components/FullScreenDialog";
import Layout from "../../../components/Layout";
import TableFooter from "../../../components/TableFooter";
import TableHeader from "../../../components/TableHeader";
import TableSkeleton from "../../../components/TableSkeleton";
import ValidationError from "../../../components/ValidationError";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import { store } from "../../../redux/store";
import { post } from "../../../utils/Api";
import { formatCurrency, formatDate } from "../../../utils/Common";
import VenueOrders from "../VenueOrders";
import { InputNumber } from "primereact/inputnumber";
import { useNavigate } from "react-router-dom";

type VenueData = {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  remarks: string;
  order_count: number;
  status: number;
  deposit: number;
  rental: number;
  target: number;
  orders_total_amount: any;
};
type UserSearchParams = {
  name: string;
  start_date: string;
  end_date: string;
  statuses: string;
};

const Venues = () => {
  const navigate = useNavigate();
  const role_id: any = store.getState().userAuth?.user?.role_id || null;
  const [visible, setVisible] = useState(false);
  const [venueLoading, setVenueLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showDetailParam, setShowDetailParam] = useState({});
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterVenueName, setFilterVenueName] = useState("");
  const [filterVenueDate, setFilterVenueDate] = useState<any>(null);
  const {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  } = usePaginatedApiData<VenueData, UserSearchParams>(
    "/admin/get_venues_listing",
    {
      name: "",
      start_date: "",
      end_date: "",
      statuses: "",
    },
    1,
  );
  const rawRecords = data as any;
  const records = rawRecords.data as VenueData[];

  const handleVenueUpdate = (row: any) => {
    formik.setValues({
      id: row.id,
      name: row.name,
      start_date: row.start_date,
      end_date: row.end_date,
      remarks: row.remarks,
      deposit: row.deposit,
      rental: row.rental,
      target: row.target,
      status: row.status === 1,
    });
    setVisible(true);
  };

  const handleDateChange = (fieldName: any, selectedDate: any) => {
    const formattedDate = selectedDate
      ? format(selectedDate, "yyyy/MM/dd")
      : "";
    formik.setFieldValue(fieldName, formattedDate);
  };

  const formatFilterDate = (date: (Date | null)[]) => {
    if (!date || date.length < 1 || !date[0]) {
      return { startDate: "", endDate: "" };
    }

    const startDate = date[0];
    const endDate = date[1];

    let startDateString = "";
    let endDateString = "";

    if (startDate) {
      const start_year = startDate.getFullYear();
      const start_month = String(startDate.getMonth() + 1).padStart(2, "0");
      const start_day = String(startDate.getDate()).padStart(2, "0");
      startDateString = `${start_year}-${start_month}-${start_day}`;
    }

    if (endDate) {
      const end_year = endDate.getFullYear();
      const end_month = String(endDate.getMonth() + 1).padStart(2, "0");
      const end_day = String(endDate.getDate()).padStart(2, "0");
      endDateString = `${end_year}-${end_month}-${end_day}`;
    }

    return { startDate: startDateString, endDate: endDateString };
  };

  const formattedFilterDate = formatFilterDate(filterVenueDate);

  const formik = useFormik({
    initialValues: {
      id: 0,
      name: "",
      start_date: null,
      end_date: null,
      deposit: 0,
      rental: 0,
      target: 0,
      remarks: "",
      status: true,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Venue Name is required"),
      start_date: Yup.date()
        .required("Start Date is required")
        .max(Yup.ref("end_date"), "Start Date cannot be after End Date"),
      end_date: Yup.date()
        .required("End Date is required")
        .min(Yup.ref("start_date"), "End Date cannot be before Start Date"),
      deposit: Yup.number()
        .typeError("Deposit must be a number")
        .min(0, "Deposit must be at least 0"),
      rental: Yup.number()
        .typeError("Rental must be a number")
        .min(0, "Rental must be at least 0"),
      target: Yup.number()
        .typeError("Target must be a number")
        .min(0, "Target must be at least 0"),
    }),
    onSubmit: async (values) => {
      const {
        id,
        name,
        start_date,
        end_date,
        status,
        remarks,
        deposit,
        rental,
        target,
      } = values;
      try {
        setVenueLoading(true);
        const formData = new FormData();
        if (id > 0) {
          formData.append("id", id as unknown as string);
        }
        formData.append("name", name);
        formData.append("start_date", start_date as unknown as string);
        formData.append("end_date", end_date as unknown as string);
        if (remarks) {
          formData.append("remarks", remarks);
        }
        formData.append("deposit", deposit as unknown as string);
        formData.append("rental", rental as unknown as string);
        formData.append("target", target as unknown as string);
        formData.append("status", status ? "1" : "0");
        const response = await post("/admin/update_venue", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setVenueLoading(false);
        if (response?.success) {
          toast.success(response?.message);
          setVisible(false);
          formik.resetForm();
          handleReload();
        }
      } catch (error: any) {
        setVenueLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  return (
    <Layout>
      <FullScreenDialog
        visible={showDetail}
        onHide={() => setShowDetail(false)}
      >
        <VenueOrders params={showDetailParam} close={setShowDetail} />
      </FullScreenDialog>
      <Dialog
        header="Filter"
        position="bottom"
        visible={filterVisible}
        style={{
          width: "100vw",
          maxWidth: "450px",
          margin: 0,
          border: 0,
        }}
        onHide={() => setFilterVisible(false)}
      >
        <div className="mb-[10px]">
          <label htmlFor="filter-first-name" className="mb-[5px] block">
            Venue Name
          </label>
          <div>
            <InputText
              id="filter-first-name"
              className="w-full"
              value={filterVenueName}
              onChange={(e) => setFilterVenueName(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-mobile" className="mb-[5px] block">
            Venue Date
          </label>
          <Calendar
            className="w-full"
            name="delivery_date"
            dateFormat="dd M yy"
            selectionMode="range"
            value={filterVenueDate}
            onChange={(e) => {
              if (Array.isArray(e.value) && e.value.length === 2) {
                const [startDate, endDate] = e.value;
                setFilterVenueDate([startDate, endDate]);
              }
            }}
          />
        </div>
        <div className="flex flex-row justify-between items-center pt-[10px]">
          <CancelButton
            label="Reset"
            onClick={() => {
              setFilterVenueName("");
              setFilterVenueDate(null);
              handleSearchParamsChange({
                name: "",
                start_date: "",
                end_date: "",
              });
              setFilterVisible(false);
            }}
          />
          <AcceptButton
            label="Apply"
            onClick={() => {
              handleSearchParamsChange({
                name: filterVenueName,
                start_date: formattedFilterDate?.startDate,
                end_date: formattedFilterDate?.endDate,
              });
              setFilterVisible(false);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        header="Venue"
        visible={visible}
        className="w-[90vw] md:w-auto border-0"
        onHide={() => {
          setVisible(false);
          formik.resetForm();
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4 rounded-[7px]">
            <div className="md:col-span-2">
              <label
                htmlFor="sku"
                className="text-[#292929] capitalize block mb-2"
              >
                Venue Name
              </label>
              <InputText
                className={`w-full ${
                  formik.touched.name && formik.errors.name ? "p-invalid" : ""
                }`}
                name="name"
                id="name"
                placeholder="Venue Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <ValidationError
                className={
                  formik.touched.name && formik.errors.name ? "block" : "hidden"
                }
              >
                {formik.errors.name}
              </ValidationError>
            </div>
            <div className="md:col-span-1">
              <label
                htmlFor="start_date"
                className="text-[#292929] capitalize block mb-2"
              >
                Start Date
              </label>
              <Calendar
                className={`w-full ${
                  formik.touched.start_date && formik.errors.start_date
                    ? "p-invalid"
                    : ""
                }`}
                name="start_date"
                inputId="start_date"
                placeholder="Start Date"
                value={
                  formik.values.start_date
                    ? new Date(formik.values.start_date)
                    : null
                }
                onChange={(e) => handleDateChange("start_date", e.value)}
                dateFormat="dd M yy"
              />
              <ValidationError
                className={
                  formik.touched.start_date && formik.errors.start_date
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.start_date}
              </ValidationError>
            </div>
            <div className="md:col-span-1">
              <label
                htmlFor="end_date"
                className="text-[#292929] capitalize block mb-2"
              >
                End Date
              </label>
              <Calendar
                className={`w-full ${
                  formik.touched.end_date && formik.errors.end_date
                    ? "p-invalid"
                    : ""
                }`}
                name="end_date"
                inputId="end_date"
                placeholder="End Date"
                value={
                  formik.values.end_date
                    ? new Date(formik.values.end_date)
                    : null
                }
                onChange={(e) => handleDateChange("end_date", e.value)}
                dateFormat="dd M yy"
              />
              <ValidationError
                className={
                  formik.touched.end_date && formik.errors.end_date
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.end_date}
              </ValidationError>
            </div>
            <div className="md:col-span-1">
              <label
                htmlFor="deposit"
                className="text-[#292929] capitalize block mb-2"
              >
                Deposit
              </label>
              <InputNumber
                name="deposit"
                id="deposit"
                className={`w-full ${
                  formik.touched.deposit && formik.errors.deposit
                    ? "p-invalid"
                    : ""
                }`}
                placeholder="Deposit"
                value={formik.values.deposit}
                onValueChange={(e) => {
                  if (!e.value) {
                    formik.setFieldValue("deposit", 0);
                  } else {
                    formik.setFieldValue("deposit", e.value);
                  }
                }}
                onBlur={formik.handleBlur}
                prefix="$ "
                minFractionDigits={2}
              />
              <ValidationError
                className={
                  formik.touched.deposit && formik.errors.deposit
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.deposit}
              </ValidationError>
            </div>
            <div className="md:col-span-1">
              <label
                htmlFor="rental"
                className="text-[#292929] capitalize block mb-2"
              >
                Rental
              </label>
              <InputNumber
                name="rental"
                id="rental"
                className={`w-full ${
                  formik.touched.rental && formik.errors.rental
                    ? "p-invalid"
                    : ""
                }`}
                placeholder="Rental"
                value={formik.values.rental}
                onValueChange={(e) => {
                  if (!e.value) {
                    formik.setFieldValue("rental", 0);
                  } else {
                    formik.setFieldValue("rental", e.value);
                  }
                }}
                onBlur={formik.handleBlur}
                prefix="$ "
                minFractionDigits={2}
              />
              <ValidationError
                className={
                  formik.touched.rental && formik.errors.rental
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.rental}
              </ValidationError>
            </div>
            <div className="md:col-span-1">
              <label
                htmlFor="target"
                className="text-[#292929] capitalize block mb-2"
              >
                Target
              </label>
              <InputNumber
                name="target"
                id="target"
                className={`w-full ${
                  formik.touched.target && formik.errors.target
                    ? "p-invalid"
                    : ""
                }`}
                placeholder="Target"
                value={formik.values.target}
                onValueChange={(e) => {
                  if (!e.value) {
                    formik.setFieldValue("target", 0);
                  } else {
                    formik.setFieldValue("target", e.value);
                  }
                }}
                onBlur={formik.handleBlur}
                prefix="$ "
                minFractionDigits={2}
              />
              <ValidationError
                className={
                  formik.touched.target && formik.errors.target
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.target}
              </ValidationError>
            </div>
            <div className="md:col-span-2 flex flex-col">
              <label
                htmlFor="Remarks"
                className="text-[#292929] capitalize block mb-2"
              >
                Remarks
              </label>
              <InputTextarea
                className={`w-full ${
                  formik.touched.remarks && formik.errors.remarks
                    ? "p-invalid"
                    : ""
                }`}
                name="remarks"
                placeholder="Remarks"
                value={formik.values.remarks}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="md:col-span-1 flex flex-col">
              <label
                htmlFor="status"
                className="text-[#292929] capitalize block mb-2"
              >
                Status
              </label>
              <InputSwitch
                name="status"
                inputId="status"
                checked={formik.values.status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <AcceptButton
              label={formik.values.id > 0 ? "Update" : "Save"}
              loading={venueLoading}
              className="mt-[20px]"
            />
          </div>
        </form>
      </Dialog>
      <TableHeader>
        <FilterButton
          onClick={() => setFilterVisible(true)}
          className="mb-[5px] mr-[10px]"
        />
        <AdminButton label="Create" onClick={() => setVisible(true)} />
      </TableHeader>
      <div className="p-[17px] card-cfg">
        {loading && <TableSkeleton />}
        {!loading && records?.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="table-cfg">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Orders</th>
                    <th>Sales</th>
                    <th>Target</th>
                    <th>Deposit</th>
                    <th>Rental</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((row: VenueData) => {
                    return (
                      <tr key={`venue-table-row-${row?.id}`}>
                        <td data-label="Name">
                          <label
                            className="text-[#E5622A] cursor-pointer underline"
                            onClick={() => handleVenueUpdate(row)}
                          >
                            {row?.name}
                          </label>
                          <p className="text-xs">
                            {formatDate(row?.start_date)} -{" "}
                            {formatDate(row?.end_date)}
                          </p>
                        </td>
                        <td data-label="Orders">
                          <label
                            className="text-[#E5622A] cursor-pointer underline"
                            onClick={() => {
                              setShowDetailParam({
                                venue: row,
                                url:
                                  role_id === 5
                                    ? "/get_all_orders"
                                    : "/get_orders",
                              });
                              setShowDetail(true);
                            }}
                          >
                            {row.order_count}
                          </label>
                        </td>
                        <td data-label="Sales">
                          <label
                            className="text-[#E5622A] cursor-pointer underline"
                            onClick={() => {
                              // Use the navigate function to navigate to the /inventory-create route
                              navigate("/sales-details", {
                                state: {
                                  filtered: row.id,
                                  date: filterVenueDate,
                                },
                              });
                            }}
                          >
                            {formatCurrency(row.orders_total_amount) ?? 0}
                          </label>
                        </td>
                        <td data-label="Target">
                          {formatCurrency(row?.target) ?? 0}
                        </td>
                        <td data-label="Deposit">
                          {formatCurrency(row?.deposit) ?? 0}
                        </td>
                        <td data-label="Rental">
                          {formatCurrency(row?.rental) ?? 0}
                        </td>
                        <td data-label="Status">
                          <div
                            className={`rounded-[30px] w-fit lg:w-full ${
                              row?.status === 0 ? "bg-gray-300" : "bg-green-300"
                            } px-[8px] py-[3px] rounded-[30px] inline text-xs w-fit lg:w-full`}
                          >
                            {row?.status === 0 ? "Inactive" : "Active"}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            />
          </>
        )}
        {!loading && records?.length <= 0 && <EmptyData />}
      </div>
    </Layout>
  );
};

export default Venues;

import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import Layout from "../../components/Layout";
import { Button } from "primereact/button";

function Home() {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [barChartData, setBarChartData] = useState({});
  const [barChartOptions, setBarChartOptions] = useState({});

  useEffect(() => {
    // Pie Charts
    const documentStyle = getComputedStyle(document.documentElement);
    const data = {
      labels: ["Product A", "Product B", "Product C"],
      datasets: [
        {
          data: [540, 325, 702],
          backgroundColor: [
            documentStyle.getPropertyValue("--blue-500"),
            documentStyle.getPropertyValue("--yellow-500"),
            documentStyle.getPropertyValue("--green-500"),
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--blue-400"),
            documentStyle.getPropertyValue("--yellow-400"),
            documentStyle.getPropertyValue("--green-400"),
          ],
        },
      ],
    };
    const options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);

    // Bar Chart
    const documentStyleBar = getComputedStyle(document.documentElement);
    const textColorBar = documentStyleBar.getPropertyValue("--text-color");
    const textColorSecondaryBar = documentStyleBar.getPropertyValue(
      "--text-color-secondary",
    );
    const surfaceBorderBar =
      documentStyleBar.getPropertyValue("--surface-border");
    const dataBar = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "Subscription",
          backgroundColor: documentStyleBar.getPropertyValue("--blue-500"),
          borderColor: documentStyleBar.getPropertyValue("--blue-500"),
          data: [65, 59, 80, 81, 56, 55, 40],
        },
        {
          label: "One Time Purchase",
          backgroundColor: documentStyleBar.getPropertyValue("--pink-500"),
          borderColor: documentStyleBar.getPropertyValue("--pink-500"),
          data: [28, 48, 40, 19, 86, 27, 90],
        },
      ],
    };
    const optionsBar = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            fontColor: textColorBar,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondaryBar,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondaryBar,
          },
          grid: {
            color: surfaceBorderBar,
            drawBorder: false,
          },
        },
      },
    };

    setBarChartData(dataBar);
    setBarChartOptions(optionsBar);
  }, []);

  return (
    <Layout>
      <div className="p-5">
        <h1 className="text-4xl text-center mb-8 dark:text-white">
          Dashboard - Coming soon...
        </h1>
      </div>
      <section className="grid md:grid-cols-2 xl:grid-cols-4 gap-2">
        <div className="pl-1 h-20 bg-green-400 rounded-lg shadow-md">
          <div className="flex w-full h-full py-2 px-4 bg-white rounded-lg justify-between">
            <div className="my-auto">
              <p className="font-bold">EARNINGS (MONTHLY)</p>
              <p className="text-lg">$40,000</p>
            </div>
            <div className="my-auto">
              <span
                className="pi pi-dollar"
                style={{ fontSize: "2.5rem" }}
              ></span>
            </div>
          </div>
        </div>
        <div className="pl-1 h-20 bg-blue-500 rounded-lg shadow-md">
          <div className="flex w-full h-full py-2 px-4 bg-white rounded-lg justify-between">
            <div className="my-auto">
              <p className="font-bold">EARNINGS (ANNUAL)</p>
              <p className="text-lg">$215,000</p>
            </div>
            <div className="my-auto">
              <span
                className="pi pi-euro"
                style={{ fontSize: "2.5rem" }}
              ></span>
            </div>
          </div>
        </div>
        <div className="pl-1 h-20 bg-purple-500 rounded-lg shadow-md">
          <div className="flex w-full h-full py-2 px-4 bg-white rounded-lg justify-between">
            <div className="my-auto">
              <p className="font-bold">TASKS</p>
              <p className="text-lg">50%</p>
            </div>
            <div className="my-auto">
              <span
                className="pi pi-hourglass"
                style={{ fontSize: "2.5rem" }}
              ></span>
            </div>
          </div>
        </div>
        <div className="pl-1 h-20 bg-yellow-400 rounded-lg shadow-md">
          <div className="flex w-full h-full py-2 px-4 bg-white rounded-lg justify-between">
            <div className="my-auto">
              <p className="font-bold">PENDING REQUESTS</p>
              <p className="text-lg">18</p>
            </div>
            <div className="my-auto">
              <span
                className="pi pi-briefcase"
                style={{ fontSize: "2.5rem" }}
              ></span>
            </div>
          </div>
        </div>
      </section>
      <section className="grid xl:grid-cols-4 gap-2 my-2">
        <div className="p-[17px] card-cfg flex justify-center items-center">
          <Chart
            type="pie"
            data={chartData}
            options={chartOptions}
            className="w-full"
          />
        </div>
        <div className="p-[17px] card-cfg xl:col-span-3">
          <Chart type="bar" data={barChartData} options={barChartOptions} />
        </div>
      </section>
      <section className="grid xl:grid-cols-2 gap-2 my-2">
        <div className="p-[17px] card-cfg">
          <div className="overflow-x-auto">
            <div className="flex justify-between items-center mb-[17px]">
              <h1>Recent Customers</h1>
              <Button link label="View" />
            </div>
            <table className="table-cfg">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Name">1</td>
                  <td data-label="Email">2</td>
                  <td data-label="Phone">3</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="p-[17px] card-cfg">
          <div className="overflow-x-auto">
            <div className="flex justify-between items-center mb-[17px]">
              <h1>Recent Orders</h1>
              <Button link label="View" />
            </div>
            <table className="table-cfg">
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Sales</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-label="Order ID">1</td>
                  <td data-label="Sales">2</td>
                  <td data-label="Date">3</td>
                  <td data-label="Status">4</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Home;

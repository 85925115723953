import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AcceptButton from "../../components/AcceptButton";
import { post } from "../../utils/Api";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const VerificationCode = () => {
  const { height } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const email = useLocation()?.state?.email;
  const [otp, setOtp] = useState(Array.from({ length: 6 }, () => ""));
  const inputLength = 6;
  const [seconds, setSeconds] = useState(60);

  const handleInputChange = (event: any, index: any) => {
    const value = event.target.value;

    if (value.match(/^\d*$/) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value !== "" && index < inputLength - 1) {
        const nextInput = event.target.nextElementSibling as HTMLInputElement;
        if (nextInput) {
          nextInput.focus();
        }
      }

      // If the last input is filled, blur the input to prevent further input
      if (index === inputLength - 1 && value !== "") {
        event.target.blur();
      }
    }
  };

  const handleInputBackspace = (event: any, index: any) => {
    if (event.key === "Backspace" && event.target.value === "") {
      if (index > 0) {
        const prevInput = event.target.previousElementSibling;
        if (prevInput) {
          prevInput.focus();
        }
      }
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const enteredOtp = otp.join(""); // Combine the OTP array into a single string
      // console.log("Entered PIN:", enteredOtp);
      setLoading(true);
      const response = await post("/password/verify-otp", {
        email,
        otp: enteredOtp,
      });
      setLoading(false);
      if (response?.success) {
        // console.log(response);
        navigate("/reset-password", { state: { email } });
      }
    } catch (error: any) {
      setLoading(false);
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
    if (seconds === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <div
      className="flex bg-[#FFEDD7] md:bg-[#F1F1F1] md:bg-ipad-background-image md:bg-cover md:bg-center md:bg-no-repeat"
      style={{ height: `${height}px` }}
    >
      <div className="w-full max-w-xs m-auto">
        <header className="flex flex-col items-center">
          <img
            src={require("../../assets/images/logo-cfg-circle.png")}
            alt="CFG Logo"
            className="h-[131px] mx-auto mb-[30px]"
          />
          <h3 className="text-[20px] mb-[20px] text-[#DA5E18]">
            Enter Verification Code
          </h3>
        </header>
        <form onSubmit={handleSubmit}>
          <div className="pb-[20px]">
            <div className="flex justify-center">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="number"
                  value={digit}
                  maxLength={1}
                  onChange={(e) => handleInputChange(e, index)}
                  onKeyDown={(e) => handleInputBackspace(e, index)}
                  className={`text-[20px] rounded-[6px] w-[32px] h-[46px] text-center mx-2 text-[#DA5E18] font-bold`}
                  disabled={index >= inputLength}
                />
              ))}
            </div>
            <div className="text-sm text-[#C54700] mt-5 mb-3 text-center">
              {seconds > 0
                ? `Your verification code will expire in ${seconds}s`
                : "Your verification code has expired."}
            </div>
          </div>
          <div className="pb-[20px]">
            <AcceptButton
              label="Verify"
              loading={loading}
              className="w-full"
              disabled={otp.length < inputLength}
            />
          </div>
        </form>
        <footer className="flex justify-center items-center">
          <NavLink
            to="/forgot-password"
            className="text-[#C54700] hover:text-[#E5622A] text-[18px]"
          >
            Cancel
          </NavLink>
        </footer>
      </div>
    </div>
  );
};

export default VerificationCode;

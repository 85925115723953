import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import commonSlice from "./slices/commonSlice";
import orderSlice from "./slices/orderSlice";
import customerSlice from "./slices/customerSlice";

const rootReducer = combineReducers({
  userAuth: authSlice,
  common: commonSlice,
  order: orderSlice,
  customer: customerSlice,
});

export default rootReducer;

import { useState } from "react";
import { useLocation } from "react-router-dom";
import FullScreenDialog from "../../../components/FullScreenDialog";
import Layout from "../../../components/Layout";
import TableFooter from "../../../components/TableFooter";
import TableHeader from "../../../components/TableHeader";
import TableSkeleton from "../../../components/TableSkeleton";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import OrderDetails from "../../OrderDetails";
import { formatCurrency, formatDate } from "../../../utils/Common";
import { Button } from "primereact/button";
import EmptyData from "../../../components/EmptyData";

type VenueOrderData = {
  id: number;
  status: number;
  agents: Array<{
    agent: {
      agent_code: string;
      full_name: string;
    };
    primary: number;
  }>;
  customer: {
    full_name: string;
    country_code: string;
    mobile: string;
    email: string;
  };
  created_at: string;
  total: string;
  venue: {
    name: string;
  };
  venue_id: number;
  type: string;
  highest_subscription_month: number;
};

let path: any;

type UserSearchParams = {
  venue_id: number;
  type: string;
};

const VenueOrders = (props: any) => {
  let venue: { id: number; name: string } = useLocation()?.state?.venue;
  if (props?.params?.venue) {
    venue = props?.params?.venue;
  }
  if (props?.params?.url) {
    path = props?.params?.url;
  }
  const [showDetail, setShowDetail] = useState(false);
  const [showDetailParam, setShowDetailParam] = useState({});

  const { data, loading, currentPage, totalPages, handlePageChange } =
    usePaginatedApiData<VenueOrderData, UserSearchParams>(
      path,
      {
        venue_id: venue?.id,
        type: "paid",
      },
      1,
    );

  const rawRecords = data as any;
  const summary = rawRecords?.summary;
  const records = rawRecords?.data?.map((item: VenueOrderData) => {
    return {
      ...item,
    };
  });

  const handleView = (order: any) => {
    setShowDetailParam({ order_id: order?.id });
    setShowDetail(true);
  };

  return (
    <Layout>
      <FullScreenDialog
        visible={showDetail}
        onHide={() => setShowDetail(false)}
      >
        <OrderDetails params={showDetailParam} close={setShowDetail} />
      </FullScreenDialog>
      {props?.close && (
        <Button link onClick={() => props.close(false)}>
          <img
            src={require("../../../assets/images/icon-back.png")}
            alt="Back icon"
            className="w-[34px] h-[34px]"
          />
        </Button>
      )}
      <TableHeader>
        <h1 className="mx-auto mb-4 text-center text-xl">{venue.name}</h1>
      </TableHeader>
      <div className="px-[17px] pt-[17px] card-cfg">
        <div className="flex flex-wrap">
          <div className="w-fit flex items-center bg-gradient-to-b from-[#B5F2FF] via-white to-white text-[#3EB2D7] text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] mb-[12px] mr-[15px] ring-1 ring-[#5BDDFA]">
            Total Sales
            <span className="text-[24px] ml-[8px]">
              {summary?.total_sales_paid != null
                ? formatCurrency(summary.total_sales_paid)
                : formatCurrency(0)}
            </span>
          </div>
        </div>
        <div className="w-fit flex flex-wrap flex-row justify-start items-center gap-x-2 mb-3 text-[12px] text-blue-500 border border-solid border-blue-500 h-[24px] rounded-[12px] pr-3 overflow-hidden">
          <div className="bg-blue-500 text-white py-1 px-2">
            <span
              className={"pi pi-info-circle"}
              style={{ fontSize: "14px" }}
            ></span>
          </div>
          <div>
            <span
              className={"pi pi-send pr-2"}
              style={{ fontSize: "12px", paddingLeft: 3 }}
            ></span>
            <label>Subscription</label>
          </div>
          <div>
            <span
              className={"pi pi-shopping-cart pr-2"}
              style={{ fontSize: "12px", paddingLeft: 3 }}
            ></span>
            <label>One Time Purchase</label>
          </div>
        </div>
        {loading && <TableSkeleton />}
        {!loading && records?.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="table-cfg">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Customer</th>
                    <th>Mobile</th>
                    <th>Agent</th>
                    <th>Total</th>
                    <th>Status</th>
                    <th>Venue</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((row: VenueOrderData) => {
                    return (
                      <tr key={`venue-listing-table-row-${row?.id}`}>
                        <td data-label="ID">{row?.id}</td>
                        <td data-label="Customer" className="capitalize">
                          {row?.customer?.full_name}
                        </td>
                        <td data-label="Mobile">
                          {row?.customer?.country_code} {row?.customer?.mobile}
                        </td>
                        <td data-label="Agent">
                          {row?.agents?.map((agent, index: number) => {
                            return (
                              <div key={`agent-${index}`}>
                                {agent?.primary === 1 ? "(P) " : "(S) "}
                                {agent?.agent?.full_name}
                                {" #"}
                                {agent?.agent?.agent_code}
                              </div>
                            );
                          })}
                        </td>
                        <td data-label="Total" className="text-right">
                          {row?.highest_subscription_month > 0 ? (
                            <span
                              className={
                                "pi pi-send md:pr-0 pr-2 md:float-left pt-[4px] text-[#da5d18]"
                              }
                              style={{ fontSize: "12px", paddingLeft: 3 }}
                            ></span>
                          ) : (
                            <span
                              className={
                                "pi pi-shopping-cart md:pr-0 pr-2 md:float-left pt-[4px]"
                              }
                              style={{ fontSize: "12px", paddingLeft: 3 }}
                            ></span>
                          )}
                          {formatCurrency(row?.total)}
                        </td>
                        <td data-label="Status" className="text-center">
                          <div
                            className={`${
                              row?.status === 1
                                ? "bg-green-300"
                                : row?.status === 0
                                ? "bg-gray-300"
                                : "bg-red-300 "
                            } px-[8px] py-[3px] rounded-[30px] inline text-xs w-fit lg:w-full`}
                          >
                            {row?.status === 1
                              ? "Paid"
                              : row?.status === 0
                              ? "Unpaid"
                              : "Failed"}
                          </div>
                        </td>
                        <td data-label="Venue" className="text-center">
                          <div className="whitespace-nowrap">
                            {row?.venue?.name ?? "NA"}
                          </div>
                        </td>
                        <td data-label="Date" className="text-center">
                          <div className="whitespace-nowrap">
                            {formatDate(row?.created_at)}
                          </div>
                        </td>
                        <td data-label="Action">
                          <button
                            className="flex ml-auto lg:mx-auto"
                            onClick={() => handleView(row)}
                          >
                            <img
                              src={require("../../../assets/images/icon-view.png")}
                              alt="View icon"
                              className="w-[24px]"
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            />
          </>
        )}
        {!loading && records?.length <= 0 && <EmptyData />}
      </div>
    </Layout>
  );
};

export default VenueOrders;

import React from "react";
import { Button } from "primereact/button";

const FilterButton = (props: any) => {
  return (
    <Button
      style={{
        border: 0,
        borderRadius: "10px",
        background: "#FFFFFF",
        padding: "9px 12px 9px 12px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        flexWrap: "nowrap",
        gap: "5px",
      }}
      {...props}
    >
      <span className="text-[#DA5E18] text-[12px] leading-[15px] font-[450]">
        Filter
      </span>
      <img
        src={require("../assets/images/icon-filter.png")}
        alt="Filter icon"
        className="h-[18px]"
      />
    </Button>
  );
};

export default FilterButton;

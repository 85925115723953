import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import Layout from "../../../components/Layout";
import useApiData from "../../../hooks/useApiData";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
// import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Checkbox } from "primereact/checkbox";
import { useNavigate } from "react-router-dom";
import AcceptButton from "../../../components/AcceptButton";
import AdminButton from "../../../components/AdminButton";
import CancelButton from "../../../components/CancelButton";
import EmptyData from "../../../components/EmptyData";
import FilterButton from "../../../components/FilterButton";
import FullScreenDialog from "../../../components/FullScreenDialog";
import TableFooter from "../../../components/TableFooter";
import TableHeader from "../../../components/TableHeader";
import TableSkeleton from "../../../components/TableSkeleton";
import { formatCurrency, isRole } from "../../../utils/Common";
import InventoryMovements from "../InventoryMovements";
import InventoryMovementForm from "./inventoryMovementForm";

type InventoryData = {
  category: {
    id: number;
    name: string;
    status: number;
  };
  source: {
    id: number;
    name: string;
  };
  category_id: number;
  cost_price: number;
  cost_price_formatted: number;
  created_at: string;
  id: number;
  image_url: string | null;
  detail_image_url: string | null;
  name: string;
  price: number;
  price_formatted: number;
  quantity: number;
  sku: string;
  free_gift: number;
  free_gift_id: number;
  free_gift_quantity: number;
  status: number;
  sub_category: {
    id: number;
    name: string;
    category_id: number;
    status: number;
  };
  low_stocks: number;
  sub_category_id: number;
  subscription: number;
  updated_at: string;
};

type UserSearchParams = {
  sku: any;
  product_name: any;
  category_id: any;
  sub_category_id: any;
  free_gift: any;
  subscription: any;
  product_barcode: any;
  low_stocks: any;
};

const Inventory = (formikValues: any) => {
  const navigate = useNavigate();
  const [stockVisible, setStockVisible] = useState(false);
  const [stockMovementProductId, setStockMovementProductId] = useState(0);
  const [showDetail, setShowDetail] = useState(false);
  const [showDetailParam, setShowDetailParam] = useState({});
  const {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  } = usePaginatedApiData<InventoryData, UserSearchParams>(
    "/admin/get_products",
    {
      sku: null,
      product_name: null,
      category_id: null,
      sub_category_id: null,
      free_gift: null,
      subscription: null,
      product_barcode: null,
      low_stocks: null,
    },
    1,
  );
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterSKU, setFilterSKU] = useState("");
  const [filterProductName, setFilterProductName] = useState("");
  const [filterCategoryName, setFilterCategoryName] = useState("");
  const [filterSubCategoryName, setFilterSubCategoryName] = useState("");
  const [filterFreeGift, setFilterFreeGift] = useState("");
  const [filterSubscription, setFilterSubscription] = useState("");
  const [filterBarcode, setFilterBarcode] = useState("");
  const [filterLowStocks, setFilterLowStocks] = useState<any>(null);
  const rawRecords = data as any;
  const records = rawRecords?.data?.map((item: InventoryData) => {
    return {
      ...item,
      ...{
        price_formatted: formatCurrency(item.price),
        cost_price_formatted: formatCurrency(item.cost_price),
      },
    };
  });
  const search = searchParams as UserSearchParams;

  const category = useApiData("/admin/get_categories");
  const categoryData = category.data as any;
  const categoryList = categoryData?.data.map((item: any) => {
    return { label: item?.name, value: item?.id };
  });
  const subCategory = useApiData(
    `/admin/get_category_by_id/${filterCategoryName || null}`,
  );
  const subCategoryData = subCategory.data as any;
  const subCategoryList = subCategoryData?.sub_category.map((item: any) => {
    return { label: item?.name, value: item?.id };
  }); // not right way to get data from API; just for testing onlyy

  const viewPage = async (data: any) => {
    console.log(data);
    if (!data.bundle) {
      const updatedFormikValues = {
        id: data.id,
        sku: data.sku,
        quantity: data.quantity,
        name: data.name,
        category_id: data.category_id,
        sub_category_id: data.sub_category_id,
        api_key_id: data.api_key_id,
        cost_price: data.cost_price,
        price: data.price,
        subscription: data.subscription,
        status: data.status,
        free_gift: data.free_gift,
        image_url: data.image_url || "",
        detail_image_url: data.detail_image_url || "",
        roast_id: data.roast_id,
        region_id: data.region_id,
        body: data.body,
        acidity: data.acidity,
        sweet: data.sweet,
        description: data.description,
        flavour: data.flavour,
        weight: data.weight,
        variety: data.variety,
        water_temp: data.water_temp,
        altitude: data.altitude,
        processing: data.processing,
        barcodes: data.barcodes,
        free_gift_id: data.free_gift_id ?? null,
        free_gift_quantity: data.free_gift_quantity ?? null,
      };
      // Use the navigate function to navigate to the /inventory-create route
      navigate("/inventory-create", {
        state: { formikValueInventory: updatedFormikValues, type: "Inventory" },
      });
    } else if (data?.pocket_coffee_boxes == null) {
      // bundle - Speciality Coffee
      const convertData = (data: any) => {
        // Convert items_detail from string to JSON object
        const itemsDetail = JSON.parse(data.bundle.items_detail);

        // Convert focs details from strings to JSON objects
        const bundleFocs = data?.bundle?.bundle_focs?.map((foc: any) => ({
          ...foc,
          items_detail: JSON.parse(foc.items_detail),
        }));

        const addOnPlanFocs = bundleFocs?.filter(
          (foc: any) => foc?.foc_type === "add_on_plan_foc",
        );

        // Convert bundle_add_ons details from strings to JSON objects
        const bundleAddOns = data?.bundle?.bundle_add_ons?.map(
          (addOn: any) => ({
            ...addOn,
            items_detail: JSON.parse(addOn.items_detail),
          }),
        );

        // Map data to match the initialValuesBundle structure
        return {
          id: data.id,
          image_url: data.image_url || "",
          detail_image_url: data.detail_image_url || "",
          status: data.status === 1,
          plan_name: data.name,
          sku: data.sku,
          api_key_id: data.api_key_id,
          subscription_option_id: data.bundle.subscription_option_id,
          stock: data.quantity.toString(),
          category_id: data.category_id,
          sub_category_id: data.sub_category_id,
          base_plan: {
            cost_price: data.cost_price,
            price: data.price,
            total_beans: data.bundle.total_beans,
            item_details: itemsDetail,
            ...(bundleFocs.find(
              (foc: any) => foc?.foc_type === "base_plan_foc",
            ) && {
              foc: {
                type: bundleFocs.find(
                  (foc: any) => foc?.foc_type === "base_plan_foc",
                ).foc_type,
                item_details: bundleFocs.find(
                  (foc: any) => foc?.foc_type === "base_plan_foc",
                ).items_detail,
              },
            }),
            ...(bundleFocs.find(
              (foc: any) => foc?.foc_type === "advance_foc",
            ) && {
              advance_foc: {
                type: bundleFocs.find(
                  (foc: any) => foc?.foc_type === "advance_foc",
                ).foc_type,
                minimum_bundle_purchase_qty: bundleFocs.find(
                  (foc: any) => foc?.foc_type === "advance_foc",
                ).minimum_bundle_purchase_qty,
                item_details: bundleFocs.find(
                  (foc: any) => foc?.foc_type === "advance_foc",
                ).items_detail,
              },
            }),
          },
          ...(bundleAddOns.length > 0 && {
            add_on_plans: bundleAddOns.map((addOn: any, index: any) => ({
              add_on_name: addOn.add_on_name,
              cost_price: addOn.cost_price,
              price: addOn.price,
              total_beans: addOn.total_beans,
              item_details: addOn.items_detail,
              ...(addOnPlanFocs[index] && {
                foc: {
                  type: addOnPlanFocs[index]?.foc_type,
                  item_details: addOnPlanFocs[index]?.items_detail,
                },
              }),
            })),
          }),
        };
      };
      const convertedData = convertData(data);

      navigate("/inventory-create", {
        // need pass data over
        state: { formikValueBundle: convertedData, type: "Bundle" },
      });
    } else if (data?.pocket_coffee_boxes != null) {
      // bundle - Pocket Coffee
      const convertData = (data: any) => {
        // Convert items_detail from string to JSON object
        const itemsDetail = JSON.parse(data.bundle.items_detail);

        // Convert focs details from strings to JSON objects
        const bundleFocs = data?.bundle?.bundle_focs?.map((foc: any) => ({
          ...foc,
          items_detail: JSON.parse(foc.items_detail),
        }));

        const addOnPlanFocs = bundleFocs?.filter(
          (foc: any) => foc?.foc_type === "add_on_plan_foc",
        );

        // Convert bundle_add_ons details from strings to JSON objects
        const bundleAddOns = data?.bundle?.bundle_add_ons?.map(
          (addOn: any) => ({
            ...addOn,
            items_detail: JSON.parse(addOn.items_detail),
          }),
        );

        // Map data to match the initialValuesBundlePocketCoffee structure
        return {
          id: data.id,
          image_url: data.image_url || "",
          detail_image_url: data.detail_image_url || "",
          status: data.status === 1,
          plan_name: data.name,
          sku: data.sku,
          api_key_id: data.api_key_id,
          subscription_option_id: data.bundle.subscription_option_id,
          stock: data.quantity.toString(),
          category_id: data.category_id,
          sub_category_id: data.sub_category_id,
          base_plan: {
            cost_price: data.cost_price,
            price: data.price,
            pocket_coffee_boxes: data.pocket_coffee_boxes,
            // total_beans: data.bundle.total_beans,
            item_details: itemsDetail,
            ...(bundleFocs.find(
              (foc: any) => foc?.foc_type === "base_plan_foc",
            ) && {
              foc: {
                type: bundleFocs.find(
                  (foc: any) => foc?.foc_type === "base_plan_foc",
                ).foc_type,
                item_details: bundleFocs.find(
                  (foc: any) => foc?.foc_type === "base_plan_foc",
                ).items_detail,
              },
            }),
            ...(bundleFocs.find(
              (foc: any) => foc?.foc_type === "advance_foc",
            ) && {
              advance_foc: {
                type: bundleFocs.find(
                  (foc: any) => foc?.foc_type === "advance_foc",
                ).foc_type,
                minimum_bundle_purchase_qty: bundleFocs.find(
                  (foc: any) => foc?.foc_type === "advance_foc",
                ).minimum_bundle_purchase_qty,
                item_details: bundleFocs.find(
                  (foc: any) => foc?.foc_type === "advance_foc",
                ).items_detail,
              },
            }),
          },
          ...(bundleAddOns.length > 0 && {
            add_on_plans: bundleAddOns.map((addOn: any, index: any) => ({
              add_on_name: addOn.add_on_name,
              cost_price: addOn.cost_price,
              price: addOn.price,
              pocket_coffee_boxes: addOn.pocket_coffee_boxes,
              // total_beans: addOn.total_beans,
              item_details: addOn.items_detail,
              ...(addOnPlanFocs[index] && {
                foc: {
                  type: addOnPlanFocs[index]?.foc_type,
                  item_details: addOnPlanFocs[index]?.items_detail,
                },
              }),
            })),
          }),
        };
      };
      const convertedData = convertData(data);

      navigate("/inventory-create", {
        // need pass data over
        state: {
          formikValueBundlePocketCoffee: convertedData,
          type: "Bundle-PocketCoffee",
        },
      });
    }
  };

  if (error) return <div>Error: {error.message}</div>;

  const handleLowStock = (event: any) => {
    if (event.checked) {
      setFilterLowStocks(true);
    } else {
      setFilterLowStocks(null);
    }
  };

  return (
    <Layout>
      <FullScreenDialog
        visible={showDetail}
        onHide={() => setShowDetail(false)}
      >
        <InventoryMovements params={showDetailParam} close={setShowDetail} />
      </FullScreenDialog>
      <Dialog
        header="Filter"
        position="bottom"
        visible={filterVisible}
        style={{
          width: "100vw",
          maxWidth: "450px",
          margin: 0,
          border: 0,
        }}
        onHide={() => setFilterVisible(false)}
      >
        <div className="mb-[10px]">
          <label htmlFor="filter-sku" className="mb-[5px] block">
            SKU
          </label>
          <div>
            <InputText
              id="filter-sku"
              className="w-full"
              value={filterSKU}
              onChange={(e) => setFilterSKU(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-product-name" className="mb-[5px] block">
            Product Name
          </label>
          <div>
            <InputText
              id="filter-product-name"
              className="w-full"
              value={filterProductName}
              onChange={(e) => setFilterProductName(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-customer-name" className="mb-[5px] block">
            Product Barcode
          </label>
          <div>
            <InputText
              id="filter-customer-name"
              className="w-full"
              value={filterBarcode}
              onChange={(e) => setFilterBarcode(e?.target?.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-category-name" className="mb-[5px] block">
            Category Name
          </label>
          <div>
            <Dropdown
              id="filter-category-name"
              optionLabel="label"
              value={filterCategoryName}
              options={categoryList}
              onChange={(e) => setFilterCategoryName(e?.value)}
              className="w-full"
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-sub-category-name" className="mb-[5px] block">
            Sub Category Name
          </label>
          <div>
            <Dropdown
              id="filter-sub-category-name"
              optionLabel="label"
              value={filterSubCategoryName}
              options={subCategoryList}
              onChange={(e) => setFilterSubCategoryName(e.target.value)}
              className="w-full"
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-free-gift" className="mb-[5px] block">
            Free Gift
          </label>
          <div>
            <Dropdown
              id="filter-free-gift"
              optionLabel="label"
              value={filterFreeGift}
              options={[
                { label: "Yes", value: "1" },
                { label: "No", value: "0" },
              ]}
              onChange={(e) => setFilterFreeGift(e.target.value)}
              className="w-full"
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-subscription" className="mb-[5px] block">
            Subscription
          </label>
          <div>
            <Dropdown
              id="filter-subscription"
              optionLabel="label"
              value={filterSubscription}
              options={[
                { label: "Yes", value: "1" },
                { label: "No", value: "0" },
              ]}
              onChange={(e) => setFilterSubscription(e.target.value)}
              className="w-full"
            />
          </div>
        </div>
        <div className="flex items-center mb-[10px]">
          <Checkbox
            id="filter-low-stock"
            checked={filterLowStocks}
            onChange={handleLowStock}
          />
          <label htmlFor="filter-low-stock" className="ml-2">
            Low Stock
          </label>
        </div>
        <div className="flex flex-row justify-between items-center pt-[10px]">
          <CancelButton
            label="Reset"
            onClick={() => {
              setFilterSKU("");
              setFilterProductName("");
              setFilterCategoryName("");
              setFilterFreeGift("");
              setFilterSubscription("");
              setFilterBarcode("");
              setFilterLowStocks(null);
              handleSearchParamsChange({
                sku: "",
                product_name: "",
                category_id: "",
                sub_category_id: "",
                free_gift: "",
                product_barcode: "",
                low_stocks: null,
              });
              setFilterVisible(false);
            }}
          />
          <AcceptButton
            label="Apply"
            onClick={() => {
              handleSearchParamsChange({
                sku: filterSKU,
                product_name: filterProductName,
                category_id: filterCategoryName,
                sub_category_id: filterSubCategoryName,
                free_gift: filterFreeGift,
                subscription: filterSubscription,
                product_barcode: filterBarcode,
                low_stocks: filterLowStocks,
              });
              setFilterVisible(false);
            }}
          />
        </div>
      </Dialog>
      <InventoryMovementForm
        product_id={stockMovementProductId}
        stockVisible={stockVisible}
        setStockVisible={setStockVisible}
        handleReload={handleReload}
      />
      <TableHeader>
        <FilterButton
          onClick={() => setFilterVisible(true)}
          className="mb-[5px] mr-[10px]"
        />
        <AdminButton
          label="Create"
          onClick={() => {
            // Use the navigate function to navigate to the /inventory-create route
            navigate("/inventory-create", {
              state: { type: "Create" },
            });
          }}
        />
      </TableHeader>
      <div className="px-[17px] pt-[17px] card-cfg">
        {loading && <TableSkeleton />}
        {!loading && records?.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="table-cfg">
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>Name</th>
                    {/* <th>Source</th> */}
                    <th>Stock</th>
                    {!isRole(["Warehouse"]) && <th>Selling Price</th>}
                    <th>Subscription</th>
                    <th>Free Gift</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((row: InventoryData) => {
                    return (
                      <tr key={`inventory-table-row-${row.id}`}>
                        <td data-label="SKU">{row.sku}</td>
                        <td data-label="Name">
                          <div className="flex items-center gap-2 justify-end lg:justify-start w-[200px]">
                            <img
                              src={
                                row.image_url ||
                                require("../../../assets/images/order/no-image.png")
                              }
                              alt=""
                              className="w-[40px] h-[40px] rounded object-cover shrink-0"
                            />
                            <div>
                              <label>{row.name}</label>
                              <p className="text-xs">
                                {row.category.name}{" "}
                                {row.sub_category?.name
                                  ? " - " + row.sub_category?.name
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </td>
                        {/* <td data-label="Source">{row.source?.name}</td> */}
                        <td
                          data-label="Stock"
                          className="text-center relative overflow-hidden"
                        >
                          <Button
                            className="p-0 text-sm"
                            link
                            label={row.quantity.toString()}
                            onClick={() => {
                              setStockMovementProductId(row.id);
                              setStockVisible(true);
                            }}
                          />
                          {row?.low_stocks && (
                            <div className="text-xs px-[25px] py-[3px] bg-[#d86565] text-white rounded rotate-[-45deg] absolute left-[-22px] top-[3px]">
                              LOW
                            </div>
                          )}
                        </td>
                        {!isRole(["Warehouse"]) && (
                          <td
                            data-label="Selling Price"
                            className="text-center"
                          >
                            {row.price_formatted}
                          </td>
                        )}
                        <td data-label="Subscription" className="text-center">
                          {row.subscription === 1 ? "Yes" : "No"}
                        </td>
                        <td data-label="Free Gift" className="text-center">
                          {row.free_gift === 1 ? "Yes" : "No"}
                        </td>
                        <td data-label="Status" className="text-center">
                          <div
                            className={`${
                              row.status === 1 ? "bg-green-300" : "bg-red-300"
                            } px-[8px] py-[3px] rounded-[30px] inline text-xs whitespace-nowrap w-fit lg:w-full`}
                          >
                            {row.status === 1 ? "Active" : "In-active"}
                          </div>
                        </td>
                        <td data-label="Action">
                          <div className="flex flex-wrap gap-3 justify-end lg:justify-start">
                            <Button
                              className="p-0 text-sm shrink-0"
                              text
                              onClick={() => viewPage(row)}
                            >
                              <img
                                src={require("../../../assets/images/icon-view.png")}
                                alt="View icon"
                                className="w-[24px]"
                              />
                            </Button>
                            <Button
                              className="p-0 text-sm shrink-0"
                              text
                              label="Restock"
                              onClick={() => {
                                setStockMovementProductId(row.id);
                                setStockVisible(true);
                              }}
                            />
                            <Button
                              className="p-0 text-sm shrink-0"
                              text
                              label="Movements"
                              onClick={() => {
                                setShowDetailParam({ product: row });
                                setShowDetail(true);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            />
          </>
        )}
        {!loading && records?.length <= 0 && <EmptyData />}
      </div>
    </Layout>
  );
};

export default Inventory;

import React, { useEffect, useState } from "react";
import { HeaderType, Inventory, InventoryGroup } from "../../interfaces";
import Layout from "../../components/Layout";
import { Button } from "primereact/button";
import { useAppSelector } from "../../hooks/useStore";
import PurchaseOptionsDialog from "../../components/PurchaseOptionsDialog";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { get } from "../../utils/Api";
import AcceptButton from "../../components/AcceptButton";
import { formatCurrency } from "../../utils/Common";
// import GridView from "./GridView";
// import { useDispatch } from "react-redux";
// import { setCartItems } from "../../redux/slices/orderSlice";
// import usePaginatedApiData from "../../hooks/usePaginatedApiData";
// import ListView from "./ListView";

// type InventorySearchParams = {
//   category_id: number | null;
//   subscription: number | null;
// };

const Plan = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [viewType, setViewType] = useState("grid");
  // const toggleViewType = (newViewType: string) => {
  //   setViewType(newViewType);
  // };
  const { cartItems, purchaseOptions, orderInfo, cart } = useAppSelector(
    (state) => state.order,
  );
  const [purchaseOptionVisible, setPurchaseOptionVisible] = useState(false);
  const [selectedPurchaseOption, setSelectedPurchaseOption] = useState(
    orderInfo?.purchaseOption?.length > 0 &&
      orderInfo?.purchaseOption[0]?.id === 2
      ? "Subscription"
      : orderInfo?.purchaseOption?.length > 0 &&
        orderInfo?.purchaseOption[0]?.id === 1
      ? "One Time Purchase"
      : "Plan",
  );
  const [plans, setPlans] = useState<any[]>([]);

  useEffect(() => {
    const getPlans = async () => {
      try {
        const response = await get(`/product/get_plans`);
        if (response.success && response.data) {
          setPlans(
            response.data?.plans?.map((plan: any) => {
              return {
                ...plan,
                ...{
                  bundle: {
                    ...plan?.bundle,
                    ...{
                      bundle_add_ons: plan?.bundle?.bundle_add_ons?.map(
                        (bundle_add_on: any) => {
                          return {
                            ...bundle_add_on,
                            ...{
                              items_detail: JSON.parse(
                                bundle_add_on?.items_detail,
                              )?.map((item: any) => {
                                const sub_category =
                                  response.data?.sub_categories?.filter(
                                    (category: any) =>
                                      category?.id === item?.sub_category_id,
                                  );
                                return {
                                  ...item,
                                  ...{
                                    sub_category: sub_category[0]?.name,
                                  },
                                };
                              }),
                              price_formatted: formatCurrency(
                                bundle_add_on?.price,
                              ),
                              price_diff_formatted: formatCurrency(
                                bundle_add_on?.price - plan?.price,
                              ),
                              quantity: 1,
                            },
                          };
                        },
                      ),
                      bundle_focs: plan?.bundle?.bundle_focs?.map(
                        (bundle_foc: any) => {
                          return {
                            ...bundle_foc,
                            ...{
                              items_detail: JSON.parse(
                                bundle_foc?.items_detail,
                              )?.map((item: any) => {
                                const sub_category =
                                  response.data?.sub_categories?.filter(
                                    (category: any) =>
                                      category?.id === item?.sub_category_id,
                                  );
                                return {
                                  ...item,
                                  ...{
                                    sub_category: sub_category[0]?.name,
                                  },
                                };
                              }),
                            },
                          };
                        },
                      ),
                      items_detail: JSON.parse(plan?.bundle?.items_detail)?.map(
                        (item: any) => {
                          const sub_category =
                            response.data?.sub_categories?.filter(
                              (category: any) =>
                                category?.id === item?.sub_category_id,
                            );
                          return {
                            ...item,
                            ...{
                              sub_category: sub_category[0]?.name,
                            },
                          };
                        },
                      ),
                    },
                  },
                  price_formatted: formatCurrency(plan?.price),
                  delivery_option_id: 2, // set default delivery option for all items
                  quantity: 1,
                },
              };
            }),
          );
        } else {
          toast.error("Something went wrong!");
        }
      } catch (error: any) {
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    };
    getPlans();
  }, []);

  const headerButton = (
    <div className="w-full h-[70px] bg-[#DA5E18] text-white flex justify-between px-5">
      <Button
        link
        className="flex flex-row items-center flex-nowrap h-full"
        onClick={() => setPurchaseOptionVisible(true)}
      >
        <span className="text-[20px] text-white">{selectedPurchaseOption}</span>
        <img
          src={require("../../assets/images/icon-arrow-down-white.png")}
          alt="Down icon"
          className="w-[24px] h-[24px] ml-[5px]"
        />
      </Button>
      <div className="flex items-center justify-end"></div>
    </div>
  );

  const KnobProgress = () => {
    return (
      <img
        src={require("../../assets/images/chart-step-1.png")}
        alt="Chart step 1"
        className="w-[55px] h-[55px]"
      />
    );
  };
  const handlePurchaseOptionSelection = (name: any) => {
    navigate("/new-order", { replace: true, state: { selection: name } });
  };

  return (
    <Layout
      headerType={HeaderType.back}
      pageTitle={"New Order"}
      headerButton={headerButton}
      stickyHeaderFooter={true}
      rightSideIcon={<KnobProgress />}
    >
      <PurchaseOptionsDialog
        selected={selectedPurchaseOption}
        visible={purchaseOptionVisible}
        onHide={() => {
          setPurchaseOptionVisible(false);
        }}
        onSelect={handlePurchaseOptionSelection}
      />
      {plans.length > 0 && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-2">
          {plans.map((plan: any) => (
            <div
              className="min-h-[420px] bg-[#F1F1F1] border border-[#FF6A16] p-4 rounded-[15px] shadow-lg flex flex-col justify-between items-center gap-y-[30px]"
              key={`new-order-plan-list-${plan.id}`}
            >
              <div className="text-center w-full">
                <div className="h-[140px] w-[140px] rounded-[5px] overflow-hidden mx-auto mb-4">
                  <img
                    src={
                      plan?.image_url ||
                      require("../../assets/images/order/no-image.png")
                    }
                    alt=""
                    className="object-cover"
                  />
                </div>
                <h1>{plan?.name}</h1>
                {plan?.bundle && (
                  <>
                    {plan.bundle?.subscription_option?.subscription_month && (
                      <div>
                        {plan.bundle.subscription_option.subscription_month}{" "}
                        months
                      </div>
                    )}
                    <hr className="bg-[#9b9b9b] my-4" />
                    {plan.bundle?.items_detail.map(
                      (item: any) =>
                        item?.quantity > 0 && (
                          <div
                            key={`new-order-plan-item-list-${item?.sub_category_id}`}
                          >
                            {item?.quantity} x {item?.sub_category}
                          </div>
                        ),
                    )}
                  </>
                )}
                <p className="text-base font-bold text-[#DA5E18] mt-4">
                  {plan?.price_formatted}
                </p>
              </div>
              {plan.available_stocks > 0 ? (
                <AcceptButton
                  label="Select"
                  className="w-full"
                  onClick={() => {
                    navigate("/plan-addons", {
                      state: {
                        plan: plan,
                      },
                    });
                  }}
                />
              ) : (
                <p className="bg-[#DA5E18] rounded-[7px] p-2 h-[30px] leading-[15px] min-w-[144px] text-sm text-center font-bold m-auto text-[#fff]">
                  Out of stock
                </p>
              )}
            </div>
          ))}
        </div>
      )}
    </Layout>
  );
};

export default Plan;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Password } from "primereact/password";
import ValidationError from "../../components/ValidationError";
import AcceptButton from "../../components/AcceptButton";
import { post } from "../../utils/Api";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const ResetPassword = () => {
  const { height } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const email = useLocation()?.state?.email;
  const formik = useFormik({
    initialValues: {
      email: email,
      password: "",
      password_confirmation: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .required("Password is required"),
      password_confirmation: Yup.string().oneOf(
        [Yup.ref("password"), ""],
        "Passwords must match",
      ),
    }),
    onSubmit: async (values) => {
      const { email, password, password_confirmation } = values;
      try {
        setLoading(true);
        const response = await post("/password/reset", {
          email,
          password,
          password_confirmation,
        });
        setLoading(false);
        if (response?.success) {
          navigate("/password-changed-success", { replace: true });
        }
      } catch (error: any) {
        setLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  return (
    <div
      className="flex bg-[#FFEDD7] md:bg-[#F1F1F1] md:bg-ipad-background-image md:bg-cover md:bg-center md:bg-no-repeat"
      style={{ height: `${height}px` }}
    >
      <div className="w-full max-w-xs m-auto">
        <header className="flex flex-col items-center">
          <img
            src={require("../../assets/images/logo-cfg-circle.png")}
            alt="CFG Logo"
            className="h-[131px] mx-auto mb-[30px]"
          />
        </header>
        <form onSubmit={formik.handleSubmit}>
          <div className="pb-[20px]">
            <Password
              className={`w-full ${
                formik.touched.password && formik.errors.password
                  ? "p-invalid"
                  : ""
              }`}
              name="password"
              placeholder="New Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              // toggleMask
              feedback={false}
              inputStyle={{ width: "100%" }}
            />
            <ValidationError
              className={
                formik.touched.password && formik.errors.password
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.password}
            </ValidationError>
          </div>
          <div className="pb-[20px]">
            <Password
              className={`w-full ${
                formik.touched.password_confirmation &&
                formik.errors.password_confirmation
                  ? "p-invalid"
                  : ""
              }`}
              name="password_confirmation"
              placeholder="Re-enter New Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              // toggleMask
              feedback={false}
              inputStyle={{ width: "100%" }}
            />
            <ValidationError
              className={
                formik.touched.password_confirmation &&
                formik.errors.password_confirmation
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.password_confirmation}
            </ValidationError>
          </div>
          <div className="pb-[20px]">
            <AcceptButton
              label="Confirm"
              loading={loading}
              className="w-full"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;

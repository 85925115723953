import Layout from "../../../components/Layout";
import TableHeader from "../../../components/TableHeader";

const Permissions = () => {
  return (
    <Layout pageTitle={"Permissions"}>
      <TableHeader></TableHeader>
      <div className="p-[17px] card-cfg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <div className="rounded-lg p-5 bg-black/[.05]">
            <h1 className="text-lg text-[#DA5E18]">Admin</h1>
            <ul className="list-inside list-disc">
              Permissions
              <li>View permissions</li>
            </ul>
            <ul className="list-inside list-disc">
              Venues
              <li>View venue listings</li>
              <li>Create/Update venue</li>
            </ul>
            <ul className="list-inside list-disc">
              Settings
              <li>View settings table</li>
              <li>Update settings variable</li>
            </ul>
            <ul className="list-inside list-disc">
              Orders
              <li>View order listings</li>
              <li>View order details</li>
              <li>Update shipment status</li>
              <li>View subscription listings</li>
              <li>View shipment listings</li>
              <li>Delete unpaid orders</li>
              <li>Manually mark orders as paid</li>
              <li>Send invoice</li>
            </ul>
            <ul className="list-inside list-disc">
              Customers
              <li>View customer listings</li>
              <li>View customer details</li>
            </ul>
            <ul className="list-inside list-disc">
              Inventory
              <li>Create/Update inventory</li>
              <li>View inventory listings</li>
              <li>Manage stock movement</li>
            </ul>
          </div>
          <div className="rounded-lg p-5 bg-black/[.05]">
            <h1 className="text-lg text-[#DA5E18]">Manager</h1>
            <ul className="list-inside list-disc">
              Permissions
              <li>View permissions</li>
            </ul>
            <ul className="list-inside list-disc">
              Venues
              <li>View venue listings</li>
              <li>Create/Update venue</li>
            </ul>
            <ul className="list-inside list-disc">
              Settings
              <li>View settings table</li>
              <li>Update settings variable</li>
            </ul>
            <ul className="list-inside list-disc">
              Orders
              <li>View order listings</li>
              <li>View order details</li>
              <li>Update shipment status</li>
              <li>View subscription listings</li>
              <li>View shipment listings</li>
              <li>Delete unpaid orders</li>
              <li>Manually mark orders as paid</li>
            </ul>
            <ul className="list-inside list-disc">
              Customers
              <li>View customer listings</li>
              <li>View customer details</li>
            </ul>
            <ul className="list-inside list-disc">
              Inventory
              <li>Create/Update inventory</li>
              <li>View inventory listings</li>
              <li>Manage stock movement</li>
            </ul>
          </div>
          <div className="rounded-lg p-5 bg-black/[.05]">
            <h1 className="text-lg text-[#DA5E18]">Warehouse</h1>
            <ul className="list-inside list-disc">
              Orders
              <li>View order listings without pricing</li>
              <li>View order details without pricing</li>
              <li>Update shipment status</li>
              <li>View subscription listings</li>
              <li>View shipment listings</li>
            </ul>
            <ul className="list-inside list-disc">
              Inventory
              <li>Create/Update inventory without pricing</li>
              <li>View inventory listings without pricing</li>
              <li>Manage stock movement</li>
            </ul>
          </div>
          <div className="rounded-lg p-5 bg-black/[.05]">
            <h1 className="text-lg text-[#DA5E18]">Agent Leader</h1>
            <ul className="list-inside list-disc">
              Venues
              <li>View venue listings</li>
              <li>Create/Update venue</li>
            </ul>
            <ul className="list-inside list-disc">
              Orders
              <li>Create new order</li>
              <li>View order listings</li>
              <li>View order details</li>
              <li>Add secondary agent</li>
              <li>Send invoice</li>
            </ul>
            <ul className="list-inside list-disc">
              Customers
              <li>View own customer listings</li>
              <li>View own customer details</li>
            </ul>
          </div>
          <div className="rounded-lg p-5 bg-black/[.05]">
            <h1 className="text-lg text-[#DA5E18]">Agent</h1>
            <ul className="list-inside list-disc">
              Orders
              <li>Create new order</li>
              <li>View own order listings</li>
              <li>View own order details</li>
              <li>View own subscription listings</li>
              <li>Send invoice</li>
            </ul>
            <ul className="list-inside list-disc">
              Customers
              <li>View own customer listings</li>
              <li>View own customer details</li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Permissions;

import React, { useState } from "react";
import Layout from "../../components/Layout";
import { store } from "../../redux/store";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { post } from "../../utils/Api";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Password } from "primereact/password";
import ValidationError from "../../components/ValidationError";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginSuccess, logout } from "../../redux/slices/authSlice";
import AcceptButton from "../../components/AcceptButton";
import CountryCodeDropdown from "../../components/CountryCodeDropdown";

const Profile = () => {
  const [visible, setVisible] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  // const [profileImage, setProfileImage] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState(""); // test

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const handleImageChange = (event: any) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const imageUrl = URL.createObjectURL(file);
  //     setProfileImage(imageUrl);
  //   }
  // };

  const handleLogout = () => {
    setUserLoading(false);
    dispatch(logout());
    navigate("/login", { replace: true });
  };

  // const FILE_SIZE = 1024 * 1024;
  // const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const onFileUpload = async (event: any) => {
    const file = event?.target?.files[0];
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = function () {
        const base64data = reader.result;
        setSelectedFile(base64data as string);
      };
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: store.getState().userAuth?.user?.first_name || "",
      last_name: store.getState().userAuth?.user?.last_name || "",
      email: store.getState().userAuth?.user?.email,
      country_code: "65",
      mobile: (store.getState().userAuth?.user?.mobile ?? "") as string,
      image_url: store.getState().userAuth?.user?.image_url || null,
    },
    validationSchema: Yup.object({
      //   image_url: Yup.mixed()
      //     .nullable()
      //     .notRequired()
      //     .test("fileSize", "File too large", (value: any) => {
      //       // Check file size if value is not null
      //       if (value) {
      //         return value.size <= FILE_SIZE;
      //       }
      //       return true; // Return true if no file is uploaded
      //     })
      //     .test(
      //       "fileFormat",
      //       "Unsupported Format",
      //       (value: any) => value && SUPPORTED_FORMATS.includes(value?.type),
      //     ),
      mobile: Yup.string()
        .min(8, "Invalid Mobile")
        // .matches(/^[0-9]{1,10}$/, "Mobile is not valid")
        .required("Mobile is required"),
      first_name: Yup.string()
        .max(16, "First name should not exceed 16 characters")
        .matches(/^[a-zA-Z\s]+$/, "Invalid first name")
        .required("First name is required"),
      last_name: Yup.string()
        .max(16, "Last name should not exceed 16 characters")
        .matches(/^[a-zA-Z\s]+$/, "Invalid first name")
        .required("Last name is required"),
      // email: Yup.string().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Invalid email address').required('Email is required'),
    }),
    onSubmit: async (values) => {
      const { first_name, last_name, mobile, image_url } = values;
      const file: any = image_url;
      try {
        setUserLoading(true);
        const formData = new FormData();
        if (typeof file === "object" && file !== null) {
          formData.append("image_url", file);
        }
        formData.append("first_name", first_name);
        formData.append("last_name", last_name);
        formData.append("mobile", mobile);
        const response = await post("/update_profile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setUserLoading(false);
        if (response?.success) {
          toast.success(response?.message);
          setVisible(false);
          setSelectedFile("");
          dispatch(
            loginSuccess({
              ...store.getState().userAuth.user,
              ...response.data,
            }),
          );
          formik.resetForm();
        }
      } catch (error: any) {
        setUserLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  const password = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string()
        .min(8, "Password should be 8-16 characters")
        .max(16, "Password should be 8-16 characters")
        .required("Current Password is required"),
      newPassword: Yup.string().required("New Password is required"),
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&[\]<>()'"_\-.,;:/}{|~+=#^`])[A-Za-z\d@$!%*?&[\]<>()'"_\-.,:;/|}{~+=#^`]{8,16}$/,
      //   "Use 8-16 characters with a mix of uppercase and lowercase of letters, numbers & symbols.",
      // ),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match")
        .required("Confirm New Password is required"),
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&[\]<>()'"_\-.,;:/}{|~+=#^`])[A-Za-z\d@$!%*?&[\]<>()'"_\-.,:;/|}{~+=#^`]{8,16}$/,
      //   "Use 8-16 characters with a mix of uppercase and lowercase of letters, numbers & symbols.",
      // ),
    }),
    onSubmit: async (values) => {
      const { currentPassword, newPassword, confirmNewPassword } = values;
      try {
        if (newPassword === confirmNewPassword) {
          setUserLoading(true);
          const response = await post("/change_password", {
            current_password: currentPassword,
            password: newPassword,
            password_confirmation: confirmNewPassword,
          });
          setUserLoading(false);
          if (response?.success) {
            toast.success(
              "Password updated successfully. Please login with the new password.",
            );
            handleLogout();
          } else {
            setUserLoading(false);
            console.error(response);
          }
        } else {
          console.error("Passwords do not match");
        }
      } catch (error: any) {
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
        setUserLoading(false);
        console.error(error);
      }
    },
  });

  return (
    <Layout>
      <Dialog
        header="Update Password"
        visible={visible}
        style={{ color: "#DA5E18", border: 0 }}
        onHide={() => {
          setVisible(false);
        }}
      >
        <form onSubmit={password.handleSubmit}>
          <label>Current Password</label>
          <div className="pb-[15px]">
            <Password
              className={`w-full ${
                password.touched.currentPassword &&
                password.errors.currentPassword
                  ? "p-invalid"
                  : ""
              }`}
              name="currentPassword"
              onChange={password.handleChange}
              onBlur={password.handleBlur}
              // toggleMask
              feedback={false}
              inputStyle={{ width: "100%" }}
            />
            <ValidationError
              className={
                password.touched.currentPassword &&
                password.errors.currentPassword
                  ? "visible"
                  : "invisible"
              }
            >
              {password.errors.currentPassword}
            </ValidationError>
          </div>
          <div className="pb-[15px]">
            <label>New Password</label>
            <Password
              className={`w-full ${
                password.touched.newPassword && password.errors.newPassword
                  ? "p-invalid"
                  : ""
              }`}
              name="newPassword"
              onChange={password.handleChange}
              onBlur={password.handleBlur}
              // toggleMask
              feedback={false}
              inputStyle={{ width: "100%" }}
            />
            <ValidationError
              className={
                password.touched.newPassword && password.errors.newPassword
                  ? "visible"
                  : "invisible"
              }
            >
              {password.errors.newPassword}
            </ValidationError>
          </div>
          <div className="pb-[15px]">
            <label>Confirm New Password</label>
            <Password
              className={`w-full ${
                password.touched.confirmNewPassword &&
                password.errors.confirmNewPassword
                  ? "p-invalid"
                  : ""
              }`}
              name="confirmNewPassword"
              onChange={password.handleChange}
              onBlur={password.handleBlur}
              // toggleMask
              feedback={false}
              inputStyle={{ width: "100%" }}
            />
            <ValidationError
              className={
                password.touched.confirmNewPassword &&
                password.errors.confirmNewPassword
                  ? "visible"
                  : "invisible"
              }
            >
              {password.errors.confirmNewPassword}
            </ValidationError>
          </div>
          <AcceptButton className="w-full" type="submit" label="Update" />
        </form>
      </Dialog>
      <div className="max-w-lg mx-auto">
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col items-center mb-[20px]">
            <div className="w-[115px] h-[115px] relative">
              {selectedFile ? (
                <div className="w-full h-full">
                  <img
                    src={selectedFile}
                    alt="Preview"
                    className="w-full h-full object-fill rounded-full border-2 border-gray-300 hover:border-gray-400 focus:outline-none focus:border-blue-400 transition duration-300"
                  />
                  <button
                    className="absolute top-[0px] right-[15px] rounded-[50%] bg-gradient-to-b from-[#FFD3AB] to-[#FFF6E3"
                    onClick={() => {
                      formik.setFieldValue("image_url", null);
                      setSelectedFile("");
                    }}
                  >
                    <img
                      src={require("../../assets/images/icon-close.png")}
                      alt="Remove icon"
                      className="w-[25px]"
                    />
                  </button>
                </div>
              ) : (
                <>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    id="image_url"
                    name="image_url"
                    hidden
                    onChange={(e: any) => {
                      onFileUpload(e);
                      formik.setFieldValue("image_url", e?.target?.files[0]);
                    }}
                  />
                  <label
                    htmlFor="image_url"
                    className="w-full h-full object-fill rounded-full border-gray-300 hover:border-gray-400 focus:outline-none focus:border-blue-400 transition duration-300"
                  >
                    <img
                      src={
                        formik.values.image_url ||
                        require("../../assets/images/icon-add-image.png")
                      }
                      alt="Add icon"
                      className="w-full h-full object-fill rounded-full border-gray-300 hover:border-gray-400 focus:outline-none focus:border-blue-400 transition duration-300"
                    />
                  </label>
                </>
              )}
            </div>
          </div>
          <div className="p-[30px] card-cfg my-[40px] max-w-2xl mx-auto">
            <div className="pb-[15px]">
              <div className="flex flex-row justify-start items-center flex-nowrap gap-x-2">
                <CountryCodeDropdown
                  className={`w-[100px] ${
                    formik.touched.country_code && formik.errors.country_code
                      ? "p-invalid"
                      : ""
                  }`}
                  name="country_code"
                  value={formik.values.country_code || "65"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled
                />
                <InputText
                  className={`w-full ${
                    formik.touched.mobile && formik.errors.mobile
                      ? "p-invalid"
                      : ""
                  }`}
                  name="mobile"
                  placeholder="Mobile"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <ValidationError
                className={
                  formik.touched.mobile && formik.errors.mobile
                    ? "visible"
                    : "invisible"
                }
              >
                {formik.errors.mobile}
              </ValidationError>
            </div>
            <div className="pb-[15px]">
              <InputText
                className={`w-full ${
                  formik.touched.first_name && formik.errors.first_name
                    ? "p-invalid"
                    : ""
                }`}
                name="first_name"
                placeholder="First Name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <ValidationError
                className={
                  formik.touched.first_name && formik.errors.first_name
                    ? "visible"
                    : "invisible"
                }
              >
                {formik.errors.first_name}
              </ValidationError>
            </div>
            <div className="pb-[15px]">
              <InputText
                className={`w-full ${
                  formik.touched.last_name && formik.errors.last_name
                    ? "p-invalid"
                    : ""
                }`}
                name="last_name"
                placeholder="Last Name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <ValidationError
                className={
                  formik.touched.last_name && formik.errors.last_name
                    ? "visible"
                    : "invisible"
                }
              >
                {formik.errors.last_name}
              </ValidationError>
            </div>
            <div className="pb-[10px]">
              <InputText
                className={`w-full ${
                  formik.touched.email && formik.errors.email ? "p-invalid" : ""
                } `}
                name="email"
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled
              />
              <label
                className={`pt-[5px] text-[10px] text-[#FF0000] flex justify-start items-center ${
                  formik.touched.email && formik.errors.email
                    ? "visible"
                    : "invisible"
                }`}
              >
                {formik.errors.email}
              </label>
            </div>
            <div className="text-[#C54700]">
              <label
                className="hover:underline cursor-pointer"
                onClick={() => setVisible(true)}
              >
                Change Password
              </label>
            </div>
            <AcceptButton
              label="Update"
              className="mt-[20px] ml-auto block"
              loading={userLoading}
            />
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default Profile;

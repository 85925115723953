import { Role } from "../interfaces";
import { logout } from "../redux/slices/authSlice";
import { store } from "../redux/store";
import { ConfirmDialogProps, confirmDialog } from "primereact/confirmdialog";
import { format, parseISO } from "date-fns";

export const formatCurrency = (amount: number | string) => {
  const numericAmount =
    typeof amount === "string" ? parseFloat(amount) : amount;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numericAmount);
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

export const formatTime = (dateString: string) => {
  if (dateString) {
    const date = parseISO(dateString);
    return format(date, "hh:mm a");
  }
};

export const isRole = (
  check_roles: string[],
  role_id: number | null = null,
) => {
  const roles = store.getState().common.roles;
  const current_role_id = role_id
    ? role_id
    : store.getState().userAuth.user?.role_id;
  if (!roles) {
    store.dispatch(logout());
    window.location.href = process.env.PUBLIC_URL + "/login";
  }
  const current_role: Role[] = roles.filter(
    (role) => role.id === current_role_id,
  );
  return current_role?.length > 0 && check_roles.includes(current_role[0].name);
};

export const showConfirmDialog = (props: ConfirmDialogProps) => {
  return confirmDialog({
    ...{
      icon: "pi pi-exclamation-triangle",
      acceptClassName:
        "border-solid border-[0.5px] border-[#FFAF66] rounded-[10px] px-[24px] py-[9px] min-w-[80px] max-h-[33px] text-[#DA5E18] text-[12px] leading-[15px] font-[500] bg-gradient-to-b from-[#FFD3AB] to-[#FFF6E3]",
      rejectClassName:
        "border-solid border-0 rounded-[10px] px-[24px] py-[9px] min-w-[80px] max-h-[33px] text-[#292929] text-[12px] leading-[15px] font-[500] bg-[#F4F4F4]",
      pt: {
        root: {
          style: { border: 0 },
        },
        header: {
          style: { borderBottom: 0 },
        },
        footer: {
          style: { borderTop: 0 },
        },
      },
    },
    ...props,
  });
};

import { endOfMonth, format, isValid, parseISO, startOfMonth } from "date-fns";
import { useFormik } from "formik";
import { Badge } from "primereact/badge";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AcceptButton from "../../../components/AcceptButton";
import CancelButton from "../../../components/CancelButton";
import CountryCodeDropdown from "../../../components/CountryCodeDropdown";
import EmptyData from "../../../components/EmptyData";
import FilterButton from "../../../components/FilterButton";
import Layout from "../../../components/Layout";
import TableFooter from "../../../components/TableFooter";
import TableSkeleton from "../../../components/TableSkeleton";
import ValidationError from "../../../components/ValidationError";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import { post } from "../../../utils/Api";
import { formatCurrency } from "../../../utils/Common";

type UserData = {
  agent_code: string;
  country_code: string;
  email: string;
  first_name: string;
  full_name: string;
  id: number;
  last_name: string;
  mobile: string;
  status: number;
  name: string;
  total_sales_amount: number;
  total_sales_quantity: string;
  total_commissions_amount: number;
  total_bean_commissions_amount: number;
  total_bean_sales_amount: number;
  total_equipment_commissions_amount: number;
  total_equipment_sales_amount: number;
  sales_details: {
    items: Array<object>;
    sales: string;
    total_commission: string;
    recognised_price_needed: number;
    total_recognised_price: number;
    realised_commission: boolean;
  };
};

type UserSearchParams = {
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  mobile: string | null;
  agent_code: string | null;
  start_date: string | null;
  end_date: string | null;
  today: string | null;
  current_month: string | null;
};

const Agents = () => {
  const [visible, setVisible] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterAgentCode, setFilterAgentCode] = useState("");
  const [filterDeliveryDate, setFilterDeliveryDate] = useState<any>(null);
  const [filterFirstName, setFilterFirstName] = useState("");
  const [filterLastName, setFilterLastName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterMobile, setFilterMobile] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [dateFilterText, setDateFilterText] = useState("");
  const formatFilterDate = (date: (Date | null)[]) => {
    if (!date || date.length < 1 || !date[0]) {
      return { startDate: "", endDate: "" };
    }

    const startDate = date[0];
    const endDate = date[1];

    let startDateString = "";
    let endDateString = "";

    if (startDate) {
      const start_year = startDate.getFullYear();
      const start_month = String(startDate.getMonth() + 1).padStart(2, "0");
      const start_day = String(startDate.getDate()).padStart(2, "0");
      startDateString = `${start_year}-${start_month}-${start_day}`;
    }

    if (endDate) {
      const end_year = endDate.getFullYear();
      const end_month = String(endDate.getMonth() + 1).padStart(2, "0");
      const end_day = String(endDate.getDate()).padStart(2, "0");
      endDateString = `${end_year}-${end_month}-${end_day}`;
    }

    return { startDate: startDateString, endDate: endDateString };
  };

  const formattedFilterDate = formatFilterDate(filterDeliveryDate);

  const navigate = useNavigate();

  const {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  } = usePaginatedApiData<UserData, UserSearchParams>(
    "/admin/get_agents_list",
    {
      first_name: null,
      last_name: null,
      email: null,
      mobile: null,
      agent_code: null,
      start_date: null,
      end_date: null,
      today: null,
      current_month: "current_month",
    },
    1,
  );

  useEffect(() => {
    setSelectedStatus("current_month");
    handleDateFilterText("current_month");
  }, []);

  const rawRecords = data as any;
  const records = rawRecords?.data?.map((item: UserData) => {
    return {
      ...item,
    };
  });

  function handleDateFilterText(status: string) {
    if (status === "today") {
      setDateFilterText(format(new Date(), "dd MMM yyyy"));
    } else if (status === "current_month") {
      const currentDate = new Date();
      const firstDayOfMonth = startOfMonth(currentDate);
      const lastDayOfMonth = endOfMonth(currentDate);

      const formattedStartDate = format(firstDayOfMonth, "dd MMM yyyy");
      const formattedEndDate = format(lastDayOfMonth, "dd MMM yyyy");

      setDateFilterText(`${formattedStartDate} - ${formattedEndDate}`);
    } else {
      setSelectedStatus("all");
      setDateFilterText("");
    }
  }

  const handleStatusClick = (status: any) => {
    setSelectedStatus(status);
    handleDateFilterText(status);
    if (status === "today") {
      handleSearchParamsChange({
        today: "today",
        current_month: null,
        start_date: null,
        end_date: null,
      });
      setFilterDeliveryDate(null);
    }
    if (status === "current_month") {
      handleSearchParamsChange({
        current_month: "current_month",
        today: null,
        start_date: null,
        end_date: null,
      });
      setFilterDeliveryDate(null);
    }
    if (status === "all") {
      handleSearchParamsChange({
        today: null,
        current_month: null,
        start_date: null,
        end_date: null,
      });
      setFilterDeliveryDate(null);
    }
  };

  const handleView = (agent: any) => {
    navigate("/user-details", {
      state: { user_data: agent, user_id: agent?.id },
    });
  };
  const handleOrderView = (agent: any) => {
    navigate("/orders", {
      state: { agent_id: agent?.id },
    });
  };

  const formik = useFormik({
    initialValues: {
      id: 0,
      first_name: "",
      last_name: "",
      email: "",
      country_code: "65",
      mobile: "",
      agent_code: "",
      status: true,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      mobile: Yup.string()
        .min(8, "Invalid Mobile")
        .required("Mobile is required"),
      agent_code: Yup.string().required("Agent code is required"),
    }),
    onSubmit: async (values) => {
      const {
        id,
        first_name,
        last_name,
        email,
        country_code,
        mobile,
        agent_code,
        status,
      } = values;
      try {
        setUserLoading(true);
        const response = await post("/admin/save_agent", {
          id: id === 0 ? null : id,
          first_name,
          last_name,
          email,
          country_code,
          mobile,
          agent_code,
          status: status ? 1 : 0,
        });
        setUserLoading(false);
        if (response?.success) {
          toast.success(response?.message);
          setVisible(false);
          formik.resetForm();
          handleReload();
        }
      } catch (error: any) {
        setUserLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  return (
    <Layout>
      <Dialog
        header="Agent"
        visible={visible}
        style={{ width: "70vw", border: 0 }}
        onHide={() => {
          setVisible(false);
          formik.resetForm();
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="pb-[10px]">
            <InputText
              className={`w-full ${
                formik.touched.first_name && formik.errors.first_name
                  ? "p-invalid"
                  : ""
              }`}
              name="first_name"
              placeholder="First name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ValidationError
              className={
                formik.touched.first_name && formik.errors.first_name
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.first_name}
            </ValidationError>
          </div>
          <div className="pb-[10px]">
            <InputText
              className={`w-full ${
                formik.touched.last_name && formik.errors.last_name
                  ? "p-invalid"
                  : ""
              }`}
              name="last_name"
              placeholder="Last name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ValidationError
              className={
                formik.touched.last_name && formik.errors.last_name
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.last_name}
            </ValidationError>
          </div>
          <div className="pb-[10px]">
            <InputText
              className={`w-full ${
                formik.touched.email && formik.errors.email ? "p-invalid" : ""
              }`}
              name="email"
              placeholder="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ValidationError
              className={
                formik.touched.email && formik.errors.email
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.email}
            </ValidationError>
          </div>
          <div className="pb-[10px]">
            <div className="flex flex-row justify-start items-center flex-nowrap gap-x-2">
              <CountryCodeDropdown
                className={`w-[100px] ${
                  formik.touched.country_code && formik.errors.country_code
                    ? "p-invalid"
                    : ""
                }`}
                name="country_code"
                value={formik.values.country_code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <InputText
                className={`w-full ${
                  formik.touched.mobile && formik.errors.mobile
                    ? "p-invalid"
                    : ""
                }`}
                name="mobile"
                placeholder="Mobile"
                value={formik.values.mobile}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <ValidationError
              className={
                formik.touched.mobile && formik.errors.mobile
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.mobile}
            </ValidationError>
          </div>
          <div className="pb-[10px]">
            <InputText
              className={`w-full ${
                formik.touched.agent_code && formik.errors.agent_code
                  ? "p-invalid"
                  : ""
              }`}
              name="agent_code"
              placeholder="Agent code"
              value={formik.values.agent_code}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ValidationError
              className={
                formik.touched.agent_code && formik.errors.agent_code
                  ? "visible"
                  : "invisible"
              }
            >
              {formik.errors.agent_code}
            </ValidationError>
          </div>
          <div className="pb-[10px] flex flex-row justify-start items-center flex-nowrap gap-x-4">
            <label htmlFor="status">Status</label>
            <InputSwitch
              name="status"
              inputId="status"
              checked={formik.values.status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <AcceptButton label="Save" loading={userLoading} />
        </form>
      </Dialog>
      <Dialog
        header="Filter"
        position="bottom"
        visible={filterVisible}
        style={{
          width: "100vw",
          maxWidth: "450px",
          margin: 0,
          border: 0,
        }}
        onHide={() => setFilterVisible(false)}
      >
        <div className="mb-[10px]">
          <label htmlFor="filter-agent-code" className="mb-[5px] block">
            Agent Code
          </label>
          <div>
            <InputText
              id="filter-agent-code"
              className="w-full"
              value={filterAgentCode}
              onChange={(e) => setFilterAgentCode(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-mobile" className="mb-[5px] block">
            Sales Date
          </label>
          <Calendar
            className="w-full"
            name="delivery_date"
            dateFormat="dd M yy"
            selectionMode="range"
            value={filterDeliveryDate}
            onChange={(e) => {
              if (Array.isArray(e.value) && e.value.length === 2) {
                const [startDate, endDate] = e.value;
                setFilterDeliveryDate([startDate, endDate]);
              }
            }}
          />
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-first-name" className="mb-[5px] block">
            First name
          </label>
          <div>
            <InputText
              id="filter-first-name"
              className="w-full"
              value={filterFirstName}
              onChange={(e) => setFilterFirstName(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-last-name" className="mb-[5px] block">
            Last name
          </label>
          <div>
            <InputText
              id="filter-last-name"
              className="w-full"
              value={filterLastName}
              onChange={(e) => setFilterLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-email" className="mb-[5px] block">
            Email
          </label>
          <div>
            <InputText
              id="filter-email"
              className="w-full"
              value={filterEmail}
              onChange={(e) => setFilterEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-mobile" className="mb-[5px] block">
            Mobile
          </label>
          <div>
            <InputText
              id="filter-mobile"
              className="w-full"
              value={filterMobile}
              onChange={(e) => setFilterMobile(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between items-center pt-[10px]">
          <CancelButton
            label="Reset"
            onClick={() => {
              setFilterFirstName("");
              setFilterLastName("");
              setFilterEmail("");
              setFilterMobile("");
              setFilterAgentCode("");
              setFilterDeliveryDate(null);
              handleSearchParamsChange({
                first_name: "",
                last_name: "",
                email: "",
                mobile: "",
                agent_code: "",
                start_date: "",
                end_date: "",
              });
              handleDateFilterText(selectedStatus);
              setFilterVisible(false);
            }}
          />
          <AcceptButton
            label="Apply"
            onClick={() => {
              const params: Partial<UserSearchParams> = {
                first_name: filterFirstName,
                last_name: filterLastName,
                email: filterEmail,
                mobile: filterMobile,
                agent_code: filterAgentCode,
                start_date: formattedFilterDate?.startDate,
                end_date: formattedFilterDate?.endDate,
              };

              if (
                formattedFilterDate?.startDate ||
                formattedFilterDate?.endDate
              ) {
                setSelectedStatus("");
                params.today = null;
                params.current_month = null;

                const parsedStartDate = parseISO(
                  formattedFilterDate?.startDate,
                );
                let formattedStartDate = "";

                if (isValid(parsedStartDate)) {
                  formattedStartDate = format(parsedStartDate, "dd MMM yyyy");
                }

                const parsedEndDate = parseISO(formattedFilterDate?.endDate);
                let formattedEndDate = "";

                if (isValid(parsedEndDate)) {
                  formattedEndDate = format(parsedEndDate, "dd MMM yyyy");
                }

                if (formattedStartDate && formattedEndDate) {
                  setDateFilterText(
                    `${formattedStartDate} - ${formattedEndDate}`,
                  );
                } else if (formattedStartDate) {
                  setDateFilterText(formattedStartDate);
                }
              }
              handleSearchParamsChange(params);
              setFilterVisible(false);
            }}
          />
        </div>
      </Dialog>
      <div className="px-[17px] pt-[17px] card-cfg">
        <div className="flex flex-wrap gap-3 mb-4 items-center">
          <div
            className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 ${
              selectedStatus === "today"
                ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }`}
            onClick={() => handleStatusClick("today")}
          >
            <img
              src={require("../../../assets/images/icon-customer-count.png")}
              alt="Filter icon"
              className="w-[27px] mr-[10px]"
            />
            Today <span className="text-[24px] ml-[8px]"></span>
          </div>
          <div
            className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 ${
              selectedStatus === "current_month"
                ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }`}
            onClick={() => handleStatusClick("current_month")}
          >
            <img
              src={require("../../../assets/images/icon-customer-count.png")}
              alt="Filter icon"
              className="w-[27px] mr-[10px]"
            />
            Current Month <span className="text-[24px] ml-[8px]"></span>
          </div>
          <div
            className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 ${
              selectedStatus === "all"
                ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }`}
            onClick={() => handleStatusClick("all")}
          >
            <img
              src={require("../../../assets/images/icon-customer-count.png")}
              alt="Filter icon"
              className="w-[27px] mr-[10px]"
            />
            All <span className="text-[24px] ml-[8px]"></span>
          </div>
          <div className="ml-auto">
            <FilterButton onClick={() => setFilterVisible(true)} />
          </div>
        </div>
        {dateFilterText !== "" && (
          <p className="my-4 text-[13px]">Date Filter: {dateFilterText}</p>
        )}
        {loading && <TableSkeleton />}
        {!loading && records?.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="table-cfg">
                <thead>
                  <tr>
                    <th>Agent</th>
                    <th>Contact</th>
                    <th># Orders</th>
                    <th>Sales</th>
                    <th>Commission</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((row: UserData) => {
                    return (
                      <tr key={`agent-table-row-${row.id}`}>
                        <td data-label="Agent">
                          <div onClick={() => handleView(row)}>
                            <p className="whitespace-nowrap text-[#E5622A] cursor-pointer underline">
                              {row.first_name} {row.last_name}
                            </p>
                            <p className="text-xs">{row.agent_code}</p>
                            <p className="text-xs">{row.name}</p>
                          </div>
                        </td>
                        <td data-label="Contact">
                          <div>
                            <p>{row.email}</p>
                            <p className="text-xs">
                              {row.country_code} {row.mobile}
                            </p>
                          </div>
                        </td>
                        <td data-label="# Orders">
                          <label
                            className="text-[#E5622A] cursor-pointer underline"
                            onClick={() => handleOrderView(row)}
                          >
                            {row.total_sales_quantity}
                          </label>
                        </td>
                        <td data-label="Sales">
                          <div className="flex items-center gap-5 justify-end md:justify-between">
                            <div className="shrink-0">
                              {row?.sales_details?.items.map((data: any) => {
                                return (
                                  <p>
                                    {data.name} :{" "}
                                    {formatCurrency(data.sales ?? 0)}
                                  </p>
                                );
                              })}
                              {/* <p>
                                {"E: "}
                                {formatCurrency(
                                  row.total_equipment_sales_amount ?? 0,
                                )}
                              </p>
                              <p>
                                {"B: "}
                                {formatCurrency(
                                  row.total_bean_sales_amount ?? 0,
                                )}
                              </p> */}
                            </div>
                            <div>
                              <div>
                                <p>(Total Sales)</p>
                                <p>
                                  {formatCurrency(
                                    row?.sales_details?.sales ?? 0,
                                  )}
                                </p>
                              </div>
                              <div>
                                <p>(Recognised Sales)</p>
                                <p>
                                  {formatCurrency(
                                    row?.sales_details
                                      ?.total_recognised_price ?? 0,
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td data-label="Commission">
                          <div className="flex items-center gap-5 justify-end md:justify-between">
                            <div className="shrink-0">
                              {row?.sales_details?.items.map((data: any) => {
                                return (
                                  <p>
                                    {data.name} :{" "}
                                    {formatCurrency(data.commission ?? 0)}
                                  </p>
                                );
                              })}
                              {/* <p>
                                {"E: "}
                                {formatCurrency(
                                  row.total_equipment_commissions_amount ?? 0,
                                )}
                              </p>
                              <p>
                                {"B: "}
                                {formatCurrency(
                                  row.total_bean_commissions_amount ?? 0,
                                )}
                              </p> */}
                            </div>
                            <div>
                              {!row?.sales_details?.realised_commission &&
                                selectedStatus === "current_month" && (
                                  <p>(Unrealised)</p>
                                )}
                              <p>
                                {formatCurrency(
                                  row?.sales_details?.total_commission ?? 0,
                                )}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td data-label="Status">
                          <div
                            className={`rounded-[30px] w-fit ${
                              row.status === 1
                                ? "bg-green-300"
                                : row.status === 0
                                ? "bg-red-300"
                                : "bg-gray-300"
                            } px-[8px] py-[3px] inline text-xs whitespace-nowrap`}
                          >
                            {row.status === 1 ? "Active" : "In-active"}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            />
          </>
        )}
        {!loading && records?.length <= 0 && <EmptyData />}
      </div>
    </Layout>
  );
};

export default Agents;

import { Route, Routes, useLocation } from "react-router-dom";
import Agents from "../screens/Backend/Agents";
import CustomerListing from "../screens/Backend/CustomerListing";
import CustomerListingDetails from "../screens/Backend/CustomerListingDetails";
import Inventory from "../screens/Backend/Inventory";
import InventoryMovements from "../screens/Backend/InventoryMovements";
import OrderListing from "../screens/Backend/OrderListing";
import ProductCategories from "../screens/Backend/ProductCategories";
import Settings from "../screens/Backend/Settings";
import Permissions from "../screens/Backend/Permissions";
import Shipments from "../screens/Backend/Shipments";
import Subscriptions from "../screens/Backend/Subscriptions";
import UserDetails from "../screens/Backend/UserDetails";
import Users from "../screens/Backend/Users";
import Checkout from "../screens/Checkout";
import ForgotPassword from "../screens/ForgotPassword";
import Home from "../screens/Home";
import Login from "../screens/Login";
import NewOrder from "../screens/NewOrder";
import NewOrderCart from "../screens/NewOrderCart";
import NewOrderConfirm from "../screens/NewOrderConfirm";
import NewOrderInfo from "../screens/NewOrderInfo";
import NotFound from "../screens/NotFound";
import OrderDetails from "../screens/OrderDetails";
import PasswordChangedSuccess from "../screens/PasswordChangedSuccess";
import PermissionDenied from "../screens/PermissionDenied";
import Profile from "../screens/Profile";
import ResetPassword from "../screens/ResetPassword";
import VerificationCode from "../screens/VerificationCode";
import OnlyGuest from "../utils/OnlyGuest";
import ProtectedRoute from "../utils/ProtectedRoute";
import PaymentStatus from "../screens/PaymentStatus";
import Venues from "../screens/Backend/Venues";
import VenueOrders from "../screens/Backend/VenueOrders";
import InventoryCreate from "../screens/Backend/InventoryCreate";
import Plan from "../screens/NewOrder/Plan";
import PlanAddons from "../screens/NewOrder/PlanAddons";
import Sales from "../screens/Backend/Sales";
import Salesdetails from "../screens/Backend/SalesDetails";
import EcommerceVideo from "../screens/Backend/EcommerceVideo";
import EcommerceVideoCreate from "../screens/Backend/EcommerceVideoCreate";
import EcommerceCarousel from "../screens/Backend/EcommerceCarousel";
import EcommerceCarouselCreate from "../screens/Backend/EcommerceCarouselCreate";
import Sku from "../screens/Backend/Sku";
import ProductMovementHistory from "../screens/Backend/ProductMovementHistory";
import PromotionCode from "../screens/Backend/PromotionCode";
import PromotionCodeCreate from "../screens/Backend/PromotionCodeCreate";
import FreeGift from "../screens/Backend/FreeGift";
import FreeGiftCreate from "../screens/Backend/FreeGiftCreate";
import Voucher from "../screens/Backend/Voucher";
import VoucherCreate from "../screens/Backend/VoucherCreate";
import Kol from "../screens/Backend/Kol";
import KolCreate from "../screens/Backend/KolCreate";
import WorkshopCreate from "../screens/Backend/WorkshopCreate";
import Workshop from "../screens/Backend/Workshop";

function RoutesNavigator() {
  const location = useLocation();
  return (
    <Routes location={location} key={location.pathname}>
      <Route
        path="/"
        element={
          <ProtectedRoute
            roles={[
              "Admin",
              "Agent",
              "Manager",
              "Warehouse",
              "Agent Leader",
              "KOL",
            ]}
          >
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute
            roles={[
              "Admin",
              "Agent",
              "Manager",
              "Warehouse",
              "Agent Leader",
              "KOL",
            ]}
          >
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/login"
        element={
          <OnlyGuest>
            <Login />
          </OnlyGuest>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <OnlyGuest>
            <ForgotPassword />
          </OnlyGuest>
        }
      />
      <Route
        path="/reset-password"
        element={
          <OnlyGuest>
            <ResetPassword />
          </OnlyGuest>
        }
      />
      <Route
        path="/verification-code"
        element={
          <OnlyGuest>
            <VerificationCode />
          </OnlyGuest>
        }
      />
      <Route
        path="/password-changed-success"
        element={
          <OnlyGuest>
            <PasswordChangedSuccess />
          </OnlyGuest>
        }
      />
      <Route
        path="/plan"
        element={
          <ProtectedRoute roles={["Admin", "Agent", "Agent Leader", "KOL"]}>
            <Plan />
          </ProtectedRoute>
        }
      />
      <Route
        path="/plan-addons"
        element={
          <ProtectedRoute roles={["Admin", "Agent", "Agent Leader", "KOL"]}>
            <PlanAddons />
          </ProtectedRoute>
        }
      />
      <Route
        path="/new-order"
        element={
          <ProtectedRoute roles={["Admin", "Agent", "Agent Leader", "KOL"]}>
            <NewOrder />
          </ProtectedRoute>
        }
      />
      <Route
        path="/new-order-cart"
        element={
          <ProtectedRoute roles={["Admin", "Agent", "Agent Leader", "KOL"]}>
            <NewOrderCart />
          </ProtectedRoute>
        }
      />
      <Route
        path="/new-order-info"
        element={
          <ProtectedRoute roles={["Admin", "Agent", "Agent Leader", "KOL"]}>
            <NewOrderInfo />
          </ProtectedRoute>
        }
      />
      <Route
        path="/new-order-confirm"
        element={
          <ProtectedRoute roles={["Admin", "Agent", "Agent Leader", "KOL"]}>
            <NewOrderConfirm />
          </ProtectedRoute>
        }
      />
      <Route
        path="/product-categories"
        element={
          <ProtectedRoute roles={["Admin", "Manager"]}>
            <ProductCategories />
          </ProtectedRoute>
        }
      />
      <Route
        path="/inventory"
        element={
          <ProtectedRoute roles={["Admin", "Manager", "Warehouse"]}>
            <Inventory />
          </ProtectedRoute>
        }
      />
      <Route
        path="/inventory-create"
        element={
          <ProtectedRoute roles={["Admin", "Manager", "Warehouse"]}>
            <InventoryCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/agents"
        element={
          <ProtectedRoute roles={["Admin", "Manager", "Agent Leader"]}>
            <Agents />
          </ProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedRoute roles={["Admin", "Manager", "Agent Leader"]}>
            <Users />
          </ProtectedRoute>
        }
      />
      <Route
        path="/orders"
        element={
          <ProtectedRoute
            roles={[
              "Admin",
              "Agent",
              "Manager",
              "Warehouse",
              "Agent Leader",
              "KOL",
            ]}
          >
            <OrderListing />
          </ProtectedRoute>
        }
      />
      <Route
        path="/order-details"
        element={
          <ProtectedRoute
            roles={["Admin", "Agent", "Manager", "Agent Leader", "KOL"]}
          >
            <OrderDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/subscriptions"
        element={
          <ProtectedRoute
            roles={[
              "Admin",
              "Agent",
              "Agent Leader",
              "Manager",
              "Warehouse",
              "KOL",
            ]}
          >
            <Subscriptions />
          </ProtectedRoute>
        }
      />
      <Route
        path="/shipments"
        element={
          <ProtectedRoute roles={["Admin", "Manager", "Warehouse"]}>
            <Shipments />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute roles={["Admin", "Manager"]}>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/user-details"
        element={
          <ProtectedRoute roles={["Admin", "Manager", "Agent Leader"]}>
            <UserDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/customer-listing"
        element={
          <ProtectedRoute
            roles={["Admin", "Agent", "Manager", "Agent Leader", "KOL"]}
          >
            <CustomerListing />
          </ProtectedRoute>
        }
      />
      <Route
        path="/customer-listing-details"
        element={
          <ProtectedRoute
            roles={["Admin", "Agent", "Manager", "Agent Leader", "KOL"]}
          >
            <CustomerListingDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/inventory-movements"
        element={
          <ProtectedRoute roles={["Admin", "Manager"]}>
            <InventoryMovements />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sales"
        element={
          <ProtectedRoute roles={["Admin", "Manager", "Agent Leader"]}>
            <Sales />
          </ProtectedRoute>
        }
      />
      <Route
        path="/venues"
        element={
          <ProtectedRoute roles={["Admin", "Manager", "Agent Leader"]}>
            <Venues />
          </ProtectedRoute>
        }
      />
      <Route
        path="/venue-orders"
        element={
          <ProtectedRoute roles={["Admin", "Manager"]}>
            <VenueOrders />
          </ProtectedRoute>
        }
      />
      <Route
        path="/permissions"
        element={
          <ProtectedRoute roles={["Admin"]}>
            <Permissions />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ecommerce-video"
        element={
          <ProtectedRoute roles={["Admin", "Manager"]}>
            <EcommerceVideo />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ecommerce-video-create"
        element={
          <ProtectedRoute roles={["Admin", "Manager"]}>
            <EcommerceVideoCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ecommerce-carousel"
        element={
          <ProtectedRoute roles={["Admin", "Manager"]}>
            <EcommerceCarousel />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ecommerce-carousel-create"
        element={
          <ProtectedRoute roles={["Admin", "Manager"]}>
            <EcommerceCarouselCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sku"
        element={
          <ProtectedRoute roles={["Admin", "Manager", "Agent Leader"]}>
            <Sku />
          </ProtectedRoute>
        }
      />
      <Route
        path="/product-movement-history"
        element={
          <ProtectedRoute roles={["Admin", "Manager", "Agent Leader"]}>
            <ProductMovementHistory />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sales-details"
        element={
          <ProtectedRoute roles={["Admin", "Manager", "Agent Leader"]}>
            <Salesdetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/promotion-code"
        element={
          <ProtectedRoute roles={["Admin", "Manager"]}>
            <PromotionCode />
          </ProtectedRoute>
        }
      />
      <Route
        path="/promotion-code-create"
        element={
          <ProtectedRoute roles={["Admin", "Manager"]}>
            <PromotionCodeCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/free-gift"
        element={
          <ProtectedRoute roles={["Admin", "Manager"]}>
            <FreeGift />
          </ProtectedRoute>
        }
      />
      <Route
        path="/free-gift-create"
        element={
          <ProtectedRoute roles={["Admin", "Manager"]}>
            <FreeGiftCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/voucher"
        element={
          <ProtectedRoute roles={["Admin", "Manager"]}>
            <Voucher />
          </ProtectedRoute>
        }
      />
      <Route
        path="/voucher-create"
        element={
          <ProtectedRoute roles={["Admin", "Manager"]}>
            <VoucherCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/kol"
        element={
          <ProtectedRoute roles={["Admin", "Manager"]}>
            <Kol />
          </ProtectedRoute>
        }
      />
      <Route
        path="/kol-create"
        element={
          <ProtectedRoute roles={["Admin", "Manager"]}>
            <KolCreate />
          </ProtectedRoute>
        }
      />
      <Route
        path="/workshop"
        element={
          <ProtectedRoute roles={["Admin", "Manager"]}>
            <Workshop />
          </ProtectedRoute>
        }
      />
      <Route
        path="/workshop-create"
        element={
          <ProtectedRoute roles={["Admin", "Manager"]}>
            <WorkshopCreate />
          </ProtectedRoute>
        }
      />
      <Route path="/payment-status" element={<PaymentStatus />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/permission-denied" element={<PermissionDenied />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default RoutesNavigator;

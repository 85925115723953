import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const ResetPassword = () => {
  const { height } = useWindowDimensions();
  const navigate = useNavigate();

  return (
    <div
      className="flex bg-[#FFEDD7] md:bg-[#F1F1F1] md:bg-ipad-background-image md:bg-cover md:bg-center md:bg-no-repeat"
      style={{ height: `${height}px` }}
    >
      <div className="w-full max-w-xs m-auto">
        <header className="flex flex-col items-center">
          <img
            src={require("../../assets/images/logo-cfg-circle.png")}
            alt="CFG Logo"
            className="h-[131px] mx-auto mb-[30px]"
          />
        </header>
        <div className="pb-[20px] flex flex-col justify-center items-center">
          <img
            src={require("../../assets/images/icon-success.png")}
            alt="Success Icon"
            className="w-[68px] h-[68px]"
          />
          <label className="text-[20px] text-[#DA5E18] pt-5 pb-2">
            Password changed successfully!
          </label>
          <label className="text-[14px] text-[#C54700]">
            Please login to your account again.
          </label>
        </div>
        <div className="pb-[20px]">
          <Button
            label="Login Now"
            className="w-full bg-[#DA5E18] text-[#fff] text-[20px] border-0"
            onClick={() => navigate("/login")}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import { useState } from "react";
import { Inventory } from "../../interfaces";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { useDispatch } from "react-redux";
import { setOrderInfo } from "../../redux/slices/orderSlice";
import { useAppSelector } from "../../hooks/useStore";

const FreeGiftSelection = (props: any) => {
  const dispatch = useDispatch();
  const { orderInfo } = useAppSelector((state) => state.order);

  const [selectedGifts, setSelectedGifts] = useState(
    orderInfo?.freeGifts ?? [],
  );

  const onGiftSelected = (e: any) => {
    let _selectedGifts: any = [...selectedGifts];

    if (e.checked) _selectedGifts.push(e.value);
    else
      _selectedGifts = _selectedGifts.filter(
        (gift: any) => gift.id !== e.value.id,
      );
    setSelectedGifts(_selectedGifts);
  };

  return (
    <Dialog
      header="Free Gift"
      position="bottom"
      visible={props?.open}
      style={{
        width: "100vw",
        maxWidth: "450px",
        margin: 0,
        border: 0,
      }}
      onHide={props?.close}
      closable={false}
    >
      {props?.items?.length > 0 &&
        props?.items?.map((row: Inventory) => (
          <div
            key={`free-gift-list-row-${row.id}`}
            className="pb-[20px] mb-[20px] border-b border-[#B9B7B7]"
          >
            <div className="flex">
              <Checkbox
                name="gift"
                value={row}
                onChange={onGiftSelected}
                checked={selectedGifts.some((item: any) => item.id === row.id)}
                disabled={+row.quantity <= 0}
              />
              <img
                src={row.image_url || `${process.env.PUBLIC_URL}/no-image.png`}
                alt=""
                className="h-[53px] w-[53px] object-cover rounded-[6px] ml-3 mr-4"
                onError={() => `${process.env.PUBLIC_URL}/no-image.png`}
              />
              <div className="flex flex-col justify-start items-start">
                <div className="text-[14px] text-[#3E3E3E]">{row.name}</div>
                <div className="text-[14px] text-[#3E3E3E]">
                  {row.category.name}
                </div>
                <div className="text-[14px] text-[#3E3E3E]">
                  {row.sub_category.name}
                </div>
                {+row.quantity <= 0 && (
                  <p className="text-xs font-bold text-[#DA5E18]">
                    Out of stock
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
      <div className="flex">
        <button
          className="rounded-[7px] w-full h-[45px] bg-[#4A4A4A] text-white flex flex-row justify-center items-center flex-nowrap mr-1.5"
          onClick={props?.close}
        >
          Skip
        </button>
        <button
          className="rounded-[7px] w-full h-[45px] bg-[#DA5E18] text-white flex flex-row justify-center items-center flex-nowrap ml-1.5"
          onClick={() => {
            dispatch(
              setOrderInfo({ ...orderInfo, ...{ freeGifts: selectedGifts } }),
            );
            props?.confirm();
          }}
        >
          Continue
        </button>
      </div>
    </Dialog>
  );
};
export default FreeGiftSelection;

import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import AcceptButton from "../../../components/AcceptButton";
import CancelButton from "../../../components/CancelButton";
import EmptyData from "../../../components/EmptyData";
import FilterButton from "../../../components/FilterButton";
import Layout from "../../../components/Layout";
import TableFooter from "../../../components/TableFooter";
import TableSkeleton from "../../../components/TableSkeleton";
import useApiData from "../../../hooks/useApiData";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import { formatCurrency, formatDate } from "../../../utils/Common";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import ExportButton from "../../../components/ExportButton";
import { format } from "date-fns";

type SalesData = {
  id: number;
  name: string;
  total_quantity: number;
  in: string;
  out: string;
  type_summary: Array<types>;
  date: string;
  daily_total: number;
  order_count: number;
  quantities: Array<item>;
  order_items: Array<product>;
  total: number;
  created_at: string;
};
type SearchParams = {
  filter: string;
};
type SummaryData = {
  total_daily_total: number;
  total_order_count: number;
};
type product = {
  id: number;
  name: string;
  quantity: number;
};
type item = {
  sku: string;
  product_id: number;
  name: string;
  quantity: number;
  type: Array<types>;
};
type types = {
  id: number;
  name: string;
  quantity: number;
};
type UserSearchParams = {
  start_date: string;
  end_date: string;
};
const ProductMovementHistory = () => {
  const [selectedStatus, setSelectedStatus] = useState("");
  const [filterDeliveryDate, setFilterDeliveryDate] = useState<any>(null);
  const {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  } = usePaginatedApiData<SalesData, UserSearchParams>(
    "/admin/get_product_movement_history",
    {
      start_date: "",
      end_date: "",
    },
    1,
  );
  console.log(data);
  const allData = data as any;
  const rawRecords = allData?.records as any;
  const records = allData?.records?.data as SalesData[];
  const summary = allData?.summary;
  const paginated_summary = allData?.paginated_summary;

  const formatFilterDate = (date: (Date | null)[]) => {
    if (!date || date.length < 1 || !date[0]) {
      return { startDate: "", endDate: "" };
    }

    const startDate = date[0];
    const endDate = date[1];

    let startDateString = "";
    let endDateString = "";

    if (startDate) {
      const start_year = startDate.getFullYear();
      const start_month = String(startDate.getMonth() + 1).padStart(2, "0");
      const start_day = String(startDate.getDate()).padStart(2, "0");
      startDateString = `${start_year}-${start_month}-${start_day}`;
    }

    if (endDate) {
      const end_year = endDate.getFullYear();
      const end_month = String(endDate.getMonth() + 1).padStart(2, "0");
      const end_day = String(endDate.getDate()).padStart(2, "0");
      endDateString = `${end_year}-${end_month}-${end_day}`;
    }

    return { startDate: startDateString, endDate: endDateString };
  };

  const formattedFilterDate = formatFilterDate(filterDeliveryDate);

  const handleExport = () => {
    console.log("test");
  };
  return (
    <Layout>
      <div className="flex justify-end">
        <ExportButton
          className="mb-[5px]"
          api={`admin/export_product_movement_history?start_date=${formattedFilterDate?.startDate}&end_date=${formattedFilterDate?.endDate}`}
          filename={`productMovement_history_${format(
            new Date(),
            "yyyyMMddHHmmss",
          )}.xlsx`}
        />
      </div>
      <div className="p-3 card-cfg">
        <div className="flex flex-wrap gap-3 items-center">
          <div className="text-[#595959]">Total Summary: </div>
          <div className="rounded-[10px] flex flex-wrap gap-3 items-center p-3">
            <div
              className={
                "w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] rounded-[10px] p-[12px] ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
              }
            >
              Total
              <span className="text-[24px] ml-[8px]">
                {" "}
                {summary?.total_summary ?? 0}
              </span>
            </div>
            <div
              className={
                "w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] rounded-[10px] p-[12px] ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
              }
            >
              Stock In
              <span className="text-[24px] ml-[8px]"> {summary?.in ?? 0}</span>
            </div>
            <div
              className={
                "w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] rounded-[10px] p-[12px] ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
              }
            >
              Stock Out
              <span className="text-[24px] ml-[8px]"> {summary?.out ?? 0}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-3 items-center">
          <div className="text-[#595959]">Paginated Summary: </div>
          <div className="rounded-[10px] flex flex-wrap gap-3 items-center p-3">
            <div
              className={
                "w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] rounded-[10px] p-[12px] ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
              }
            >
              Total
              <span className="text-[24px] ml-[8px]">
                {" "}
                {paginated_summary?.total_summary ?? 0}
              </span>
            </div>
            <div
              className={
                "w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] rounded-[10px] p-[12px] ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
              }
            >
              Stock In
              <span className="text-[24px] ml-[8px]">
                {" "}
                {paginated_summary?.in ?? 0}
              </span>
            </div>
            <div
              className={
                "w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] rounded-[10px] p-[12px] ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
              }
            >
              Stock Out
              <span className="text-[24px] ml-[8px]">
                {" "}
                {paginated_summary?.out ?? 0}
              </span>
            </div>
          </div>
          <div className="ml-auto flex items-center">
            <div className="mb-[5px] block text-[#595959] w-auto mr-2 whitespace-nowrap">
              Date Range:
            </div>
            <Calendar
              className="text-center w-full mr-2"
              name="delivery_date"
              dateFormat="dd M yy"
              selectionMode="range"
              value={filterDeliveryDate}
              onChange={(e) => {
                if (Array.isArray(e.value) && e.value.length === 2) {
                  const [startDate, endDate] = e.value;
                  setFilterDeliveryDate([startDate, endDate]);
                }
              }}
            />
            <AcceptButton
              label="Apply"
              onClick={() => {
                handleSearchParamsChange({
                  start_date: formattedFilterDate?.startDate,
                  end_date: formattedFilterDate?.endDate,
                });
              }}
            />
            <CancelButton
              label="Reset"
              onClick={() => {
                setFilterDeliveryDate(null);
                handleSearchParamsChange({
                  start_date: "",
                  end_date: "",
                });
              }}
            />
          </div>
        </div>
        {!loading && records?.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="table-cfg">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Total Quantity</th>
                    <th>In</th>
                    <th>Out</th>
                    <th>Type Summary</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((row: SalesData) => {
                    return (
                      <tr key={`sales-table-row-${row?.id}`}>
                        <td data-label="id">{row?.id}</td>
                        <td data-label="name">{row?.name}</td>
                        <td data-label="total_quantity">
                          {row?.total_quantity}
                        </td>
                        <td data-label="in">{row?.in}</td>
                        <td data-label="out">{row?.out}</td>
                        <td>
                          {row.type_summary.map((type: types) => {
                            return (
                              <div key={`sales-table-div-${type?.name}`}>
                                {type?.name} (x{type?.quantity})
                              </div>
                            );
                          })}
                        </td>
                        {/* <td data-label="Sales">
                          {formatCurrency(row?.total) ?? 0}
                        </td> */}
                        {/* <td data-label="Date">{formatDate(row?.created_at)}</td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={rawRecords.last_page}
              setCurrentPage={handlePageChange}
            />
          </>
        )}
        {!loading && records?.length <= 0 && <EmptyData />}
      </div>
    </Layout>
  );
};

export default ProductMovementHistory;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Customer } from "../../interfaces";

const initialState = {
  customer: {} as Customer,
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    updateCustomer: (state, action: PayloadAction<Customer>) => {
      state.customer = action.payload;
    },
  },
});

export const { updateCustomer } = customerSlice.actions;

export default customerSlice.reducer;

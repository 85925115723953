import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useState } from "react";
import AcceptButton from "../../../components/AcceptButton";
import CancelButton from "../../../components/CancelButton";
import EmptyData from "../../../components/EmptyData";
import FilterButton from "../../../components/FilterButton";
import ExportButton from "../../../components/ExportButton";
import Layout from "../../../components/Layout";
import TableFooter from "../../../components/TableFooter";
import TableSkeleton from "../../../components/TableSkeleton";
import useApiData from "../../../hooks/useApiData";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import { formatCurrency } from "../../../utils/Common";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

type SalesData = {
  date: string;
  daily_total: number;
  order_count: number;
};
type SearchParams = {
  venue_id: number;
  source: number;
  start_date: string;
  end_date: string;
};
type SummaryData = {
  total_daily_total: number;
  total_order_count: number;
};
const Venues = () => {
  const navigate = useNavigate();
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterVenue, setFilterVenue] = useState(0);
  const [selectedFilterVenue, setSelectedFilterVenue] = useState(0);
  const [filterSource, setFilterSource] = useState(0);
  const [filterDate, setFilterDate] = useState<any>(null);

  const venue = useApiData("/venue/get_all_venues");
  const venueData = venue as any;
  let venueList = [];

  if (venueData?.data) {
    venueList = venueData.data.map((item: any) => {
      return { label: item?.name, value: item?.id };
    });
    venueList.unshift({ label: "All Venues", value: 0 });
  }

  const selectedLabel = venueList.find(
    (item: { value: number }) => item.value === selectedFilterVenue,
  )?.label;

  const formatFilterDate = (date: (Date | null)[]) => {
    if (!date || date.length < 1 || !date[0]) {
      return { startDate: "", endDate: "" };
    }

    const startDate = date[0];
    const endDate = date[1];

    let startDateString = "";
    let endDateString = "";

    if (startDate) {
      const start_year = startDate.getFullYear();
      const start_month = String(startDate.getMonth() + 1).padStart(2, "0");
      const start_day = String(startDate.getDate()).padStart(2, "0");
      startDateString = `${start_year}-${start_month}-${start_day}`;
    }

    if (endDate) {
      const end_year = endDate.getFullYear();
      const end_month = String(endDate.getMonth() + 1).padStart(2, "0");
      const end_day = String(endDate.getDate()).padStart(2, "0");
      endDateString = `${end_year}-${end_month}-${end_day}`;
    }

    return { startDate: startDateString, endDate: endDateString };
  };

  const formattedFilterDate = formatFilterDate(filterDate);

  const source = useApiData("/get_source_type");
  const sourceData = source as any;
  let sourceList = [];

  if (sourceData?.data) {
    sourceList = sourceData.data.map((item: any) => {
      return { label: item?.name, value: item?.id };
    });
    sourceList.unshift({ label: "All Sources", value: 0 });
  }

  const {
    data,
    loading,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
  } = usePaginatedApiData<SalesData, SearchParams>(
    "/admin/get_daily_sales",
    {
      venue_id: 0,
      source: 0,
      start_date: "",
      end_date: "",
    },
    1,
  );
  const rawRecords = data as any;
  const records = rawRecords.data as SalesData[];
  const summary = rawRecords?.summary as SummaryData;

  return (
    <Layout>
      <div className="flex justify-end">
        <ExportButton
          className="mb-[5px]"
          api={
            `/admin/export_daily_sales?source=${filterSource}` +
            `&start_date=${formattedFilterDate?.startDate}` +
            `&end_date=${formattedFilterDate?.endDate}` +
            `&venue_id=${filterVenue}`
          }
          filename={`sales_${format(new Date(), "yyyyMMddHHmmss")}.xlsx`}
        />
      </div>
      <Dialog
        header="Filter"
        position="bottom"
        visible={filterVisible}
        style={{
          width: "100vw",
          maxWidth: "450px",
          margin: 0,
          border: 0,
        }}
        onHide={() => setFilterVisible(false)}
      >
        {filterSource === 1 && (
          <div className="mb-3">
            <label htmlFor="filter-venue" className="mb-2 block">
              Venue
            </label>
            <div>
              <Dropdown
                id="filter-venue"
                optionLabel="label"
                value={filterVenue}
                options={venueList}
                onChange={(e) => setFilterVenue(e?.value)}
                className="w-full"
              />
            </div>
          </div>
        )}
        <div className="mb-[10px]">
          <label htmlFor="filter-mobile" className="mb-[5px] block">
            Order Date
          </label>
          <Calendar
            className="w-full"
            name="delivery_date"
            dateFormat="dd M yy"
            selectionMode="range"
            value={filterDate}
            onChange={(e) => {
              if (Array.isArray(e.value) && e.value.length === 2) {
                const [startDate, endDate] = e.value;
                setFilterDate([startDate, endDate]);
              }
            }}
          />
        </div>
        <div className="flex flex-row justify-between items-center pt-[10px]">
          <CancelButton
            label="Reset"
            onClick={() => {
              setFilterVenue(0);
              setSelectedFilterVenue(0);
              setFilterDate("");
              handleSearchParamsChange({
                venue_id: 0,
                start_date: "",
                end_date: "",
              });
              setFilterVisible(false);
            }}
          />
          <AcceptButton
            label="Apply"
            onClick={() => {
              setSelectedFilterVenue(filterVenue);
              handleSearchParamsChange({
                venue_id: filterVenue,
                start_date: formattedFilterDate?.startDate,
                end_date: formattedFilterDate?.endDate,
              });
              setFilterVisible(false);
            }}
          />
        </div>
      </Dialog>
      <div className="p-3 card-cfg">
        <div className="flex flex-wrap gap-3 mb-4 items-center">
          <div
            className={`w-fit flex h-[51px] items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]`}
          >
            <img
              src={require("../../../assets/images/icon-customer-count.png")}
              alt="Filter icon"
              className="w-[27px] mr-[10px]"
            />
            <Dropdown
              id="filter-venue"
              optionLabel="label"
              value={filterSource}
              options={sourceList}
              onChange={(e) => {
                const newSource = e.value;
                setFilterSource(newSource);
                handleSearchParamsChange({
                  source: newSource,
                  venue_id: 0,
                });
                setFilterVenue(0);
                setSelectedFilterVenue(0);
              }}
              className="w-full"
            />
          </div>
          {filterSource === 1 && (
            <div
              className={
                "w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] rounded-[10px] p-[12px] ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
              }
            >
              {selectedLabel}
            </div>
          )}

          <div
            className={
              "w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] rounded-[10px] p-[12px] ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }
          >
            Orders
            <span className="text-[24px] ml-[8px]">
              {summary?.total_order_count ?? 0}
            </span>
          </div>
          <div
            className={
              "w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] rounded-[10px] p-[12px] ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }
          >
            Sales
            <span className="text-[24px] ml-[8px]">
              {summary?.total_daily_total
                ? formatCurrency(summary.total_daily_total)
                : 0}
            </span>
          </div>

          {/* {filterSource === 1 && ( */}
          <FilterButton
            onClick={() => setFilterVisible(true)}
            className="ml-auto"
          />
          {/* )} */}
        </div>
        <AcceptButton
          type="submit"
          label="View Details"
          className="ml-auto mb-5"
          onClick={() => {
            // Use the navigate function to navigate to the /inventory-create route
            navigate("/sales-details", {
              state: { filtered: filterVenue },
            });
          }}
        />
        {loading && <TableSkeleton />}
        {!loading && records?.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="table-cfg">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Orders</th>
                    <th>Sales</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((row: SalesData) => {
                    return (
                      <tr key={`sales-table-row-${row?.date}`}>
                        <td data-label="Date">{row?.date}</td>
                        <td data-label="Orders">{row?.order_count}</td>
                        <td data-label="Sales">
                          {formatCurrency(row?.daily_total) ?? 0}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            />
          </>
        )}
        {!loading && records?.length <= 0 && <EmptyData />}
      </div>
    </Layout>
  );
};

export default Venues;

import React from "react";

const ValidationError = ({ children, className }: any) => {
  return (
    <label
      className={`pt-[5px] text-[10px] text-[#FF0000] flex justify-start items-center ${className}`}
    >
      <img
        src={require("../assets/images/icon-error.png")}
        alt="Error icon"
        className="h-[12px] mr-[5px]"
      />
      {children}
    </label>
  );
};

export default ValidationError;

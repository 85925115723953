import axios from "axios";
import { toast } from "react-toastify";
import { store } from "../redux/store";
import { logout } from "../redux/slices/authSlice";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
  },
});

const apiKey = process.env.REACT_APP_API_KEY;
if (apiKey) {
  axiosInstance.defaults.headers.common["x-api-key"] = apiKey;
} else {
  console.error("API key is not defined in the environment variables.");
}

axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getState().userAuth.user?.token;
    if (token) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === 401) {
      toast.error("Token expired: Try login again!");
      store.dispatch(logout());
      window.location.href = process.env.PUBLIC_URL + "/login";
    }
    return Promise.reject(error);
  },
);

export const get = async (url: string, config = {}) => {
  const response = await axiosInstance.get(url, config);
  return response.data;
};

export const post = async (url: string, data = {}, config = {}) => {
  const response = await axiosInstance.post(url, data, config);
  return response.data;
};

export const put = async (url: string, data = {}, config = {}) => {
  const response = await axiosInstance.put(url, data, config);
  return response.data;
};

export const remove = async (url: string, config = {}) => {
  const response = await axiosInstance.delete(url, config);
  return response.data;
};

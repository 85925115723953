import React from "react";
import { Button } from "primereact/button";

const AcceptButton = ({ label, ...buttonProps }: any) => {
  return (
    <Button
      style={{
        border: "0.5px solid #FFAF66",
        borderRadius: "10px",
        background: "linear-gradient(180deg, #FFD3AB 0%, #FFF6E3 100%)",
        padding: "9px 24px 9px 24px",
        minWidth: "80px",
        maxHeight: "33px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        flexWrap: "nowrap",
        gap: "5px",
      }}
      {...buttonProps}
    >
      <span className="text-[#DA5E18] text-[12px] leading-[15px] font-[500]">
        {label ?? "Ok"}
      </span>
    </Button>
  );
};

export default AcceptButton;
//test

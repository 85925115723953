import { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { useAppSelector } from "../../hooks/useStore";
import { useLocation } from "react-router-dom";

const OneTimePurchase = ({ data, onDeliveryOptionChange }: any) => {
  const location = useLocation();
  const { deliveryOptions } = useAppSelector((state) => state.order);
  const [img, setImg] = useState(
    data?.image_url || require("../../assets/images/order/no-image.png"),
  );

  return (
    <div className="bg-[#F7F7F7] rounded-[11px] text-[14px] text-[#3E3E3E] font-normal">
      <div className="flex mb-2 px-[10px] py-[9px] gap-x-[16px]">
        <img
          src={img}
          alt=""
          className="h-[68px] w-[68px] object-cover rounded-[6px]"
          onError={() => setImg(`${process.env.PUBLIC_URL}/no-image.png`)}
        />
        <div className="flex justify-between gap-x-[16px] w-full">
          <div className="grow flex flex-col justify-between">
            <label className="font-normal text-[#3E3E3E]">{data?.name}</label>
            <Dropdown
              options={deliveryOptions?.map((option: any) => {
                return {
                  label: option?.name,
                  value: option?.id,
                };
              })}
              value={data?.delivery_option_id}
              onChange={(e) => {
                onDeliveryOptionChange({
                  ...data,
                  ...{ delivery_option_id: e.value },
                });
              }}
              name="delivery_option_id"
              placeholder="Delivery Option"
              className="h-[30px] flex items-center max-w-[200px]"
              disabled={location.pathname === "/new-order-confirm"}
            />
          </div>
          <div className="shrink-0">
            <label className="text-[#3E3E3E] text-[16px] font-bold">
              X{data?.quantity}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneTimePurchase;

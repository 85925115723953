import Layout from "../../../components/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { get, post, put } from "../../../utils/Api";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import AcceptButton from "../../../components/AcceptButton";
import { format } from "date-fns";
import ValidationError from "../../../components/ValidationError";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";

const WorkshopCreate = (props: any) => {
  const navigate = useNavigate();
  const [workshop_id] = useState(useLocation()?.state?.workshop_id);
  const [workshopLoading, setWorkshopLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  useEffect(() => {
    if (workshop_id) {
      getWorkshopDetail();
    }
  }, []);

  const getWorkshopDetail = async () => {
    try {
      const response = await get(`/admin/workshop/${workshop_id}`);
      if (response?.success && response?.data) {
        formik.setValues({
          id: response?.data?.id || null,
          name: response?.data?.name || "",
          description: response?.data?.description || "",
          time: response?.data?.time || "",
          image: response?.data?.image_url || "",
          link: response?.data?.link || "",
          status: response?.data?.status || 0,
        });
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      id: null,
      name: "",
      time: "",
      image: "",
      link: "",
      description: "",
      status: 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      description: Yup.string().required("Description is required"),
      link: Yup.string().required("Link is required"),
      image: Yup.mixed().required("Image is required"),
    }),
    onSubmit: async (values) => {
      const { id, name, time, image, link, description, status } = values;
      let response;
      const file: any = image;
      const formData = new FormData();

      try {
        setWorkshopLoading(true);
        if (id) {
          formData.append("id", id as unknown as string);
          formData.append("_method", "put");
        }
        formData.append("name", name);
        formData.append("time", time);
        formData.append("image", file);
        formData.append("link", link);
        formData.append("description", description);
        formData.append("status", status as unknown as string);
        if (!id) {
          response = await post("/admin/workshop", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        } else {
          response = await post(`/admin/workshop/${id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }
        setWorkshopLoading(false);

        if (response?.success) {
          toast.success(response?.message);
          setSelectedFile("");
          navigate("/workshop");
        }
      } catch (error: any) {
        setWorkshopLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  const onFileUpload = async (event: any) => {
    const file = event?.target?.files[0];
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = function () {
        const base64data = reader.result;
        setSelectedFile(base64data as string);
      };
    }
  };

  return (
    <Layout>
      <button onClick={() => navigate(-1)} className="py-[8px] px-[12px]">
        <img
          src={require("../../../assets/images/icon-back.png")}
          alt="Back icon"
          className="w-[34px] h-[34px]"
        />
      </button>
      <div className="min-h-auto p-[17px] card-cfg">
        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-left items-center pb-[15px] text-[#DA5E18]">
            <label>Workshop</label>
          </div>
          <div className="flex md:justify-start md:flex-row flex-col gap-4">
            <div className="bg-[#f7f3f3] text-center p-[10px] rounded-[7px]">
              <div className="flex flex-col justify-center items-center pb-6">
                <div
                  className={`w-[128px] h-[128px] border border-solid rounded-[6px] bg-[#FFFFFF] flex justify-center relative ${
                    formik.touched.image && formik.errors.image
                      ? "border-[#f44336]"
                      : "border-[#ACACAC]"
                  }`}
                >
                  {selectedFile || formik.values.image ? (
                    <div className="w-full h-full relative">
                      <img
                        src={selectedFile || formik.values.image}
                        onError={() =>
                          setSelectedFile(
                            `${process.env.PUBLIC_URL}/no-image.png`,
                          )
                        }
                        alt="Preview"
                        className="m-[auto] w-full h-full object-cover rounded-[6px] p-[10px]"
                      />
                      <button
                        className="absolute top-[-10px] right-[-10px] rounded-[50%] bg-gradient-to-b from-[#FFD3AB] to-[#FFF6E3]"
                        onClick={() => {
                          formik.setFieldValue("image", null);
                          setSelectedFile("");
                        }}
                      >
                        <img
                          src={require("../../../assets/images/icon-close.png")}
                          alt="Remove icon"
                          className="w-[25px]"
                        />
                      </button>
                    </div>
                  ) : (
                    <>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        id="image"
                        name="image"
                        hidden
                        onChange={(e: any) => {
                          onFileUpload(e);
                          formik.setFieldValue("image", e?.target?.files[0]);
                          setSelectedFile(
                            URL.createObjectURL(e?.target?.files[0]),
                          );
                        }}
                      />
                      <label
                        htmlFor="image"
                        className="text-[12px] leading-[15px] text-[#4C4C4C] font-[400] w-[80px] flex justify-center items-center flex-col text-center gap-y-[7px]"
                      >
                        <img
                          src={require("../../../assets/images/icon-add-circle.png")}
                          alt="Add icon"
                          className="w-[22px]"
                        />
                        Add Image
                      </label>
                    </>
                  )}
                </div>

                <div className="flex items-center mt-[15px]">
                  <label
                    htmlFor="status"
                    className="text-[12px] text-[#6F6F6F] mr-[5px]"
                  >
                    Status
                  </label>
                  <InputSwitch
                    name="status"
                    inputId="status"
                    checked={formik.values.status ? true : false}
                    onChange={(e) => {
                      formik.setFieldValue(`status`, e.value ? 1 : 0);
                    }}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>

            <div className="grid md:grid-cols-2 w-full gap-4 bg-[#f7f3f3] p-4 rounded-[7px]">
              <div className="flex flex-col">
                <label className="ml-[5px] mb-[5px]">Name</label>
                <InputText
                  className={`w-full ${
                    formik.touched.name && formik.errors.name ? "p-invalid" : ""
                  }`}
                  name="name"
                  placeholder="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <ValidationError
                  className={
                    formik.touched.name && formik.errors.name
                      ? "block"
                      : "hidden"
                  }
                >
                  {formik.errors.name}
                </ValidationError>
              </div>
              <div className="flex flex-col">
                <label className="ml-[5px] mb-[5px]">Time</label>
                <InputText
                  className={`w-full ${
                    formik.touched.time && formik.errors.time ? "p-invalid" : ""
                  }`}
                  name="time"
                  placeholder="Time"
                  value={formik.values.time}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <ValidationError
                  className={
                    formik.touched.time && formik.errors.time
                      ? "block"
                      : "hidden"
                  }
                >
                  {formik.errors.time}
                </ValidationError>
              </div>
              <div className="flex flex-col">
                <label className="ml-[5px] mb-[5px]">Link</label>
                <InputText
                  className={`w-full ${
                    formik.touched.link && formik.errors.link ? "p-invalid" : ""
                  }`}
                  name="link"
                  placeholder="Link"
                  value={formik.values.link}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <ValidationError
                  className={
                    formik.touched.link && formik.errors.link
                      ? "block"
                      : "hidden"
                  }
                >
                  {formik.errors.link}
                </ValidationError>
              </div>
              <div className="flex flex-col md:col-span-2">
                <label className="ml-[5px] mb-[5px]">Description</label>
                <InputTextarea
                  className={`w-full ${
                    formik.touched.description && formik.errors.description
                      ? "p-invalid"
                      : ""
                  }`}
                  name="description"
                  placeholder="Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <ValidationError
                  className={
                    formik.touched.description && formik.errors.description
                      ? "block"
                      : "hidden"
                  }
                >
                  {formik.errors.description}
                </ValidationError>
              </div>
            </div>
          </div>

          <AcceptButton
            label={workshop_id ? "Update" : "Save"}
            className="mt-[20px] ml-auto block"
            loading={workshopLoading}
          />
        </form>
      </div>
    </Layout>
  );
};

export default WorkshopCreate;

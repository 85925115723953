import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Role } from "../../interfaces";

const initialState = {
  navExpanded: false,
  roles: [] as Role[],
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    toggleMenu: (state, action: PayloadAction<boolean>) => {
      state.navExpanded = action.payload;
    },
    setRoles: (state, action: PayloadAction<Role[]>) => {
      state.roles = action.payload;
    },
  },
});

export const { toggleMenu, setRoles } = commonSlice.actions;

export default commonSlice.reducer;

import React from "react";

interface DataType {
  nPages: number;
  currentPage: number;
  setCurrentPage: any;
}

const pageRange = (page: number, pageCount: number) => {
  let start = page - 2,
    end = page + 2;

  if (end > pageCount) {
    start -= end - pageCount;
    end = pageCount;
  }
  if (start <= 0) {
    end += (start - 1) * -1;
    start = 1;
  }
  end = end > pageCount ? pageCount : end;
  const nos = [];
  for (let i = start; i <= end; i++) {
    nos.push(i);
  }
  return nos;
};

const Pagination = ({ nPages, currentPage, setCurrentPage }: DataType) => {
  // const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
  const range = pageRange(currentPage, nPages);

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  return (
    <>
      <div className="md:hidden flex flex-row justify-center items-center flex-nowrap gap-[8px] w-full">
        <button
          type="button"
          onClick={prevPage}
          className="rounded-[6px] bg-[#F5F5F5] text-[12px] text-[#DA5E18] uppercase py-[9px] px-[20px] min-w-[96px]"
        >
          Previous
        </button>
        <button
          type="button"
          onClick={nextPage}
          className="rounded-[6px] bg-[#F5F5F5] text-[12px] text-[#DA5E18] uppercase py-[9px] px-[20px] min-w-[96px]"
        >
          Next
        </button>
      </div>
      <div className="hidden md:flex justify-center items-center flex-row flex-nowrap">
        <button type="button" onClick={prevPage}>
          <img
            src={require("../assets/images/icon-chevron-left.png")}
            alt="Left icon"
            className="w-[24px] h-[24px]"
          />
        </button>
        {range.map((pgNumber) => (
          <button
            type="button"
            key={pgNumber}
            onClick={() => setCurrentPage(pgNumber)}
            className={`px-3 text-[12px] leading-[15px] ${
              currentPage === pgNumber
                ? "text-[#DA5E18] font-[700]"
                : "text-[#848484] font-[400]"
            }`}
          >
            {pgNumber}
          </button>
        ))}
        <button type="button" onClick={nextPage}>
          <img
            src={require("../assets/images/icon-chevron-right.png")}
            alt="Right icon"
            className="w-[24px] h-[24px]"
          />
        </button>
      </div>
    </>
  );
};

export default Pagination;

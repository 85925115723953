import React, { useState } from "react";
import Layout from "../../../components/Layout";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import { useNavigate } from "react-router-dom";
import TableHeader from "../../../components/TableHeader";
import TableFooter from "../../../components/TableFooter";
import {
  formatCurrency,
  formatDate,
  formatTime,
  isRole,
} from "../../../utils/Common";
import { Dialog } from "primereact/dialog";
import TableSkeleton from "../../../components/TableSkeleton";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useDispatch } from "react-redux";
import {
  setCartItems,
  setOrderInfo,
  setCart,
  setBillingInfo,
  setAdditionalInfo,
} from "../../../redux/slices/orderSlice";
import { useAppSelector } from "../../../hooks/useStore";
import CancelButton from "../../../components/CancelButton";
import AcceptButton from "../../../components/AcceptButton";
import FilterButton from "../../../components/FilterButton";
import OrderDetails from "../../OrderDetails";
import FullScreenDialog from "../../../components/FullScreenDialog";
import ExportButton from "../../../components/ExportButton";
import { format } from "date-fns";
import useApiData from "../../../hooks/useApiData";
import EmptyData from "../../../components/EmptyData";
import AdminButton from "../../../components/AdminButton";
import { useLocation } from "react-router-dom";

type OrderData = {
  id: number;
  status: number;
  agent_sales: string;
  agents: Array<{
    agent: {
      agent_code: string;
      full_name: string;
    };
    primary: number;
  }>;
  customer: {
    full_name: string;
    country_code: string;
    mobile: string;
    email: string;
  };
  source: {
    id: number;
    name: string;
  };
  created_at: string;
  total: string;
  highest_subscription_month: number;
  venue: {
    name: string;
  };
  venue_id: number;
  agent_id: number;
  remarks: string;
};

type OrderSearchParams = {
  statuses: string;
  start_date: string;
  end_date: string;
  customer_name: string;
  mobile: string;
  product_barcode: string;
  product_sku: string;
  venue_id: string;
  agent_id: string;
  order_id: string;
  source: string;
};

const OrderListing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAppSelector((state) => state.userAuth);
  const [showDetail, setShowDetail] = useState(false);
  const [showDetailParam, setShowDetailParam] = useState({});
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterStatuses, setFilterStatuses] = useState("");
  const [filterCustomerName, setFilterCustomerName] = useState("");
  const [filterCustomerMobile, setFilterCustomerMobile] = useState("");
  const [filterOrderDate, setFilterOrderDate] = useState<any>(null);
  const [filterBarcode, setFilterBarcode] = useState("");
  const [filterSKU, setFilterSKU] = useState("");
  const [filterVenue, setFilterVenue] = useState("");
  const [agent_id] = useState(useLocation()?.state?.agent_id);
  const [filterAgent, setFilterAgent] = useState(agent_id || "");
  const [filterOrderId, setFilterOrderId] = useState("");
  const [selectedSource, setSelectedSource] = useState("");

  const agents = useApiData("agent/get_agents?include_al=true");
  const agentsData = agents as any;
  const agentList = agentsData?.data?.map((item: any) => {
    return {
      label: `${item?.full_name} (${item?.agent_code})`,
      value: item?.id,
    };
  });

  const venue = useApiData("/venue/get_all_venues");
  const venueData = venue as any;
  const venueList = venueData?.data?.map((item: any) => {
    return { label: item?.name, value: item?.id };
  });

  const formatFilterDate = (date: (Date | null)[]) => {
    if (!date || date.length < 1 || !date[0]) {
      return { startDate: "", endDate: "" };
    }

    const startDate = date[0];
    const endDate = date[1];

    let startDateString = "";
    let endDateString = "";

    if (startDate) {
      const start_year = startDate.getFullYear();
      const start_month = String(startDate.getMonth() + 1).padStart(2, "0");
      const start_day = String(startDate.getDate()).padStart(2, "0");
      startDateString = `${start_year}-${start_month}-${start_day}`;
    }

    if (endDate) {
      const end_year = endDate.getFullYear();
      const end_month = String(endDate.getMonth() + 1).padStart(2, "0");
      const end_day = String(endDate.getDate()).padStart(2, "0");
      endDateString = `${end_year}-${end_month}-${end_day}`;
    }

    return { startDate: startDateString, endDate: endDateString };
  };

  const formattedFilterDate = formatFilterDate(filterOrderDate);

  const statusOptions = [
    { label: "Paid", value: "1" },
    { label: "Unpaid", value: "0" },
    { label: "Failed", value: "3" },
    { label: "Refunded", value: "4" },
  ];

  const handleView = (order: any) => {
    // navigate("/order-details", {
    //   state: { order_id: order?.id, order_data: order },
    // });
    setShowDetailParam({ order_id: order?.id });
    setShowDetail(true);
  };
  const {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  } = usePaginatedApiData<OrderData, OrderSearchParams>(
    "/get_orders",
    {
      statuses: "",
      start_date: "",
      end_date: "",
      customer_name: "",
      mobile: "",
      product_barcode: "",
      product_sku: "",
      venue_id: "",
      agent_id: agent_id || "",
      order_id: "",
      source: "",
    },
    1,
  );

  // const [visible, setVisible] = useState(false);
  const rawRecords = data as any;
  const summary = rawRecords?.summary;
  const records = rawRecords?.data?.map((item: OrderData) => {
    return {
      ...item,
      ...{
        agent_sales: formatCurrency(item.agent_sales),
        total: formatCurrency(item.total),
      },
    };
  });

  const handleOnCreateOrder = () => {
    dispatch(setCartItems([]));
    dispatch(setOrderInfo({}));
    dispatch(setCart([]));
    dispatch(setBillingInfo({}));
    dispatch(setAdditionalInfo({}));
    navigate("/new-order");
  };

  const handleSourceClick = (source: any) => {
    setSelectedSource(source);
    handleSearchParamsChange({
      source: source,
    });
  };

  const headerButton = isRole(["Agent", "Agent Leader", "KOL"]) ? (
    <button
      className="w-full h-[70px] bg-[#DA5E18] text-white flex flex-row justify-center items-center flex-nowrap md:hidden"
      onClick={handleOnCreateOrder}
    >
      <img
        src={require("../../../assets/images/icon-add-white.png")}
        alt="Add icon"
        className="w-[24px] h-[24px] mr-[5px]"
      />
      New Order
    </button>
  ) : null;

  // const orders = useApiData("/admin/get_orders");

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;

  return (
    <Layout headerButton={headerButton}>
      <FullScreenDialog
        visible={showDetail}
        onHide={() => {
          setShowDetail(false);
        }}
      >
        <OrderDetails
          params={showDetailParam}
          close={() => {
            setShowDetail(false);
            handleReload();
          }}
        />
      </FullScreenDialog>
      <Dialog
        header="Filter"
        position="bottom"
        visible={filterVisible}
        style={{
          width: "100vw",
          maxWidth: "450px",
          margin: 0,
          border: 0,
        }}
        onHide={() => setFilterVisible(false)}
      >
        <div className="mb-[10px]">
          <label htmlFor="filter-order-id" className="mb-[5px] block">
            Order ID #
          </label>
          <div>
            <InputText
              id="filter-order-id"
              className="w-full"
              value={filterOrderId}
              onChange={(e) => setFilterOrderId(e?.target?.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-agent-name" className="mb-[5px] block">
            Agent Name
          </label>
          <div>
            <Dropdown
              id="filter-agent-name"
              optionLabel="label"
              value={filterAgent}
              options={agentList}
              filter
              showClear
              onChange={(e) => setFilterAgent(e?.value)}
              className="w-full"
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-status" className="mb-[5px] block">
            Status
          </label>
          <div>
            <Dropdown
              id="filter-status"
              optionLabel="label"
              value={filterStatuses}
              options={statusOptions}
              filter
              showClear
              onChange={(e) => setFilterStatuses(e?.value)}
              className="w-full"
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-venue" className="mb-[5px] block">
            Venue
          </label>
          <div>
            <Dropdown
              id="filter-venue"
              optionLabel="label"
              value={filterVenue}
              options={venueList}
              filter
              showClear
              onChange={(e) => setFilterVenue(e?.value)}
              className="w-full"
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-customer-name" className="mb-[5px] block">
            Customer Name
          </label>
          <div>
            <InputText
              id="filter-customer-name"
              className="w-full"
              value={filterCustomerName}
              onChange={(e) => setFilterCustomerName(e?.target?.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-customer-mobile" className="mb-[5px] block">
            Customer Mobile
          </label>
          <div>
            <InputText
              id="filter-customer-mobile"
              className="w-full"
              value={filterCustomerMobile}
              onChange={(e) => setFilterCustomerMobile(e?.target?.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-mobile" className="mb-[5px] block">
            Order Date
          </label>
          <Calendar
            className="w-full"
            name="delivery_date"
            dateFormat="dd M yy"
            selectionMode="range"
            value={filterOrderDate}
            onChange={(e) => {
              if (Array.isArray(e.value) && e.value.length === 2) {
                const [startDate, endDate] = e.value;
                setFilterOrderDate([startDate, endDate]);
              }
            }}
          />
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-customer-name" className="mb-[5px] block">
            Product Barcode
          </label>
          <div>
            <InputText
              id="filter-product-barcode"
              className="w-full"
              value={filterBarcode}
              onChange={(e) => setFilterBarcode(e?.target?.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-customer-name" className="mb-[5px] block">
            Product SKU
          </label>
          <div>
            <InputText
              id="filter-product-sku"
              className="w-full"
              value={filterSKU}
              onChange={(e) => setFilterSKU(e?.target?.value)}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between items-center pt-[10px]">
          <CancelButton
            label="Reset"
            onClick={() => {
              setFilterAgent("");
              setFilterStatuses("");
              setFilterOrderDate("");
              setFilterCustomerName("");
              setFilterCustomerMobile("");
              setFilterBarcode("");
              setFilterSKU("");
              setFilterVenue("");
              setFilterOrderId("");
              handleSearchParamsChange({
                statuses: "",
                start_date: "",
                end_date: "",
                customer_name: "",
                product_barcode: "",
                product_sku: "",
                venue_id: "",
                agent_id: "",
                order_id: "",
              });
              setFilterVisible(false);
            }}
          />
          <AcceptButton
            label="Apply"
            onClick={() => {
              handleSearchParamsChange({
                statuses: filterStatuses,
                start_date: formattedFilterDate?.startDate,
                end_date: formattedFilterDate?.endDate,
                customer_name: filterCustomerName,
                mobile: filterCustomerMobile,
                product_barcode: filterBarcode,
                product_sku: filterSKU,
                venue_id: filterVenue,
                agent_id: filterAgent,
                order_id: filterOrderId,
              });
              setFilterVisible(false);
            }}
          />
        </div>
      </Dialog>
      <TableHeader>
        <div className="flex flex-row justify-between items-end flex-nowrap w-full">
          <ExportButton
            className="mb-[5px]"
            api={
              `/export_orders?statuses=${filterStatuses}` +
              `&start_date=${formattedFilterDate?.startDate}` +
              `&end_date=${formattedFilterDate?.endDate}` +
              `&customer_name=${filterCustomerName}` +
              `&mobile=${filterCustomerMobile}` +
              `&product_barcode=${filterBarcode}` +
              `&product_sku=${filterSKU}` +
              `&venue_id=${filterVenue}` +
              `&agent_id=${filterAgent}`
            }
            filename={`orders_${format(new Date(), "yyyyMMddHHmmss")}${
              user?.id
            }.xlsx`}
          />
        </div>
        <FilterButton
          onClick={() => setFilterVisible(true)}
          className="mb-[5px] shrink-0"
        />
        {isRole(["Agent", "Agent Leader", "KOL"]) && (
          <AdminButton
            label="New Order"
            onClick={handleOnCreateOrder}
            className="md:ml-[10px] hidden md:block"
          />
        )}
        {/* <div className="rounded-full bg-[#FFFFFF] w-[32px] h-[32px] flex justify-center items-center mx-[20px]">
          <img
            src={require("../../../assets/images/icon-search.png")}
            alt="Search icon"
            className="w-[18px]"
          />
        </div> */}
      </TableHeader>
      <div className="px-[17px] pt-[17px] card-cfg">
        <div className="flex flex-wrap flex-row justify-start items-center">
          <div className="flex flex-wrap">
            <div
              className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] mb-[12px] mr-[15px] cursor-pointer ring-1 ${
                selectedSource === ""
                  ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                  : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
              }`}
              onClick={() => handleSourceClick("")}
            >
              Total Sales{" "}
              <span className="text-[24px] ml-[8px]">
                {formatCurrency(summary?.total_sales_paid ?? 0)}
              </span>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div
              className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] mb-[12px] mr-[15px] cursor-pointer ring-1 ${
                selectedSource === "2"
                  ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                  : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
              }`}
              onClick={() => handleSourceClick("2")}
            >
              E-Commerce Sales{" "}
              <span className="text-[24px] ml-[8px]">
                {formatCurrency(summary?.total_sales_paid_ecommerce ?? 0)}
              </span>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div
              className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] mb-[12px] mr-[15px] cursor-pointer ring-1 ${
                selectedSource === "1"
                  ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                  : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
              }`}
              onClick={() => handleSourceClick("1")}
            >
              POS Sales{" "}
              <span className="text-[24px] ml-[8px]">
                {formatCurrency(summary?.total_sales_paid_pos ?? 0)}
              </span>
            </div>
          </div>
        </div>
        <div className="w-fit flex flex-wrap flex-row justify-start items-center gap-x-2 mb-3 text-[12px] text-blue-500 border border-solid border-blue-500 h-[24px] rounded-[12px] pr-3 overflow-hidden">
          <div className="bg-blue-500 text-white py-1 px-2">
            <span
              className={"pi pi-info-circle"}
              style={{ fontSize: "14px" }}
            ></span>
          </div>
          <div>
            <span
              className={"pi pi-send pr-2"}
              style={{ fontSize: "12px", paddingLeft: 3 }}
            ></span>
            <label>Subscription</label>
          </div>
          <div>
            <span
              className={"pi pi-shopping-cart pr-2"}
              style={{ fontSize: "12px", paddingLeft: 3 }}
            ></span>
            <label>One Time Purchase</label>
          </div>
        </div>
        {loading && <TableSkeleton />}
        {!loading && records?.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="table-cfg">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Customer</th>
                    <th>Agent</th>
                    <th>Source</th>
                    <th>Total</th>
                    <th>Status</th>
                    <th>Venue</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((row: OrderData) => {
                    return (
                      <tr key={`order-listing-table-row-${row?.id}`}>
                        <td data-label="ID">
                          <label
                            className="text-[#E5622A] cursor-pointer underline"
                            onClick={() => handleView(row)}
                          >
                            #{row?.id}
                          </label>
                          {row?.remarks !== null && (
                            <span
                              className={
                                "pi pi-comment md:float-right pt-[4px] text-[#E5622A] md:pl-0 pl-2 "
                              }
                              style={{ fontSize: "12px" }}
                            ></span>
                          )}
                        </td>
                        <td data-label="Customer">
                          <div className="whitespace-nowrap capitalize">
                            <p>{row?.customer?.full_name}</p>
                            <p className="text-xs">
                              {row?.customer?.country_code}{" "}
                              {row?.customer?.mobile}
                            </p>
                          </div>
                        </td>
                        <td data-label="Agent">
                          {row?.agents?.map((agent, index: number) => {
                            return (
                              <div
                                className={`whitespace-nowrap ${
                                  agent?.primary === 1 ? "" : "text-xs"
                                }`}
                                key={`agent-${index}`}
                              >
                                {agent?.primary === 1 ? "(P) " : "(S) "}
                                {agent?.agent?.full_name}
                                {" #"}
                                {agent?.agent?.agent_code}
                              </div>
                            );
                          })}
                        </td>
                        <td data-label="Source" className="text-center">
                          <div className="whitespace-nowrap">
                            {row?.source?.name ?? "N/A"}
                          </div>
                        </td>
                        <td data-label="Total" className="text-right">
                          {row?.highest_subscription_month > 0 ? (
                            <span
                              className={
                                "pi pi-send md:pr-0 pr-2 md:float-left pt-[4px] text-[#da5d18]"
                              }
                              style={{ fontSize: "12px", paddingLeft: 3 }}
                            ></span>
                          ) : (
                            <span
                              className={
                                "pi pi-shopping-cart md:pr-0 pr-2 md:float-left pt-[4px]"
                              }
                              style={{ fontSize: "12px", paddingLeft: 3 }}
                            ></span>
                          )}
                          <p>{row?.total}</p>
                          {isRole(["Agent", "Agent Leader", "KOL"]) &&
                            row?.agents?.length > 1 &&
                            row?.status === 1 && (
                              <p className="text-xs">{row?.agent_sales}</p>
                            )}
                        </td>
                        <td data-label="Status" className="text-center">
                          <div
                            className={`${
                              row?.status === 1
                                ? "bg-green-300"
                                : row?.status === 0
                                ? "bg-gray-300"
                                : "bg-red-300 "
                            } px-[8px] py-[3px] rounded-[30px] inline text-xs w-fit lg:w-full`}
                          >
                            {row?.status === 1
                              ? "Paid"
                              : row?.status === 0
                              ? "Unpaid"
                              : row?.status === 4
                              ? "Refunded"
                              : "Failed"}
                          </div>
                        </td>
                        <td data-label="Venue" className="text-center">
                          <div className="whitespace-nowrap">
                            {row?.venue?.name ?? "N/A"}
                          </div>
                        </td>
                        <td data-label="Date" className="text-center">
                          <div className="whitespace-nowrap">
                            <p>{formatDate(row?.created_at)}</p>
                            <p className="text-xs">
                              {formatTime(row?.created_at)}
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            />
          </>
        )}
        {!loading && records?.length <= 0 && <EmptyData />}
      </div>
    </Layout>
  );
};

export default OrderListing;

import React from "react";
import { Provider } from "react-redux";
import RoutesNavigator from "./routes";
import { store, persistor } from "./redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import "primeicons/primeicons.css";
import { ConfirmDialog } from "primereact/confirmdialog";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RoutesNavigator />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <ConfirmDialog />
      </PersistGate>
    </Provider>
  );
}

export default App;

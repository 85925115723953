import React from "react";
import { Dropdown } from "primereact/dropdown";
import CountryCodes from "../utils/countryPhoneCodes.json";

const CountryCodeDropdown = ({ options, ...dropdownProps }: any) => {
  return (
    <Dropdown
      options={CountryCodes.map((CountryCode: any) => {
        return {
          label: `${CountryCode?.country} (${CountryCode?.code})`,
          value: CountryCode?.code,
        };
      })}
      placeholder="Country Code"
      filter
      valueTemplate={(option) => {
        if (option) {
          return option?.value;
        }
      }}
      {...dropdownProps}
    />
  );
};

export default CountryCodeDropdown;

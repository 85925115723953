import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import { HeaderType } from "../interfaces";
import { store } from "../redux/store";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Pusher from "pusher-js";
import PaymentNotification from "../components/PaymentNotification";
import { useAppSelector } from "../hooks/useStore";
import { toggleMenu } from "../redux/slices/commonSlice";
import PageLinks from "./PageLinks";

export const BackIcon = () => {
  const navigate = useNavigate();
  return (
    <button onClick={() => navigate(-1)}>
      <img
        src={require("../assets/images/icon-back.png")}
        alt="Back icon"
        className="w-[34px] h-[34px]"
      />
    </button>
  );
};

const Layout = ({
  children,
  stickyHeaderFooter = false,
  headerButton = null,
  footerButton = null,
  headerType = HeaderType.default,
  leftSideIcon = <BackIcon />,
  pageTitle = null,
  rightSideIcon = null,
  doNotCallPusher = false,
}: any) => {
  const { height } = useWindowDimensions();
  const { navExpanded } = useAppSelector((state) => state.common);
  const dispatch = useDispatch();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const user_id: any = store.getState().userAuth?.user?.id || null;
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [pusherData, setPusherData] = useState({});

  const { REACT_APP_PUSHER_APP_KEY, REACT_APP_PUSHER_APP_CLUSTER }: any =
    process.env;
  const [pusher, setPusher] = useState<Pusher | null>(null);

  const handleNotificationDialogClose = () => {
    setNotificationVisible(false);
  };

  useEffect(() => {
    const pusherInstance = new Pusher(REACT_APP_PUSHER_APP_KEY, {
      cluster: REACT_APP_PUSHER_APP_CLUSTER,
    });
    setPusher(pusherInstance);
    const channel = pusherInstance.subscribe("CFG");
    channel.bind(
      `update-content-for-update-payment-status-${user_id}`,
      (data: any) => {
        if (
          JSON.parse(data?.response_data)?.body?.type ===
            "payment_intent.succeeded" ||
          JSON.parse(data?.response_data)?.body?.type ===
            "payment_intent.payment_failed"
        ) {
          if (!doNotCallPusher) {
            setPusherData(JSON.parse(data?.response_data));
            setNotificationVisible(true);
          }
        }
      },
    );
    return () => {
      if (pusherInstance) {
        pusherInstance.disconnect();
      }
    };
  }, []);

  return (
    <div
      className={`bg-[#FFEDD7] md:bg-[#F1F1F1] md:bg-ipad-background-image md:bg-cover md:bg-center md:bg-no-repeat md:bg-fixed w-screen overflow-auto`}
      style={{ height: `${height}px` }}
    >
      <div
        className={`flex flex-col main-panel ${navExpanded ? "expand" : ""}`}
        style={{ height: `${height}px` }}
      >
        <Sidebar
          visible={isMenuOpen}
          position="left"
          blockScroll={true}
          showCloseIcon={false}
          onHide={() => setMenuOpen(false)}
          className="bg-[#FFF9F0]"
        >
          <div className="pb-[20px] flex">
            <button onClick={() => setMenuOpen(false)} className="ml-auto">
              <img
                src={require("../assets/images/icon-close.png")}
                alt="Close icon"
                className="w-[33px] h-[33px]"
              />
            </button>
          </div>
          <PageLinks setMenuOpen={setMenuOpen} />
        </Sidebar>
        <header className="md:hidden px-[10px] pt-[30px] pb-[20px]">
          <div className="flex flex-row flex-nowrap justify-between items-center gap-x-2">
            <div className="shrink-0 min-w-[55px]">
              {headerType === HeaderType.default && (
                <button
                  id="hamburger"
                  className="focus:outline-none"
                  onClick={() => setMenuOpen(true)}
                >
                  <img
                    src={require("../assets/images/icon-hamburger.png")}
                    alt="Menu icon"
                    className="w-[33px] h-[33px]"
                  />
                </button>
              )}
              {headerType === HeaderType.back && leftSideIcon}
            </div>
            <div className="grow flex flex-row justify-center items-center flex-nowrap uppercase text-[#000000] text-[15px] overflow-hidden">
              {!pageTitle && (
                <NavLink to="/">
                  <img
                    src={require("../assets/images/logo-cfg.png")}
                    alt="CFG Logo"
                    className="h-[40px] mx-auto"
                  />
                </NavLink>
              )}
              {pageTitle && (
                <div className="truncate text-[#FF6A16] text-lg">
                  {pageTitle}
                </div>
              )}
            </div>
            <div className="shrink-0 min-w-[55px]">
              {headerType === HeaderType.default && !rightSideIcon && (
                <NavLink to="/profile">
                  <img
                    src={require("../assets/images/icon-profile.png")}
                    alt="Profile icon"
                    className="w-[33px] h-[33px] ml-auto"
                  />
                </NavLink>
              )}
              {rightSideIcon}
            </div>
          </div>
        </header>
        {headerButton && <div>{headerButton}</div>}
        <div
          className={`side-menu bg-ipad-logo bg-no-repeat bg-fixed bg-[left_30px_top] bg-[length:200px_150px] ${
            navExpanded ? "close" : ""
          } hidden md:block fixed top-0 left-0 h-full overflow-x-hidden overflow-y-auto p-[15px]`}
        >
          <button
            id="hamburger"
            className="mb-[45px]"
            onClick={() => dispatch(toggleMenu(!navExpanded))}
          >
            <img
              src={require("../assets/images/icon-hamburger.png")}
              alt="Menu icon"
              className="w-[33px] h-[33px] relative top-[15px] left-[11px]"
            />
          </button>
          <PageLinks setMenuOpen={setMenuOpen} isMenuMinimized={navExpanded} />
        </div>
        <main
          className={`flex-1 md:bg-[#333333] md:bg-opacity-70 ${
            stickyHeaderFooter ? "overflow-y-auto" : ""
          } py-5 px-[10px]`}
        >
          {children}
        </main>
        {footerButton && <footer>{footerButton}</footer>}
      </div>
      <PaymentNotification
        open={notificationVisible}
        close={handleNotificationDialogClose}
        data={pusherData}
      />
    </div>
  );
};

export default Layout;

import Layout from "../../../components/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { get, post, put } from "../../../utils/Api";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import AcceptButton from "../../../components/AcceptButton";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { Checkbox } from "primereact/checkbox";
import { SelectButton } from "primereact/selectbutton";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect";
import { format } from "date-fns";
import ValidationError from "../../../components/ValidationError";
import TableFooter from "../../../components/TableFooter";
import { formatCurrency, formatDate, formatTime } from "../../../utils/Common";
import TableSkeleton from "../../../components/TableSkeleton";
import FullScreenDialog from "../../../components/FullScreenDialog";
import OrderDetails from "../../OrderDetails";
import CancelButton from "../../../components/CancelButton";
import { Dialog } from "primereact/dialog";
import TableHeader from "../../../components/TableHeader";
import FilterButton from "../../../components/FilterButton";
import { Dropdown } from "primereact/dropdown";

type OrderData = {
  id: number;
  total: number;
  status: number;
  created_at: string;
};

type OrderSearchParams = {
  start_date: string;
  end_date: string;
};

const FreeGiftCreate = (props: any) => {
  const navigate = useNavigate();
  const [gift_id] = useState(useLocation()?.state?.gift_id);
  const [giftLoading, setGiftLoading] = useState(false);
  const [selectedFreeGifts, setSelectedFreeGifts] = useState<any>(null);
  const [selectedProducts, setSelectedProducts] = useState<any>(null);
  const [allProducts, setAllProducts] = useState<any>(null);
  const [allFreeGifts, setAllFreeGifts] = useState<any>(null);
  const [orderFilterVisible, setOrderFilterVisible] = useState(false);
  const [filterOrderDate, setFilterOrderDate] = useState<any>(null);
  const [validDate, setValidDate] = useState<any>(null);
  const [ordersData, setOrdersData] = useState([]);

  const [showDetail, setShowDetail] = useState(false);
  const [showDetailParam, setShowDetailParam] = useState({});

  // const sources = useApiData("/admin/get_sources");
  // const sourcesData = sources.data as any;
  // const sourcesList = sourcesData?.map((item: any) => {
  //   return { label: item?.name, value: item?.id };
  // });
  const productTemplate = (option: any) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src={option.image_url}
          className={`mr-2`}
          style={{ width: "18px" }}
        />
        <div>{option.name}</div>
      </div>
    );
  };

  const sourcesOptions = [
    { label: "POS", value: 1 },
    { label: "E-Commerce", value: 2 },
  ];
  const giftTypeOptions = [
    { label: "Product", value: 1 },
    { label: "Category", value: 2 },
  ];
  const purchaseMethodOptions = [
    { name: "Order Price", value: "order_price" },
    { name: "Order Quantity", value: "order_qty" },
  ];
  const discountMethodOptions = [
    { name: "Percentage", value: "percent" },
    { name: "Fixed Value", value: "fixed" },
  ];
  const targetTypeOptions = [
    { name: "New Register", value: "new_register" },
    { name: "Existing Customor", value: "existing_customor" },
    { name: "All Customor", value: "all" },
  ];
  const productsOptions = [
    { name: "All Products", value: 1 },
    { name: "Designated Products", value: 0 },
  ];

  useEffect(() => {
    if (gift_id) {
      getGiftDetail();
    }
    getAllProducts();
    getAllFreeGifts();
  }, []);

  const getGiftDetail = async () => {
    try {
      const response = await get(`/admin/giveaway/${gift_id}`);
      if (response?.success && response?.data) {
        const giveAwayProductsIds: any = response?.data?.giveaway_products.map(
          (item: any) => item.id,
        );
        const designatedProductsIds: any =
          response?.data?.designated_products.map((item: any) => item.id);

        setSelectedProducts(designatedProductsIds[0]);
        setSelectedFreeGifts(giveAwayProductsIds);

        // setOrdersData(response?.data?.orders);

        formik.setValues({
          id: response?.data?.id || null,
          name: response?.data?.name || "",
          api_key_id: response?.data?.api_key_id || "",
          min_purchase_qty: response?.data?.min_purchase_qty || null,
          giveaway_qty: response?.data?.giveaway_qty || null,
          valid_date_start: response?.data?.valid_date_start
            ? format(new Date(response?.data?.valid_date_start), "yyyy-MM-dd")
            : "",
          valid_date_end: response?.data?.valid_date_end
            ? format(new Date(response?.data?.valid_date_end), "yyyy-MM-dd")
            : "",
          status: response?.data?.status || 0,
          // created_at: response?.data?.created_at || "",
          designated_product_ids: designatedProductsIds.toString() || "",
          giveaway_product_ids: giveAwayProductsIds.toString() || "",
        });

        setValidDate([
          new Date(response?.data?.valid_date_start),
          new Date(response?.data?.valid_date_end),
        ]);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const getAllFreeGifts = async () => {
    try {
      const response = await get("/admin/get_all_products?is_free_gift=1");
      if (response?.success && response?.data) {
        setAllFreeGifts(response?.data);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const getAllProducts = async () => {
    try {
      const response = await get("/admin/get_all_products?is_plan=1");
      if (response?.success && response?.data) {
        setAllProducts(response?.data);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      id: null,
      name: "",
      designated_product_ids: "",
      min_purchase_qty: null,
      giveaway_product_ids: "",
      giveaway_qty: null,
      valid_date_start: "",
      valid_date_end: "",
      status: 0,
      // created_at: "",
      api_key_id: "", //source “pos” or "ecommerce""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      valid_date_start: Yup.date().required("Start Date is required"),
      valid_date_end: Yup.date().required("End Date is required"),
      designated_product_ids: Yup.string().required("Product is required"),
      giveaway_product_ids: Yup.string().required("Free Gifts is required"),
      giveaway_qty: Yup.number().required("Quantity is required"),
      min_purchase_qty: Yup.number().required(
        "Mininum Purchase Quantity is required",
      ),
      api_key_id: Yup.string().required("Source is required"),
    }),
    onSubmit: async (values) => {
      const {
        id,
        name,
        designated_product_ids,
        min_purchase_qty,
        giveaway_product_ids,
        giveaway_qty,
        valid_date_start,
        valid_date_end,
        status,
        api_key_id,
      } = values;
      console.log("onSubmit");
      let response;
      try {
        setGiftLoading(true);
        if (gift_id) {
          response = await put(`/admin/giveaway/${gift_id}`, values);
        } else {
          const formData = new FormData();
          formData.append("name", name);
          formData.append("designated_product_ids", designated_product_ids);
          formData.append(
            "min_purchase_qty",
            min_purchase_qty as unknown as string,
          );
          formData.append("giveaway_product_ids", giveaway_product_ids);
          formData.append("giveaway_qty", giveaway_qty as unknown as string);

          formData.append("valid_date_start", valid_date_start);
          formData.append("valid_date_end", valid_date_end);
          formData.append("status", status as unknown as string);
          formData.append("api_key_id", api_key_id);

          console.log(formData);

          response = await post("/admin/giveaway", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }
        setGiftLoading(false);

        if (response?.success) {
          toast.success(response?.message);
          navigate("/free-gift");
        }
      } catch (error: any) {
        setGiftLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  const handleDateChange = (fieldName: any, selectedDate: any) => {
    const formattedDate = selectedDate
      ? format(selectedDate, "yyyy-MM-dd")
      : "";
    formik.setFieldValue(fieldName, formattedDate);
  };

  // if(code_id) {
  const {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  } = usePaginatedApiData<OrderData, OrderSearchParams>(
    `admin/giveaway/${gift_id}/orders`,
    {
      start_date: "",
      end_date: "",
    },
    1,
  );
  const rawRecords = data as any;
  const records = rawRecords.orders as OrderData[];
  // }

  const handleView = (id: any) => {
    setShowDetailParam({ order_id: id });
    setShowDetail(true);
  };

  const formatCalanderDate = (date: (Date | null)[]) => {
    if (!date || date.length < 1 || !date[0]) {
      return { startDate: "", endDate: "" };
    }

    const startDate = date[0];
    const endDate = date[1];

    let startDateString = "";
    let endDateString = "";

    if (startDate) {
      const start_year = startDate.getFullYear();
      const start_month = String(startDate.getMonth() + 1).padStart(2, "0");
      const start_day = String(startDate.getDate()).padStart(2, "0");
      startDateString = `${start_year}-${start_month}-${start_day}`;
    }

    if (endDate) {
      const end_year = endDate.getFullYear();
      const end_month = String(endDate.getMonth() + 1).padStart(2, "0");
      const end_day = String(endDate.getDate()).padStart(2, "0");
      endDateString = `${end_year}-${end_month}-${end_day}`;
    }

    return { startDate: startDateString, endDate: endDateString };
  };

  const formattedFilterDate = formatCalanderDate(filterOrderDate);
  // const formattedValidDate = formatCalanderDate(filterOrderDate);

  return (
    <Layout>
      <FullScreenDialog
        visible={showDetail}
        onHide={() => setShowDetail(false)}
      >
        <OrderDetails params={showDetailParam} close={setShowDetail} />
      </FullScreenDialog>
      <Dialog
        header="Filter"
        position="bottom"
        visible={orderFilterVisible}
        style={{
          width: "100vw",
          maxWidth: "450px",
          margin: 0,
          border: 0,
        }}
        onHide={() => setOrderFilterVisible(false)}
      >
        <div className="mb-[10px]">
          <label htmlFor="filter-mobile" className="mb-[5px] block">
            Order Date
          </label>
          <Calendar
            className="w-full"
            name="order_date"
            dateFormat="dd M yy"
            selectionMode="range"
            value={filterOrderDate}
            onChange={(e) => {
              if (Array.isArray(e.value) && e.value.length === 2) {
                const [startDate, endDate] = e.value;
                setFilterOrderDate([startDate, endDate]);
              }
            }}
          />
        </div>
        <div className="flex flex-row justify-between items-center pt-[10px]">
          <CancelButton
            label="Reset"
            onClick={() => {
              setFilterOrderDate(null);
              handleSearchParamsChange({
                start_date: "",
                end_date: "",
              });
              setOrderFilterVisible(false);
            }}
          />
          <AcceptButton
            label="Apply"
            onClick={() => {
              handleSearchParamsChange({
                start_date: formattedFilterDate?.startDate,
                end_date: formattedFilterDate?.endDate,
              });
              setOrderFilterVisible(false);
            }}
          />
        </div>
      </Dialog>
      <button onClick={() => navigate(-1)} className="py-[8px] px-[12px]">
        <img
          src={require("../../../assets/images/icon-back.png")}
          alt="Back icon"
          className="w-[34px] h-[34px]"
        />
      </button>
      <div className="min-h-auto p-[17px] card-cfg">
        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-left items-center pb-[15px] text-[#DA5E18]">
            <label>Free Gift</label>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Name</label>
              <InputText
                className={`w-full ${
                  formik.touched.name && formik.errors.name ? "p-invalid" : ""
                }`}
                name="name"
                placeholder="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <ValidationError
                className={
                  formik.touched.name && formik.errors.name ? "block" : "hidden"
                }
              >
                {formik.errors.name}
              </ValidationError>
            </div>
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Sources</label>
              <Dropdown
                className={`w-full ${
                  formik.touched.api_key_id && formik.errors.api_key_id
                    ? "p-invalid"
                    : ""
                }}`}
                options={sourcesOptions}
                placeholder="Select Source"
                value={formik.values.api_key_id}
                onChange={(e) => formik.setFieldValue(`api_key_id`, e.value)}
                onBlur={formik.handleBlur}
              />
              <ValidationError
                className={
                  formik.touched.api_key_id && formik.errors.api_key_id
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.api_key_id}
              </ValidationError>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Product</label>
              <Dropdown
                value={selectedProducts}
                onChange={(e) => {
                  setSelectedProducts(e.value);
                  formik.setFieldValue(
                    `designated_product_ids`,
                    e.value.toString(),
                  );
                  console.log(e.value);
                }}
                options={allProducts}
                optionLabel="name"
                optionValue="id"
                filter
                placeholder="Select Product"
                className={`w-auto md:w-20rem ${
                  formik.touched.designated_product_ids &&
                  formik.errors.designated_product_ids
                    ? "p-invalid"
                    : ""
                }`}
                itemTemplate={productTemplate}
              />
              <ValidationError
                className={
                  formik.touched.designated_product_ids &&
                  formik.errors.designated_product_ids
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.designated_product_ids}
              </ValidationError>
            </div>
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">
                Minimum Purchase Quantity
              </label>
              <InputNumber
                className={`w-auto ${
                  formik.touched.min_purchase_qty &&
                  formik.errors.min_purchase_qty
                    ? "p-invalid"
                    : ""
                }`}
                name="min_purchase_qty"
                placeholder="Mininum Purchase"
                value={formik.values.min_purchase_qty}
                onChange={(e) =>
                  formik.setFieldValue("min_purchase_qty", e.value)
                }
                onBlur={formik.handleBlur}
              />
              <ValidationError
                className={
                  formik.touched.min_purchase_qty &&
                  formik.errors.min_purchase_qty
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.min_purchase_qty}
              </ValidationError>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Free Gift</label>
              <MultiSelect
                value={selectedFreeGifts}
                onChange={(e) => {
                  setSelectedFreeGifts(e.value);
                  formik.setFieldValue(
                    `giveaway_product_ids`,
                    e.value.toString(),
                  );
                  console.log(e.value);
                }}
                options={allFreeGifts}
                optionLabel="name"
                optionValue="id"
                filter
                placeholder="Select Free Gift"
                maxSelectedLabels={1}
                className={`w-auto md:w-20rem ${
                  formik.touched.giveaway_product_ids &&
                  formik.errors.giveaway_product_ids
                    ? "p-invalid"
                    : ""
                }`}
                itemTemplate={productTemplate}
              />
              <ValidationError
                className={
                  formik.touched.giveaway_product_ids &&
                  formik.errors.giveaway_product_ids
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.giveaway_product_ids}
              </ValidationError>
            </div>
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Quantity</label>
              <InputNumber
                className={`w-full ${
                  formik.touched.giveaway_qty && formik.errors.giveaway_qty
                    ? "p-invalid"
                    : ""
                }`}
                name="quantity"
                placeholder="Free Gifts Quantity"
                value={formik.values.giveaway_qty}
                onChange={(e) => formik.setFieldValue("giveaway_qty", e.value)}
                onBlur={formik.handleBlur}
              />
              <ValidationError
                className={
                  formik.touched.giveaway_qty && formik.errors.giveaway_qty
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.giveaway_qty}
              </ValidationError>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">Valid Date</label>
              <Calendar
                className={`w-full ${
                  (formik.touched.valid_date_start &&
                    formik.errors.valid_date_start) ||
                  (formik.touched.valid_date_end &&
                    formik.errors.valid_date_end)
                    ? "p-invalid"
                    : ""
                }`}
                name="valid_date"
                placeholder="Valid Date"
                dateFormat="dd M yy"
                selectionMode="range"
                value={validDate}
                onChange={(e) => {
                  if (Array.isArray(e.value) && e.value.length === 2) {
                    const [startDate, endDate] = e.value;
                    setValidDate([startDate, endDate]);
                    handleDateChange("valid_date_start", startDate);
                    handleDateChange("valid_date_end", endDate);
                  }
                }}
              />
              <ValidationError
                className={
                  formik.touched.valid_date_start &&
                  formik.errors.valid_date_start
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.valid_date_start}
              </ValidationError>
              <ValidationError
                className={
                  formik.touched.valid_date_end && formik.errors.valid_date_end
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.valid_date_end}
              </ValidationError>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label className="ml-[5px] mb-[5px]">
                Status : {formik.values.status ? "Active" : "Inactive"}
              </label>
              <InputSwitch
                name="status"
                inputId="status"
                checked={formik.values.status ? true : false}
                onChange={(e) => {
                  formik.setFieldValue(`status`, e.value ? 1 : 0);
                }}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <AcceptButton
            label={gift_id ? "Update" : "Save"}
            className="mt-[20px] ml-auto block"
            loading={giftLoading}
          />
        </form>
        {gift_id && (
          <div className="pt-[17px]">
            <div className="flex flex-wrap">
              <div className="flex w-full justify-between items-center pb-[15px] text-[#DA5E18]">
                <div className="flex flex-wrap gap-3 items-center">
                  <label>Orders History</label>
                  <div
                    className={
                      "w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] rounded-[10px] p-[12px] ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
                    }
                  >
                    Orders
                    <span className="text-[24px] ml-[8px]">
                      {rawRecords?.orders_total_qty ?? 0}
                    </span>
                  </div>
                  <div
                    className={
                      "w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] rounded-[10px] p-[12px] ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
                    }
                  >
                    Sales
                    <span className="text-[24px] ml-[8px]">
                      {rawRecords?.orders_total_sales
                        ? formatCurrency(rawRecords.orders_total_sales)
                        : 0}
                    </span>
                  </div>
                </div>
                <FilterButton onClick={() => setOrderFilterVisible(true)} />
              </div>
            </div>
            {loading && gift_id && <TableSkeleton />}
            {!loading && gift_id && records && (
              <div className="overflow-x-auto">
                <table className="table-cfg">
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((row: OrderData) => {
                      return (
                        <tr key={`table-row-${row?.id}`}>
                          <td data-label="Order ID">
                            <label
                              className="text-[#E5622A] cursor-pointer underline"
                              onClick={() => handleView(row?.id)}
                            >
                              #{row?.id}
                            </label>
                          </td>
                          <td data-label="Total">
                            {formatCurrency(row?.total)}
                          </td>
                          <td data-label="Status" className="text-center">
                            <div
                              className={`${
                                row?.status === 1
                                  ? "bg-green-300"
                                  : row?.status === 0
                                  ? "bg-gray-300"
                                  : "bg-red-300 "
                              } px-[8px] py-[3px] rounded-[30px] inline text-xs w-fit lg:w-full`}
                            >
                              {row?.status === 1
                                ? "Paid"
                                : row?.status === 0
                                ? "Unpaid"
                                : row?.status === 4
                                ? "Refunded"
                                : "Failed"}
                            </div>
                          </td>
                          <td data-label="Date">
                            <div className="whitespace-nowrap">
                              {formatDate(row?.created_at)}
                              <span className="text-xs pl-[5px]">
                                {formatTime(row?.created_at)}
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}

            {/* <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            /> */}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default FreeGiftCreate;
function useApiData(arg0: string) {
  throw new Error("Function not implemented.");
}

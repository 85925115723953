import { Navigate, useLocation } from "react-router-dom";
import { isRole } from "./Common";
import { useAppSelector } from "../hooks/useStore";

const ProtectedRoute = ({ children, roles }: any) => {
  const location = useLocation();
  const { user } = useAppSelector((state) => state.userAuth);
  if (user?.email) {
    if (isRole(roles)) {
      return children;
    }
    return <Navigate to="/permission-denied" />;
  }
  return <Navigate to="/login" state={{ path: location.pathname }} replace />;
};

export default ProtectedRoute;

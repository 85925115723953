import { useFormik } from "formik";
import { InputSwitch } from "primereact/inputswitch";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Layout from "../../../components/Layout";
import { post } from "../../../utils/Api";
import { TabPanel, TabView } from "primereact/tabview";
import { useLocation, useNavigate } from "react-router-dom";
import AcceptButton from "../../../components/AcceptButton";
import ValidationError from "../../../components/ValidationError";
import { InputTextarea } from "primereact/inputtextarea";

const EcommerceVideoCreate = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedFile, setSelectedFile] = useState("");
  const [selectedVideo, setSelectedVideo] = useState<File | null>(null);
  const [videoLoading, setvideoLoading] = useState(false);
  const [videoThumbnail, setVideoThumbnail] = useState("");

  console.log("test", location.state.formikValueEcommerceVideo);
  const formikValueEcommerceVideo = location.state
    ? location.state.formikValueEcommerceVideo
    : null;

  useEffect(() => {
    if (formikValueEcommerceVideo && formikValueEcommerceVideo.thumbnail_url) {
      setSelectedFile(formikValueEcommerceVideo.thumbnail_url);
    }
  }, [formikValueEcommerceVideo]);

  const extractVideoThumbnail = (file: File) => {
    const url = URL.createObjectURL(file);
    const video = document.createElement("video");
    video.src = url;
    video.onloadedmetadata = () => {
      video.currentTime = 1;
    };
    video.onseeked = () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        if (blob) {
          setVideoThumbnail(URL.createObjectURL(blob));
          formik_ecommerce_video.setFieldValue("thumbnail_url", blob);
        }
      }, "image/jpeg");
    };
  };

  interface FormValues {
    id: number | string;
    url: Blob | null;
    thumbnail_url: Blob | null;
    title: string;
    subtitle: string;
    status: boolean;
  }

  const formik_ecommerce_video = useFormik<FormValues>({
    initialValues: {
      id: formikValueEcommerceVideo?.id || 0,
      thumbnail_url: null,
      title: formikValueEcommerceVideo?.title || "",
      subtitle: formikValueEcommerceVideo?.subtitle || "",
      status: formikValueEcommerceVideo?.status === 1 ? true : true,
      url: formikValueEcommerceVideo?.url || "", // Added url field
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Video Title is required"),
      subtitle: Yup.string().required("Video Subtitle is required"),
      url: Yup.mixed().test(
        "url-required",
        "Video is required",
        function (value) {
          if (
            formik_ecommerce_video.values.id !== 0 &&
            !formik_ecommerce_video.values.url
          ) {
            return true;
          }
          return false;
        },
      ),
    }),
    onSubmit: async (values) => {
      const { id, title, subtitle, status } = values;
      try {
        setvideoLoading(true);
        const formData = new FormData();
        if (id !== 0) {
          formData.append("id", id as unknown as string);
        }
        if (values?.thumbnail_url instanceof Blob) {
          formData.append(
            "thumbnail_url",
            values.thumbnail_url,
            "thumbnail.jpg",
          );
        }
        if (selectedVideo) {
          formData.append("url", selectedVideo);
        }
        formData.append("title", title);
        formData.append("subtitle", subtitle);
        formData.append("status", status ? "1" : "0");
        for (const [key, value] of formData.entries()) {
          console.log(key, value);
        }
        const response = await post("/admin/save_ecommerce_video", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setvideoLoading(false);
        if (response?.success) {
          toast.success(response?.message);
          setSelectedFile("");
          formik_ecommerce_video.resetForm();
          navigate("/ecommerce-video");
        }
      } catch (error: any) {
        setvideoLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  return (
    <Layout>
      <TabView>
        <TabPanel header="Video" leftIcon="pi pi-video mr-2">
          <div className="card-cfg !shadow-none">
            <form onSubmit={formik_ecommerce_video.handleSubmit}>
              <div className="flex md:justify-start md:flex-row flex-col gap-4">
                <div className="bg-[#f7f3f3] text-center p-[10px] rounded-[7px]">
                  <div
                    className={`w-[128px] h-[128px] border border-solid rounded-[6px] bg-[#FFFFFF] m-auto flex justify-center items-center relative ${
                      formik_ecommerce_video.touched.thumbnail_url &&
                      formik_ecommerce_video.errors.thumbnail_url
                        ? "border-[#f44336]"
                        : "border-[#ACACAC]"
                    }`}
                  >
                    {selectedFile || videoThumbnail ? (
                      <div className="w-full h-full relative">
                        <img
                          src={selectedFile || videoThumbnail}
                          onError={() =>
                            setSelectedFile(
                              `${process.env.PUBLIC_URL}/no-image.png`,
                            )
                          }
                          alt="Preview"
                          className="m-[auto] w-full h-full object-cover rounded-[6px] p-[10px]"
                        />
                        <button
                          className="absolute top-[-10px] right-[-10px] rounded-[50%] bg-gradient-to-b from-[#FFD3AB] to-[#FFF6E3]"
                          onClick={() => {
                            formik_ecommerce_video.setFieldValue(
                              "thumbnail_url",
                              null,
                            );
                            setSelectedFile("");
                            setSelectedVideo(null);
                            setVideoThumbnail("");
                          }}
                        >
                          <img
                            src={require("../../../assets/images/icon-close.png")}
                            alt="Remove icon"
                            className="w-[25px]"
                          />
                        </button>
                      </div>
                    ) : (
                      <>
                        <input
                          type="file"
                          accept="video/*, image/png, image/jpeg"
                          id="thumbnail_url"
                          name="thumbnail_url"
                          hidden
                          onChange={(e: any) => {
                            const file = e.target.files[0];
                            if (file) {
                              if (file.type.startsWith("video/")) {
                                setSelectedVideo(file);
                                extractVideoThumbnail(file);
                              } else {
                                setSelectedFile(URL.createObjectURL(file));
                                formik_ecommerce_video.setFieldValue(
                                  "thumbnail_url",
                                  file,
                                );
                              }
                            }
                          }}
                        />
                        <label
                          htmlFor="thumbnail_url"
                          className="text-[12px] leading-[15px] text-[#4C4C4C] font-[400] w-[80px] flex justify-center items-center flex-col text-center gap-y-[7px]"
                        >
                          <img
                            src={require("../../../assets/images/icon-add-circle.png")}
                            alt="Add icon"
                            className="w-[22px]"
                          />
                          Add Video
                        </label>
                      </>
                    )}
                  </div>
                  <ValidationError
                    className={
                      formik_ecommerce_video.touched.url &&
                      formik_ecommerce_video.errors.url
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik_ecommerce_video.errors.url}
                  </ValidationError>
                  <div className="order-9 md:order-9 xl:order-9">
                    <label
                      htmlFor="status"
                      className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block"
                    >
                      Status
                    </label>
                    <InputSwitch
                      name="status"
                      inputId="status"
                      checked={formik_ecommerce_video.values.status}
                      onChange={formik_ecommerce_video.handleChange}
                      onBlur={formik_ecommerce_video.handleBlur}
                    />
                  </div>
                </div>
                <div className="grid md:grid-cols-3 xl:grid-cols-4 w-full gap-4 bg-[#f7f3f3] p-[10px] rounded-[7px]">
                  <div className="order-3 md:order-3 xl:order-3">
                    <label
                      htmlFor="name"
                      className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
                    >
                      Video Title
                    </label>
                    <InputTextarea
                      className={`w-full ${
                        formik_ecommerce_video.touched.title &&
                        formik_ecommerce_video.errors.title
                          ? "p-invalid"
                          : ""
                      }`}
                      name="title"
                      id="title"
                      placeholder="Video Title"
                      value={formik_ecommerce_video.values.title}
                      onChange={formik_ecommerce_video.handleChange}
                      onBlur={formik_ecommerce_video.handleBlur}
                    />
                    <ValidationError
                      className={
                        formik_ecommerce_video.touched.title &&
                        formik_ecommerce_video.errors.title
                          ? "visible"
                          : "invisible"
                      }
                    >
                      {formik_ecommerce_video.errors.title}
                    </ValidationError>
                  </div>
                  <div className="order-3 md:order-3 xl:order-3">
                    <label
                      htmlFor="name"
                      className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
                    >
                      Video Subtitle
                    </label>
                    <InputTextarea
                      className={`w-full ${
                        formik_ecommerce_video.touched.subtitle &&
                        formik_ecommerce_video.errors.subtitle
                          ? "p-invalid"
                          : ""
                      }`}
                      name="subtitle"
                      id="subtitle"
                      placeholder="Video subtitle"
                      value={formik_ecommerce_video.values.subtitle}
                      onChange={formik_ecommerce_video.handleChange}
                      onBlur={formik_ecommerce_video.handleBlur}
                    />
                    <ValidationError
                      className={
                        formik_ecommerce_video.touched.subtitle &&
                        formik_ecommerce_video.errors.subtitle
                          ? "visible"
                          : "invisible"
                      }
                    >
                      {formik_ecommerce_video.errors.subtitle}
                    </ValidationError>
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                <AcceptButton
                  label={
                    formik_ecommerce_video.values.id !== 0 ? "Update" : "Save"
                  }
                  loading={videoLoading}
                  className="mt-[20px]"
                  type="submit"
                />
              </div>
            </form>
          </div>
        </TabPanel>
      </TabView>
    </Layout>
  );
};

export default EcommerceVideoCreate;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/Layout";
import usePaginatedApiData from "../../hooks/usePaginatedApiData";
import { HeaderType, Inventory, InventoryGroup } from "../../interfaces";
import {
  setCart,
  setCartItems,
  setOrderInfo,
  updateDeliveryOptions,
  updateOrderSettings,
  // updatePurchaseOptions,
  updateSubscriptionOptions,
} from "../../redux/slices/orderSlice";
import { get } from "../../utils/Api";
import FreeGiftSelection from "./FreeGiftSelection";
import GridView from "./GridView";
import GridViewSkeleton from "./GridViewSkeleton";
import ListView from "./ListView";
import ListViewSkeleton from "./ListViewSkeleton";
// import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import PurchaseOptionsDialog from "../../components/PurchaseOptionsDialog";
import { useAppSelector } from "../../hooks/useStore";
import { store } from "../../redux/store";
import { formatCurrency } from "../../utils/Common";

type InventorySearchParams = {
  category_id: number | null;
  subscription: number | null;
};

export const KnobProgress = () => {
  return (
    <img
      src={require("../../assets/images/chart-step-1.png")}
      alt="Chart step 1"
      className="w-[55px] h-[55px]"
    />
  );
};

const NewOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selection = useLocation()?.state?.selection;
  const subOptions = useAppSelector((state) => state.order.subscriptionOptions);
  const { cartItems, purchaseOptions, orderInfo, cart } = useAppSelector(
    (state) => state.order,
  );
  const [viewType, setViewType] = useState("grid");
  const toggleViewType = (newViewType: string) => {
    setViewType(newViewType);
  };
  const [purchaseOptionVisible, setPurchaseOptionVisible] = useState(false);
  const [selectedCategoryOption, setSelectedCategoryOption] = useState<
    number | null
  >(null);
  const [selectedPurchaseOption, setSelectedPurchaseOption] = useState(
    orderInfo?.purchaseOption?.length > 0 &&
      orderInfo?.purchaseOption[0]?.id === 2
      ? "Subscription"
      : orderInfo?.purchaseOption?.length > 0 &&
        orderInfo?.purchaseOption[0]?.id === 3
      ? "Plan"
      : "One Time Purchase",
  );
  const [allPurchaseOptions, setAllPurchaseOptions] = useState<any[]>([]);
  const [allCategories, setAllCategories] = useState<any[]>([]);
  const [subscriptionOptions, setSubscriptionOptions] = useState<any[]>([]);
  const [selectedSubscriptionOption, setSelectedSubscriptionOption] = useState(
    orderInfo?.subscriptionInfo?.subscriptionId ?? "",
  );
  const [checkedSelfCollect, setCheckedSelfCollect] = useState(
    orderInfo?.subscriptionInfo?.selfCollect ?? false,
  );
  const [freeGiftItems, setFreeGiftItems] = useState<Inventory[]>([]);
  const [freeGiftVisible, setFreeGiftVisible] = useState(false);

  const {
    data,
    loading,
    // error,
    // searchParams,
    // currentPage,
    // totalPages,
    handleSearchParamsChange,
    // handlePageChange,
    // handleReload,
  } = usePaginatedApiData<InventoryGroup[], InventorySearchParams>(
    "product/get_product_listings",
    {
      category_id: null,
      subscription:
        orderInfo?.purchaseOption?.length > 0 &&
        orderInfo?.purchaseOption[0]?.id === 2
          ? 1
          : null,
    },
    1,
  );

  const handlePurchaseOptionSelection = (name: any) => {
    if (name === "Plan") {
      navigate("/plan", { replace: true });
    } else {
      setSelectedPurchaseOption(name);
      setPurchaseOptionVisible(false);
      setRecords([]);
      let search = {};
      if (name === "Subscription") {
        setSelectedCategoryOption(null);
        search = { category_id: null, subscription: 1 };
      } else {
        setSelectedSubscriptionOption(
          orderInfo?.subscriptionInfo?.subscriptionId ?? "",
        );
        search = { subscription: null };
      }
      handleSearchParamsChange(search);
    }
  };

  useEffect(() => {
    if (selection) {
      handlePurchaseOptionSelection(selection);
    }
  }, [selection]);

  const handleCategoryOptionSelection = (id: number | null) => {
    setSelectedCategoryOption(id);
    setRecords([]);
    handleSearchParamsChange({
      category_id: id,
    });
  };

  const rawRecords = data as InventoryGroup[];
  // const search = searchParams as InventorySearchParams;
  const [records, setRecords] = useState<InventoryGroup[]>([]);

  useEffect(() => {
    const getAllSettings = async () => {
      try {
        const [
          orderSettingsResponse,
          // purchaseOptionsResponse,
          subscriptionOptionsResponse,
          deliveryOptionsResponse,
          mainCategoriesResponse,
          freeGiftsResponse,
        ] = await Promise.all([
          get(`get_setting`),
          // get(`/product/get_purchase_options`),
          get(`/product/get_subscription_options`),
          get(`/product/get_delivery_options`),
          get(`/product/get_categories`),
          get(`product/get_free_gifts`),
        ]);
        if (orderSettingsResponse.success && orderSettingsResponse.data) {
          dispatch(updateOrderSettings(orderSettingsResponse.data));
        }
        // if (purchaseOptionsResponse.success && purchaseOptionsResponse.data) {
        //   dispatch(updatePurchaseOptions(purchaseOptionsResponse.data));
        //   setAllPurchaseOptions(purchaseOptionsResponse.data);
        // }
        if (
          subscriptionOptionsResponse.success &&
          subscriptionOptionsResponse.data
        ) {
          dispatch(updateSubscriptionOptions(subscriptionOptionsResponse.data));
          setSubscriptionOptions(
            subscriptionOptionsResponse?.data.map((option: any) => {
              return {
                value: option?.id,
                label: option?.name,
              };
            }),
          );
        }
        if (deliveryOptionsResponse.success && deliveryOptionsResponse.data) {
          dispatch(updateDeliveryOptions(deliveryOptionsResponse.data));
        }
        if (mainCategoriesResponse.success && mainCategoriesResponse?.data) {
          setAllCategories(
            mainCategoriesResponse?.data.map((item: any) => {
              return {
                value: item?.id,
                label: item?.name,
              };
            }),
          );
        }
        if (freeGiftsResponse.success && freeGiftsResponse?.data) {
          setFreeGiftItems(freeGiftsResponse?.data);
        }
      } catch (error) {
        toast.error("Something went wrong!");
      }
    };
    getAllSettings();
  }, []);

  useEffect(() => {
    if (rawRecords?.length > 0) {
      setRecords(
        rawRecords.map((group: InventoryGroup) => {
          return {
            ...group,
            ...{
              products: group.products.map((item: Inventory) => {
                return {
                  ...item,
                  ...{
                    delivery_option_id: 2, // set default delivery option for all items
                  },
                };
              }),
            },
          };
        }),
      );
    }
  }, [rawRecords]);

  const headerButton = (
    <div className="w-full h-[70px] bg-[#DA5E18] text-white flex justify-between px-5">
      <Button
        link
        className="flex flex-row items-center flex-nowrap h-full"
        onClick={() => setPurchaseOptionVisible(true)}
      >
        <span className="text-[20px] text-white">{selectedPurchaseOption}</span>
        <img
          src={require("../../assets/images/icon-arrow-down-white.png")}
          alt="Down icon"
          className="w-[24px] h-[24px] ml-[5px]"
        />
      </Button>
      <div className="flex items-center justify-end">
        <div
          className={`p-[5px] mr-2 cursor-pointer ${
            viewType === "grid" ? "bg-[#FF6A16] rounded-full" : ""
          }`}
          onClick={() => toggleViewType("grid")}
        >
          <img
            src={require("../../assets/images/order/icon-grid-white.png")}
            alt="Grid View Icon"
            className={`w-[31px] h-[31px]`}
          />
        </div>
        <div
          className={`p-[5px] cursor-pointer ${
            viewType === "list" ? "bg-[#FF6A16] rounded-full" : ""
          }`}
          onClick={() => toggleViewType("list")}
        >
          <img
            src={require("../../assets/images/order/icon-list-white.png")}
            alt="List View Icon"
            className={`w-[31px] h-[31px]`}
          />
        </div>
      </div>
    </div>
  );

  const handleCloseFreeGift = () => {
    setFreeGiftVisible(false);
    viewCartPage();
  };

  const handleFreeGiftConfirm = () => {
    setFreeGiftVisible(false);
    viewCartPage();
  };

  const handleSelfCollectCheck = (event: any) => {
    setCheckedSelfCollect(event.checked);
  };

  const viewCartPage = () => {
    const orderInfo = store.getState().order.orderInfo;
    const items: Inventory[] = cartItems.map((item: any) => {
      return { ...item, ...{ price_formatted: formatCurrency(item?.price) } };
    });
    const subscriptionOption = subOptions?.filter(
      (item: any) => item?.id === orderInfo?.subscriptionInfo?.subscriptionId,
    );
    const subMonth =
      subscriptionOption?.length > 0
        ? subscriptionOption[0]?.subscription_month
        : 1;
    const sum =
      items?.length > 0
        ? items
            .map((item: Inventory) => {
              return item.price * item.quantity * subMonth;
            })
            ?.reduce((prev, next) => prev + next)
        : 0;
    const updatedInfo = {
      ...orderInfo,
      ...{
        sum,
        sum_formatted: formatCurrency(sum),
        subscriptionOption,
        purchaseOption: purchaseOptions.filter(
          (item: any) => item?.name === selectedPurchaseOption,
        ),
      },
    };
    if (items?.length > 0) {
      if (cart) {
        dispatch(
          setCart([...cart, ...[{ orderInfo: updatedInfo, cartItems: items }]]),
        );
      } else {
        dispatch(setCart([{ orderInfo: updatedInfo, cartItems: items }]));
      }
    }
    dispatch(setCartItems([]));
    dispatch(setOrderInfo({}));
    navigate("/new-order-cart");
  };

  const footerButton = (
    <div className="flex">
      {cart?.length > 0 && (
        <Button
          className="shrink-0 h-[70px] bg-[#4A4A4A] text-white text-[20px] px-[20px] flex flex-row justify-center items-center flex-nowrap rounded-none border-[#4A4A4A]"
          onClick={viewCartPage}
        >
          Next
        </Button>
      )}
      <Button
        className="w-full h-[70px] bg-[#DA5E18] text-white text-[20px] flex flex-row justify-center items-center flex-nowrap rounded-none"
        onClick={() => {
          if (cartItems.length > 0) {
            if (selectedPurchaseOption === "Subscription") {
              if (selectedSubscriptionOption === "") {
                toast.error("Please select Monthly Subscription");
              } else {
                dispatch(
                  setOrderInfo({
                    subscriptionInfo: {
                      subscriptionId: selectedSubscriptionOption,
                      selfCollect: checkedSelfCollect,
                    },
                  }),
                );
                if (freeGiftItems?.length > 0) {
                  setFreeGiftVisible(true);
                } else {
                  viewCartPage();
                }
              }
            } else {
              viewCartPage();
            }
          } else {
            toast.error("Please add Item to continue.");
          }
        }}
      >
        Create {selectedPurchaseOption}
      </Button>
    </div>
  );

  const updateRedux = (item: Inventory) => {
    const items = [...cartItems];
    const index = items.findIndex((el: Inventory) => el.id === item.id);
    if (index === -1) {
      if (item.quantity > 0) {
        items.push(item);
        dispatch(setCartItems(items));
      }
    } else {
      if (item.quantity > 0) {
        items[index] = item;
      } else {
        items.splice(index, 1);
      }
      dispatch(setCartItems(items));
    }
  };

  return (
    <Layout
      headerType={HeaderType.back}
      pageTitle={"New Order"}
      headerButton={headerButton}
      footerButton={footerButton}
      stickyHeaderFooter={true}
      rightSideIcon={<KnobProgress />}
    >
      <FreeGiftSelection
        items={freeGiftItems}
        open={freeGiftVisible}
        close={handleCloseFreeGift}
        confirm={handleFreeGiftConfirm}
      />
      {/* <Dialog
        header="Purchase Options"
        visible={purchaseOptionVisible}
        style={{ width: "350px", border: 0 }}
        onHide={() => {
          setPurchaseOptionVisible(false);
        }}
      >
        <div className="flex flex-col items-center gap-y-4">
          {allPurchaseOptions.map((item: any) => (
            <button
              className={`text-lg py-[2px] w-full border border-solid rounded ${
                selectedPurchaseOption === item?.name
                  ? " border-[#DA5E18] text-[#DA5E18] "
                  : " border-[#D8D8D8] text-[#000] "
              }`}
              key={item?.id}
              onClick={() => handlePurchaseOptionSelection(item?.name)}
            >
              {item?.name}
            </button>
          ))}
        </div>
      </Dialog> */}
      <PurchaseOptionsDialog
        selected={selectedPurchaseOption}
        visible={purchaseOptionVisible}
        onHide={() => {
          setPurchaseOptionVisible(false);
        }}
        onSelect={handlePurchaseOptionSelection}
      />
      <div className="px-2.5 min-h-screen">
        <div className="grid grid-cols-1 gap-x-2 gap-y-2">
          {selectedPurchaseOption === "One Time Purchase" && (
            <div className="col-span-1">
              <Dropdown
                options={[...[{ value: null, label: "All" }], ...allCategories]}
                value={selectedCategoryOption}
                onChange={(e) => handleCategoryOptionSelection(e?.value)}
                name="category_id"
                placeholder="Category"
                className="h-[30px] flex items-center w-full"
              />
            </div>
          )}
          {selectedPurchaseOption === "Subscription" && (
            <>
              <div className="col-span-1">
                <Dropdown
                  options={subscriptionOptions}
                  value={selectedSubscriptionOption}
                  onChange={(e) => setSelectedSubscriptionOption(e.value)}
                  name="subscription_option_id"
                  placeholder="Monthly Subscription"
                  className="h-[30px] flex items-center w-full"
                />
              </div>
              <div className="col-span-1 flex items-center">
                <Checkbox
                  name="self_collected"
                  onChange={handleSelfCollectCheck}
                  checked={checkedSelfCollect}
                />
                <label className="text-[#FFFFFF] capitalize text-[14px] pl-1.5">
                  Self Collect For First Order
                </label>
              </div>
            </>
          )}
        </div>
        {loading &&
          (viewType === "grid" ? <GridViewSkeleton /> : <ListViewSkeleton />)}
        {records?.length > 0 &&
          records.map((row: InventoryGroup, index: number) => {
            return (
              <div key={`new-order-subcategory-${index}`}>
                <div className="py-3 bg-[#343426] rounded-[7px] pl-3 mt-[20px] mb-[10px]">
                  <label className="text-[#DA5E18] text-base capitalize">
                    {row.sub_category?.name == null
                      ? "Bundle"
                      : row.sub_category?.name}
                  </label>
                </div>
                {viewType === "grid" ? (
                  <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2">
                    {row.products?.length > 0 &&
                      row.products.map((row: Inventory) => (
                        <div
                          className="col-span-1"
                          key={`new-order-table-grid-row-${row.id}`}
                        >
                          <GridView
                            item={row}
                            onQuantityChange={(event: Inventory) =>
                              updateRedux(event)
                            }
                          />
                        </div>
                      ))}
                  </div>
                ) : (
                  <div>
                    {row.products?.length > 0 &&
                      row.products.map((row: Inventory) => (
                        <div key={`new-order-table-list-row-${row.id}`}>
                          <ListView
                            item={row}
                            onQuantityChange={(event: Inventory) =>
                              updateRedux(event)
                            }
                          />
                        </div>
                      ))}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </Layout>
  );
};

export default NewOrder;

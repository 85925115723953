import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { HeaderType } from "../../interfaces";
import { useAppSelector } from "../../hooks/useStore";
import { useDispatch } from "react-redux";
import {
  setAdditionalInfo,
  setBillingInfo,
} from "../../redux/slices/orderSlice";
import { get, post } from "../../utils/Api";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
// import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import ValidationError from "../../components/ValidationError";
import { toast } from "react-toastify";
import CountryCodeDropdown from "../../components/CountryCodeDropdown";
import useDeliveryDateRequired from "../../hooks/useDeliveryDateRequired";
// import CancelButton from "../../components/CancelButton";

export const KnobProgress = () => {
  return (
    <img
      src={require("../../assets/images/chart-step-3.png")}
      alt="Chart step 3"
      className="w-[55px] h-[55px]"
    />
  );
};

const CustomerDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchResult, setSearchResult] = useState(null);
  const [agents, setAgents] = useState<any[]>([]);
  const { billingInfo, additionalInfo } = useAppSelector(
    (state) => state.order,
  );
  const [showAddressDialog, setShowAddressDialog] = useState(false);
  const [allAddress, setAllAddress] = useState<any[]>([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [confirmationSource, setConfirmationSource] = useState("");
  const [isCustomerSearched, setIsCustomerSearched] = useState(false);
  const deliveryDateRequired = useDeliveryDateRequired();
  const [venues, setVenues] = useState<any[]>([]);
  const [checkPostalCode, setCheckPostalCode] = useState(false);

  const formik = useFormik({
    initialValues: {
      customer_id: billingInfo?.customer_id || "",
      country_code: billingInfo?.country_code || "65",
      mobile: billingInfo?.mobile || "",
      first_name: billingInfo?.first_name || "",
      last_name: billingInfo?.last_name || "",
      email: billingInfo?.email || "",
      // dob: "",
      postal_code: billingInfo?.postal_code || "",
      street: billingInfo?.street || "",
      unit_no: billingInfo?.unit_no || "",
      shared_agent: billingInfo?.shared_agent || null,
      venue: billingInfo?.venue || null,
      same_as_billing_address: billingInfo?.same_as_billing_address || false,
      shipping_country_code: billingInfo?.shipping_country_code || "65",
      shipping_mobile: billingInfo?.shipping_mobile || "",
      shipping_first_name: billingInfo?.shipping_first_name || "",
      shipping_last_name: billingInfo?.shipping_last_name || "",
      shipping_postal_code: billingInfo?.shipping_postal_code || "",
      shipping_unit_no: billingInfo?.shipping_unit_no || "",
      shipping_street: billingInfo?.shipping_street || "",
    },
    validationSchema: Yup.object({
      // country_code: Yup.string().required("Country Code is required"),
      mobile: Yup.string()
        .min(8, "Invalid Mobile")
        // .matches(/^[0-9]{1,10}$/, "Mobile is not valid")
        .required("Mobile is required"),
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      // dob: Yup.date().required("Birthday Date is required"),
      email: Yup.string().required("Email is required"),
      postal_code: Yup.string()
        .min(6, "Invalid Postal Code")
        .matches(/^[0-9]+$/, "Invalid Postal Code")
        .required("Postal Code is required"),
      street: Yup.string().required("Street Name is required"),
      unit_no: Yup.string().required("Unit No. is required"),
      venue: Yup.string().required("Please Select A Venue"),
      shipping_mobile: Yup.string().when(
        "same_as_billing_address",
        (same_as_billing_address, schema) => {
          if (same_as_billing_address[0] === false && deliveryDateRequired)
            return schema
              .min(8, "Invalid Shipping Mobile")
              .required("Shipping Mobile is required");
          return schema;
        },
      ),
      shipping_first_name: Yup.string().when(
        "same_as_billing_address",
        (same_as_billing_address, schema) => {
          if (same_as_billing_address[0] === false && deliveryDateRequired)
            return schema.required("Shipping First Name is required");
          return schema;
        },
      ),
      shipping_last_name: Yup.string().when(
        "same_as_billing_address",
        (same_as_billing_address, schema) => {
          if (same_as_billing_address[0] === false && deliveryDateRequired)
            return schema.required("Shipping Last Name is required");
          return schema;
        },
      ),
      shipping_email: Yup.string().when(
        "same_as_billing_address",
        (same_as_billing_address, schema) => {
          if (same_as_billing_address[0] === false && deliveryDateRequired)
            return schema.required("Shipping Email is required");
          return schema;
        },
      ),
      shipping_postal_code: Yup.string().when(
        "same_as_billing_address",
        (same_as_billing_address, schema) => {
          if (same_as_billing_address[0] === false && deliveryDateRequired)
            return schema
              .min(6, "Invalid Shipping Postal Code")
              .matches(/^[0-9]+$/, "Invalid Shipping Postal Code")
              .required("Shipping Postal Code is required");
          return schema;
        },
      ),
      shipping_street: Yup.string().when(
        "same_as_billing_address",
        (same_as_billing_address, schema) => {
          if (same_as_billing_address[0] === false && deliveryDateRequired)
            return schema.required("Shipping Street Name is required");
          return schema;
        },
      ),
      shipping_unit_no: Yup.string().when(
        "same_as_billing_address",
        (same_as_billing_address, schema) => {
          if (same_as_billing_address[0] === false && deliveryDateRequired)
            return schema.required("Shipping Unit No. is required");
          return schema;
        },
      ),
      // shared_agent: Yup.string().required("Shared Agent is required"),
    }),
    onSubmit: async (values) => {
      // console.log(values);
      try {
        const formData = new FormData();
        // console.log("form data?", formData);
        if (values?.first_name) {
          formData.append("first_name", values?.first_name);
        }
        if (values?.last_name) {
          formData.append("last_name", values?.last_name);
        }
        if (values?.email) {
          formData.append("email", values?.email);
        }
        if (values?.mobile) {
          formData.append("mobile", values?.mobile);
        }
        if (values?.country_code) {
          formData.append("country_code", values?.country_code);
        }
        if (values?.customer_id !== "") {
          formData.append("customer_id", values?.customer_id);
        }
        const response = await post(`validate_customer_info`, formData);
        if (response?.success) {
          dispatch(setBillingInfo(values));
          if (values.venue) {
            const venue = venues.filter((venue: any) => {
              if (venue.id === values.venue) {
                return venue;
              }
            });
            dispatch(
              setAdditionalInfo({
                ...additionalInfo,
                ...{ venue },
              }),
            );
          }
          navigate("/new-order-confirm");
        }
      } catch (error: any) {
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  const [sameAsBilling, setSameAsBilling] = useState(
    formik.values.same_as_billing_address,
  );

  const handleCheckboxChange = () => {
    const newSameAsBilling = !sameAsBilling; // Toggle the value
    setSameAsBilling(newSameAsBilling);

    if (newSameAsBilling) {
      // Update shipping fields with billing fields
      formik.setFieldValue("shipping_country_code", formik.values.country_code);
      formik.setFieldValue("shipping_mobile", formik.values.mobile);
      formik.setFieldValue("shipping_first_name", formik.values.first_name);
      formik.setFieldValue("shipping_last_name", formik.values.last_name);
      formik.setFieldValue("shipping_postal_code", formik.values.postal_code);
      formik.setFieldValue("shipping_street", formik.values.street);
      formik.setFieldValue("shipping_unit_no", formik.values.unit_no);
    } else {
      // Clear shipping fields
      formik.setFieldValue("shipping_country_code", "65");
      formik.setFieldValue("shipping_mobile", "");
      formik.setFieldValue("shipping_first_name", "");
      formik.setFieldValue("shipping_last_name", "");
      formik.setFieldValue("shipping_postal_code", "");
      formik.setFieldValue("shipping_street", "");
      formik.setFieldValue("shipping_unit_no", "");
    }

    formik.setFieldValue("same_as_billing_address", newSameAsBilling);
  };

  const handleSearch = async (fieldType: any) => {
    const searchField = fieldType === "shipping" ? "shipping_mobile" : "mobile";
    const fieldValue = formik.values[searchField];

    if (!fieldValue || fieldValue.length < 8) {
      return;
    }

    if (fieldType === "main") {
      setIsCustomerSearched(true);
    }

    try {
      const response = await get(
        `customer/get_customer_by_mobile?mobile=${fieldValue}`,
      );
      if (response.success && response.data) {
        const searchResult = response.data;
        let updatedValues = {};

        if (fieldType === "main") {
          updatedValues = {
            customer_id: searchResult?.id,
            mobile: searchResult?.mobile,
            first_name: searchResult?.first_name,
            last_name: searchResult?.last_name,
            email: searchResult?.email,
            postal_code: searchResult?.address?.postal_code || "",
            street: searchResult?.address?.street || "",
            unit_no: searchResult?.address?.unit_no || "",
          };
        }
        if (fieldType === "shipping" || sameAsBilling) {
          updatedValues = {
            ...updatedValues,
            ...{
              shipping_mobile: searchResult?.mobile,
              shipping_first_name: searchResult?.first_name,
              shipping_last_name: searchResult?.last_name,
              shipping_postal_code: searchResult?.address?.postal_code || "",
              shipping_street: searchResult?.address?.street || "",
              shipping_unit_no: searchResult?.address?.unit_no || "",
            },
          };
        }
        formik.setValues({
          ...formik.values,
          ...updatedValues,
        });
        toast.success(response.message);
      } else {
        toast.error("Unable to search for contact");
      }
    } catch (error: any) {
      setSearchResult(null);
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  const getData = async () => {
    try {
      const [agentsResponse, VenuesResponse] = await Promise.all([
        get("agent/get_agents?type=withoutSelf"),
        get("venue/get_venues"),
      ]);
      if (agentsResponse?.success && agentsResponse?.data) {
        setAgents(agentsResponse.data);
      }
      if (VenuesResponse?.success && VenuesResponse?.data) {
        setVenues(VenuesResponse.data);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (sameAsBilling && formik) {
      formik.setFieldValue("shipping_country_code", formik.values.country_code);
      formik.setFieldValue("shipping_mobile", formik.values.mobile);
      formik.setFieldValue("shipping_first_name", formik.values.first_name);
      formik.setFieldValue("shipping_last_name", formik.values.last_name);
      formik.setFieldValue("shipping_postal_code", formik.values.postal_code);
      formik.setFieldValue("shipping_street", formik.values.street);
      formik.setFieldValue("shipping_unit_no", formik.values.unit_no);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sameAsBilling,
    formik?.values?.country_code,
    formik?.values?.mobile,
    formik?.values?.first_name,
    formik?.values?.last_name,
    formik?.values?.postal_code,
    formik?.values?.street,
    formik?.values?.unit_no,
  ]);

  const getAddress = async (postalcode: string, type: any) => {
    try {
      if (!postalcode || postalcode.length < 6) {
        return;
      }
      const response = await get(`get_address/${postalcode}`);
      if (response?.success && response?.data) {
        if (response?.data?.length === 1) {
          const fullAddress = response?.data[0]?.ADDRESS;
          const cleanedAddress = fullAddress
            .replace(/\sSINGAPORE\s\d{6}/, "")
            .replace(/\d{6}/g, "")
            .trim();
          if (type === "address") {
            formik.setFieldValue("street", cleanedAddress);
            formik.setFieldValue("unit_no", "");
          }
          if (type === "shipping_address" || sameAsBilling) {
            formik.setFieldValue("shipping_street", cleanedAddress);
            formik.setFieldValue("shipping_unit_no", "");
          }
          toast.success(response?.message);
          setCheckPostalCode(true);
        } else if (response?.data?.length > 1) {
          //postal code 569933 to get multiple address
          setAllAddress(response?.data);
          if (type === "address") {
            openAddressDialog();
          } else if (type === "shipping_address") {
            openShippingAddressDialog();
          }
          setCheckPostalCode(true);
        } else {
          toast.error("Address Not Found");
        }
      } else {
        console.error(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmAddress = () => {
    if (confirmationSource === "address") {
      formik.setFieldValue("street", selectedAddress);
      formik.setFieldValue("unit_no", "");
    }
    if (confirmationSource === "shipping_address" || sameAsBilling) {
      formik.setFieldValue("shipping_street", selectedAddress);
      formik.setFieldValue("shipping_unit_no", "");
    }
    setShowAddressDialog(false);
  };

  const openAddressDialog = () => {
    setConfirmationSource("address");
    setShowAddressDialog(true);
  };

  const openShippingAddressDialog = () => {
    setConfirmationSource("shipping_address");
    setShowAddressDialog(true);
  };

  const footerButton = (
    <button
      className={`w-full h-[70px] bg-[#DA5E18] text-white flex flex-row justify-center items-center flex-nowrap text-[20px] ${
        isCustomerSearched ? "" : "disabled:opacity-75"
      }`}
      onClick={() => {
        if (isCustomerSearched || formik.values.customer_id !== "") {
          if (!checkPostalCode && additionalInfo.deliveryDate) {
            toast.error("Please Select a valid Postal Code.");
          } else {
            formik.handleSubmit();
          }
        } else {
          toast.error("Please Search for Customer in System.");
        }
      }}
    >
      Confirm
    </button>
  );

  return (
    <Layout
      headerType={HeaderType.back}
      pageTitle={"New Orders"}
      footerButton={footerButton}
      stickyHeaderFooter={true}
      rightSideIcon={<KnobProgress />}
    >
      <Dialog
        header="Select Address"
        visible={showAddressDialog}
        onHide={() => setShowAddressDialog(false)}
        style={{ width: "90vw", border: 0 }}
        closable={false}
      >
        {allAddress.map((item: any, index: number) => {
          const cleanedAddress = item?.ADDRESS.replace(/\sSINGAPORE\s\d{6}/, "")
            .replace(/\d{6}/g, "")
            .trim();
          return (
            <div
              key={index}
              className="flex items-center mb-[20px] pb-[20px] border-b border-[#B9B7B7]"
            >
              <RadioButton
                value={item?.ADDRESS}
                name="addressRadioGroup"
                onClick={() => setSelectedAddress(cleanedAddress)}
              />
              <label className="ml-2 text-[#495057] text-[16px] leading-5">
                {cleanedAddress}
              </label>
            </div>
          );
        })}
        <button
          className="rounded-[7px] w-full h-[45px] bg-[#DA5E18] text-white flex flex-row justify-center items-center flex-nowrap"
          onClick={() => handleConfirmAddress()}
        >
          Confirm
        </button>
      </Dialog>
      <div className="flex flex-row justify-between items-center flex-nowrap">
        {/* <CancelButton label="Reset" onClick={() => formik.resetForm()} /> */}
      </div>
      <div className="pt-[9px]">
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <div className="bg-[rgba(247,247,247,0.5)] rounded-lg border-[#E3E3E3] border-[0.8px] pt-[14px] px-[9px] pb-[14px] mb-[16px] shadow-[2px_4px_4px_0_rgba(0,0,0,0.06)] text-sm">
            <label className="block text-[16px] text-[#DA5E18] bg-[#343426] rounded-[7px] px-[15px] py-[8px] max-w-[150px] text-center mb-[9px]">
              Customer Info
            </label>
            <div className="pb-[9px]">
              <div className="flex flex-row justify-start items-center gap-x-2">
                <CountryCodeDropdown
                  className={`w-[100px]`}
                  name="country_code"
                  value={formik.values.country_code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <InputText
                  className={`w-full ${
                    formik.touched.mobile && formik.errors.mobile
                      ? "p-invalid"
                      : ""
                  }`}
                  name="mobile"
                  placeholder="Mobile"
                  value={formik.values.mobile}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldValue("customer_id", "");
                  }}
                  onBlur={formik.handleBlur}
                />
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  className="rounded-full !bg-[#343426] !text-white p-[7px] w-[33px] h-[33px] flex justify-center items-center shrink-0"
                  onClick={() => handleSearch("main")}
                  disabled={formik.values.mobile.length < 8}
                >
                  <img
                    src={require("../../assets/images/icon-search.png")}
                    alt="Search icon"
                    className="w-[18px]"
                  />
                </Button>
              </div>
              <ValidationError
                className={
                  formik.touched.mobile && formik.errors.mobile
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.mobile}
              </ValidationError>
            </div>
            <div className="pb-[9px]">
              <div className="flex flex-row justify-start items-center flex-nowrap gap-x-2">
                <InputText
                  className={`w-full ${
                    formik.touched.first_name && formik.errors.first_name
                      ? "p-invalid"
                      : ""
                  }`}
                  name="first_name"
                  placeholder="First Name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <InputText
                  className={`w-full ${
                    formik.touched.last_name && formik.errors.last_name
                      ? "p-invalid"
                      : ""
                  }`}
                  name="last_name"
                  placeholder="Last Name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <ValidationError
                className={
                  formik.touched.first_name && formik.errors.first_name
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.first_name}
              </ValidationError>
              <ValidationError
                className={
                  formik.touched.last_name && formik.errors.last_name
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.last_name}
              </ValidationError>
            </div>
            <div className="pb-[9px]">
              <InputText
                className={`w-full ${
                  formik.touched.email && formik.errors.email ? "p-invalid" : ""
                }`}
                name="email"
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <ValidationError
                className={
                  formik.touched.email && formik.errors.email
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.email}
              </ValidationError>
            </div>

            {/* <div className="pb-[9px]">
              <Calendar
                className={`w-full ${
                  formik.touched.dob && formik.errors.dob ? "p-invalid" : ""
                }`}
                value={formik.values.dob}
                placeholder="Birthday"
                onChange={(e) => formik.setFieldValue("dob", e.value)}
                dateFormat="dd/mm/yy"
                yearRange="1900:2100"
              />
              <ValidationError
                className={
                  formik.touched.dob && formik.errors.dob ? "block" : "hidden"
                }
              >
                {formik.errors.dob}
              </ValidationError>
            </div> */}

            <div className="border-y-[0.5px] border-[#CFCFCF] my-[30px]"></div>
            <label className="block text-[16px] text-[#DA5E18] bg-[#343426] rounded-[7px] px-[15px] py-[8px] max-w-[150px] text-center">
              Billing Address
            </label>
            <div className="pb-[9px]">
              <div className="flex flex-row justify-start items-center gap-x-2 pt-[9px]">
                <InputText
                  className={`w-full ${
                    formik.touched.postal_code && formik.errors.postal_code
                      ? "p-invalid"
                      : ""
                  }`}
                  name="postal_code"
                  placeholder="Postal Code"
                  value={formik.values.postal_code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={6}
                />
                <Button
                  type="button"
                  severity="secondary"
                  outlined
                  className="rounded-full !bg-[#343426] !text-white p-[7px] w-[33px] h-[33px] flex justify-center items-center shrink-0"
                  onClick={() =>
                    getAddress(formik.values.postal_code, "address")
                  }
                  disabled={formik.values.postal_code.length < 6}
                >
                  <img
                    src={require("../../assets/images/icon-search.png")}
                    alt="Search icon"
                    className="w-[18px]"
                  />
                </Button>
              </div>
              <ValidationError
                className={
                  formik.touched.postal_code && formik.errors.postal_code
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.postal_code}
              </ValidationError>
            </div>
            <div className="pb-[9px]">
              <div>
                <InputTextarea
                  className={`w-full resize-none ${
                    formik.touched.street && formik.errors.street
                      ? "p-invalid"
                      : ""
                  }`}
                  rows={5}
                  name="street"
                  placeholder="Street Name"
                  value={formik.values.street}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <ValidationError
                className={
                  formik.touched.street && formik.errors.street
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.street}
              </ValidationError>
            </div>
            <div className="pb-[29px]">
              <div>
                <InputText
                  className={`w-full ${
                    formik.touched.unit_no && formik.errors.unit_no
                      ? "p-invalid"
                      : ""
                  }`}
                  name="unit_no"
                  placeholder="Unit No."
                  value={formik.values.unit_no}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (!formik.values.unit_no.startsWith("#")) {
                      formik.setFieldValue("unit_no", `#${newValue}`);
                    } else {
                      formik.setFieldValue("unit_no", newValue);
                    }
                    if (sameAsBilling) {
                      if (!formik.values.unit_no.startsWith("#")) {
                        formik.setFieldValue(
                          "shipping_unit_no",
                          `#${newValue}`,
                        );
                      } else {
                        formik.setFieldValue("shipping_unit_no", newValue);
                      }
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
              </div>
              <ValidationError
                className={
                  formik.touched.unit_no && formik.errors.unit_no
                    ? "block"
                    : "hidden"
                }
              >
                {formik.errors.unit_no}
              </ValidationError>
            </div>
            <div className={`${deliveryDateRequired ? "block" : "hidden"}`}>
              <div className="pb-[9px] flex items-center justify-between">
                <label className="text-[16px] text-[#DA5E18] bg-[#343426] rounded-[7px] block px-[15px] py-[8px] min-w-[150px] text-center">
                  Shipping Address
                </label>

                <div className="flex items-center">
                  <Checkbox
                    inputId="same_as_billing_address"
                    checked={sameAsBilling}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    htmlFor="same_as_billing_address"
                    className="ml-[6px] text-[#DA5E18] md:text-black"
                  >
                    Same as Billing Address
                  </label>
                </div>
              </div>
              <div className="pb-[9px]">
                <div className="flex flex-row justify-start items-center gap-x-2">
                  <CountryCodeDropdown
                    className={`w-[100px] ${
                      formik.touched.shipping_country_code &&
                      formik.errors.shipping_country_code
                        ? "p-invalid"
                        : ""
                    }`}
                    name="shipping_country_code"
                    value={
                      sameAsBilling
                        ? formik.values.country_code
                        : formik.values.shipping_country_code
                    }
                    readOnly={sameAsBilling}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <InputText
                    className={`w-full ${
                      formik.touched.shipping_mobile &&
                      formik.errors.shipping_mobile
                        ? "p-invalid"
                        : ""
                    }`}
                    name="shipping_mobile"
                    placeholder="Mobile"
                    value={
                      sameAsBilling
                        ? formik.values.mobile
                        : formik.values.shipping_mobile
                    }
                    readOnly={sameAsBilling}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <Button
                    type="button"
                    severity="secondary"
                    outlined
                    className="rounded-full !bg-[#343426] !text-white p-[7px] w-[33px] h-[33px] flex justify-center items-center shrink-0"
                    onClick={() => handleSearch("shipping")}
                    disabled={formik.values.shipping_mobile.length < 8}
                  >
                    <img
                      src={require("../../assets/images/icon-search.png")}
                      alt="Search icon"
                      className="w-[18px]"
                    />
                  </Button>
                </div>
                <ValidationError
                  className={
                    formik.touched.shipping_mobile &&
                    formik.errors.shipping_mobile
                      ? "block"
                      : "hidden"
                  }
                >
                  {formik.errors.shipping_mobile}
                </ValidationError>
              </div>
              <div className="pb-[9px]">
                <div className="flex flex-row justify-start items-center flex-nowrap gap-x-2">
                  <InputText
                    className={`w-full ${
                      formik.touched.shipping_first_name &&
                      formik.errors.shipping_first_name
                        ? "p-invalid"
                        : ""
                    }`}
                    name="shipping_first_name"
                    placeholder="First Name"
                    value={
                      sameAsBilling
                        ? formik.values.first_name
                        : formik.values.shipping_first_name
                    }
                    readOnly={sameAsBilling}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <InputText
                    className={`w-full ${
                      formik.touched.shipping_last_name &&
                      formik.errors.shipping_last_name
                        ? "p-invalid"
                        : ""
                    }`}
                    name="shipping_last_name"
                    placeholder="Last Name"
                    value={
                      sameAsBilling
                        ? formik.values.last_name
                        : formik.values.shipping_last_name
                    }
                    readOnly={sameAsBilling}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <ValidationError
                  className={
                    formik.touched.shipping_first_name &&
                    formik.errors.shipping_first_name
                      ? "block"
                      : "hidden"
                  }
                >
                  {formik.errors.shipping_first_name}
                </ValidationError>
                <ValidationError
                  className={
                    formik.touched.shipping_last_name &&
                    formik.errors.shipping_last_name
                      ? "block"
                      : "hidden"
                  }
                >
                  {formik.errors.shipping_last_name}
                </ValidationError>
              </div>
              <div className="pb-[9px]">
                <div className="flex flex-row justify-start items-center gap-x-2">
                  <InputText
                    className={`w-full ${
                      formik.touched.shipping_postal_code &&
                      formik.errors.shipping_postal_code
                        ? "p-invalid"
                        : ""
                    }`}
                    name="shipping_postal_code"
                    placeholder="Postal Code"
                    value={
                      sameAsBilling
                        ? formik.values.postal_code
                        : formik.values.shipping_postal_code
                    }
                    readOnly={sameAsBilling}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <Button
                    type="button"
                    severity="secondary"
                    outlined
                    className="rounded-full !bg-[#343426] !text-white p-[7px] w-[33px] h-[33px] flex justify-center items-center shrink-0"
                    onClick={() =>
                      getAddress(
                        formik.values.shipping_postal_code,
                        "shipping_address",
                      )
                    }
                    disabled={formik.values.shipping_postal_code.length < 6}
                  >
                    <img
                      src={require("../../assets/images/icon-search.png")}
                      alt="Search icon"
                      className="w-[18px]"
                    />
                  </Button>
                </div>
                <ValidationError
                  className={
                    formik.touched.shipping_postal_code &&
                    formik.errors.shipping_postal_code
                      ? "block"
                      : "hidden"
                  }
                >
                  {formik.errors.shipping_postal_code}
                </ValidationError>
              </div>
              <div className="pb-[9px]">
                <div>
                  <InputTextarea
                    className={`w-full resize-none ${
                      formik.touched.shipping_street &&
                      formik.errors.shipping_street
                        ? "p-invalid"
                        : ""
                    }`}
                    rows={5}
                    name="shipping_street"
                    placeholder="Street Name"
                    value={
                      sameAsBilling
                        ? formik.values.street
                        : formik.values.shipping_street
                    }
                    readOnly={sameAsBilling}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <ValidationError
                  className={
                    formik.touched.shipping_street &&
                    formik.errors.shipping_street
                      ? "block"
                      : "hidden"
                  }
                >
                  {formik.errors.shipping_street}
                </ValidationError>
              </div>
              <div className="pb-[9px]">
                <div>
                  <InputText
                    className={`w-full ${
                      formik.touched.shipping_unit_no &&
                      formik.errors.shipping_unit_no
                        ? "p-invalid"
                        : ""
                    }`}
                    name="shipping_unit_no"
                    placeholder="Unit No."
                    value={
                      sameAsBilling
                        ? formik.values.unit_no
                        : formik.values.shipping_unit_no
                    }
                    readOnly={sameAsBilling}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (!formik.values.shipping_unit_no.startsWith("#")) {
                        formik.setFieldValue(
                          "shipping_unit_no",
                          `#${newValue}`,
                        );
                      } else {
                        formik.setFieldValue("shipping_unit_no", newValue);
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <ValidationError
                  className={
                    formik.touched.shipping_unit_no &&
                    formik.errors.shipping_unit_no
                      ? "block"
                      : "hidden"
                  }
                >
                  {formik.errors.shipping_unit_no}
                </ValidationError>
              </div>
            </div>
          </div>
          <div className="bg-[rgba(247,247,247,0.5)] rounded-lg border-[#E3E3E3] border-[0.8px] mt-[9px] mb-[25px] pt-[17px] px-[9px] pb-[17px] shadow-[2px_4px_4px_0_rgba(0,0,0,0.06)] text-sm">
            <label className="block text-[16px] text-[#DA5E18] bg-[#343426] rounded-[7px] mb-[9px] py-[8px] px-[15px] max-w-[150px] text-center">
              Shared Agent?
            </label>
            <Dropdown
              className={`w-full ${
                formik.touched.shared_agent && formik.errors.shared_agent
                  ? "p-invalid"
                  : ""
              }`}
              options={agents.map((agent) => ({
                label: `${agent?.full_name} - [#${agent?.agent_code}]`,
                value: agent.id, // Pass the agent object as the value
              }))}
              filter
              showClear={!!formik.values.shared_agent}
              name="shared_agent"
              placeholder="Please Select Collaborating Agents"
              value={formik.values.shared_agent}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ValidationError
              className={
                formik.touched.shared_agent && formik.errors.shared_agent
                  ? "block"
                  : "hidden"
              }
            >
              {formik.errors.shared_agent}
            </ValidationError>
          </div>
          <div className="bg-[rgba(247,247,247,0.5)] rounded-lg border-[#E3E3E3] border-[0.8px] mt-[9px] mb-[25px] pt-[17px] px-[9px] pb-[17px] shadow-[2px_4px_4px_0_rgba(0,0,0,0.06)] text-sm">
            <label className="block text-[16px] text-[#DA5E18] bg-[#343426] rounded-[7px] px-[15px] py-[8px] mb-[9px] max-w-[150px] text-center">
              Venue
            </label>
            <Dropdown
              className={`w-full ${
                formik.touched.venue && formik.errors.venue ? "p-invalid" : ""
              }`}
              options={venues.map((venue) => ({
                label: venue?.name,
                value: venue?.id,
              }))}
              filter
              showClear={!!formik.values.venue}
              name="venue"
              placeholder="Please Select Your Current Venue"
              value={formik.values.venue}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ValidationError
              className={
                formik.touched.venue && formik.errors.venue ? "block" : "hidden"
              }
            >
              {formik.errors.venue}
            </ValidationError>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default CustomerDetail;

import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layout";
import useApiData from "../../../hooks/useApiData";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputNumber } from "primereact/inputnumber";
import { post } from "../../../utils/Api";
import { toast } from "react-toastify";
import AcceptButton from "../../../components/AcceptButton";
import { Dropdown } from "primereact/dropdown";

const Settings = () => {
  const [settingLoading, setSettingLoading] = useState(false);
  const { data, isLoading, error } = useApiData(`get_setting`);
  const setting = data as any;

  useEffect(() => {
    if (setting?.id) {
      formik.setFieldValue("id", setting.id);
    }
    if (setting?.delivery_date_days_offset) {
      formik.setFieldValue(
        "delivery_date_days_offset",
        setting.delivery_date_days_offset,
      );
    }
    if (setting?.delivery_fee) {
      formik.setFieldValue("delivery_fee", setting.delivery_fee);
    }
    if (setting?.commission_threshold) {
      formik.setFieldValue(
        "commission_threshold",
        setting.commission_threshold,
      );
    }
    if (setting?.gst) {
      formik.setFieldValue("gst", setting.gst);
    }
    if (setting?.min_spend_free_delivery) {
      formik.setFieldValue(
        "min_spend_free_delivery",
        setting.min_spend_free_delivery,
      );
    }
    if (setting?.min_subscription_order) {
      formik.setFieldValue(
        "min_subscription_order",
        setting.min_subscription_order,
      );
    }
    if (setting?.low_stocks) {
      formik.setFieldValue("low_stocks", setting.low_stocks);
    }
    if (setting?.bean_commissions) {
      formik.setFieldValue("bean_commissions", setting.bean_commissions);
    }
    if (setting?.equipment_commissions) {
      formik.setFieldValue(
        "equipment_commissions",
        setting.equipment_commissions,
      );
    }
    if (setting?.pocket_price_rules) {
      formik.setFieldValue("pocket_price_rules", setting.pocket_price_rules);
    }
  }, [setting]);

  const formik = useFormik({
    initialValues: {
      id: 0,
      delivery_date_days_offset: 0,
      delivery_fee: 0,
      commission_threshold: 0,
      gst: 0,
      min_spend_free_delivery: 0,
      min_subscription_order: 0,
      low_stocks: 0,
      bean_commissions: 0,
      equipment_commissions: 0,
      pocket_price_rules: [],
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
      try {
        setSettingLoading(true);
        const response = await post("admin/update_setting", values);
        setSettingLoading(false);
        if (response?.success) {
          toast.success(response?.message);
        }
      } catch (error: any) {
        setSettingLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  interface PocketPriceRule {
    count: string;
    price: string;
    api_key_id: number;
  }

  const sourcesOptions = [
    { label: "POS", value: 1 },
    { label: "E-Commerce", value: 2 },
  ];

  const handlePocketChange = (
    index: number,
    type: keyof PocketPriceRule,
    value: any,
  ) => {
    if (type === "api_key_id") {
      formik.setFieldValue(`pocket_price_rules[${index}].${type}`, value);
    } else {
      const newValue = value === null ? "" : value.toString();
      formik.setFieldValue(`pocket_price_rules[${index}].${type}`, newValue);
    }
    console.log(formik.values.pocket_price_rules);
  };

  const handlePocketAddClick = () => {
    const newRules = [
      ...formik.values.pocket_price_rules,
      { count: "", price: "" },
    ];
    formik.setFieldValue("pocket_price_rules", newRules);
  };

  const handlePocketRemoveClick = (index: number) => {
    const newRules = formik.values.pocket_price_rules.filter(
      (_, i) => i !== index,
    );
    formik.setFieldValue("pocket_price_rules", newRules);
  };

  return (
    <Layout pageTitle={"Settings"}>
      <div className="p-[30px] card-cfg my-[40px] max-w-2xl mx-auto">
        <div className="text-[16px] text-[#DA5E18] leading-[36px] font-[500] capitalize border-b-[4px] border-[#DA5E18] border-solid mb-[20px] inline-block">
          General Setting
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col md:flex-row justify-start items-start md:items-center flex-nowrap gap-x-4 py-[20px] border-b-[1px] border-[#D1D1D1] border-solid">
            <div className="min-w-[40%] mb-2 md:m-0">
              <label
                htmlFor="delivery_date_days_offset"
                className="text-[14px] text-[#595959] font-[500] capitalize"
              >
                Delivery T + Day
              </label>
            </div>
            <div className="min-w-[40%]">
              <InputNumber
                className={`w-full ${
                  formik.touched.delivery_date_days_offset &&
                  formik.errors.delivery_date_days_offset
                    ? "p-invalid"
                    : ""
                }`}
                inputId="delivery_date_days_offset"
                placeholder="Enter number of days"
                value={formik.values.delivery_date_days_offset}
                onValueChange={(e) =>
                  formik.setFieldValue("delivery_date_days_offset", e.value)
                }
                // onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                useGrouping={false}
                suffix=" Days"
              />
            </div>
            <div className="min-w-[20%]"></div>
          </div>
          <div className="flex flex-col md:flex-row justify-start items-start md:items-center flex-nowrap gap-x-4 py-[20px] border-b-[1px] border-[#D1D1D1] border-solid">
            <div className="min-w-[40%] mb-2 md:m-0">
              <label
                htmlFor="delivery_fee"
                className="text-[14px] text-[#595959] font-[500] capitalize"
              >
                Delivery Fee
              </label>
            </div>
            <div className="min-w-[40%]">
              <InputNumber
                className={`w-full ${
                  formik.touched.delivery_fee && formik.errors.delivery_fee
                    ? "p-invalid"
                    : ""
                }`}
                inputId="delivery_fee"
                placeholder="Enter delivery fee"
                value={formik.values.delivery_fee}
                onValueChange={(e) =>
                  formik.setFieldValue("delivery_fee", e.value)
                }
                // onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                locale="en-US"
                minFractionDigits={2}
                prefix="$ "
              />
            </div>
            <div className="min-w-[20%]"></div>
          </div>
          <div className="flex flex-col md:flex-row justify-start items-start md:items-center flex-nowrap gap-x-4 py-[20px] border-b-[1px] border-[#D1D1D1] border-solid">
            <div className="min-w-[40%] mb-2 md:m-0">
              <label
                htmlFor="gst"
                className="text-[14px] text-[#595959] font-[500] capitalize"
              >
                GST
              </label>
            </div>
            <div className="min-w-[40%]">
              <InputNumber
                className={`w-full ${
                  formik.touched.gst && formik.errors.gst ? "p-invalid" : ""
                }`}
                inputId="gst"
                placeholder="Enter gst %"
                value={formik.values.gst}
                onValueChange={(e) => formik.setFieldValue("gst", e.value)}
                // onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                useGrouping={false}
                suffix=" %"
              />
            </div>
            <div className="min-w-[20%]"></div>
          </div>
          <div className="flex flex-col md:flex-row justify-start items-start md:items-center flex-nowrap gap-x-4 py-[20px] border-b-[1px] border-[#D1D1D1] border-solid">
            <div className="min-w-[40%] mb-2 md:m-0">
              <label
                htmlFor="min_spend_free_delivery"
                className="text-[14px] text-[#595959] font-[500] capitalize"
              >
                Free Delivery Min. Spend
              </label>
            </div>
            <div className="min-w-[40%]">
              <InputNumber
                className={`w-full ${
                  formik.touched.min_spend_free_delivery &&
                  formik.errors.min_spend_free_delivery
                    ? "p-invalid"
                    : ""
                }`}
                inputId="min_spend_free_delivery"
                placeholder="Enter min spend"
                value={formik.values.min_spend_free_delivery}
                onValueChange={(e) =>
                  formik.setFieldValue("min_spend_free_delivery", e.value)
                }
                // onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                locale="en-US"
                minFractionDigits={2}
                prefix="$ "
              />
            </div>
            <div className="min-w-[20%]"></div>
          </div>
          <div className="flex flex-col md:flex-row justify-start items-start md:items-center flex-nowrap gap-x-4 py-[20px] border-b-[1px] border-[#D1D1D1] border-solid">
            <div className="min-w-[40%] mb-2 md:m-0">
              <label
                htmlFor="min_subscription_order"
                className="text-[14px] text-[#595959] font-[500] capitalize"
              >
                Min. Subscription Quantity
              </label>
            </div>
            <div className="min-w-[40%]">
              <InputNumber
                className={`w-full ${
                  formik.touched.min_subscription_order &&
                  formik.errors.min_subscription_order
                    ? "p-invalid"
                    : ""
                }`}
                inputId="min_subscription_order"
                placeholder="Enter min order"
                value={formik.values.min_subscription_order}
                onValueChange={(e) =>
                  formik.setFieldValue("min_subscription_order", e.value)
                }
                // onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                useGrouping={false}
                suffix=" Tins"
              />
            </div>
            <div className="min-w-[20%]"></div>
          </div>
          <div className="flex flex-col md:flex-row justify-start items-start md:items-center flex-nowrap gap-x-4 py-[20px] border-b-[1px] border-[#D1D1D1] border-solid">
            <div className="min-w-[40%] mb-2 md:m-0">
              <label
                htmlFor="low_stocks"
                className="text-[14px] text-[#595959] font-[500] capitalize"
              >
                Low Stock
              </label>
            </div>
            <div className="min-w-[40%]">
              <InputNumber
                className={`w-full ${
                  formik.touched.low_stocks && formik.errors.low_stocks
                    ? "p-invalid"
                    : ""
                }`}
                inputId="low_stocks"
                placeholder="Enter stock count"
                value={formik.values.low_stocks}
                onValueChange={(e) =>
                  formik.setFieldValue("low_stocks", e.value)
                }
                // onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                useGrouping={false}
                prefix="< "
              />
            </div>
            <div className="min-w-[20%]"></div>
          </div>
          <div className="flex flex-col md:flex-row justify-start items-start md:items-center flex-nowrap gap-x-4 py-[20px] border-b-[1px] border-[#D1D1D1] border-solid">
            <div className="min-w-[40%] mb-2 md:m-0">
              <label
                htmlFor="commission_threshold"
                className="text-[14px] text-[#595959] font-[500] capitalize"
              >
                Commission Threshold
              </label>
            </div>
            <div className="min-w-[40%]">
              <InputNumber
                className={`w-full ${
                  formik.touched.commission_threshold &&
                  formik.errors.commission_threshold
                    ? "p-invalid"
                    : ""
                }`}
                inputId="commission_threshold"
                placeholder="Enter Commission Threshold"
                value={formik.values.commission_threshold}
                onValueChange={(e) =>
                  formik.setFieldValue("commission_threshold", e.value)
                }
                // onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                locale="en-US"
                minFractionDigits={2}
                prefix="$ "
              />
            </div>
            <div className="min-w-[20%]"></div>
          </div>
          <div className="flex flex-col md:flex-row justify-start items-start md:items-center flex-nowrap gap-x-4 py-[20px] border-b-[1px] border-[#D1D1D1] border-solid">
            <div className="min-w-[40%] mb-2 md:m-0 flex flex-col md:flex-row">
              <label
                htmlFor="pocket_price_rules"
                className="text-[14px] text-[#595959] font-[500] capitalize"
              >
                Pocket Coffee Discount
              </label>
              <div
                className={`cursor-pointer inline-block items-center shrink-0 ml-2`}
              >
                <img
                  src={require("../../../assets/images/icon-add-circle.png")}
                  alt="Add Icon"
                  className={`w-[20px] h-[20px] m-auto border rounded-[10px]`}
                  onClick={() => handlePocketAddClick()}
                />
              </div>
            </div>

            <div className="min-w-[40%] -mb-2">
              {formik.values.pocket_price_rules?.map(
                (item: any, index: number) => (
                  <div
                    className="w-full flex flex-row justify-around md:items-center gap-x-2 mb-2 pr-2"
                    key={index}
                  >
                    <Dropdown
                      className={`w-[40%] ${
                        formik.touched.pocket_price_rules &&
                        formik.errors.pocket_price_rules
                          ? "p-invalid"
                          : ""
                      }}`}
                      options={sourcesOptions}
                      placeholder="Select Source"
                      value={item.api_key_id}
                      onChange={(e) =>
                        handlePocketChange(index, "api_key_id", e.value)
                      }
                      onBlur={formik.handleBlur}
                    />
                    <InputNumber
                      className={`w-[20%] ${
                        formik.touched.pocket_price_rules &&
                        formik.errors.pocket_price_rules
                          ? "p-invalid"
                          : ""
                      }`}
                      inputId={`pocket_coffee_count_${index}`}
                      placeholder="Enter Count"
                      value={
                        isNaN(parseInt(item?.count))
                          ? null
                          : parseInt(item?.count)
                      }
                      onValueChange={(e) => {
                        handlePocketChange(index, "count", e.value);
                      }}
                      onBlur={formik.handleBlur}
                      locale="en-US"
                      inputClassName="max-w-full"
                    />
                    <InputNumber
                      className={`w-[40%] ${
                        formik.touched.pocket_price_rules &&
                        formik.errors.pocket_price_rules
                          ? "p-invalid"
                          : ""
                      }`}
                      inputId={`pocket_coffee_price_${index}`}
                      placeholder="Enter Price"
                      value={
                        isNaN(parseInt(item?.price))
                          ? null
                          : parseInt(item?.price)
                      }
                      onValueChange={(e) =>
                        handlePocketChange(index, "price", e.value)
                      }
                      // onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      locale="en-US"
                      minFractionDigits={2}
                      prefix="$ "
                      inputClassName="max-w-full"
                    />
                    <div
                      className={`cursor-pointer inline-block items-center shrink-0`}
                    >
                      <img
                        src={require("../../../assets/images/order/icon-delete-circle.png")}
                        alt="Delete Icon"
                        className={`w-[24px] h-[24px] m-auto border rounded-[14px]`}
                        onClick={() => handlePocketRemoveClick(index)}
                      />
                    </div>
                  </div>
                ),
              )}
            </div>
            <div className="min-w-[20%]"></div>
          </div>
          <AcceptButton
            label="Update"
            className="mt-[20px] ml-auto block"
            loading={settingLoading}
          />
        </form>
      </div>
    </Layout>
  );
};

export default Settings;

import EmptyData from "../../../components/EmptyData";
import Layout from "../../../components/Layout";
import TableSkeleton from "../../../components/TableSkeleton";
import useApiData from "../../../hooks/useApiData";
import AdminButton from "../../../components/AdminButton";
import TableHeader from "../../../components/TableHeader";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";

const EcommerceCarousel = () => {
  const { data, isLoading, error } = useApiData(`/admin/carousel`);
  const records = data as any;
  const images = records?.data;

  const navigate = useNavigate();

  const openVideo = (url: any) => {
    window.open(url, "_blank");
  };

  const viewPage = async (data: any) => {
    console.log(data);
    const updatedFormikValues = {
      id: data.id,
      image_link: data.image_link,
      image_url: data.image_url,
    };
    navigate("/ecommerce-carousel-create", {
      state: {
        formikValueEcommerceCarousel: updatedFormikValues,
      },
    });
  };

  return (
    <Layout>
      <TableHeader>
        <AdminButton
          label="Create"
          onClick={() => {
            navigate("/ecommerce-carousel-create", {
              state: { type: "Create" },
            });
          }}
        />
      </TableHeader>
      <div className="p-3 card-cfg">
        {isLoading && <TableSkeleton />}
        {!isLoading && images?.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="table-cfg">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Link</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {images.map((row: any) => {
                    return (
                      <tr key={`ecommerce-video-table-row-${row.id}`}>
                        <td data-label="Video" className="">
                          <div className="flex justify-between lg:justify-center">
                            <img
                              src={
                                row.image_url ||
                                require("../../../assets/images/order/no-image.png")
                              }
                              alt=""
                              className="w-[80px] h-[80px] md:w-[120px] md:h-[120px] rounded object-cover shrink-0"
                              // onClick={() => openVideo(row?.url)}
                            />
                          </div>
                        </td>
                        <td data-label="Link" className="lg:md:w-64">
                          {row?.image_link}
                        </td>
                        <td data-label="Action">
                          <div className="flex flex-wrap gap-3 justify-end lg:justify-start">
                            <Button
                              className="p-0 text-sm shrink-0"
                              text
                              onClick={() => viewPage(row)}
                            >
                              <img
                                src={require("../../../assets/images/icon-view.png")}
                                alt="View icon"
                                className="w-[24px]"
                              />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
        {images?.length <= 0 && <EmptyData />}
      </div>
    </Layout>
  );
};

export default EcommerceCarousel;

import React from "react";
import { Button } from "primereact/button";

const CancelButton = ({ label, ...buttonProps }: any) => {
  return (
    <Button
      style={{
        border: 0,
        borderRadius: "10px",
        background: "#F4F4F4",
        padding: "9px 24px 9px 24px",
        minWidth: "80px",
        maxHeight: "33px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        flexWrap: "nowrap",
        gap: "5px",
      }}
      {...buttonProps}
    >
      <span className="text-[#292929] text-[12px] leading-[15px] font-[500]">
        {label ?? "Cancel"}
      </span>
    </Button>
  );
};

export default CancelButton;

import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-[#FFEDD7] md:bg-[#F1F1F1] md:bg-ipad-background-image md:bg-cover md:bg-center md:bg-no-repeat md:bg-fixed w-screen h-screen flex justify-center items-center">
      <span
        className="text-[#DA5E18] text-[20px] leading-[30px] font-[700]"
        onClick={() => navigate("/", { replace: true })}
      >
        Page Not Found. Bring me to home.
      </span>
    </div>
  );
};

export default NotFound;

import React, { useEffect, useReducer } from "react";
import { get } from "../utils/Api";

type State<TData, TSearchParams> = {
  data: TData[];
  loading: boolean;
  error: Error | null;
  searchParams: TSearchParams;
  currentPage: number;
  totalPages: number;
};

type Action<TData, TSearchParams> =
  | { type: "FETCH_REQUEST" }
  | { type: "FETCH_SUCCESS"; payload: { data: TData[]; totalPages: number } }
  | { type: "FETCH_FAILURE"; payload: { error: Error } }
  | { type: "CHANGE_SEARCH_PARAM"; payload: { params: TSearchParams } }
  | { type: "CHANGE_PAGE"; payload: { page: number } }
  | { type: "RELOAD_DATA" };

const dataReducer = <TData, TSearchParams>(
  state: State<TData, TSearchParams>,
  action: Action<TData, TSearchParams>,
): State<TData, TSearchParams> => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        data: action.payload.data,
        totalPages: action.payload.totalPages,
        loading: false,
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case "CHANGE_SEARCH_PARAM":
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          ...action.payload.params,
        },
        currentPage: 1,
      };
    case "CHANGE_PAGE":
      return {
        ...state,
        currentPage: action.payload.page,
      };
    case "RELOAD_DATA":
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

const usePaginatedApiData = <TData, TSearchParams>(
  initialUrl: string,
  initialSearchParams: TSearchParams,
  initialPage: number,
) => {
  const [state, dispatch] = useReducer(dataReducer, {
    data: [] as TData,
    loading: false,
    error: null,
    searchParams: initialSearchParams,
    currentPage: initialPage,
    totalPages: 0,
  } as State<TData, TSearchParams>);

  const { data, loading, error, searchParams, currentPage, totalPages } = state;

  const fetchData = async () => {
    dispatch({ type: "FETCH_REQUEST" });
    try {
      const o = Object.fromEntries(
        Object.entries(searchParams as Record<string, string>).filter(
          ([_, v]) => v != null,
        ),
      ); // Remove Null or Undefined Values from Object
      const queryParams = new URLSearchParams({
        page: currentPage.toString(),
        ...(o as Record<string, string>),
        // ...(searchParams as Record<string, string>),
      });
      const response = await get(`${initialUrl}?${queryParams}`);
      if (!response?.data) {
        throw new Error("Network response was not ok");
      }
      dispatch({
        type: "FETCH_SUCCESS",
        payload: {
          data: response.data,
          totalPages: response.data.last_page,
        },
      });
    } catch (error: any) {
      dispatch({ type: "FETCH_FAILURE", payload: { error } });
    }
  };

  useEffect(() => {
    fetchData();
  }, [initialUrl, currentPage, searchParams]);

  const handleSearchParamsChange = (
    newSearchParams: Partial<TSearchParams>,
  ) => {
    dispatch({
      type: "CHANGE_SEARCH_PARAM",
      payload: {
        params: {
          ...(searchParams as any),
          ...newSearchParams,
        },
      },
    });
    // if ((newSearchParams as any).page === undefined) {
    //   dispatch({ type: "CHANGE_PAGE", payload: { page: currentPage } });
    // }
  };

  const handlePageChange = (page: number) => {
    dispatch({ type: "CHANGE_PAGE", payload: { page } });
  };

  const handleReload = () => {
    dispatch({ type: "RELOAD_DATA" });
    fetchData();
  };

  return {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  };
};

export default usePaginatedApiData;

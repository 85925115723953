import React from "react";
import { Button } from "primereact/button";
import Lottie from "react-lottie-player";
import EmotyImage from "./../assets/lottie/empty_data.json";

const EmptyData = () => {
  return (
    <>
      <div className="hidden md:block">
        <Lottie
          loop
          animationData={EmotyImage}
          play
          style={{ width: 500, height: 500, margin: "auto" }}
        />
      </div>
      <div className="md:hidden">
        <Lottie
          loop
          animationData={EmotyImage}
          play
          style={{ width: 300, height: 300, margin: "auto" }}
        />
      </div>
    </>
  );
};

export default EmptyData;

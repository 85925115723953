import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import QRCode from "qrcode.react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useCopyToClipboard } from "usehooks-ts";
import Pusher from "pusher-js";
import { store } from "../redux/store";

const QRcode = (props: any) => {
  const [value, copy] = useCopyToClipboard();
  const [pusher, setPusher] = useState<Pusher | null>(null);
  const user_id: any = store.getState().userAuth?.user?.id || null;
  const { REACT_APP_PUSHER_APP_KEY, REACT_APP_PUSHER_APP_CLUSTER }: any =
    process.env;

  const handleClose = (type: any) => {
    props?.close({
      order_id: props?.orderId,
      type,
    });
  };

  useEffect(() => {
    const pusherInstance = new Pusher(REACT_APP_PUSHER_APP_KEY, {
      cluster: REACT_APP_PUSHER_APP_CLUSTER,
    });
    setPusher(pusherInstance);
    const channel = pusherInstance.subscribe("CFG");
    channel.bind(
      `update-content-for-update-payment-status-${user_id}`,
      (data: any) => {
        if (
          JSON.parse(data?.response_data)?.body?.type ===
            "payment_intent.succeeded" ||
          JSON.parse(data?.response_data)?.body?.type ===
            "payment_intent.payment_failed"
        ) {
          if (
            JSON.parse(data?.response_data)?.body?.order_id === props?.orderId
          ) {
            handleClose("auto");
          }
        }
      },
    );
    return () => {
      if (pusherInstance) {
        pusherInstance.disconnect();
      }
    };
  }, [props]);

  return (
    <Dialog
      header={`Payment for Order #${props?.orderId}`}
      visible={props?.open}
      // onHide={handleClose("manual")}
      onHide={() => {
        handleClose("manual");
      }}
      style={{ width: "350px", border: 0 }}
    >
      <div className="flex flex-col justify-center items-center p-5">
        <QRCode value={props?.data} size={200} />
        <label className="text-[#DA5E18] text-center leading-4 py-5">
          Please scan this unique QR Code to make payment.
        </label>
        <Button
          className="text-xs"
          onClick={() => {
            copy(props?.data);
            toast.success("Checkout link has been copied to clipboard");
          }}
        >
          Copy Link
          <span
            className="pi pi-copy"
            style={{ fontSize: ".7rem", paddingLeft: 3 }}
          ></span>
        </Button>
      </div>
    </Dialog>
  );
};

export default QRcode;

import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import AcceptButton from "../../../components/AcceptButton";
import CancelButton from "../../../components/CancelButton";
import EmptyData from "../../../components/EmptyData";
import FilterButton from "../../../components/FilterButton";
import Layout from "../../../components/Layout";
import TableFooter from "../../../components/TableFooter";
import TableSkeleton from "../../../components/TableSkeleton";
import useApiData from "../../../hooks/useApiData";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import { formatCurrency, formatDate } from "../../../utils/Common";
import { Button } from "primereact/button";

type SalesData = {
  date: string;
  daily_total: number;
  order_count: number;
  quantities: Array<item>;
  total_sum: number;
  order_items: Array<product>;
  id: number;
  total: number;
  created_at: string;
};
type SearchParams = {
  filter: string;
};
type SummaryData = {
  total_daily_total: number;
  total_order_count: number;
};
type product = {
  id: number;
  name: string;
  quantity: number;
};
type item = {
  sku: string;
  product_id: number;
  name: string;
  quantity: number;
  type: Array<types>;
};
type types = {
  id: number;
  name: string;
  quantity: number;
};
const Sku = () => {
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterVenue, setFilterVenue] = useState(0);
  const [selectedFilterVenue, setSelectedFilterVenue] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isPopupOpen, setPopupOpen] = useState(false);

  const {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  } = usePaginatedApiData<SalesData, SearchParams>(
    "/admin/get_sku",
    {
      filter: selectedStatus,
    },
    1,
  );
  const rawRecords = data as any;
  const records = rawRecords.data as SalesData[];
  const total = rawRecords?.total_sum;
  const quantities = rawRecords?.quantities;
  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  useEffect(() => {
    setSelectedStatus("current_week");
    handleDateFilterText("current_week");
  }, []);

  function handleDateFilterText(status: string) {
    if (status === "current") {
      setSelectedStatus("current");
    } else if (status === "current_week") {
      setSelectedStatus("current_week");
    } else if (status === "current_month") {
      setSelectedStatus("current_month");
    } else {
      setSelectedStatus("current_year");
    }
  }
  const handleStatusClick = (status: any) => {
    setSelectedStatus(status);
    handleDateFilterText(status);
    handleSearchParamsChange({
      filter: status,
    });
    if (status === "current") {
      console.log("current");
    }
    if (status === "current_week") {
      console.log("current_week");
    }
    if (status === "current_month") {
      console.log("current_month");
    }
    if (status === "current_year") {
      console.log("current_year");
    }
  };

  return (
    <Layout>
      <div className="p-3 card-cfg">
        <div className="flex flex-wrap gap-3 mb-4 items-center">
          <div
            className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 ${
              selectedStatus === "current"
                ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }`}
            onClick={() => handleStatusClick("current")}
          >
            <img
              src={require("../../../assets/images/icon-customer-count.png")}
              alt="Filter icon"
              className="w-[27px] mr-[10px]"
            />
            Current <span className="text-[24px] ml-[8px]"></span>
          </div>
          <div
            className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 ${
              selectedStatus === "current_week"
                ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }`}
            onClick={() => handleStatusClick("current_week")}
          >
            <img
              src={require("../../../assets/images/icon-customer-count.png")}
              alt="Filter icon"
              className="w-[27px] mr-[10px]"
            />
            Current Week <span className="text-[24px] ml-[8px]"></span>
          </div>
          <div
            className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 ${
              selectedStatus === "current_month"
                ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }`}
            onClick={() => handleStatusClick("current_month")}
          >
            <img
              src={require("../../../assets/images/icon-customer-count.png")}
              alt="Filter icon"
              className="w-[27px] mr-[10px]"
            />
            Current Month <span className="text-[24px] ml-[8px]"></span>
          </div>
          <div
            className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 ${
              selectedStatus === "current_year"
                ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }`}
            onClick={() => handleStatusClick("current_year")}
          >
            <img
              src={require("../../../assets/images/icon-customer-count.png")}
              alt="Filter icon"
              className="w-[27px] mr-[10px]"
            />
            Current Year <span className="text-[24px] ml-[8px]"></span>
          </div>
          {/* <div className="ml-auto">
              <FilterButton onClick={() => setFilterVisible(true)} />
            </div> */}
        </div>
        <div className="flex flex-wrap gap-3 mb-4 items-center">
          <div
            className={
              "w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] rounded-[10px] p-[12px] ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }
          >
            Sales
            <span className="text-[24px] ml-[8px]">
              {formatCurrency(total) ?? 0}
            </span>
          </div>
          <div className="ml-auto">
            <Button
              onClick={isPopupOpen ? closePopup : openPopup}
              style={{
                border: 0,
                borderRadius: "10px",
                background: "#FFFFFF",
                padding: "12px 12px 12px 12px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                flexWrap: "nowrap",
                gap: "5px",
              }}
            >
              <span className="text-[#DA5E18] text-[12px] leading-[15px] font-[450]">
                {isPopupOpen ? "Close Details" : "View Details"}
              </span>
            </Button>
          </div>
        </div>
        {loading && <TableSkeleton />}
        {isPopupOpen && (
          <div className="popup mb-10">
            <div className="popup-content">
              {!loading && records?.length > 0 && (
                <>
                  <div className="overflow-x-auto">
                    <table className="table-cfg">
                      <thead>
                        <tr>
                          <th>SKU</th>
                          <th>Name</th>
                          <th>Quantity</th>
                          <th>Movement Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {quantities.map((row: item) => {
                          return (
                            <tr key={`sku-table-row-${row?.product_id}`}>
                              <td data-label="Name">{row?.sku}</td>
                              <td data-label="Name">{row?.name}</td>
                              <td data-label="In">{row?.quantity}</td>
                              <td>
                                {row.type.map((type: types) => {
                                  return (
                                    <div>
                                      {type?.name} (x{type?.quantity})
                                    </div>
                                  );
                                })}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {!loading && records?.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="table-cfg">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Items</th>
                    <th>Sales</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((row: SalesData) => {
                    return (
                      <tr key={`sales-table-row-${row?.id}`}>
                        <td data-label="Date">{row?.id}</td>
                        <td>
                          {row.order_items.map((product: product) => {
                            return (
                              <div key={`sales-table-div-${product?.id}`}>
                                {product?.name} (x{product?.quantity})
                              </div>
                            );
                          })}
                        </td>
                        <td data-label="Sales">
                          {formatCurrency(row?.total) ?? 0}
                        </td>
                        <td data-label="Date">{formatDate(row?.created_at)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.last_page}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            />
          </>
        )}
        {!loading && records?.length <= 0 && <EmptyData />}
      </div>
    </Layout>
  );
};

export default Sku;

import { useState } from "react";

const Subscription = ({ data }: any) => {
  const [img, setImg] = useState(
    data?.image_url || require("../../assets/images/order/no-image.png"),
  );

  return (
    <div className="bg-[#F7F7F7] rounded-[11px] text-[14px] text-[#3E3E3E] font-normal">
      <div className="flex mb-2 px-[10px] py-[9px]">
        <img
          src={img}
          alt=""
          className="h-[68px] w-[68px] object-cover rounded-[6px]"
          onError={() => setImg(`${process.env.PUBLIC_URL}/no-image.png`)}
        />
        <div className="ml-[16px] flex justify-between w-full">
          <div className="flex flex-col">
            <label className="font-normal text-[#3E3E3E]">{data?.name}</label>
            <label className="font-normal text-[#3E3E3E]">
              {data?.sub_category?.name}
            </label>
            {data?.type === "product" && (
              <label className="font-normal text-[#3E3E3E]">(Base Plan)</label>
            )}
            {data?.type === "add_on" && (
              <label className="font-normal text-[#3E3E3E]">(Addon)</label>
            )}
            {data?.type === "base_plan_foc" && (
              <label className="font-normal text-[#3E3E3E]">(FOC)</label>
            )}
            {data?.type === "advance_foc" && (
              <label className="font-normal text-[#3E3E3E]">
                (Upgraded - FOC)
              </label>
            )}
            {data?.type === "add_on_plan_foc" && (
              <label className="font-normal text-[#3E3E3E]">
                (Addon - FOC)
              </label>
            )}
          </div>
          <div>
            <label className="text-[#3E3E3E] text-[16px] font-bold">
              X{data?.quantity}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;

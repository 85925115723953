import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Layout from "../../../components/Layout";
import { post, put, remove } from "../../../utils/Api";
import { TabPanel, TabView } from "primereact/tabview";
import { useLocation, useNavigate } from "react-router-dom";
import AcceptButton from "../../../components/AcceptButton";
import ValidationError from "../../../components/ValidationError";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { showConfirmDialog } from "../../../utils/Common";

const EcommerceCarouselCreate = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedFile, setSelectedFile] = useState("");
  const [selectedVideo, setSelectedVideo] = useState<File | null>(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [videoThumbnail, setVideoThumbnail] = useState("");

  console.log("test", location.state.formikValueEcommerceCarousel);
  const formikValueEcommerceCarousel = location.state
    ? location.state.formikValueEcommerceCarousel
    : null;

  useEffect(() => {
    if (
      formikValueEcommerceCarousel &&
      formikValueEcommerceCarousel.image_url
    ) {
      setSelectedFile(formikValueEcommerceCarousel.image_url);
    }
  }, [formikValueEcommerceCarousel]);

  const onFileUpload = async (event: any) => {
    const file = event?.target?.files[0];
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = function () {
        const base64data = reader.result;
        setSelectedFile(base64data as string);
      };
    }
  };

  const deleteConfirmation = () => {
    showConfirmDialog({
      message: "Do you want to delete this image?",
      header: "Confirmation",
      accept: async () => {
        const response = await remove(
          `/admin/carousel/${formikValueEcommerceCarousel?.id}`,
        );
        if (response.success) {
          toast.success(response.message);
          navigate("/ecommerce-carousel");
        } else {
          toast.error("Something went wrong!");
        }
      },
    });
  };

  interface FormValues {
    id: number | string;
    image_url: string;
    image_link: string;
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      id: formikValueEcommerceCarousel?.id || 0,
      image_link: formikValueEcommerceCarousel?.image_link || "",
      image_url: formikValueEcommerceCarousel?.image_url || "",
    },
    validationSchema: Yup.object({
      // image_url: Yup.required("Image link is required"),
    }),
    onSubmit: async (values) => {
      const { id, image_url, image_link } = values;
      try {
        setImageLoading(true);
        const formData = new FormData();
        const file: any = image_url;

        if (id !== 0) {
          formData.append("id", id as unknown as string);
          formData.append("_method", "put");
        }
        formData.append("image_url", file);
        formData.append("image_link", image_link);
        for (const [key, value] of formData.entries()) {
          console.log(key, value);
        }
        let response;
        if (id === 0) {
          response = await post("/admin/carousel", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        } else {
          response = await post(`/admin/carousel/${id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }

        setImageLoading(false);
        if (response?.success) {
          toast.success(response?.message);
          setSelectedFile("");
          formik.resetForm();
          navigate("/ecommerce-carousel");
        }
      } catch (error: any) {
        setImageLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  return (
    <Layout>
      <TabView>
        <TabPanel header="Image" leftIcon="pi pi-image mr-2">
          <div className="card-cfg !shadow-none">
            <form onSubmit={formik.handleSubmit}>
              <div className="flex md:justify-start md:flex-row flex-col gap-4">
                <div className="bg-[#f7f3f3] text-center p-[10px] rounded-[7px]">
                  <div
                    className={`w-[128px] h-[128px] border border-solid rounded-[6px] bg-[#FFFFFF] m-auto flex justify-center items-center relative ${
                      formik.touched.image_url && formik.errors.image_url
                        ? "border-[#f44336]"
                        : "border-[#ACACAC]"
                    }`}
                  >
                    {selectedFile || formik.values.image_url ? (
                      <div className="w-full h-full relative">
                        <img
                          src={selectedFile || formik.values.image_url}
                          onError={() =>
                            setSelectedFile(
                              `${process.env.PUBLIC_URL}/no-image.png`,
                            )
                          }
                          alt="Preview"
                          className="m-[auto] w-full h-full object-cover rounded-[6px] p-[10px]"
                        />
                        <button
                          className="absolute top-[-10px] right-[-10px] rounded-[50%] bg-gradient-to-b from-[#FFD3AB] to-[#FFF6E3]"
                          onClick={() => {
                            formik.setFieldValue("image_url", null);
                            setSelectedFile("");
                          }}
                        >
                          <img
                            src={require("../../../assets/images/icon-close.png")}
                            alt="Remove icon"
                            className="w-[25px]"
                          />
                        </button>
                      </div>
                    ) : (
                      <>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          id="image_url"
                          name="image_url"
                          hidden
                          onChange={(e: any) => {
                            onFileUpload(e);
                            formik.setFieldValue(
                              "image_url",
                              e?.target?.files[0],
                            );
                            setSelectedFile(
                              URL.createObjectURL(e?.target?.files[0]),
                            );
                          }}
                        />
                        <label
                          htmlFor="image_url"
                          className="text-[12px] leading-[15px] text-[#4C4C4C] font-[400] w-[80px] flex justify-center items-center flex-col text-center gap-y-[7px]"
                        >
                          <img
                            src={require("../../../assets/images/icon-add-circle.png")}
                            alt="Add icon"
                            className="w-[22px]"
                          />
                          Add Image
                        </label>
                      </>
                    )}
                  </div>
                  <ValidationError
                    className={
                      formik.touched.image_url && formik.errors.image_url
                        ? "visible"
                        : "invisible"
                    }
                  >
                    {formik.errors.image_url}
                  </ValidationError>
                </div>
                <div className="grid md:grid-cols-3 xl:grid-cols-4 w-full gap-4 bg-[#f7f3f3] p-[10px] rounded-[7px]">
                  <div className="order-3 md:order-3 xl:order-3">
                    <label
                      htmlFor="image_link"
                      className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block pl-[10px]"
                    >
                      Image Link
                    </label>
                    <InputText
                      className={`w-full ${
                        formik.touched.image_link && formik.errors.image_link
                          ? "p-invalid"
                          : ""
                      }`}
                      name="image_link"
                      id="image_link"
                      placeholder="Image Link"
                      value={formik.values.image_link}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <ValidationError
                      className={
                        formik.touched.image_link && formik.errors.image_link
                          ? "visible"
                          : "invisible"
                      }
                    >
                      {formik.errors.image_link}
                    </ValidationError>
                  </div>
                </div>
              </div>
              <div
                className={`flex items-center ${
                  formikValueEcommerceCarousel
                    ? "justify-between"
                    : "justify-end"
                }`}
              >
                {formikValueEcommerceCarousel && (
                  <Button
                    severity="danger"
                    type="button"
                    label="Delete"
                    onClick={() => {
                      deleteConfirmation();
                    }}
                  />
                )}

                <AcceptButton
                  label={formik.values.id !== 0 ? "Update" : "Save"}
                  loading={imageLoading}
                  className="mt-[20px]"
                  type="submit"
                />
              </div>
            </form>
          </div>
        </TabPanel>
      </TabView>
    </Layout>
  );
};

export default EcommerceCarouselCreate;

import React, { useState } from "react";
import { Button } from "primereact/button";
import { get } from "../utils/Api";
import { toast } from "react-toastify";

const ExportButton = ({ label, api, filename, mime, ...buttonProps }: any) => {
  const [isExporting, setIsExporting] = useState(false);

  const exportToExcel = async () => {
    if (api) {
      try {
        setIsExporting(true);
        const response = await get(api, { responseType: "blob" });
        const blob = new Blob([response], {
          type:
            mime ||
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename || `export.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (error: any) {
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      } finally {
        setIsExporting(false);
      }
    }
  };

  return (
    <Button
      style={{
        border: 0,
        borderRadius: "10px",
        background: "#FFFFFF",
        padding: "9px 12px 9px 12px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        flexWrap: "nowrap",
        gap: "5px",
      }}
      onClick={exportToExcel}
      loading={isExporting}
      {...buttonProps}
    >
      <span className="text-[#DA5E18] text-[12px] leading-[15px] font-[450]">
        {isExporting ? "Exporting..." : label ?? "Export"}
      </span>
      <img
        src={require("../assets/images/icon-export.png")}
        alt="Export icon"
        className="h-[18px]"
      />
    </Button>
  );
};

export default ExportButton;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { logout } from "../redux/slices/authSlice";
import { isRole } from "../utils/Common";

const PageLinks = ({ setMenuOpen, isMenuMinimized }: any) => {
  // if menu is minimized style for icon
  const linkStyle = {
    fontSize: isMenuMinimized ? "inherit" : "21px",
    marginLeft: isMenuMinimized ? "-8px" : "0",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [collapsedSections, setCollapsedSections] = useState({
    inventory:
      location.pathname !== "/inventory" &&
      location.pathname !== "/product-categories" &&
      location.pathname !== "/inventory-create" &&
      location.pathname !== "/product-movement-history",
    promotions:
      location.pathname !== "/promotion-code" &&
      location.pathname !== "/free-gift" &&
      location.pathname !== "/voucher" &&
      location.pathname !== "/kol",
    orders:
      location.pathname !== "/orders" &&
      location.pathname !== "/subscriptions" &&
      location.pathname !== "/shipments",
    accounts:
      location.pathname !== "/customer-listing" &&
      location.pathname !== "/users" &&
      location.pathname !== "/profile" &&
      location.pathname !== "/customer-listing-details" &&
      location.pathname !== "/user-details",
    sales:
      location.pathname !== "/venues" &&
      location.pathname !== "/sales" &&
      location.pathname !== "/agents" &&
      location.pathname !== "/sku",
    ecommerce:
      location.pathname !== "/ecommerce-video" &&
      location.pathname !== "/ecommerce-video-create" &&
      location.pathname !== "/ecommerce-carousel" &&
      location.pathname !== "/ecommerce-carousel-create",
    // Add other sections as needed
  });

  const CollapsibleSection = ({
    title,
    icon,
    isCollapsed,
    toggle,
    children,
  }: any) => {
    return (
      <div className="py-3 md:px-[15px] whitespace-nowrap">
        <div onClick={() => toggle()} className="cursor-pointer">
          <div className="flex justify-between mb-[5px] ">
            {isMenuMinimized ? <span>{icon}</span> : <span>{title}</span>}
            {!isMenuMinimized &&
              (isCollapsed ? (
                <span className="pi pi-plus"></span>
              ) : (
                <span className="pi pi-minus"></span>
              ))}
          </div>
        </div>
        {!isCollapsed && <div className="pl-[5px]">{children}</div>}
      </div>
    );
  };

  const showInventorySection = () => {
    return isRole(["Admin", "Manager", "Warehouse"]);
  };

  const showPromotionSection = () => {
    return isRole(["Admin", "Manager"]);
  };

  const showOrdersSection = () => {
    return isRole([
      "Admin",
      "Agent",
      "Manager",
      "Warehouse",
      "Agent Leader",
      "KOL",
    ]);
  };

  const showAccountsSection = () => {
    return isRole(["Admin", "Agent", "Manager", "Agent Leader", "KOL"]);
  };

  const showSalesSection = () => {
    return isRole(["Admin", "Manager", "Agent Leader"]);
  };

  const showEcommerceSection = () => {
    return isRole(["Admin", "Manager"]);
  };

  type SectionKey =
    | "inventory"
    | "promotions"
    | "orders"
    | "accounts"
    | "sales"
    | "ecommerce"; // Add other section keys as needed

  const toggleSection = (section: SectionKey) => {
    setCollapsedSections((prev: any) => {
      // Toggle the section open/close state
      return {
        ...prev,
        [section]: !prev[section],
      };
    });
  };

  return (
    <div className="overflow-hidden">
      <div className="divide-y divide-[#CFCFCF] divide-solid md:divide-none text-[#DA5E18]">
        {isRole([
          "Admin",
          "Agent",
          "Warehouse",
          "Manager",
          "Agent Leader",
          "KOL",
        ]) && (
          <div
            className={`py-3 md:px-[15px] whitespace-nowrap ${
              location.pathname === "/"
                ? "md:bg-[#DA5E18] md:rounded-[9px]"
                : ""
            }`}
          >
            <NavLink
              className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                location.pathname === "/"
                  ? "md:text-[#FFFFFF]"
                  : "text-[#DA5E18]"
              }`}
              to="/"
            >
              <span
                className="pi pi-chart-line"
                style={{
                  fontSize: "21px",
                }}
              ></span>
              {!isMenuMinimized && "Dashboard"}
            </NavLink>
          </div>
        )}
        {showInventorySection() && (
          <CollapsibleSection
            title="Inventory"
            icon={<span className="pi pi-box text-[21px]"></span>}
            isCollapsed={collapsedSections.inventory}
            toggle={() => toggleSection("inventory")}
            isMenuMinimized={isMenuMinimized}
          >
            {isRole(["Admin", "Manager", "Warehouse"]) && (
              <div
                className={`py-3 md:px-[15px] whitespace-nowrap ${
                  location.pathname === "/inventory" ||
                  location.pathname === "/inventory-create"
                    ? "md:bg-[#DA5E18] md:rounded-[9px]"
                    : ""
                }`}
              >
                <NavLink
                  className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                    location.pathname === "/inventory" ||
                    location.pathname === "/inventory-create"
                      ? "md:text-[#FFFFFF]"
                      : "text-[#DA5E18]"
                  }`}
                  to="/inventory"
                >
                  <span className="pi pi-box" style={linkStyle}></span>
                  {!isMenuMinimized && "Inventory"}
                </NavLink>
              </div>
            )}{" "}
            {isRole(["Admin", "Manager"]) && (
              <div
                className={`py-3 md:px-[15px] whitespace-nowrap ${
                  location.pathname === "/product-categories"
                    ? "md:bg-[#DA5E18] md:rounded-[9px]"
                    : ""
                }`}
              >
                <NavLink
                  className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                    location.pathname === "/product-categories"
                      ? "md:text-[#FFFFFF]"
                      : "text-[#DA5E18]"
                  }`}
                  to="/product-categories"
                >
                  <span className="pi pi-tags" style={linkStyle}></span>
                  {!isMenuMinimized && "Categories"}
                </NavLink>
              </div>
            )}
            {isRole(["Admin", "Manager", "Warehouse"]) && (
              <div
                className={`py-3 md:px-[15px] whitespace-nowrap ${
                  location.pathname === "/product-movement-history"
                    ? "md:bg-[#DA5E18] md:rounded-[9px]"
                    : ""
                }`}
              >
                <NavLink
                  className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                    location.pathname === "/product-movement-history"
                      ? "md:text-[#FFFFFF]"
                      : "text-[#DA5E18]"
                  }`}
                  to="/product-movement-history"
                >
                  <span className="pi pi-history" style={linkStyle}></span>
                  {!isMenuMinimized && "Movement History"}
                </NavLink>
              </div>
            )}
          </CollapsibleSection>
        )}
        {showPromotionSection() && (
          <CollapsibleSection
            title="Promotion Centre"
            icon={<span className="pi pi-box text-[21px]"></span>}
            isCollapsed={collapsedSections.promotions}
            toggle={() => toggleSection("promotions")}
            isMenuMinimized={isMenuMinimized}
          >
            {isRole(["Admin", "Manager"]) && (
              <div
                className={`py-3 md:px-[15px] whitespace-nowrap ${
                  location.pathname === "/promotion-code"
                    ? "md:bg-[#DA5E18] md:rounded-[9px]"
                    : ""
                }`}
              >
                <NavLink
                  className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                    location.pathname === "/promotion-code"
                      ? "md:text-[#FFFFFF]"
                      : "text-[#DA5E18]"
                  }`}
                  to="/promotion-code"
                >
                  <span className="pi pi-qrcode" style={linkStyle}></span>
                  {!isMenuMinimized && "Promotion Code"}
                </NavLink>
              </div>
            )}
            {isRole(["Admin", "Manager"]) && (
              <div
                className={`py-3 md:px-[15px] whitespace-nowrap ${
                  location.pathname === "/free-gift"
                    ? "md:bg-[#DA5E18] md:rounded-[9px]"
                    : ""
                }`}
              >
                <NavLink
                  className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                    location.pathname === "/free-gift"
                      ? "md:text-[#FFFFFF]"
                      : "text-[#DA5E18]"
                  }`}
                  to="/free-gift"
                >
                  <span className="pi pi-gift" style={linkStyle}></span>
                  {!isMenuMinimized && "Free Gift"}
                </NavLink>
              </div>
            )}
            {isRole(["Admin", "Manager"]) && (
              <div
                className={`py-3 md:px-[15px] whitespace-nowrap ${
                  location.pathname === "/voucher"
                    ? "md:bg-[#DA5E18] md:rounded-[9px]"
                    : ""
                }`}
              >
                <NavLink
                  className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                    location.pathname === "/voucher"
                      ? "md:text-[#FFFFFF]"
                      : "text-[#DA5E18]"
                  }`}
                  to="/voucher"
                >
                  <span className="pi pi-ticket" style={linkStyle}></span>
                  {!isMenuMinimized && "Member Voucher"}
                </NavLink>
              </div>
            )}
            {isRole(["Admin", "Manager"]) && (
              <div
                className={`py-3 md:px-[15px] whitespace-nowrap ${
                  location.pathname === "/kol"
                    ? "md:bg-[#DA5E18] md:rounded-[9px]"
                    : ""
                }`}
              >
                <NavLink
                  className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                    location.pathname === "/kol"
                      ? "md:text-[#FFFFFF]"
                      : "text-[#DA5E18]"
                  }`}
                  to="/kol"
                >
                  <span className="pi pi-share-alt" style={linkStyle}></span>
                  {!isMenuMinimized && "KOL"}
                </NavLink>
              </div>
            )}
          </CollapsibleSection>
        )}
        {showOrdersSection() && (
          <CollapsibleSection
            title="Orders"
            icon={<span className="pi pi-shopping-bag text-[21px]"></span>}
            isCollapsed={collapsedSections.orders}
            toggle={() => toggleSection("orders")}
            className="p-[10px]"
            isMenuMinimized={isMenuMinimized}
          >
            {isRole([
              "Admin",
              "Agent",
              "Manager",
              "Warehouse",
              "Agent Leader",
              "KOL",
            ]) && (
              <div
                className={`py-3 md:px-[15px] whitespace-nowrap ${
                  location.pathname === "/orders"
                    ? "md:bg-[#DA5E18] md:rounded-[9px]"
                    : ""
                }`}
              >
                <NavLink
                  className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                    location.pathname === "/orders"
                      ? "md:text-[#FFFFFF]"
                      : "text-[#DA5E18]"
                  }`}
                  to="/orders"
                >
                  <span className="pi pi-shopping-bag" style={linkStyle}></span>
                  {!isMenuMinimized && "Orders"}
                </NavLink>
              </div>
            )}
            {isRole([
              "Admin",
              "Agent",
              "Agent Leader",
              "Manager",
              "Warehouse",
              "KOL",
            ]) && (
              <div
                className={`py-3 md:px-[15px] whitespace-nowrap ${
                  location.pathname === "/subscriptions"
                    ? "md:bg-[#DA5E18] md:rounded-[9px]"
                    : ""
                }`}
              >
                <NavLink
                  className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                    location.pathname === "/subscriptions"
                      ? "md:text-[#FFFFFF]"
                      : "text-[#DA5E18]"
                  }`}
                  to="/subscriptions"
                >
                  <span className="pi pi-send" style={linkStyle}></span>
                  {!isMenuMinimized && "Subscriptions"}
                </NavLink>
              </div>
            )}
            {isRole(["Admin", "Manager", "Warehouse"]) && (
              <div
                className={`py-3 md:px-[15px] whitespace-nowrap ${
                  location.pathname === "/shipments"
                    ? "md:bg-[#DA5E18] md:rounded-[9px]"
                    : ""
                }`}
              >
                <NavLink
                  className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                    location.pathname === "/shipments"
                      ? "md:text-[#FFFFFF]"
                      : "text-[#DA5E18]"
                  }`}
                  to="/shipments"
                >
                  <span className="pi pi-truck" style={linkStyle}></span>
                  {!isMenuMinimized && "Shipments"}
                </NavLink>
              </div>
            )}{" "}
          </CollapsibleSection>
        )}
        {showSalesSection() && (
          <CollapsibleSection
            title="Sales / Venues"
            icon={<span className="pi pi-dollar text-[21px]"></span>}
            isCollapsed={collapsedSections.sales}
            toggle={() => toggleSection("sales")}
            isMenuMinimized={isMenuMinimized}
          >
            {isRole(["Admin", "Manager", "Agent Leader"]) && (
              <div
                className={`py-3 md:px-[15px] whitespace-nowrap ${
                  location.pathname === "/venues"
                    ? "md:bg-[#DA5E18] md:rounded-[9px]"
                    : ""
                }`}
              >
                <NavLink
                  className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                    location.pathname === "/venues"
                      ? "md:text-[#FFFFFF]"
                      : "text-[#DA5E18]"
                  }`}
                  to="/venues"
                >
                  <span className="pi pi-map-marker" style={linkStyle}></span>
                  {!isMenuMinimized && "Venues"}
                </NavLink>
              </div>
            )}
            {isRole(["Admin", "Manager", "Agent Leader"]) && (
              <div
                className={`py-3 md:px-[15px] whitespace-nowrap ${
                  location.pathname === "/sales"
                    ? "md:bg-[#DA5E18] md:rounded-[9px]"
                    : ""
                }`}
              >
                <NavLink
                  className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                    location.pathname === "/sales"
                      ? "md:text-[#FFFFFF]"
                      : "text-[#DA5E18]"
                  }`}
                  to="/sales"
                >
                  <span className="pi pi-map-marker" style={linkStyle}></span>
                  {!isMenuMinimized && "Sales"}
                </NavLink>
              </div>
            )}
            {isRole(["Admin", "Agent Leader"]) && (
              <div
                className={`py-3 md:px-[15px] whitespace-nowrap ${
                  location.pathname === "/agents"
                    ? "md:bg-[#DA5E18] md:rounded-[9px]"
                    : ""
                }`}
              >
                <NavLink
                  className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                    location.pathname === "/agents"
                      ? "md:text-[#FFFFFF]"
                      : "text-[#DA5E18]"
                  }`}
                  to="/agents"
                >
                  <span className="pi pi-dollar" style={linkStyle}></span>
                  {!isMenuMinimized && "Agent Sales"}
                </NavLink>
              </div>
            )}
            {isRole(["Admin", "Manager", "Agent Leader"]) && (
              <div
                className={`py-3 md:px-[15px] whitespace-nowrap ${
                  location.pathname === "/sku"
                    ? "md:bg-[#DA5E18] md:rounded-[9px]"
                    : ""
                }`}
              >
                <NavLink
                  className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                    location.pathname === "/sku"
                      ? "md:text-[#FFFFFF]"
                      : "text-[#DA5E18]"
                  }`}
                  to="/sku"
                >
                  <span className="pi pi-map-marker" style={linkStyle}></span>
                  {!isMenuMinimized && "SKU"}
                </NavLink>
              </div>
            )}
          </CollapsibleSection>
        )}
        {showAccountsSection() && (
          <CollapsibleSection
            title="Accounts"
            icon={<span className="pi pi-users text-[21px]"></span>}
            isCollapsed={collapsedSections.accounts}
            toggle={() => toggleSection("accounts")}
            isMenuMinimized={isMenuMinimized}
          >
            {isRole(["Admin", "Agent", "Manager", "Agent Leader", "KOL"]) && (
              <div
                className={`py-3 md:px-[15px] whitespace-nowrap ${
                  location.pathname === "/customer-listing" ||
                  location.pathname === "/customer-listing-details"
                    ? "md:bg-[#DA5E18] md:rounded-[9px]"
                    : ""
                }`}
              >
                <NavLink
                  className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                    location.pathname === "/customer-listing" ||
                    location.pathname === "/customer-listing-details"
                      ? "md:text-[#FFFFFF]"
                      : "text-[#DA5E18]"
                  }`}
                  to="/customer-listing"
                >
                  <span className="pi pi-users" style={linkStyle}></span>
                  {!isMenuMinimized &&
                    (isRole(["Agent Leader"]) ? "My Customers" : "Customers")}
                </NavLink>
              </div>
            )}
            {isRole(["Admin", "Manager", "Agent Leader"]) && (
              <div
                className={`py-3 md:px-[15px] whitespace-nowrap ${
                  location.pathname === "/users" ||
                  location.pathname === "/user-details"
                    ? "md:bg-[#DA5E18] md:rounded-[9px]"
                    : ""
                }`}
              >
                <NavLink
                  className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                    location.pathname === "/users" ||
                    location.pathname === "/user-details"
                      ? "md:text-[#FFFFFF]"
                      : "text-[#DA5E18]"
                  }`}
                  to="/users"
                >
                  <span className="pi pi-user-plus" style={linkStyle}></span>
                  {!isMenuMinimized &&
                    (isRole(["Agent Leader"]) ? "Agents List" : "Users")}
                </NavLink>
              </div>
            )}
            {isRole([
              "Admin",
              "Agent",
              "Manager",
              "Warehouse",
              "Agent Leader",
              "KOL",
            ]) && (
              <div
                className={`py-3 md:px-[15px] whitespace-nowrap ${
                  location.pathname === "/profile"
                    ? "md:bg-[#DA5E18] md:rounded-[9px]"
                    : ""
                }`}
              >
                <NavLink
                  className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                    location.pathname === "/profile"
                      ? "md:text-[#FFFFFF]"
                      : "text-[#DA5E18]"
                  }`}
                  to="/profile"
                >
                  <span className="pi pi-id-card" style={linkStyle}></span>
                  {!isMenuMinimized && "Profile"}
                </NavLink>
              </div>
            )}
          </CollapsibleSection>
        )}
        {showEcommerceSection() && (
          <CollapsibleSection
            title="Ecommerce"
            icon={<span className="pi pi-shopping-cart text-[21px]"></span>}
            isCollapsed={collapsedSections.ecommerce}
            toggle={() => toggleSection("ecommerce")}
            isMenuMinimized={isMenuMinimized}
          >
            <div
              className={`py-3 md:px-[15px] whitespace-nowrap ${
                location.pathname === "/ecommerce-video" ||
                location.pathname === "/ecommerce-video-create"
                  ? "md:bg-[#DA5E18] md:rounded-[9px]"
                  : ""
              }`}
            >
              <NavLink
                className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                  location.pathname === "/ecommerce-video" ||
                  location.pathname === "/ecommerce-video-create"
                    ? "md:text-[#FFFFFF]"
                    : "text-[#DA5E18]"
                }`}
                to="/ecommerce-video"
              >
                <span className="pi pi-video" style={linkStyle}></span>
                {!isMenuMinimized && "Videos"}
              </NavLink>
            </div>
            <div
              className={`py-3 md:px-[15px] whitespace-nowrap ${
                location.pathname === "/ecommerce-carousel" ||
                location.pathname === "/ecommerce-carousel-create"
                  ? "md:bg-[#DA5E18] md:rounded-[9px]"
                  : ""
              }`}
            >
              <NavLink
                className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                  location.pathname === "/ecommerce-carousel" ||
                  location.pathname === "/ecommerce-carousel-create"
                    ? "md:text-[#FFFFFF]"
                    : "text-[#DA5E18]"
                }`}
                to="/ecommerce-carousel"
              >
                <span className="pi pi-tags" style={linkStyle}></span>
                {!isMenuMinimized && "Carousel"}
              </NavLink>
            </div>
          </CollapsibleSection>
        )}
        {isRole(["Admin", "Manager"]) && (
          <div
            className={`py-3 md:px-[15px] whitespace-nowrap ${
              location.pathname === "/workshop" ||
              location.pathname === "/workshop-create"
                ? "md:bg-[#DA5E18] md:rounded-[9px]"
                : ""
            }`}
          >
            <NavLink
              className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                location.pathname === "/workshop" ||
                location.pathname === "/workshop-create"
                  ? "md:text-[#FFFFFF]"
                  : "text-[#DA5E18]"
              }`}
              to="/workshop"
            >
              <span
                className="pi pi-th-large"
                style={{
                  fontSize: "21px",
                }}
              ></span>
              {!isMenuMinimized && "Workshop"}
            </NavLink>
          </div>
        )}
        {isRole(["Admin", "Manager"]) && (
          <div
            className={`py-3 md:px-[15px] whitespace-nowrap ${
              location.pathname === "/settings"
                ? "md:bg-[#DA5E18] md:rounded-[9px]"
                : ""
            }`}
          >
            <NavLink
              className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                location.pathname === "/settings"
                  ? "md:text-[#FFFFFF]"
                  : "text-[#DA5E18]"
              }`}
              to="/settings"
            >
              <span
                className="pi pi-sliders-h"
                style={{
                  fontSize: "21px",
                }}
              ></span>
              {!isMenuMinimized && "Settings"}
            </NavLink>
          </div>
        )}
        {isRole(["Admin"]) && (
          <div
            className={`py-3 md:px-[15px] whitespace-nowrap ${
              location.pathname === "/permissions"
                ? "md:bg-[#DA5E18] md:rounded-[9px]"
                : ""
            }`}
          >
            <NavLink
              className={`flex flex-row flex-nowrap content-start items-center gap-[15px] w-full ${
                location.pathname === "/permissions"
                  ? "md:text-[#FFFFFF]"
                  : "text-[#DA5E18]"
              }`}
              to="/permissions"
            >
              <span
                className="pi pi-key"
                style={{
                  fontSize: "21px",
                }}
              ></span>
              {!isMenuMinimized && "Permissions"}
            </NavLink>
          </div>
        )}
      </div>
      <button
        onClick={() => {
          setMenuOpen(false);
          dispatch(logout());
          navigate("/login", { replace: true });
        }}
        className="flex flex-row flex-nowrap content-start items-center gap-[15px] w-full text-[#DA5E18] md:mx-[15px] pt-[30px]"
      >
        <img
          src={require("../assets/images/icon-logout.png")}
          alt="Logout icon"
          className="w-[21px]"
        />
        {!isMenuMinimized && "Logout"}
      </button>
    </div>
  );
};

export default PageLinks;

import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useLocation } from "react-router-dom";

const PaymentStatus = () => {
  const { height } = useWindowDimensions();
  const location = useLocation();
  const paymentIntent = location.state.paymentIntent;
  const [paymentStatus, setPaymentStatus] = useState(paymentIntent.status);
  console.log(paymentIntent);

  useEffect(() => {
    setPaymentStatus(paymentIntent.status);
    console.log(paymentIntent.status);
  }, [paymentIntent.status]);

  return (
    <div
      className="flex bg-[#FFEDD7] md:bg-[#F1F1F1] md:bg-ipad-background-image md:bg-cover md:bg-center md:bg-no-repeat"
      style={{ height: `${height}px` }}
    >
      <div className="w-full max-w-xs m-auto">
        <header className="flex flex-col items-center">
          <img
            src={require("../../assets/images/logo-cfg-circle.png")}
            alt="CFG Logo"
            className="h-[131px] mx-auto mb-[30px]"
          />
        </header>
        {paymentStatus === "processing" ? (
          <div className="pb-[20px] flex flex-col justify-center items-center md:bg-white md:rounded-[7px] md:p-5">
            <label className="text-[26px] text-[#000000] pt-5 pb-2 text-center">
              Payment Processing ...
            </label>
          </div>
        ) : paymentStatus === "succeeded" ? (
          <div className="pb-[20px] flex flex-col justify-center items-center md:bg-white md:rounded-[7px] md:p-5">
            <label className="text-[26px] text-[#000000] pt-5 pb-2 text-center">
              Payment Success!
            </label>
            <i
              className="pi pi-check"
              style={{ fontSize: "4rem", color: "green" }}
            ></i>
            <label className="text-[20px] text-[#000000] pt-5 pb-2 text-center">
              Your payment is currently being processed, and an invoice will be
              sent to your email shortly.
            </label>
          </div>
        ) : paymentStatus === "payment_failed" ? (
          <div className="pb-[20px] flex flex-col justify-center items-center md:bg-white md:rounded-[7px] md:p-5">
            <label className="text-[26px] text-[#000000] pt-5 pb-2 text-center">
              Payment Failed!
            </label>
            <i
              className="pi pi-times"
              style={{ fontSize: "4rem", color: "red" }}
            ></i>
            <label className="text-[20px] text-[#000000] pt-5 pb-2 text-center">
              Please try again.
            </label>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PaymentStatus;

import React from "react";
import { Dialog } from "primereact/dialog";

const FullScreenDialog = ({ children, onHide, visible }: any) => {
  return (
    <Dialog
      showHeader={false}
      maximized={true}
      pt={{
        content: {
          style: {
            margin: 0,
            padding: 0,
          },
        },
      }}
      onHide={onHide}
      visible={visible}
    >
      {children}
    </Dialog>
  );
};

export default FullScreenDialog;

import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/authSlice";

const PermissionDenied = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="bg-[#FFEDD7] md:bg-[#F1F1F1] md:bg-ipad-background-image md:bg-cover md:bg-center md:bg-no-repeat md:bg-fixed w-screen h-screen flex justify-center items-center">
      <span
        className="text-[#DA5E18] text-[20px] leading-[30px] font-[700] cursor-pointer"
        onClick={() => {
          dispatch(logout());
          navigate("/login", { replace: true });
        }}
      >
        Permission Denied. Bring me to home.
      </span>
    </div>
  );
};

export default PermissionDenied;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState, User } from "../../interfaces";

const initialState: AuthState = {
  user: null,
  error: null,
};

const authSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.error = null;
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.error = null;
    },
  },
});

export const { loginSuccess, loginFailure, logout } = authSlice.actions;

export default authSlice.reducer;

import React from "react";
import { Button } from "primereact/button";

const AdminButton = (props: any) => {
  return (
    <Button
      style={{
        border: 0,
        borderRadius: "15px 15px 0 0",
        minWidth: "130px",
        background: "linear-gradient(180deg, #BFFFD1 0%, #69DBFF 100%)",
        color: "#46749E",
        marginRight: "30px",
      }}
      {...props}
    />
  );
};

export default AdminButton;

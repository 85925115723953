import React from "react";
import OneTimePurchase from "../screens/NewOrderCart/OneTimePurchase";
import Subscription from "../screens/NewOrderCart/Subscription";
import { useDispatch } from "react-redux";
import {
  getAdditionalInfo,
  setAdditionalInfo,
  setBillingInfo,
  setCart,
  setCartItems,
  setOrderInfo,
} from "../redux/slices/orderSlice";
import { useAppSelector } from "../hooks/useStore";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const CartItems = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cart } = useAppSelector((state) => state.order);

  return (
    <>
      {cart?.map(({ cartItems, orderInfo }: any, index: number) => (
        <div key={`cart-group-${index}`}>
          {orderInfo?.purchaseOption?.length > 0 &&
            orderInfo?.purchaseOption[0]?.id === 1 && (
              <>
                <div className="flex items-center justify-between bg-[#343426] rounded-[7px] mb-[10px] py-[5px] pl-[16px] pr-[5px]">
                  <label className="text-[#DA5E18] text-[16px]">
                    One Time Purchase
                  </label>
                  <div className="flex">
                    <Button
                      link
                      className={`p-[5px]`}
                      onClick={() => {
                        dispatch(setCartItems(cartItems));
                        dispatch(setOrderInfo(orderInfo));
                        dispatch(
                          setCart(
                            cart.filter((item: any, i: number) => i !== index),
                          ),
                        );
                        navigate("/new-order");
                      }}
                    >
                      <img
                        src={require("../assets/images/order/icon-edit-circle.png")}
                        alt="Edit Icon"
                        className={`w-[28px] h-[28px]`}
                      />
                    </Button>
                    <Button
                      link
                      className={`p-[5px]`}
                      onClick={() => {
                        if (cart?.length > 1) {
                          dispatch(
                            setCart(
                              cart.filter(
                                (item: any, i: number) => i !== index,
                              ),
                            ),
                          );
                          dispatch(getAdditionalInfo());
                        } else {
                          dispatch(setCart([]));
                          dispatch(setBillingInfo({}));
                          dispatch(setAdditionalInfo({}));
                          navigate("/new-order");
                        }
                      }}
                    >
                      <img
                        src={require("../assets/images/order/icon-delete-circle.png")}
                        alt="Delete Icon"
                        className={`w-[28px] h-[28px]`}
                      />
                    </Button>
                  </div>
                </div>
                <div className="rounded-[10px] border-[0.8px] p-[16px] mb-[18px] border-[#E3E3E3] bg-white shadow-[2px_4px_4px_0_rgba(0,0,0,0.06)]">
                  <div>
                    <div className="flex items-center justify-end mb-[9px] px-[13px]"></div>
                    {cartItems.map((item: any) => (
                      <OneTimePurchase
                        key={`otp-items-${item?.id}`}
                        data={item}
                        onDeliveryOptionChange={(event: any) => {
                          const items: any = [...cart];
                          const { cartItems }: any = items[index];
                          const citems: any = [...cartItems];
                          const itemIndex = cartItems.findIndex(
                            (el: any) => el?.id === event?.id,
                          );
                          citems[itemIndex] = event;
                          dispatch(
                            setCart(
                              items.map((item: any, ind: number) => {
                                if (ind === index) {
                                  return { ...item, ...{ cartItems: citems } };
                                } else {
                                  return item;
                                }
                              }),
                            ),
                          );
                          dispatch(getAdditionalInfo());
                        }}
                      />
                    ))}
                    <p className="text-right my-[9px] text-[18px] text-[#DA5E18] px-[13px]">
                      {orderInfo?.sum_formatted}
                    </p>
                  </div>
                </div>
              </>
            )}
          {orderInfo?.purchaseOption?.length > 0 &&
            orderInfo?.purchaseOption[0]?.id === 2 && (
              <>
                <div className="flex items-center justify-between bg-[#343426] rounded-[7px] mb-[10px] py-[5px] pl-[16px] pr-[5px]">
                  <label className="text-[#DA5E18] text-[16px]">
                    Subscription Coffee
                  </label>
                  <div className="flex">
                    <Button
                      link
                      className={`p-[5px]`}
                      onClick={() => {
                        dispatch(setCartItems(cartItems));
                        dispatch(setOrderInfo(orderInfo));
                        dispatch(
                          setCart(
                            cart.filter((item: any, i: number) => i !== index),
                          ),
                        );
                        navigate("/new-order");
                      }}
                    >
                      <img
                        src={require("../assets/images/order/icon-edit-circle.png")}
                        alt="Edit Icon"
                        className={`w-[28px] h-[28px]`}
                      />
                    </Button>
                    <Button
                      link
                      className={`p-[5px]`}
                      onClick={() => {
                        if (cart?.length > 1) {
                          dispatch(
                            setCart(
                              cart.filter(
                                (item: any, i: number) => i !== index,
                              ),
                            ),
                          );
                          dispatch(getAdditionalInfo());
                        } else {
                          dispatch(setCart([]));
                          dispatch(setBillingInfo({}));
                          dispatch(setAdditionalInfo({}));
                          navigate("/new-order");
                        }
                      }}
                    >
                      <img
                        src={require("../assets/images/order/icon-delete-circle.png")}
                        alt="Delete Icon"
                        className={`w-[28px] h-[28px]`}
                      />
                    </Button>
                  </div>
                </div>
                <div className="rounded-[10px] border-[0.8px] p-[16px] mb-[18px] border-[#E3E3E3] bg-white shadow-[2px_4px_4px_0_rgba(0,0,0,0.06)]">
                  <div>
                    <div className="flex items-center justify-end mb-[9px] px-[13px]">
                      <label className="text-[#3E3E3E] font-medium text-base">
                        {orderInfo?.subscriptionOption[0]?.subscription_month}{" "}
                        months
                      </label>
                    </div>
                    {cartItems.map((item: any) => (
                      <Subscription
                        data={item}
                        key={`subscription-items-${item?.id}`}
                      />
                    ))}
                    <p className="text-right my-[9px] text-[18px] text-[#DA5E18] px-[13px]">
                      {orderInfo?.sum_formatted}
                    </p>
                    {orderInfo?.freeGifts?.length > 0 && (
                      <>
                        <div>Free Gifts</div>
                        {orderInfo.freeGifts.map((item: any) => (
                          <Subscription
                            data={{ ...item, ...{ quantity: 1 } }}
                            key={`free-items-${item?.id}`}
                          />
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          {orderInfo?.purchaseOption?.length > 0 &&
            orderInfo?.purchaseOption[0]?.id === 3 && (
              <>
                <div className="flex items-center justify-between bg-[#343426] rounded-[7px] mb-[10px] pl-[16px] pr-[5px]">
                  <label className="text-[#DA5E18] text-[16px]">Plan</label>
                  <div className="flex">
                    <Button
                      link
                      className={`p-[5px]`}
                      onClick={() => {
                        if (cart?.length > 1) {
                          dispatch(
                            setCart(
                              cart.filter(
                                (item: any, i: number) => i !== index,
                              ),
                            ),
                          );
                          dispatch(getAdditionalInfo());
                        } else {
                          dispatch(setCart([]));
                          dispatch(setBillingInfo({}));
                          dispatch(setAdditionalInfo({}));
                          navigate("/new-order");
                        }
                      }}
                    >
                      <img
                        src={require("../assets/images/order/icon-delete-circle.png")}
                        alt="Delete Icon"
                        className={`w-[28px] h-[28px]`}
                      />
                    </Button>
                  </div>
                </div>
                <div className="rounded-[10px] border-[0.8px] p-[16px] mb-[18px] border-[#E3E3E3] bg-white shadow-[2px_4px_4px_0_rgba(0,0,0,0.06)]">
                  <div>
                    <div className="flex items-center justify-end mb-[9px] px-[13px]">
                      <label className="text-[#3E3E3E] font-medium text-base">
                        {orderInfo?.subscriptionOption[0]?.subscription_month}{" "}
                        months
                      </label>
                    </div>
                    {cartItems.map((cartItem: any, cartIndex: number) => (
                      <React.Fragment key={`cart-items-${cartIndex}`}>
                        <div className="flex items-center justify-start mb-[9px] px-[13px]">
                          <label className="text-[#3E3E3E] font-medium text-base">
                            {cartItem?.name}
                          </label>
                          {cartItem?.add_on_name && (
                            <label className="text-[#3E3E3E] font-medium text-base pl-1">
                              [{cartItem?.add_on_name}]
                            </label>
                          )}
                          <Button
                            text
                            className="p-0 ml-2"
                            onClick={() => {
                              dispatch(setOrderInfo(orderInfo));
                              dispatch(
                                setCart(
                                  cart.map((cartItem: any, b: number) => {
                                    if (b === index) {
                                      return {
                                        ...cartItem,
                                        ...{
                                          cartItems: cartItem.cartItems.filter(
                                            (ci: any, ciIndex: number) =>
                                              cartIndex !== ciIndex,
                                          ),
                                        },
                                      };
                                    }
                                    return cartItem;
                                  }),
                                ),
                              );
                              navigate("/plan-addons", {
                                state: {
                                  plan: cartItem,
                                  isEdit: true,
                                  cartIndex: index,
                                },
                              });
                            }}
                          >
                            <img
                              src={require("../assets/images/icon-edit.png")}
                              alt="Edit Icon"
                              className={`w-[28px] h-[28px]`}
                            />
                          </Button>
                        </div>
                        {console.log(cartItem)}
                        {cartItem?.bundle_items.map(
                          (item: any) =>
                            cartItem?.quantity &&
                            item?.quantity &&
                            item?.type && (
                              <Subscription
                                data={{
                                  ...item,
                                  ...{
                                    quantity:
                                      cartItem?.quantity * item?.quantity,
                                  },
                                }}
                                key={`subscription-items-${item?.id}-${item?.type}`}
                              />
                            ),
                        )}
                      </React.Fragment>
                    ))}
                    <p className="text-right my-[9px] text-[18px] text-[#DA5E18] px-[13px]">
                      {orderInfo?.sum_formatted}
                    </p>
                  </div>
                </div>
              </>
            )}
        </div>
      ))}
    </>
  );
};

export default CartItems;

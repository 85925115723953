import { Skeleton } from "primereact/skeleton";

const ListViewSkeleton = () => {
  return (
    <div>
      <div className="pb-2 pt-5">
        <Skeleton width="100px" height="20px"></Skeleton>
      </div>
      <div className="mb-2">
        <Skeleton height="140px"></Skeleton>
      </div>
      <div className="mb-2">
        <Skeleton height="140px"></Skeleton>
      </div>
      <div className="mb-2">
        <Skeleton height="140px"></Skeleton>
      </div>
      <div className="mb-2">
        <Skeleton height="140px"></Skeleton>
      </div>
      <div className="mb-2">
        <Skeleton height="140px"></Skeleton>
      </div>
      <div className="mb-2">
        <Skeleton height="140px"></Skeleton>
      </div>
    </div>
  );
};

export default ListViewSkeleton;

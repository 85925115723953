import React from "react";

const TableHeader = ({ children }: any) => {
  return (
    <div className="flex flex-nowrap flex-row justify-end items-end w-full">
      {children}
    </div>
  );
};

export default TableHeader;

import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AcceptButton from "../../../components/AcceptButton";
import CancelButton from "../../../components/CancelButton";
import EmptyData from "../../../components/EmptyData";
import FilterButton from "../../../components/FilterButton";
import Layout from "../../../components/Layout";
import TableFooter from "../../../components/TableFooter";
import TableSkeleton from "../../../components/TableSkeleton";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import { updateCustomer } from "../../../redux/slices/customerSlice";
import { formatDate } from "../../../utils/Common";
import useApiData from "../../../hooks/useApiData";

type UserData = {
  country_code: string;
  email: string;
  first_name: string;
  full_name: string;
  id: number;
  last_name: string;
  mobile: string;
  segment: string;
  address: {
    postal_code: string;
    street: string;
    unit_no: string;
  };
  status: number;
  source: {
    id: string;
    name: string;
  };
  created_at: string;
  is_blacklist: number;
};

type UserSearchParams = {
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  mobile: string | null;
  sort_by: string | null;
  sorting_column: string | null;
  statuses: string | null;
  segment: string | null;
  source: string | null;
};

const CustomerListing = () => {
  const [filterVisible, setFilterVisible] = useState(false);
  const [sortVisible, setSortVisible] = useState(false);
  const [filterFirstName, setFilterFirstName] = useState("");
  const [filterLastName, setFilterLastName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterMobile, setFilterMobile] = useState("");
  const [filterStatuses, setFilterStatuses] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortColumn, setSortColumn] = useState("first_name");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [filterSource, setFilterSource] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const statusOptions = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
  ];

  const source = useApiData("/get_source_type");
  const sourceData = source as any;
  let sourceList = [];

  if (sourceData?.data) {
    sourceList = sourceData.data.map((item: any) => {
      return { label: item?.name, value: item?.id };
    });
    sourceList.unshift({ label: "All Sources", value: 0 });
  }

  const {
    data,
    loading,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
  } = usePaginatedApiData<UserData, UserSearchParams>(
    "/get_customers",
    {
      first_name: null,
      last_name: null,
      email: null,
      mobile: null,
      sort_by: null,
      sorting_column: null,
      statuses: null,
      segment: null,
      source: null,
    },
    1,
  );

  const rawRecords = data as any;
  const customers = rawRecords?.data;
  const summary = rawRecords?.summary;

  const handleView = (customer: any) => {
    dispatch(updateCustomer(customer));
    navigate("/customer-listing-details", { state: { customer: customer } });
  };

  const handleSort = (event: any) => {
    setSortBy(event.value);
  };

  const handleStatusClick = (status: any) => {
    setSelectedStatus(status);
    handleSearchParamsChange({
      segment: status,
    });
  };

  return (
    <Layout>
      <Dialog
        header="Filter"
        position="bottom"
        visible={filterVisible}
        style={{
          width: "100vw",
          maxWidth: "450px",
          margin: 0,
          border: 0,
        }}
        onHide={() => setFilterVisible(false)}
      >
        <div className="mb-[10px]">
          <label htmlFor="filter-first-name" className="mb-[5px] block">
            First name
          </label>
          <div>
            <InputText
              id="filter-first-name"
              className="w-full"
              value={filterFirstName}
              onChange={(e) => setFilterFirstName(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-last-name" className="mb-[5px] block">
            Last name
          </label>
          <div>
            <InputText
              id="filter-last-name"
              className="w-full"
              value={filterLastName}
              onChange={(e) => setFilterLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-mobile" className="mb-[5px] block">
            Mobile
          </label>
          <div>
            <InputText
              id="filter-mobile"
              className="w-full"
              value={filterMobile}
              onChange={(e) => setFilterMobile(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-email" className="mb-[5px] block">
            Email
          </label>
          <div>
            <InputText
              id="filter-email"
              className="w-full"
              value={filterEmail}
              onChange={(e) => setFilterEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-status" className="mb-[5px] block">
            Status
          </label>
          <div>
            <Dropdown
              id="filter-status"
              optionLabel="label"
              value={filterStatuses}
              options={statusOptions}
              onChange={(e) => setFilterStatuses(e?.value)}
              className="w-full"
            />
          </div>
        </div>
        <div className="flex flex-row justify-between items-center pt-[10px]">
          <CancelButton
            label="Reset"
            onClick={() => {
              setFilterFirstName("");
              setFilterLastName("");
              setFilterEmail("");
              setFilterStatuses("");
              handleSearchParamsChange({
                first_name: "",
                last_name: "",
                mobile: "",
                email: "",
                statuses: "",
              });
              setFilterVisible(false);
            }}
          />
          <AcceptButton
            label="Apply"
            onClick={() => {
              handleSearchParamsChange({
                first_name: filterFirstName,
                last_name: filterLastName,
                mobile: filterMobile,
                email: filterEmail,
                statuses: filterStatuses,
              });
              setFilterVisible(false);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        header="Sort"
        position="bottom"
        visible={sortVisible}
        style={{
          width: "100vw",
          maxWidth: "450px",
          margin: 0,
          border: 0,
        }}
        onHide={() => setSortVisible(false)}
      >
        <div className="pb-[10px]">
          <label className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block">
            Order By
          </label>
          <div className="flex flex-col">
            <div className="flex flex-col space-y-2">
              <Dropdown
                value={sortColumn}
                options={[
                  { label: "First Name", value: "first_name" },
                  { label: "Last Name", value: "last_name" },
                  { label: "Email", value: "email" },
                  { label: "Mobile", value: "mobile" },
                ]}
                onChange={(e) => setSortColumn(e.value)}
                placeholder="Select"
              />
            </div>
          </div>
        </div>
        <div className="pb-[10px]">
          <label className="text-[16px] text-[#292929] font-[500] capitalize mb-[16px] block">
            Sort By
          </label>
          <div className="flex flex-wrap gap-3">
            <div className="flex items-center">
              <RadioButton
                name="sort"
                value="desc"
                onChange={handleSort}
                checked={sortBy === "1"}
              />
              <label htmlFor="in" className="ml-2">
                Latest to Oldest
              </label>
            </div>
            <div className="flex items-center">
              <RadioButton
                name="sort"
                value="asc"
                onChange={handleSort}
                checked={sortBy === "2"}
              />
              <label htmlFor="out" className="ml-2">
                Oldest to Latest
              </label>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center pt-[10px]">
          <CancelButton
            label="Reset"
            onClick={() => {
              setSortVisible(false);
            }}
          />
          <AcceptButton
            label="Apply"
            onClick={() => {
              handleSearchParamsChange({
                sorting_column: sortColumn,
                sort_by: sortBy,
              });
              setSortVisible(false);
            }}
          />
        </div>
      </Dialog>
      <div className="px-[17px] pt-[17px] card-cfg">
        <div className="flex flex-wrap gap-3 mb-4 items-center">
          <div
            className={`w-fit flex h-[51px] items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]`}
          >
            <img
              src={require("../../../assets/images/icon-customer-count.png")}
              alt="Filter icon"
              className="w-[27px] mr-[10px]"
            />
            <Dropdown
              id="filter-venue"
              optionLabel="label"
              value={filterSource}
              options={sourceList}
              onChange={(e) => {
                const newSource = e.value;
                setFilterSource(newSource);
                handleSearchParamsChange({
                  source: newSource,
                });
              }}
              className="w-full"
            />
          </div>
          <div
            className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 ${
              selectedStatus === "new"
                ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }`}
            onClick={() => handleStatusClick("new")}
          >
            <img
              src={require("../../../assets/images/icon-customer-count.png")}
              alt="Filter icon"
              className="w-[27px] mr-[10px]"
            />
            New <span className="text-[24px] ml-[8px]">{summary?.new}</span>
          </div>
          <div
            className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 ${
              selectedStatus === "returning"
                ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }`}
            onClick={() => handleStatusClick("returning")}
          >
            <img
              src={require("../../../assets/images/icon-customer-count.png")}
              alt="Filter icon"
              className="w-[27px] mr-[10px]"
            />
            Returning{" "}
            <span className="text-[24px] ml-[8px]">{summary?.returning}</span>
          </div>
          <div
            className="w-fit flex items-center bg-gradient-to-b from-[#B5F2FF] via-white to-white text-[#3EB2D7] text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] cursor-pointer ring-1 ring-[#5BDDFA]"
            onClick={() => handleStatusClick("")}
          >
            <img
              src={require("../../../assets/images/icon-customer-count.png")}
              alt="Filter icon"
              className="w-[27px] mr-[10px]"
            />
            Total <span className="text-[24px] ml-[8px]">{summary?.total}</span>
          </div>
          <div className="flex gap-2 ml-auto">
            <FilterButton
              onClick={() => setFilterVisible(true)}
              className="mb-[5px]"
            />
            <button
              className="flex flex-row flex-nowrap justify-center items-center gap-x-[5px] bg-[#FFFFFF] rounded-[10px] px-[12px] py-[7px] mb-[5px]"
              onClick={() => setSortVisible(true)}
            >
              <span className="text-[#DA5E18] text-[12px] leading-[15px] font-[450]">
                Sort
              </span>
              <img
                src={require("../../../assets/images/order/sort-icon.png")}
                alt="Sort icon"
                className="w-[18px]"
              />
            </button>
          </div>
        </div>
        {loading && <TableSkeleton />}
        {!loading && customers?.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="table-cfg">
                <thead>
                  <tr>
                    <th>Customer</th>
                    <th>Mobile</th>
                    <th>Email</th>
                    <th>Source</th>
                    <th>Account Creation</th>
                    <th>Segment</th>
                  </tr>
                </thead>
                <tbody>
                  {customers.map((row: UserData) => {
                    return (
                      <tr key={`customer-table-row-${row?.id}`}>
                        <td data-label="Customer">
                          <label
                            className={`${
                              row?.is_blacklist
                                ? "text-[#848484]"
                                : "text-[#E5622A]"
                            } cursor-pointer underline`}
                            onClick={() => handleView(row)}
                          >
                            {row?.full_name}
                          </label>
                        </td>
                        <td data-label="Mobile">
                          <div className="whitespace-nowrap">
                            {row?.country_code} {row?.mobile}
                          </div>
                        </td>
                        <td data-label="Email">{row?.email}</td>
                        <td data-label="Source">{row?.source?.name}</td>
                        <td data-label="Account Creation">
                          {formatDate(row?.created_at)}
                        </td>
                        <td data-label="Segment" className="text-center">
                          <div
                            className={`${
                              row.segment === "New"
                                ? "bg-green-300"
                                : "bg-yellow-300"
                            } px-[8px] py-[3px] rounded-[30px] inline text-xs w-fit lg:w-full`}
                          >
                            {row.segment}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            />
          </>
        )}
        {!loading && customers?.length <= 0 && <EmptyData />}
      </div>
    </Layout>
  );
};

export default CustomerListing;

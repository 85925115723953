import { Skeleton } from "primereact/skeleton";

const GridViewSkeleton = () => {
  return (
    <div>
      <div className="pb-2 pt-5">
        <Skeleton width="100px" height="20px"></Skeleton>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <div className="col-span-1">
          <Skeleton height="280px"></Skeleton>
        </div>
        <div className="col-span-1">
          <Skeleton height="280px"></Skeleton>
        </div>
        <div className="col-span-1">
          <Skeleton height="280px"></Skeleton>
        </div>
        <div className="col-span-1">
          <Skeleton height="280px"></Skeleton>
        </div>
        <div className="col-span-1">
          <Skeleton height="280px"></Skeleton>
        </div>
        <div className="col-span-1">
          <Skeleton height="280px"></Skeleton>
        </div>
      </div>
    </div>
  );
};
export default GridViewSkeleton;

import React, { useEffect, useState } from "react";
import { useAppSelector } from "./useStore";

const useDeliveryDateRequired = () => {
  const { cart } = useAppSelector((state) => state.order);
  const [deliveryDateRequired, setDeliveryDateRequired] = useState(true);

  useEffect(() => {
    setDeliveryDateRequired(false);
    let d = 0;
    cart?.map((item: any) => {
      item?.cartItems?.map((cart: any) => {
        if (cart?.delivery_option_id === 2) {
          d++;
        }
      });
    });
    if (d > 0) {
      setDeliveryDateRequired(true);
    }
  }, [cart]);

  return deliveryDateRequired;
};

export default useDeliveryDateRequired;

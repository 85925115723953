import React from "react";
import Pagination from "./Pagination";

const TableFooter = ({
  itemsFrom,
  itemsTo,
  itemsTotal,
  currentPage,
  totalPages,
  setCurrentPage,
}: any) => {
  return (
    <div className="flex flex-nowrap flex-col md:flex-row justify-center md:justify-between items-center py-[28px]">
      <div className="text-[#828282] text-[12px] leading-[15px] font-[400] hidden md:block">
        Total Records:{" "}
        <span className="text-[#DA5E18] font-[700]">{itemsTotal}</span>
      </div>
      <div className="text-[#828282] text-[12px] leading-[15px] font-[400] hidden md:block">
        Showing: <span className="text-[#DA5E18] font-[700]">{itemsFrom} </span>
        to
        <span className="text-[#DA5E18] font-[700]"> {itemsTo}</span>
      </div>
      <Pagination
        nPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <div className="md:hidden text-[12px] leading-[15px] text-[#828282] font-[400] pt-[7px]">
        {itemsFrom} to {itemsTo} of {itemsTotal} items
      </div>
    </div>
  );
};

export default TableFooter;

import React, { useEffect, useState } from "react";
import { get } from "../utils/Api";

const useApiData = (initialUrl: string) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get(`${initialUrl}`);
        if (response?.data) {
          setData(response.data);
          throw new Error("Network response was not ok");
        } else {
          setError("Error fetching data");
        }
      } catch (error) {
        setError("An error occurred");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [initialUrl]);

  return { data, isLoading, error };
};

export default useApiData;

import React, { useState } from "react";
import Layout from "../../../components/Layout";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import { InputText } from "primereact/inputtext";
// import { useNavigate } from "react-router-dom";
import TableSkeleton from "../../../components/TableSkeleton";
import TableHeader from "../../../components/TableHeader";
import TableFooter from "../../../components/TableFooter";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { formatDate } from "../../../utils/Common";
import AcceptButton from "../../../components/AcceptButton";
import CancelButton from "../../../components/CancelButton";
import FilterButton from "../../../components/FilterButton";
import FullScreenDialog from "../../../components/FullScreenDialog";
import OrderDetails from "../../OrderDetails";
import ExportButton from "../../../components/ExportButton";
import { format } from "date-fns";
import EmptyData from "../../../components/EmptyData";
import { useAppSelector } from "../../../hooks/useStore";

type UserData = {
  id: number;
  customer: {
    first_name: string;
    last_name: string;
    // full_name: string;
    mobile: string;
    email: string;
  };
  month_index: number;
  order: any;
  order_id: number;
  shipments: [];
  shipping_date: string;
  status: number;
};
type UserSearchParams = {
  first_name: string;
  last_name: string;
  mobile: string;
  start_date: string;
  end_date: string;
  statuses: string;
};

const Subscriptions = () => {
  // const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.userAuth);
  const [showDetail, setShowDetail] = useState(false);
  const [showDetailParam, setShowDetailParam] = useState({});

  const handleView = (order: any) => {
    // navigate("/order-details", {
    //   state: { order_id: order?.order_item.order.id },
    // });
    setShowDetailParam({
      order_id: order?.order_id,
      order_month_index: order?.month_index,
    });
    setShowDetail(true);
  };
  const {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  } = usePaginatedApiData<UserData, UserSearchParams>(
    "/admin/get_shipments",
    {
      mobile: "",
      first_name: "",
      last_name: "",
      start_date: "",
      end_date: "",
      statuses: "",
    },
    1,
  );

  const rawRecords = data as any;
  const records = rawRecords?.data as UserData[];
  const summary = rawRecords?.summary;
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterFirstName, setFilterFirstName] = useState("");
  const [filterLastName, setFilterLastName] = useState("");
  const [filterMobile, setFilterMobile] = useState("");
  const [filterDeliveryDate, setFilterDeliveryDate] = useState<any>(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const formatFilterDate = (date: (Date | null)[]) => {
    if (!date || date.length < 1 || !date[0]) {
      return { startDate: "", endDate: "" };
    }

    const startDate = date[0];
    const endDate = date[1];

    let startDateString = "";
    let endDateString = "";

    if (startDate) {
      const start_year = startDate.getFullYear();
      const start_month = String(startDate.getMonth() + 1).padStart(2, "0");
      const start_day = String(startDate.getDate()).padStart(2, "0");
      startDateString = `${start_year}-${start_month}-${start_day}`;
    }

    if (endDate) {
      const end_year = endDate.getFullYear();
      const end_month = String(endDate.getMonth() + 1).padStart(2, "0");
      const end_day = String(endDate.getDate()).padStart(2, "0");
      endDateString = `${end_year}-${end_month}-${end_day}`;
    }

    return { startDate: startDateString, endDate: endDateString };
  };

  const formattedFilterDate = formatFilterDate(filterDeliveryDate);
  // console.log(formattedFilterDate?.startDate);

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;

  const handleStatusClick = (status: any) => {
    setSelectedStatus(status);
    handleSearchParamsChange({
      statuses: status,
    });
  };

  return (
    <Layout>
      <FullScreenDialog
        visible={showDetail}
        onHide={() => setShowDetail(false)}
      >
        <OrderDetails
          params={showDetailParam}
          close={() => {
            setShowDetail(false);
            handleReload();
          }}
        />
      </FullScreenDialog>
      <Dialog
        header="Filter"
        position="bottom"
        visible={filterVisible}
        style={{
          width: "100vw",
          maxWidth: "450px",
          margin: 0,
          border: 0,
        }}
        onHide={() => setFilterVisible(false)}
      >
        <div className="mb-[10px]">
          <label htmlFor="filter-first-name" className="mb-[5px] block">
            First name
          </label>
          <div>
            <InputText
              id="filter-first-name"
              className="w-full"
              value={filterFirstName}
              onChange={(e) => setFilterFirstName(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-last-name" className="mb-[5px] block">
            Last name
          </label>
          <div>
            <InputText
              id="filter-last-name"
              className="w-full"
              value={filterLastName}
              onChange={(e) => setFilterLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-mobile" className="mb-[5px] block">
            Mobile
          </label>
          <div>
            <InputText
              id="filter-mobile"
              className="w-full"
              value={filterMobile}
              onChange={(e) => setFilterMobile(e.target.value)}
            />
          </div>
        </div>
        <div className="mb-[10px]">
          <label htmlFor="filter-mobile" className="mb-[5px] block">
            Shipping Date
          </label>
          <Calendar
            className="w-full"
            name="delivery_date"
            dateFormat="dd M yy"
            selectionMode="range"
            value={filterDeliveryDate}
            onChange={(e) => {
              if (Array.isArray(e.value) && e.value.length === 2) {
                const [startDate, endDate] = e.value;
                setFilterDeliveryDate([startDate, endDate]);
              }
            }}
          />
        </div>
        <div className="flex flex-row justify-between items-center pt-[10px]">
          <CancelButton
            label="Reset"
            onClick={() => {
              setFilterFirstName("");
              setFilterLastName("");
              setFilterMobile("");
              setFilterDeliveryDate(null);
              handleSearchParamsChange({
                first_name: "",
                last_name: "",
                mobile: "",
                start_date: "",
                end_date: "",
              });
              setFilterVisible(false);
            }}
          />
          <AcceptButton
            label="Apply"
            onClick={() => {
              handleSearchParamsChange({
                first_name: filterFirstName,
                last_name: filterLastName,
                mobile: filterMobile,
                start_date: formattedFilterDate?.startDate,
                end_date: formattedFilterDate?.endDate,
              });
              setFilterVisible(false);
            }}
          />
        </div>
      </Dialog>
      <TableHeader>
        <div className="flex flex-row justify-between items-center flex-nowrap w-full">
          <ExportButton
            className="mb-[5px]"
            api={`/admin/export_shipments?first_name=${filterFirstName}&last_name=${filterLastName}&mobile=${filterMobile}&start_date=${formattedFilterDate?.startDate}&end_date=${formattedFilterDate?.endDate}`}
            filename={`shipments_${format(new Date(), "yyyyMMddHHmmss")}${
              user?.id
            }.xlsx`}
          />
        </div>
        <FilterButton
          onClick={() => setFilterVisible(true)}
          className="mb-[5px]"
        />
        {/* <div className="rounded-full bg-[#FFFFFF] w-[32px] h-[32px] flex justify-center items-center mx-[20px]">
          <img
            src={require("../../../assets/images/icon-search.png")}
            alt="Search icon"
            className="w-[18px]"
          />
        </div> */}
      </TableHeader>
      <div className="p-[17px] card-cfg">
        <div className="flex flex-wrap flex-row justify-start items-center">
          <div
            className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] mb-[12px] mr-[15px] cursor-pointer ring-1 ${
              selectedStatus === "1"
                ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }`}
            onClick={() => handleStatusClick("1")}
          >
            <img
              src={require("../../../assets/images/icon-to-prepare.png")}
              alt="Filter icon"
              className="w-[27px] mr-[10px]"
            />
            To Prepare{" "}
            <span className="text-[24px] ml-[8px]">
              {summary && summary[0]?.total_quantity}
            </span>
          </div>
          <div
            className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] mb-[12px] mr-[15px] cursor-pointer ring-1 ${
              selectedStatus === "2"
                ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }`}
            onClick={() => handleStatusClick("2")}
          >
            <img
              src={require("../../../assets/images/icon-to-ship.png")}
              alt="Filter icon"
              className="w-[27px] mr-[10px]"
            />
            Pending Pick up{" "}
            <span className="text-[24px] ml-[8px]">
              {summary && summary[1]?.total_quantity}
            </span>
          </div>
          <div
            className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] mb-[12px] mr-[15px] cursor-pointer ring-1 ${
              selectedStatus === "3"
                ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }`}
            onClick={() => handleStatusClick("3")}
          >
            <img
              src={require("../../../assets/images/icon-to-ship.png")}
              alt="Filter icon"
              className="w-[27px] mr-[10px]"
            />
            Shipped{" "}
            <span className="text-[24px] ml-[8px]">
              {summary && summary[2]?.total_quantity}
            </span>
          </div>
          <div
            className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] mb-[12px] mr-[15px] cursor-pointer ring-1 ${
              selectedStatus === "4"
                ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }`}
            onClick={() => handleStatusClick("4")}
          >
            <img
              src={require("../../../assets/images/icon-shipped.png")}
              alt="Filter icon"
              className="w-[27px] mr-[10px]"
            />
            Delivered{" "}
            <span className="text-[24px] ml-[8px]">
              {summary && summary[3]?.total_quantity}
            </span>
          </div>
          <div
            className={`w-fit flex items-center bg-gradient-to-b via-white to-white text-[14px] leading-[20px] font-[450] rounded-[10px] p-[12px] mb-[12px] mr-[15px] cursor-pointer ring-1 ${
              selectedStatus === "5"
                ? "text-[#DA5E18] from-[#FEEFBA] ring-[#FF9153]"
                : "text-[#3EB2D7] from-[#B5F2FF] ring-[#5BDDFA]"
            }`}
            onClick={() => handleStatusClick("5")}
          >
            <img
              src={require("../../../assets/images/icon-shipped.png")}
              alt="Filter icon"
              className="w-[27px] mr-[10px]"
            />
            Self Collected{" "}
            <span className="text-[24px] ml-[8px]">
              {summary && summary[4]?.total_quantity}
            </span>
          </div>
          <CancelButton
            label="Reset"
            className={"mb-[12px]"}
            onClick={() => handleStatusClick("")}
          />
        </div>
        <div className="w-fit flex flex-wrap flex-row justify-start items-center gap-x-2 mb-3 text-[12px] text-blue-500 border border-solid border-blue-500 h-[24px] rounded-[12px] pr-3 overflow-hidden">
          <div className="bg-blue-500 text-white py-1 px-2">
            <span
              className={"pi pi-info-circle"}
              style={{ fontSize: "14px" }}
            ></span>
          </div>
          <div>
            <span
              className={"pi pi-send pr-2"}
              style={{ fontSize: "12px", paddingLeft: 3 }}
            ></span>
            <label>Subscription</label>
          </div>
          <div>
            <span
              className={"pi pi-shopping-cart pr-2"}
              style={{ fontSize: "12px", paddingLeft: 3 }}
            ></span>
            <label>One Time Purchase</label>
          </div>
        </div>
        {loading && <TableSkeleton />}
        {!loading && records?.length > 0 && (
          <>
            <div className="overflow-x-auto">
              <table className="table-cfg">
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Customer</th>
                    <th>Product</th>
                    <th>Status</th>
                    <th>Shipping Date</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((row: UserData) => {
                    return (
                      <tr key={`shipments-table-row-${row?.id}`}>
                        <td data-label="Order ID">
                          <label
                            className="text-[#E5622A] cursor-pointer underline"
                            onClick={() => handleView(row)}
                          >
                            #{row?.order_id}
                          </label>
                        </td>
                        <td data-label="Customer" className="capitalize">
                          {row?.customer?.first_name} {row?.customer?.last_name}
                        </td>
                        <td data-label="Product">
                          {row?.shipments?.map((item: any) => (
                            <div
                              key={`shipments-table-item-${item?.id}`}
                              className="whitespace-nowrap"
                            >
                              <span
                                className={`pi ${
                                  item?.subscription === 1
                                    ? "pi-send"
                                    : "pi-shopping-cart"
                                } pr-2`}
                                style={{ fontSize: ".8rem", paddingLeft: 3 }}
                              ></span>
                              {item?.shipped_out_qty} x {item?.product?.name}
                            </div>
                          ))}
                        </td>
                        <td data-label="Status" className="text-center">
                          <div
                            className={`whitespace-nowrap rounded-[30px] w-fit lg:w-full ${
                              row?.status === 1
                                ? "bg-yellow-300"
                                : row?.status === 2
                                ? "bg-orange-300"
                                : row?.status === 3
                                ? "bg-blue-300"
                                : row?.status === 4
                                ? "bg-green-300"
                                : row?.status === 5
                                ? "bg-green-300"
                                : "bg-red-300 "
                            } px-[8px] py-[3px] rounded-[30px] inline text-xs w-fit lg:w-full`}
                          >
                            {row?.status === 1
                              ? "To Prepare"
                              : row?.status === 2
                              ? "Pending Pick up"
                              : row?.status === 3
                              ? "Shipped"
                              : row?.status === 4
                              ? "Delivered"
                              : row?.status === 5
                              ? "Self Collected"
                              : "Unknown Status"}
                          </div>
                        </td>
                        <td data-label="Shipping Date" className="text-center">
                          <div className="whitespace-nowrap">
                            {formatDate(row?.shipping_date)}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <TableFooter
              itemsFrom={rawRecords?.from}
              itemsTo={rawRecords?.to}
              itemsTotal={rawRecords?.total}
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={handlePageChange}
            />
          </>
        )}
        {!loading && records?.length <= 0 && <EmptyData />}
      </div>
    </Layout>
  );
};

export default Subscriptions;

import { useState } from "react";
import Layout from "../../../components/Layout";
import { HeaderType } from "../../../interfaces";
// import { useNavigate } from "react-router-dom";
import { InputTextarea } from "primereact/inputtextarea";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { store } from "../../../redux/store";
import usePaginatedApiData from "../../../hooks/usePaginatedApiData";
import TableFooter from "../../../components/TableFooter";
import TableSkeleton from "../../../components/TableSkeleton";
import {
  formatCurrency,
  formatDate,
  showConfirmDialog,
} from "../../../utils/Common";
import FullScreenDialog from "../../../components/FullScreenDialog";
import OrderDetails from "../../OrderDetails";
import CountryCodeDropdown from "../../../components/CountryCodeDropdown";
import AcceptButton from "../../../components/AcceptButton";
import { toast } from "react-toastify";
import { get, post } from "../../../utils/Api";
import { useDispatch } from "react-redux";
import { updateCustomer } from "../../../redux/slices/customerSlice";
import { Button } from "primereact/button";

type CustomerData = {
  agents: Array<{
    agent: {
      agent_code: string;
      full_name: string;
    };
    primary: number;
  }>;
  billing_address_id: number;
  created_at: string;
  customer_id: number;
  delivery_fee: number;
  gst: number;
  gst_amount: number;
  highest_subscription_month: number;
  id: number;
  order_items: Array<{
    id: number;
    name: string;
    order_id: number;
  }>;
  remarks: string;
  shipping_address_id: number;
  status: number;
  subtotal: number;
  total: number;
  is_blacklist: number;
};
type CustomerSearchParams = {
  first_name: string;
  last_name: string;
  mobile: string;
};

const CustomerListingDetails = () => {
  // const navigate = useNavigate();
  const [showDetail, setShowDetail] = useState(false);
  const [showDetailParam, setShowDetailParam] = useState({});
  const [userLoading, setUserLoading] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      id: store.getState()?.customer?.customer?.id || "",
      first_name: store.getState()?.customer?.customer?.first_name || "",
      last_name: store.getState()?.customer?.customer?.last_name || "",
      email: store.getState()?.customer?.customer?.email || "",
      country_code: store.getState()?.customer?.customer?.country_code || "65",
      mobile: store.getState()?.customer?.customer?.mobile || "",
      street: store.getState()?.customer?.customer?.address?.street || "",
      unit_no: store.getState()?.customer?.customer?.address?.unit_no || "",
      postal_code:
        store.getState()?.customer?.customer?.address?.postal_code || "",
      is_blacklist: store.getState()?.customer?.customer?.is_blacklist || 0,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      email: Yup.string().required("Email is required"),
      mobile: Yup.string()
        .min(8, "Invalid Mobile")
        // .matches(/^[0-9]{1,10}$/, "Mobile is not valid")
        .required("Mobile is required"),
      // street: Yup.string().required("Street Name is required"),
      // unit_no: Yup.string().required("Unit No. is required"),
      // postal_code: Yup.string().required("Postal Code is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { id, first_name, last_name, email, country_code, mobile } = values;
      try {
        setUserLoading(true);
        const response = await post("/admin/edit_customer", {
          id,
          first_name,
          last_name,
          email,
          country_code,
          mobile,
        });

        setUserLoading(false);

        if (response?.success) {
          toast.success(response?.message);

          const currentCustomer = store.getState().customer.customer;

          const updatedCustomerData = {
            ...currentCustomer,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            email: response.data.email,
            country_code: response.data.country_code,
            mobile: response.data.mobile,
          };
          // dispatch the action to update the Redux store
          dispatch(updateCustomer(updatedCustomerData));
        }
      } catch (error: any) {
        setUserLoading(false);
        if (
          error?.response?.data?.message &&
          typeof error?.response?.data?.message === "string"
        ) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  const {
    data,
    loading,
    error,
    searchParams,
    currentPage,
    totalPages,
    handleSearchParamsChange,
    handlePageChange,
    handleReload,
  } = usePaginatedApiData<CustomerData, CustomerSearchParams>(
    `/customer/get_customer_orders_history/${
      store.getState()?.customer?.customer?.id
    }`,
    {
      mobile: "",
      first_name: "",
      last_name: "",
    },
    1,
  );

  const rawRecords = data as any;
  const records = rawRecords?.data as CustomerData[];

  const handleView = (id: any) => {
    // navigate("/order-details", {
    //   state: { order_id: id },
    // });
    setShowDetailParam({ order_id: id });
    setShowDetail(true);
  };

  const blockConfirmation = (is_blacklist: number) => {
    showConfirmDialog({
      message: is_blacklist
        ? "Do you want to unblock this customer?"
        : "Do you want to block this customer?",
      header: "Confirmation",
      accept: async () => {
        // const formData = new FormData();
        // formData.append("info", mobile);
        const currentCustomer = store.getState().customer.customer;

        const response = await post("/admin/edit_customer", {
          ...formik.values,
          is_blacklist: is_blacklist ? 0 : 1,
        });

        if (response.success) {
          toast.success(response.message);

          const currentCustomer = store.getState().customer.customer;

          const updatedCustomerData = {
            ...currentCustomer,
            is_blacklist: response.data.is_blacklist,
          };
          // dispatch the action to update the Redux store
          dispatch(updateCustomer(updatedCustomerData));
        } else {
          toast.error("Something went wrong!");
        }
        handleReload();
      },
    });
  };

  return (
    <div className="bg-[#F8F8F9]">
      <FullScreenDialog
        visible={showDetail}
        onHide={() => setShowDetail(false)}
      >
        <OrderDetails params={showDetailParam} close={setShowDetail} />
      </FullScreenDialog>
      <Layout headerType={HeaderType.back} stickyHeaderFooter={true}>
        <div className="min-h-auto p-[17px] card-cfg">
          <form onSubmit={formik.handleSubmit}>
            <div className="flex justify-left items-center pb-[15px] text-[#DA5E18]">
              <label>Customer Details</label>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label className="ml-[5px] text-[12px]">First Name</label>
                <InputText
                  className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] w-full capitalize"
                  name="first_name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="flex flex-col">
                <label className="ml-[5px] text-[12px]">Last Name</label>
                <InputText
                  className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] w-full"
                  name="last_name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="flex flex-col">
                <label className="ml-[5px] text-[12px]">Email</label>
                <InputText
                  className="bg-[#F8F8F8] border-[0.3px] rounded-[5px] w-full"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="flex flex-col">
                <label className="ml-[5px] text-[12px]">Mobile</label>
                <div className="flex">
                  <CountryCodeDropdown
                    className={`w-[100px] mr-[10px] bg-[#F8F8F8] border-[0.3px] rounded-[5px] opacity-100 ${
                      formik.touched.country_code && formik.errors.country_code
                        ? "p-invalid"
                        : ""
                    }`}
                    name="country_code"
                    value={formik.values.country_code}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <InputText
                    className={`w-full bg-[#F8F8F8] border-[0.3px] rounded-[5px] ${
                      formik.touched.mobile && formik.errors.mobile
                        ? "p-invalid"
                        : ""
                    }`}
                    name="mobile"
                    placeholder="Mobile"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="flex flex-col col-span-1 lg:col-span-2">
                <label className="ml-[5px] text-[12px]">Address</label>
                <InputTextarea
                  rows={4}
                  name="remarks"
                  value={`${formik.values?.street}\n${formik.values?.unit_no}\nSINGAPORE ${formik.values?.postal_code}`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="bg-[#F8F8F8] mb-[5px]"
                  disabled
                />
              </div>
            </div>
            <div className="flex flex-row justify-between items-center flex-nowrap gap-x-4">
              <Button
                severity="danger"
                type="button"
                label={
                  store.getState()?.customer?.customer?.is_blacklist
                    ? "Unblock"
                    : "Block"
                }
                onClick={() => {
                  // setVisible(false);
                  blockConfirmation(
                    store.getState()?.customer?.customer?.is_blacklist,
                  );
                }}
              />
              <AcceptButton
                label="Update"
                className="mt-[20px] ml-auto block"
              />
            </div>
          </form>
          {loading && (
            <div className="pt-[17px]">
              <TableSkeleton />
            </div>
          )}
          {!loading && records?.length > 0 && (
            <div className="pt-[17px]">
              <div className="flex flex-wrap">
                <div className="flex justify-left items-center pb-[15px] text-[#DA5E18]">
                  <label>Orders History</label>
                </div>
              </div>
              <div className="w-fit flex flex-wrap flex-row justify-start items-center gap-x-2 mb-3 text-[12px] text-blue-500 border border-solid border-blue-500 h-[24px] rounded-[12px] pr-3 overflow-hidden">
                <div className="bg-blue-500 text-white py-1 px-2">
                  <span
                    className={"pi pi-info-circle"}
                    style={{ fontSize: "14px" }}
                  ></span>
                </div>
                <div>
                  <span
                    className={"pi pi-send pr-2"}
                    style={{ fontSize: "12px", paddingLeft: 3 }}
                  ></span>
                  <label>Subscription</label>
                </div>
                <div>
                  <span
                    className={"pi pi-shopping-cart pr-2"}
                    style={{ fontSize: "12px", paddingLeft: 3 }}
                  ></span>
                  <label>One Time Purchase</label>
                </div>
              </div>
              <div className="overflow-x-auto">
                <table className="table-cfg">
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Agent</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((row: CustomerData) => {
                      return (
                        <tr key={`customerListingDetails-table-row-${row?.id}`}>
                          <td data-label="Order ID">{row?.id}</td>
                          <td data-label="Agent">
                            {row?.agents.map((agent, index: number) => {
                              return (
                                <div key={`agent-${index}`}>
                                  {agent.primary === 1 ? "(P) " : "(S) "}
                                  {agent.agent.full_name}
                                  {" #"}
                                  {agent.agent.agent_code}
                                </div>
                              );
                            })}
                          </td>
                          <td data-label="Total" className="text-right">
                            {row?.highest_subscription_month > 0 ? (
                              <span
                                className={
                                  "pi pi-send md:pr-0 pr-2 md:float-left pt-[4px] text-[#da5d18]"
                                }
                                style={{ fontSize: "12px", paddingLeft: 3 }}
                              ></span>
                            ) : (
                              <span
                                className={
                                  "pi pi-shopping-cart md:pr-0 pr-2 md:float-left pt-[4px]"
                                }
                                style={{ fontSize: "12px", paddingLeft: 3 }}
                              ></span>
                            )}
                            {formatCurrency(row?.total)}
                          </td>
                          <td data-label="Status" className="text-center">
                            <div
                              className={`${
                                row?.status === 1
                                  ? "bg-green-300"
                                  : row?.status === 0
                                  ? "bg-gray-300"
                                  : "bg-red-300 "
                              } px-[8px] py-[3px] rounded-[30px] inline text-xs w-fit lg:w-full`}
                            >
                              {row?.status === 1
                                ? "Paid"
                                : row?.status === 0
                                ? "Unpaid"
                                : "Failed"}
                            </div>
                          </td>
                          <td data-label="Date">
                            <div className="whitespace-nowrap">
                              {formatDate(row?.created_at)}
                            </div>
                          </td>
                          <td data-label="Action">
                            <button
                              className="flex ml-auto lg:mx-auto"
                              onClick={() => handleView(row?.id)}
                            >
                              <img
                                src={require("../../../assets/images/icon-view.png")}
                                alt="View icon"
                                className="w-[24px]"
                              />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <TableFooter
                itemsFrom={rawRecords?.from}
                itemsTo={rawRecords?.to}
                itemsTotal={rawRecords?.total}
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={handlePageChange}
              />
            </div>
          )}
        </div>
      </Layout>
    </div>
  );
};

export default CustomerListingDetails;

import { format } from "date-fns";
import { Message } from "primereact/message";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DateDialog from "../../components/DateDialog";
import Layout from "../../components/Layout";
import CartItems from "../../components/cartItems";
import useDeliveryDateRequired from "../../hooks/useDeliveryDateRequired";
import { useAppSelector } from "../../hooks/useStore";
import { HeaderType } from "../../interfaces";
import {
  getAdditionalInfo,
  setAdditionalInfo,
} from "../../redux/slices/orderSlice";

export const KnobProgress = () => {
  return (
    <img
      src={require("../../assets/images/chart-step-2.png")}
      alt="Chart step 2"
      className="w-[55px] h-[55px]"
    />
  );
};

const NewOrderCart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderSettings, additionalInfo, isLoading, isError, cart } =
    useAppSelector((state) => state.order);
  const deliveryDateRequired = useDeliveryDateRequired();
  const [dateDialog, setDateDialog] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState(
    additionalInfo?.deliveryDate || null,
  );
  const [minDate, setMinDate] = useState(new Date());

  const getMonthDaysLeft = (): number => {
    const date = new Date();
    return (
      new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() -
      date.getDate()
    );
  };

  useEffect(() => {
    if (orderSettings?.delivery_date_days_offset) {
      const today = new Date();
      const future = new Date();
      let addDays = orderSettings.delivery_date_days_offset;
      const daysTillMonthEnd: number = getMonthDaysLeft();
      new Date(future.setDate(today.getDate() + addDays));
      if (cart[0]?.orderInfo?.subscriptionInfo?.selfCollect) {
        if (future.getMonth() === today.getMonth()) {
          addDays = daysTillMonthEnd + 1;
        }
      }
      setMinDate(new Date(today.setDate(today.getDate() + addDays)));
    }
    dispatch(getAdditionalInfo({ deliveryDate }));
  }, []);

  const footerButton = (
    <div className="flex">
      <button
        className="w-full h-[70px] bg-[#4A4A4A] text-white flex flex-row justify-center items-center flex-nowrap text-[20px]"
        onClick={() => navigate("/new-order")}
      >
        Add More
      </button>
      <button
        className="w-full h-[70px] bg-[#DA5E18] text-white flex flex-row justify-center items-center flex-nowrap text-[20px]"
        onClick={() => {
          if (!isLoading) {
            if (isError) {
              toast.error(isError);
            } else if (deliveryDateRequired && !deliveryDate) {
              toast.error(
                "Please select estimated shipment date before checkout",
              );
            } else {
              dispatch(
                setAdditionalInfo({
                  ...additionalInfo,
                  ...{ deliveryDate },
                }),
              );
              navigate("/new-order-info");
            }
          }
        }}
      >
        Check Out
      </button>
    </div>
  );

  return (
    <Layout
      headerType={HeaderType.back}
      pageTitle={"New Order"}
      footerButton={footerButton}
      stickyHeaderFooter={true}
      rightSideIcon={<KnobProgress />}
    >
      <DateDialog
        open={dateDialog}
        close={setDateDialog}
        minDate={minDate}
        value={deliveryDate}
        onChange={(e: any) => setDeliveryDate(e?.value)}
      />
      <CartItems />
      {deliveryDateRequired && (
        <div className="bg-[#fff] w-full h-[44px]">
          <div
            className="flex items-center justify-center h-full"
            onClick={() => setDateDialog(true)}
          >
            <img
              src={require("../../assets/images/order/icon-calendar-black.png")}
              alt="Calendar Icon"
              className={`w-[24px] h-[24px] mr-1.5`}
            />
            {deliveryDate && (
              <label className="text-[#434343] text-[14px]">
                Estimated Shipment Date: {format(deliveryDate, "dd MMM yyyy")}
              </label>
            )}
            {!deliveryDate && (
              <label className="text-[#434343] text-[14px]">
                Estimated Shipment Date: --Select--
              </label>
            )}
          </div>
        </div>
      )}
      <div className="bg-[#F7F7F7] py-4 px-4">
        <div className="flex justify-between">
          <div className="text-[#434343] text-[14px]">Subtotal</div>
          <div className="text-[#DA5E18] text-[14px]">
            {" "}
            {additionalInfo?.sum_formatted}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-[#434343] text-[14px]">Discount</div>
          <div className="text-[#DA5E18] text-[14px]">
            {"-"}
            {additionalInfo?.discount_formatted}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-[#434343] text-[14px]">Delivery Fee</div>
          <div className="text-[#DA5E18] text-[14px]">
            {" "}
            {additionalInfo?.delivery_formatted}
          </div>
        </div>
        {orderSettings?.gst !== 0 ? (
          <div className="flex justify-between">
            <div className="text-[#434343] text-[14px]">
              GST ({orderSettings?.gst}%)
            </div>
            <div className="text-[#DA5E18] text-[14px]">
              {additionalInfo?.tax_formatted}
            </div>
          </div>
        ) : null}
        <div className="flex justify-between mt-2">
          <div className="text-[#434343] font-bold text-[14px]">Total</div>
          <div className="text-[#DA5E18] text-[20px]">
            {" "}
            {additionalInfo?.total_formatted}
          </div>
        </div>
        <div className="flex justify-center">
          {isError && <Message severity="error" text={isError} />}
        </div>
      </div>
    </Layout>
  );
};

export default NewOrderCart;

import Layout from "../../../components/Layout";
import { TabPanel, TabView } from "primereact/tabview";
import { useLocation, useNavigate } from "react-router-dom";
import InventoryComponent from "../../../components/InventoryComponent";
import BundleComponent from "../../../components/BundleComponent";
import BundlePocketCoffeeComponent from "../../../components/BundlePocketCoffeeComponent";

const InventoryCreate = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location.state.type;
  const activeIndex =
    type === "Inventory"
      ? 0
      : type === "Bundle"
      ? 1
      : type === "Bundle-PocketCoffee"
      ? 2
      : type === "Create"
      ? 0 // Set activeIndex to 0 when type is "Create"
      : 0; // Default to 0

  return (
    <Layout>
      <button onClick={() => navigate(-1)} className="py-[8px] px-[12px]">
        <img
          src={require("../../../assets/images/icon-back.png")}
          alt="Back icon"
          className="w-[34px] h-[34px]"
        />
      </button>
      <TabView activeIndex={activeIndex}>
        {(type === "Inventory" || type === "Create") && (
          <TabPanel header="Inventory" leftIcon="pi pi-box mr-2">
            <InventoryComponent />
          </TabPanel>
        )}
        {(type === "Bundle" || type === "Create") && (
          <TabPanel header="Bundle" leftIcon="pi pi-shopping-cart mr-2">
            <BundleComponent />
          </TabPanel>
        )}
        {(type === "Bundle-PocketCoffee" || type === "Create") && (
          <TabPanel
            header="Pocket Coffee"
            leftIcon="pi pi-cart-plus
          mr-2"
          >
            <BundlePocketCoffeeComponent />
          </TabPanel>
        )}
      </TabView>
    </Layout>
  );
};

export default InventoryCreate;

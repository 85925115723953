import { useLocation } from "react-router-dom";
import MakePayment from "../MakePayment";
import { useEffect, useState } from "react";
import { get } from "../../utils/Api";
import { formatCurrency } from "../../utils/Common";
import { toast } from "react-toastify";

type Order = {
  id: number;
  total: number;
  client_secret: string;
  email: string;
};

const Checkout = () => {
  useEffect(() => {
    getOrder();
  }, []);

  const [orderData, setOrderData] = useState<Order | null>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const getOrder = async () => {
    try {
      const response: any = await get(`/get_order_by_payment_intent?id=${id}`);
      if (response.success && response.data) {
        setOrderData(response.data);
      }
    } catch (error: any) {
      if (
        error?.response?.data?.message &&
        typeof error?.response?.data?.message === "string"
      ) {
        toast.error(error.response.data.message);
      }
    }
  };

  return (
    <div className="bg-[#FFEDD7] w-screen h-screen flex flex-col items-center justify-center p-5 md:p-10">
      <img
        src={require("../../assets/images/logo-cfg.png")}
        alt="CFG Logo"
        className="h-[40px] mx-auto"
      />
      <div>
        {orderData ? (
          <>
            <div className="py-5 text-center">
              <h1 className="pb-1">ORDER #{orderData.id}</h1>
              <h2 className="text-xl">
                Total: {formatCurrency(orderData.total)}
              </h2>
            </div>
            <MakePayment clientSecret={orderData.client_secret} />
            <h2 className="pt-5 text-center">
              Invoice will be sent to {orderData.email} upon successful payment
            </h2>
          </>
        ) : (
          <div className="py-5 text-center">No Order Found!</div>
        )}
      </div>
    </div>
  );
};

export default Checkout;

import { Skeleton } from "primereact/skeleton";
import React from "react";

const TableSkeleton = () => {
  return (
    <div className="py-5">
      <table className="table-cfg">
        <thead>
          <tr>
            <th>
              <Skeleton></Skeleton>
            </th>
            <th>
              <Skeleton></Skeleton>
            </th>
            <th>
              <Skeleton></Skeleton>
            </th>
            <th>
              <Skeleton></Skeleton>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Skeleton></Skeleton>
            </td>
            <td>
              <Skeleton></Skeleton>
            </td>
            <td>
              <Skeleton></Skeleton>
            </td>
            <td>
              <Skeleton></Skeleton>
            </td>
          </tr>
          <tr>
            <td>
              <Skeleton></Skeleton>
            </td>
            <td>
              <Skeleton></Skeleton>
            </td>
            <td>
              <Skeleton></Skeleton>
            </td>
            <td>
              <Skeleton></Skeleton>
            </td>
          </tr>
          <tr>
            <td>
              <Skeleton></Skeleton>
            </td>
            <td>
              <Skeleton></Skeleton>
            </td>
            <td>
              <Skeleton></Skeleton>
            </td>
            <td>
              <Skeleton></Skeleton>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableSkeleton;
